import { prettyJson, clearArray, valOr, getElemNames,
  StrictParseNumber,
  StrictParseNumberOr,
} from '../SharedUtils.js'
export { Units } from '../Common/Units.js'
import { SystemCoolingDesignTemps } from '../BuildingComponents/SystemDesignTemps.js'

import Papa from 'papaparse';

export class WeatherDataParser {
  constructor() {
  }

  static parseWeatherDataCSV(rawCsv) {
    // Parse a bunch of tables from the CSV (skipping a bunch that we don't use)
    //console.log("Parsing weather data CSV: ", rawCsv);

    let csv = Papa.parse(rawCsv);
    let weatherData = {}
    weatherData.Location = WeatherDataParser.parseTable(csv, 'Location', false, {
      colTypes: {
        'Country': 'String',
        'State': 'String',
        'City': 'String',
      }
    })
    // Note: Heating and Cooling tables may have some 'N/A' values, so turn off strict number checking
    weatherData.Heating = WeatherDataParser.parseTable(csv, 'Heating', false, {
      colsToParse: {
        '99.6 Dry Bulb': 'Number',
        '99% Dry Bulb': 'Number',
      }
    })
    weatherData.Cooling = WeatherDataParser.parseTable(csv, 'Cooling', false, {
      numTables: 2,
      colsToParse: {
        'Hottest Month': 'Number',
        '0.4% Dry Bulb': 'Number',
        '0.4% Dry Bulb MCWB': 'Number',
        '1% Dry Bulb': 'Number',
        '1% Dry Bulb MCWB': 'Number',
        '2% Dry Bulb': 'Number',
        '2% Dry Bulb MCWB': 'Number',
        '0.4% Wet Bulb': 'Number',
        '0.4% Wet Bulb MCDB': 'Number',
      }
    })
    weatherData.Temperatures = WeatherDataParser.parseTable(csv, 'Temperature, Degree-days, Degree-hours', true, {
      rowsToParse: {
        'Average Dry Bulb': 'Number',
      },
    })
    weatherData.DryBulb = WeatherDataParser.parseTable(csv, 'Dry Bulb and Mean Coincident Wet Bulb', true)
    weatherData.WetBulb = WeatherDataParser.parseTable(csv, 'Wet Bulb and Mean Coincident Dry Bulb', true)
    weatherData.MeanDailyTempRange = WeatherDataParser.parseTable(csv, 'Mean Daily Temperature Range', true)
    weatherData.SolarIrradiance = WeatherDataParser.parseTable(csv, 'Solar Irradiance', true)

    // These fields have no associated overridable Field, but are used in the commercial app
    let dryBulbTempsTable = weatherData.DryBulb;
    weatherData.dryBulbDesignTempsByMonth = {
      [SystemCoolingDesignTemps.Temp0p4]: WeatherDataParser.parseMonthsVector(dryBulbTempsTable, '0.4% Dry Bulb'),
      [SystemCoolingDesignTemps.Temp2p0]: WeatherDataParser.parseMonthsVector(dryBulbTempsTable, '2% Dry Bulb'),
      [SystemCoolingDesignTemps.Temp5p0]: WeatherDataParser.parseMonthsVector(dryBulbTempsTable, '5% Dry Bulb'),
      [SystemCoolingDesignTemps.Temp10p0]: WeatherDataParser.parseMonthsVector(dryBulbTempsTable, '10% Dry Bulb'),
    }
    weatherData.wetBulbDesignTempsByMonth = {
      [SystemCoolingDesignTemps.Temp0p4]: WeatherDataParser.parseMonthsVector(dryBulbTempsTable, '0.4% Dry Bulb MCWB'),
      [SystemCoolingDesignTemps.Temp2p0]: WeatherDataParser.parseMonthsVector(dryBulbTempsTable, '2% Dry Bulb MCWB'),
      [SystemCoolingDesignTemps.Temp5p0]: WeatherDataParser.parseMonthsVector(dryBulbTempsTable, '5% Dry Bulb MCWB'),
      [SystemCoolingDesignTemps.Temp10p0]: WeatherDataParser.parseMonthsVector(dryBulbTempsTable, '10% Dry Bulb MCWB'),
    }

    weatherData.dryBulbRangeByMonth = WeatherDataParser.parseMonthsVector(weatherData.MeanDailyTempRange, 'Mean Dry Bulb Range')
    weatherData.wetBulbRangeByMonth = WeatherDataParser.parseMonthsVector(weatherData.MeanDailyTempRange, '5% Dry Bulb MCWB Range')
    //console.log("DryBulbRangeByMonth: ", weatherData.dryBulbRangeByMonth);
    //console.log("WetBulbRangeByMonth: ", weatherData.wetBulbRangeByMonth);

    weatherData.tauBValuesByMonth = WeatherDataParser.parseMonthsVector(weatherData.SolarIrradiance, 'Tau,b');
    weatherData.tauDValuesByMonth = WeatherDataParser.parseMonthsVector(weatherData.SolarIrradiance, 'Tau,d');
    //console.log("Tau,b values by month: ", weatherData.tauBValuesByMonth);
    //console.log("Tau,d values by month: ", weatherData.tauDValuesByMonth);

    // console.log(`Read weather data:\n${prettyJson(weatherData)}`);
    return weatherData;
  }

  static parseMonthsVector(table, rowName) {
    let vector = new Array(12);
    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    //console.log("Parsing months vector for: ", table);
    //console.log("RowName: ", rowName);
    for (let i = 0; i < 12; ++i) {
      vector[i] = StrictParseNumber(table[rowName][months[i]]);
    }
    //console.log("Parsed months vector: ", vector);
    return vector;
  }


  static _findFirstRow(csv, tableName) {
    for (let i = 0; i < csv.data.length; ++i) {
      if (csv.data[i][0] == tableName) {
        return i + 1;
      }
    }
    throw new Error(`Could not find table ${tableName} in the WeatherData`);
  }

  // Returns one row past the end of the table
  static _findRowAfterEnd(csv, tableName) {
    let firstRow = this._findFirstRow(csv, tableName)
    let curRow = firstRow + 1;
    while (csv.data[curRow][0] != "") {
      curRow++;
    }
    return curRow;
  }

  static _findFirstNonEmptyRow(csv, startRow) {
    let curRow = startRow;
    while (csv.data[curRow][0] == "") {
      curRow++;
    }
    return curRow;
  }

  static _readCell(strValue, colName, opts) {
    opts = valOr(opts, {})
    let colTypes = valOr(opts.colTypes, {})
    let colType = valOr(colTypes[colName], 'Number')
    try {
      if (colType == 'String') {
        // Try to parse to a number, or just return the string
        return StrictParseNumberOr(strValue, strValue);
      } else if (colType == 'Number') {
        return StrictParseNumber(strValue);
      } else {
        throw new Error(`Unknown column type: ${colType}`);
      }
    } catch (err) {
      throw new Error(`Error parsing column '${colName}' with value '${strValue}': ${err.message}`);
    }
  }

  static _parseSimpleTable(csv, tableName, opts) {
    opts = valOr(opts, {})
    let firstRow = this._findFirstRow(csv, tableName)
    let obj = {}
    // The full table may be split into multiple tables if there are many columns (see Cooling)
    let numTables = valOr(opts.numTables, 1)
    let tableStartRow = firstRow;
    for (let tableNum = 0; tableNum < numTables; ++tableNum) {
      let colNames = []
      for (let i = 0; i < csv.data[tableStartRow].length; ++i) {
        let colName = csv.data[tableStartRow][i];
        if (!colName) {
          break;
        }
        colNames.push(colName);
      }
      // console.log(`Row: ${tableStartRow}, Columns: ${prettyJson(colNames)}`)
      let colsToParse = valOr(opts.colsToParse, null);
      for (let i = 0; i < colNames.length; ++i) {
        if (colsToParse && !colsToParse[colNames[i]]) {
          continue;
        }
        obj[colNames[i]] = this._readCell(csv.data[tableStartRow + 1][i], colNames[i], opts)
      }
      
      tableStartRow = this._findFirstNonEmptyRow(csv, tableStartRow + 2);
    }
    // console.log(`Parsed ${tableName}:\n${prettyJson(obj)}`);
    return obj;
  }

  static _parseRowColTable(csv, tableName, opts) {
    opts = valOr(opts, {})
    let firstRow = this._findFirstRow(csv, tableName)
    let colNames = []
    for (let i = 1; i < csv.data[firstRow].length; ++i) {
      let colName = csv.data[firstRow][i];
      if (!colName) {
        break;
      }
      colNames.push(colName);
    }
    // console.log(`Columns: ${prettyJson(colNames)}`)
    let rowsToParse = valOr(opts.rowsToParse, null);
    let obj = {}
    let curRow = firstRow + 1;
    while (csv.data[curRow][0] != "") {
      let rowName = csv.data[curRow][0];
      if (rowsToParse && !rowsToParse[rowName]) {
        curRow++;
        continue;
      }
      obj[rowName] = {};
      for (let i = 0; i < colNames.length; ++i) {
        obj[rowName][colNames[i]] = this._readCell(csv.data[curRow][i + 1], colNames[i], opts)
      }
      curRow++;
    }
    // console.log(`Parsed ${tableName}:\n${prettyJson(obj)}`);
    return obj;
  }

  static parseTable(csv, tableName, rowColTable, opts) {
    if (!rowColTable) {
      return this._parseSimpleTable(csv, tableName, opts);
    } else {
      return this._parseRowColTable(csv, tableName, opts);
    }
  }
};