<script setup>
import { ref, onMounted, reactive, computed, watch } from 'vue'

const props = defineProps({
  title: {
    default: "",
  },
  border: {
    default: true,
  },
});

let styles = computed(() => {
  return {
    InputGroup: props.border,
    InputGroupNotBordered: !props.border,
  }
});

</script>

<template>
  <div class="mb-s" :class="styles">
    <p class="mute-header">{{title}}</p>
    <slot></slot>
  </div>
</template>

<style scoped>
.InputGroup {
  max-width: 1000px;
  border: 1px solid var(--b-20);
  padding: var(--xs);
}

.InputGroupNotBordered {
  max-width: 1000px;
}

</style>
