import * as ser from '../Common/SerUtil.js'
import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
  interpolateInMap, doubleInterpolateInMap,
  IdsMap, PleaseContactStr,
  IntervalTimer,
} from '../Base.js'
import { extendArray } from '../SharedUtils.js'
import { WindowsData, } from '../MaterialData/Windows/WindowsData.js' 
import { MaterialDataTable } from '../MaterialData/MaterialDataTable.js'
import { TimeSeriesDataTable } from '../MaterialData/TimeSeriesDataTable.js'
import {
  TimeSeriesData_Table,
  ActivityLevels_Table,
  LuminairesData_Table,
} from '../MaterialData/MaterialDataTableRegistry.js'
import { CalcContext } from '../Common/CalcContext.js'
import { IACDebugger } from '../Components/IACDebugger.js'

import { Project, ProjectTypes } from '../Project.js'

export { Units } from '../Common/Units.js'

import {
  FieldType,
  kFieldTypesData,
  FieldInputType,
  Field,
  FieldGroup,
  ProjectUnits,
} from '../Common/Field.js'
export * from '../Common/Field.js'

import { DoorType } from '../Components/DoorType.js'
import { ExteriorShadingType } from '../Components/ExteriorShadingType.js'
import { InteriorShadingType } from '../Components/InteriorShadingType.js'
import { WindowType } from '../Components/WindowType.js'
import { WallType, RoofType } from '../Components/WallType.js'
import { SkylightType } from '../Components/SkylightType.js'
import { BufferSpaceType } from '../Components/BufferSpaceType.js'

import { BuildingAndEnv } from './BuildingAndEnv.js'
import { System } from './System.js'
import { Space } from './Space.js'
import { Schedule } from './Schedule.js'

export class BuildingLoadResults {
  init(proj) {
    this.proj = proj

    // TODO
  }
}
setupClass(BuildingLoadResults);

class CommercialDebugData {
  init(proj) {
    this.proj = proj

    this.iacDebugger = IACDebugger.create(proj)
  }
}
setupClass(CommercialDebugData);

class CommercialGuideSteps {
  init() {
    this.currentStep = 1;
    this.setLocationDone = false;
    this.createSpacesDone = false;
    this.createSystemsDone = false;
    this.runCalculationsDone = false;

    this.serFields = [
      'currentStep',
      'setLocationDone',
      'createSpacesDone',
      'createSystemsDone',
      'runCalculationsDone',
    ]
  }

  allDone() {
    return this.setLocationDone &&
      this.createSpacesDone &&
      this.createSystemsDone &&
      this.runCalculationsDone;
  }

  reset() {
    this.currentStep = 1;
    this.setLocationDone = false;
    this.createSpacesDone = false;
    this.createSystemsDone = false;
    this.runCalculationsDone = false;
  }
}
setupClass(CommercialGuideSteps);

export class CommercialProject extends Project {    
  init(id, opts) {
    super.init(id, opts);
  }

  setup() {
    this.type = ProjectTypes.Commercial;

    this.buildingAndEnv = BuildingAndEnv.create();
    this.systems = [];
    this.spaces = [];
    this.schedules = [];

    this.wallTypes = [];
    this.roofTypes = [];
    this.windowTypes = [];
    this.interiorShadingTypes = [];
    this.exteriorShadingTypes = [];
    this.doorTypes = [];
    this.skylightTypes = [];
    this.bufferSpaceTypes = [];

    this.windowsData = new WindowsData();
    this.timeSeriesData = new TimeSeriesDataTable(TimeSeriesData_Table);
    this.activityLevelsData = new MaterialDataTable(ActivityLevels_Table);
    this.luminairesData = new MaterialDataTable(LuminairesData_Table);

    this.loadResults = null;
    this.guide = CommercialGuideSteps.create();

    if (!this.workerProject) {
      this.debugData = CommercialDebugData.create(this);
    }
  }

  get serFields() {
    return [
      ...super.serFields,

      'guide',

      'buildingAndEnv',
      ser.arrayField('systems', () => { return System.create(); }),
      ser.arrayField('spaces', () => { return Space.create(); }),
      ser.arrayField('schedules', () => { return Schedule.create(); }),

      ser.arrayField('wallTypes', () => { return WallType.create(); }),
      ser.arrayField('roofTypes', () => { return RoofType.create(); }),
      ser.arrayField('windowTypes', () => { return WindowType.create(); }),
      ser.arrayField('interiorShadingTypes', () => { return InteriorShadingType.create(); }),
      ser.arrayField('exteriorShadingTypes', () => { return ExteriorShadingType.create(); }),
      ser.arrayField('doorTypes', () => { return DoorType.create(); }),
      ser.arrayField('skylightTypes', () => { return SkylightType.create(); }),
      ser.arrayField('bufferSpaceTypes', () => { return BufferSpaceType.create(); }),
    ]
  }

  async onSetupNewProject() {
    console.log("Setting up new Commercial project...");

    this.shownWelcomeMessage = false;

    // Set the default value to Winnipeg MB, Canada
    // Do this here b/c we only want to do this on project create.
    await this.buildingAndEnv.locationData.setLocation({
      fullName: 'Winnipeg, MB. Canada',
      name: 'Winnipeg',
      path: ['Canada', 'MB', 'Winnipeg'],
    });

    // Add starter schedules
    let defaultSchedules = Schedule.createDefaultSchedules();
    extendArray(this.schedules, defaultSchedules);
  }

  async onPostReadFromJson() {
    console.log("Post read from JSON for Commercial project...");
    console.log("Reloading location data...");
    await this.buildingAndEnv.locationData.reloadData();
  }

  getLocationData() {
    return this.buildingAndEnv.locationData;
  }

  getProjectUnits() {
    return ProjectUnits.Imperial;
  }

  getBaseRoute() {
    return `/building/${this.id}`;
  }

  getSystemWithId(id) {
    return this.systems.find((system) => system.id === id);
  }
};
setupClass(CommercialProject);