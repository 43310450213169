import { reactive, } from 'vue'
import { collection, query, where, or, doc, getDoc,
  getDocs, setDoc, addDoc, updateDoc, deleteDoc, } from "firebase/firestore";
import * as ser from './Common/SerUtil.js'
import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
  interpolateInMap, doubleInterpolateInMap,
  IdsMap, PleaseContactStr,
  IntervalTimer,
} from './Base.js'

import { prettyJson, clearArray, valOr, getElemNames,
  getElemWithNameValue,
  addElem, removeElem, elemIn,
  hashObject,
  downloadTextFile,
} from './SharedUtils.js'

import { ProjectLock } from './Common/ProjectLock.js'
import { Watcher } from './Common/Watcher.js'
import { ErrorCollector } from './Common/ErrorCollector.js'

import { gApp, DebugOn, IsTestEnv, } from './Globals.js'

export let ProjectTypes = makeEnum({
  Residential: "Residential",
  Commercial: "Commercial",
})

class EditorState {
  constructor() {
    this.selectedWall = null;
    this.selectedRoof = null;
    this.selectedWindow = null;
    this.selectedSkylight = null;
    this.selectedInternalShading = null;
    this.selectedExternalShading = null;
    this.selectedBufferSpace = null;
    this.selectedSchedule = null;
    this.selectedSystemForResults = null;
  }
}

/**
 * Project base-class
 */
export class Project {
  init(id, opts) {
    opts = valOr(opts, {});

    this.id = id;
    this.useMockTimes = IsTestEnv();
    // This gets set to true by toplevel App once the Project has been successfully
    // loaded and is ready to be edited + saved.
    this.doneLoading = false;
    // False until the user has seen the welcome message (via modal)
    // Note: default to true so that existing projects don't show it
    this.shownWelcomeMessage = true;
    // This is the project owner id and email
    this.userId = 0;
    this.userEmail = "Unknown";
    this.name = "MyProj";
    this.type = ProjectTypes.Residential;
    this.sharedUsers = [];
    this.lockedBy = null;
    this.lastOpenedTime = this.useMockTimes ? 1000 : Date.now();
    this.saving = false;
    this.lastSaveHash = null;
    this.debugOn = false;
    this.helpPaneId = null;
    this.helpPaneVisible = false;
    this.editorState = reactive(new EditorState());

    this.idsMap = new IdsMap();
    this.watcher = new Watcher({name: "ProjectWatcher"});

    this.projectErrors = null;

    // True when this project is created in a web worker, for
    // the sole purpose of running a calculation.
    this.workerProject = valOr(opts.workerProject, false);
    if (!this.workerProject) {
      this.checkLockTimer = new IntervalTimer(() => {
        this.updateLockStatus();
      }, 2, {onlyWhenVisible: true})
    }

    this.childObjs = '$auto'
  }

  get serFields() {
    return [
      'userId',
      'userEmail',
      'name',
      'type',
      'sharedUsers',
      'lastOpenedTime',
      'debugOn',
      'idsMap',
      'shownWelcomeMessage',
    ];
  }

  async setupNewProject() {
    // Call this upon creating a new project. Sets some defaults correctly
    console.log("Setting up new project...");
    await this.onSetupNewProject();
  }

  async onSetupNewProject() {
    // Override in subclasses
  }

  async onPostReadFromJson() {
    // Override in subclasses
  }

  isDoneLoading() {
    return this.doneLoading;
  }

  setDoneLoading() {
    this.doneLoading = true;
  }

  hasShownWelcomeMessage() {
    return this.shownWelcomeMessage;
  }

  setShownWelcomeMessage() {
    this.shownWelcomeMessage = true;
  }

  getName() {
    return this.name;
  }

  isCommercial() {
    return this.type == ProjectTypes.Commercial;
  }

  isResidential() {
    return this.type == ProjectTypes.Residential;
  }

  getBaseRoute() {
    return new Error("Override in subclass");
  }

  makeId(typeName) {
    return this.idsMap.makeId(typeName);
  }

  /*
  Use this as a central place to add watch effects that should be scoped to the project.
  They will be removed when the project is destroyed.
  */
  addWatchEffect(effectFunc, opts) {
    return this.watcher.addWatchEffect(effectFunc, opts);
  }

  async close() {
    console.log("CLOSING PROJECT: " + this.name);
    console.log("Unregistering watchers...");
    this.watcher.unwatchAll();

    console.log("Updating timers...");
    if (this.checkLockTimer) {
      this.checkLockTimer.stop();
    }

    await this.onCloseProject();

    console.log("Saving project...");
    await this.save();

    console.log("Releasing lock...");
    await ProjectLock.tryReleaseLock(this.id);

    console.log("DONE");
  }

  async onCloseProject() {
    // Note: override in subclasses
  }

  getUiName() {
    return this.name || "Untitled-Project";
  }

  setHelpId(helpId, open) {
    this.helpPaneId = helpId;
    if (valOr(open, false)) {
      this.helpPaneVisible = true;
    }
  }

  setHelpPaneVisible(isVisible) {
    this.helpPaneVisible = isVisible;
  }

  async updateLockStatus() {
    /*
    // TODO - this is for when read+write support comes
    let lockData = await ProjectLock.getLockData(this.id);
    console.log("UPDATING LOCK STATUS: ", lockData);
    this.lockedBy = lockData ? lockData.userEmail : null;
    */
    let curOwner = await ProjectLock.updateOwnership(this.id);
    if (curOwner !== gApp.getUser().email) {
      console.log(`Another user (${curOwner}) booted you from the project!`);
      gApp.router.replace('/');
    }
    this.lockedBy = curOwner
  }

  getProjectJson() {
    return ser.writeToJson(this, {debug: false});
  }

  exportToFile() {
    console.log("Exporting project to file");
    let projectData = this.getProjectJson();
    // Get a date string of the form YYYY-MM-DD
    let now = new Date();
    let dateStr = new Intl.DateTimeFormat('en-CA').format(now);
    let fileName = `${this.name}_${dateStr}.hwise`;
    downloadTextFile(prettyJson(projectData), fileName);
    gApp.toast("Project exported");
  }

  async save() {
    if (!this.doneLoading) {
      console.log("Project not yet done loading. Ignoring save.");
      return;
    }
    if (this.saving) {
      console.log("Already saving proj.")
      return;
    }
    this.saving = true;
    try {
      // We only save if something has changed (indicated by a changed
      // hash value). It's cheap to serialize the project, so can check
      // this often.
      // console.log("Checking if save required: " + this.id);

      let debug = true;
      let jsonData = ser.writeToJson(this, {debug: false});
      let hashVal = hashObject(jsonData);
      if (!this.lastSaveHash || hashVal !== this.lastSaveHash) {
        console.log(`Project change detected for Proj ${this.id}. Saving. ${this.lastSaveHash} -> ${hashVal}`);
        if (debug) {
          //console.log("Save data: ", prettyJson(jsonData));
          console.log(`Project data: `, jsonData);
        }

        // Check that user has the lock
        let hasLock = await ProjectLock.hasLock(this.id);
        if (!hasLock) {
          console.log(`Ignoring save. Does not have lock for ${this.id}`);
          return;
        }

        await setDoc(doc(gApp.db, 'projects', this.id), jsonData);
        console.log("Save complete");
        this.lastSaveHash = hashVal;
      }
    } finally {
      this.saving = false;
    }
  }

  async updateLastOpenedTime() {
    console.log("Updating lastOpenedTime");
    if (this.useMockTimes) {
      return;
    }
    this.lastOpenedTime = Date.now();
    await updateDoc(doc(gApp.db, 'projects', this.id), {
      lastOpenedTime: this.lastOpenedTime,
    }) 
  }

  async updateSharedUsers() {
    console.log("Updating sharedUsers");
    await updateDoc(doc(gApp.db, 'projects', this.id), {
      sharedUsers: this.sharedUsers,
    }) 
  }

  addSharedUser(userEmail) {
    console.log("Adding shared user!");
    if (userEmail == this.userEmail) {
      gApp.toast(`${userEmail} can already access the project.`, {type: 'error'})
      return;
    }
    if (!elemIn(userEmail, this.sharedUsers)) {
      this.sharedUsers.push(userEmail);
    }
    this.updateSharedUsers();
    gApp.toast(`Shared the project with: ${userEmail}`)
    gApp.sendUserNotif(userEmail, {type: 'AddedToProject', owner: this.userEmail, name: this.name})
  }

  removeSharedUser(userEmail) {
    removeElem(this.sharedUsers, userEmail);
    this.updateSharedUsers();
    gApp.toast(`Removed ${userEmail} from the project.`);
    gApp.sendUserNotif(userEmail, {type: 'RemovedFromProject', owner: this.userEmail, name: this.name})
  }

  async rename(newName) {
    this.name = newName;
    await updateDoc(doc(gApp.db, 'projects', this.id), {
      name: this.name,
    }) 
    gApp.toast(`Renamed project`)
  }

  /*
  Null if no errors. Otherwise, an array of error strings.
  */
  getProjectErrors() {
    return this.projectErrors;
  }

  updateProjectErrors(opts) {
    /*
    Note: we run this before calculating the results, and store the errors. We do not want to make this reactive b/c
    , the way it is setup with probing the objects, it will update whenever any field changes in the object hierarchy.
    We used to run this every second, but changed to now only run before calculations are run (more sensible).
    */
    // Visit child objects hierarchy to collect all errors
    // console.log("===============UPDATING PROJECT ERRORS===============");
    let errorCollector = new ErrorCollector(this, opts);
    let errors = errorCollector.getErrors();
    // console.log("===============DONE===============");
    this.projectErrors = errors.length > 0 ? errors : null;
  }
};
setupClass(Project)