<script setup>
import { ref, computed, onMounted, watch, } from 'vue'
import { gApp, DebugOn, } from '../State.js'
import FieldInput from './FieldInput.vue'
import SimpleCollapse from './SimpleCollapse.vue'
import HelpSection from './HelpSection.vue'
import BuildingUserGuide from './BuildingUserGuide.vue'
import BasicModal from './utils/BasicModal.vue'

let proj = computed(() => {
  return gApp.proj();
})

const welcomeModalRef = ref(null);

const handleWelcomeModalDone = () => {
  // Could store a flag in localStorage to not show again for this project
  console.log("Welcome modal closed");
};

onMounted(() => {
  let proj = gApp.proj();
  if (proj && !proj.hasShownWelcomeMessage()) {
    console.log("Showing welcome modal");
    welcomeModalRef.value.showModal();
    proj.setShownWelcomeMessage();
  }
});

</script>

<template>
  <div class="Container">
    <BasicModal 
      ref="welcomeModalRef"
      :showCancel="false"
      doneText="Get Started"
      @onDone="handleWelcomeModalDone"
    >
      <div class="welcome-content">
        <p>
          <h2 class="welcome-heading pc">Welcome to your new project.</h2>
        </p>
        <p>
          To get started, follow the <b>Project Guide</b> below.
        </p>
        <p>
          It will guide you through each step of the load calculation process.
        </p>
      </div>
    </BasicModal>
    
    <HelpSection id="home">
      <h4 class="mb-s">Welcome to the Commercial Building Designer!</h4>
      <p class="mb-s">Let's get started.</p>
      <p class=""></p>
      <p class="mb-s">This is the <b>Quick Help</b> pane. Click a <i class="bi-question-circle pc"></i> anywhere to open it.</p>
      <p class="mb-s">This page shows the <b>Load Results</b> for your building. The table will be mostly empty until you give more info
        about your building.
      </p>
      <p>Go to the <router-link :to="{path: `/building/${proj.id}/location`}"><b>Location</b></router-link> tab now to get started.</p>
    </HelpSection>
    <div class="BorderedSection_x mb-xl">
      <BuildingUserGuide />
    </div>
  </div>
</template>

<style scoped>
.Section {
  /* margin-bottom: var(--space-s); */
}

.LastSection {
  margin-bottom: var(--space-xl);
}

.WallsTable td {
  vertical-align: top;
}

.UsersTable {
  border: 1px solid var(--b-20);
  min-width: 600px;
}

.ShareBtn {
  padding: var(--space-xxs);
  /* margin-left: auto; */
}

.SharingRemoveBtn {
  margin-left: auto;
}

.MainHelp {
  width: 520px;
}

.welcome-content {
  text-align: left;
  padding: var(--space-xs);
}

.welcome-heading {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.welcome-content p {
  margin-bottom: var(--space-m);
  line-height: 1.5;
}

.welcome-steps {
  margin: var(--space-m) 0;
  padding-left: var(--space-l);
}

.welcome-steps li {
  margin-bottom: var(--space-s);
  line-height: 1.5;
}

</style>
