import { makeEnum, makeOptions,
  setupClass, lookupData, 
  interpolateInMap,
} from '../Base.js'

import * as psy from './Psychrometrics.js'
import * as calc from './ResidentialCalculations.js'
export { Units } from '../Common/Units.js'

import {
  FieldType,
  Field,
  FieldGroup,
} from '../Common/Field.js'

import { WallType } from './WallType.js'
import { BufferSpaceType } from './BufferSpaceType.js'

import { gApp, } from '../Globals.js'


export class Partition {
  init() {
    this.wallType = Field.makeTypeSelect('Wall Type', gApp.proj().wallTypes, null, {
      errorWhenEmpty: `You must create a Wall Type.`,
      metadata: {
        typeClass: WallType,
      }
    })
    this.size = new Field({
      name: 'Size',
      type: FieldType.Area,
      requiresInput: true,
    })
    this.bufferSpaceType = Field.makeTypeSelect('Buffer Space', gApp.proj().bufferSpaceTypes, null, {
      errorWhenEmpty: `You must create a Buffer Space Type`,
      metadata: {
        typeClass: BufferSpaceType,
      }
    })

    this.serFields = [
      'wallType',
      'size',
      'bufferSpaceType',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Partition',
    }
  }

  getWallType() {
    return this.wallType.lookupValue();
  }

  getBufferSpaceType() {
    return this.bufferSpaceType.lookupValue();
  }

  _calcLoads(ctx, isHeating, optArgs) {
    ctx.startSection(`Partition - ${isHeating ? 'Heating' : 'Cooling'}`);

    let wallType = this.getWallType();
    let bufferSpaceType = this.getBufferSpaceType();
    ctx.log(`Wall type: ${wallType.name.value}, Buffer space type: ${bufferSpaceType.name.value}`);

    ctx.A = this.size.value;

    let weatherData = ctx.toplevelData.locationData;
    if (gApp.proj().isResidential()) {
      ctx.t_i = isHeating ? ctx.toplevelData.indoorWinterTemp
        : ctx.toplevelData.indoorSummerTemp;
      ctx.t_o = isHeating ? ctx.designTemps.heating : ctx.designTemps.cooling;
    } else {
      // For commercial, t_i and t_o must be given in the optArgs
      ctx.log("For commercial projects, using t_i and t_o given in optArgs...")
      ctx.assert(optArgs, 'optArgs must be given for commercial projects');
      ctx.assert(optArgs.t_i !== undefined, 'optArgs.t_i must be given for commercial projects');
      ctx.assert(optArgs.t_o !== undefined, 'optArgs.t_o must be given for commercial projects');
      ctx.t_i = optArgs.t_i;
      ctx.t_o = optArgs.t_o;
    }

    ctx.elevation = weatherData.elevation;
    ctx.P_loc = psy.calcLocalPressure(ctx.elevation);
    ctx.C_s = 1.1 * ctx.P_loc / psy.P_std;
    ctx.t_b = bufferSpaceType.calcTemps(ctx, ctx.C_s, ctx.t_o, ctx.t_i, isHeating);

    ctx.R = wallType.getRValue();
    ctx.U = ctx.eval('1.0 / R', {}, 'U');
    ctx.q = calc.calcPartitionOpaqueQ(ctx, ctx.A,
      ctx.U, ctx.t_i, ctx.t_b, isHeating);

    let q = ctx.q;
    ctx.endSection();
    return q;
  }

  calcOutputs(ctx) {
    let heatingRes = this._calcLoads(ctx, true);
    let coolingRes = this._calcLoads(ctx, false);

    let outputs = {
      q_heating: heatingRes,
      q_cooling: coolingRes,
    }
    return outputs;
  }
}
setupClass(Partition)