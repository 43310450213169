import { ref, reactive } from 'vue'
import { lookupData } from '../Base.js'
import {
  PI, cos, sin, toRads, toDegs, arcsin, arccos, arctan, sqrt, log, ln, exp,
  clampClampNegativePiToPi,
} from '../Common/Math.js'
import { describeFunc } from '../Common/CalcContext.js';


// Solar azimuth
export function calcPhi(ctx, surfaceDir) {
  let azimuthAngle = lookupData(SurfaceAzimuthAngleMap, [surfaceDir])
  return ctx.eval('abs(azimuthAngle/180.0 - 1)', {azimuthAngle}, 'phi');
}

export let SurfaceAzimuthAngleMap = {
  S: 0,
  SSW: 22.5,
  SW: 45,
  WSW: 67.5,
  W: 90,
  WNW: 112.5,
  NW: 135,
  NNW: 157.5,
  N: 180,
  NNE: -157.5,
  NE: -135,
  ENE: -112.5,
  E: -90,
  ESE: -67.5,
  SE: -45,
  SSE: -22.5,
};

export function calc_E_o(ctx, n) {
  return ctx.eval('E_sc*(1 + 0.033*cos(2*PI*(n - 3)/365.0))', {
    n: n,
    E_sc: 433.3,
  }, 'E_o');
}

/**
 * Calculate solar irradiance on a tilted surface
 * In general use this func instead of the functions above (more compact)
 */
export let calc_E_t = describeFunc('calc_E_t', {
  timeZone: 'None', // UTC time zone value
  localStdTime: 'None', // 0.00-23.99
  dayOfYear: 'None', // 1-365
  latitude: 'None',
  longitude: 'None',
  tau_b: 'None', // beam optical depth (from weather data)
  tau_d: 'None', // diffuse optical depth (from weather data)
  surfaceDir: 'None', // surface direction
  surfaceTiltAngleDegs: 'None', // surface tilt angle, in degrees
  groundReflectance: 'None', // ground reflectance
}, 'None', (ctx, timeZone, localStdTime, dayOfYear, latitude, longitude,
  tau_b, tau_d, surfaceDir, surfaceTiltAngleDegs,
  groundReflectance) => {

  let funcArgs = {
    timeZone, localStdTime, dayOfYear, latitude, longitude,
    tau_b, tau_d, surfaceDir, surfaceTiltAngleDegs,
    groundReflectance,
  };
  let cachedVal = ctx.funcCache.get('calc_E_t', funcArgs);
  if (cachedVal) {
    ctx.log('Using cached value');
    return cachedVal;
  }

  ctx.n = dayOfYear;
  ctx.tau_b = tau_b;
  ctx.tau_d = tau_d;
  ctx.E_o = calc_E_o(ctx, ctx.n);
  ctx.ET = ctx.eval('2.2918*(0.0075 + 0.1868*cos(gamma) - 3.0277*sin(gamma) - 1.4615*cos(2*gamma) - 4.089*sin(2*gamma))', {
    gamma: 2*PI*(ctx.n - 1)/365.0
  }, 'ET');
  ctx.AST = ctx.eval('LST + ET/60.0 + (Lon - LSM)/15.0', {
    LST: localStdTime,
    Lon: longitude,
    LSM: 15 * timeZone,
  }, 'AST');
  // Solar declination angle
  ctx.delta_rads = ctx.eval('toRads(23.45*sin(2*PI*(n + 284)/365.0))', {
    toRads,
  }, 'delta_rads');
  ctx.H_rads = ctx.eval('toRads(15*(AST - 12))', {
    toRads,
  }, 'H');
  ctx.beta_rads = ctx.eval('arcsin(cos(L)*cos(delta_rads)*cos(H_rads) + sin(L)*sin(delta_rads))', {
    L: toRads(latitude),
    arcsin,
  }, 'beta_rads');
  ctx.beta_rads = ctx.eval('max(beta_rads, 0)', {}, 'beta_rads');

  ctx.sin_phi = ctx.eval('sin(H_rads)*cos(delta_rads)/cos(beta_rads)', {}, 'sin_phi');
  ctx.cos_phi = ctx.eval('(cos(H_rads)*cos(delta_rads)*sin(L) - sin(delta_rads)*cos(L))/cos(beta_rads)', {
    L: toRads(latitude),
  }, 'cos_phi');
  if (ctx.sin_phi > 0 && ctx.cos_phi > 0) {
    ctx.phi = ctx.eval('arcsin(sin_phi)', {arcsin}, 'phi');
  } else if (ctx.sin_phi < 0 && ctx.cos_phi < 0) {
    ctx.phi = ctx.eval('-PI - arcsin(sin_phi)', {arcsin}, 'phi');
  } else if (ctx.sin_phi < 0 && ctx.cos_phi > 0) {
    ctx.phi = ctx.eval('arcsin(sin_phi)', {arcsin}, 'phi');
  } else {
    ctx.phi = ctx.eval('PI - arcsin(sin_phi)', {arcsin}, 'phi');
  }

  ctx.m = ctx.eval('1/(sin(beta_rads) + 0.50572*((6.07995 + beta_degs)^(-1.6364)))', {
    beta_degs: toDegs(ctx.beta_rads),
  }, 'm');

  ctx.ab = ctx.eval('1.454 - 0.406*tau_b - 0.268*tau_d + 0.021*tau_b*tau_d', {}, 'ab');
  ctx.ad = ctx.eval('0.507 + 0.205*tau_b - 0.080*tau_d - 0.190*tau_b*tau_d', {}, 'ad');
  ctx.E_b = ctx.eval('E_o*exp(-tau_b*(m^ab))', {
  }, 'E_b');
  ctx.E_d = ctx.eval('E_o*exp(-tau_d*(m^ad))', {
  }, 'E_d');

  ctx.psi_degs = lookupData(SurfaceAzimuthAngleMap, [surfaceDir])
  ctx.psi_rads = toRads(ctx.psi_degs);
  ctx.gamma_rads = ctx.eval('clampAngleNegativePiToPi(phi - psi_rads)', {
    clampAngleNegativePiToPi: clampClampNegativePiToPi,
  }, 'gamma_rads');
  ctx.tilt_angle_rads = toRads(surfaceTiltAngleDegs);
  if (ctx.beta_rads === 0) {
    ctx.cosTheta = 0;
    ctx.cosThetaStar = 0;
  } else {
    ctx.cosTheta = ctx.eval('cos(beta_rads)*cos(gamma_rads)*sin(tilt_angle_rads) + sin(beta_rads)*cos(tilt_angle_rads)', {
    }, 'cosTheta');
    ctx.cosThetaStar = ctx.eval('cos(beta_rads)*cos(gamma_rads)*sin(PI/2.0) + sin(beta_rads)*cos(PI/2.0)', {
      PI: PI,
    }, 'cosThetaStar');
  }
  ctx.thetaDegs = toDegs(Math.acos(ctx.cosTheta))

  // Direct beam solar irradiance
  ctx.E_t_b = ctx.eval('max(E_b*cosTheta, 0)', {}, 'E_t_b');

  // Diffuse solar irradiance
  ctx.Y = ctx.eval('max(0.45, 0.55 + 0.437*cosThetaStar+0.313*cosThetaStar^2)', {}, 'Y');
  if (ctx.tilt_angle_rads <= PI/2.0) {
    ctx.E_t_d = ctx.eval('E_d*(Y*sin(tilt_angle_rads) + cos(tilt_angle_rads))', {
    }, 'E_t_d');
  } else {
    ctx.E_t_d = ctx.eval('E_d*Y*sin(tilt_angle_rads)', {
    }, 'E_t_d');
  }

  // Reflected solar irradiance
  ctx.rho_g = groundReflectance;
  ctx.E_t_r = ctx.eval('(E_b*sin(beta_rads) + E_d)*rho_g * (1 - cos(tilt_angle_rads))/2.0', {
  }, 'E_t_r');

  ctx.E_t = ctx.eval('E_t_b + E_t_d + E_t_r', {}, 'E_t');
  let res = {
    E_t: ctx.E_t,
    E_t_b: ctx.E_t_b,
    E_t_d: ctx.E_t_d,
    E_t_r: ctx.E_t_r,
    theta_degs: ctx.thetaDegs,
    beta_rads: ctx.beta_rads,
    gamma_rads: ctx.gamma_rads,

    debug: {
      E_o: ctx.E_o,
      ET: ctx.ET,
      AST: ctx.AST,
      delta_rads: ctx.delta_rads,
      H_rads: ctx.H_rads,
      beta_rads: ctx.beta_rads,
      sin_phi: ctx.sin_phi,
      cos_phi: ctx.cos_phi,
      phi: ctx.phi,
      m: ctx.m,
      ab: ctx.ab,
      ad: ctx.ad,
      E_b: ctx.E_b,
      E_d: ctx.E_d,
      psi_degs: ctx.psi_degs,
      psi_rads: ctx.psi_rads,
      gamma_rads: ctx.gamma_rads,
      tilt_angle_rads: ctx.tilt_angle_rads,
      cosTheta: ctx.cosTheta,
      cosThetaStar: ctx.cosThetaStar,
      thetaDegs: ctx.thetaDegs,
      Y: ctx.Y,
    }
  };
  ctx.funcCache.set('calc_E_t', funcArgs, res);
  return res;
});

// Returns 1-365
export function getDayOfYear(monthIndex, dayOfMonth) {
  if (monthIndex < 0 || monthIndex > 11) {
    throw new Error(`Invalid monthIndex: ${monthIndex}`);
  }
  if (dayOfMonth < 1 || dayOfMonth > 31) {
    throw new Error(`Invalid dayOfMonth: ${dayOfMonth}`);
  }
  const date = new Date(2024, monthIndex, dayOfMonth);
  const startOfYear = new Date(2024, 0, 1);
  const dayOfYear = Math.floor((date - startOfYear) / (1000 * 60 * 60 * 24)) + 1;
  return dayOfYear;
}
