import {
  makeEnum, lookupData,
  interpolateInMap,
} from '../Base.js'
import { valOr, getShortMonthName, getHourString, } from '../SharedUtils.js'

import { DoorColor, } from '../Components/DoorType.js'
import { ShadesOrientation } from '../Components/InteriorShadingType.js'
import { FloorType } from '../Components/Floor.js'
import { getSeasonOfMonth } from '../Components/Common.js'
import { Units, getLabel, } from '../Common/Units.js'

import { gApp, DebugOn } from '../Globals.js'

import { InfiltrationHours } from './Space.js'
import { CalcPsychrometrics, PsyCalcMethod, adjustForAltitude } from '../Components/Psychrometrics.js'
import * as solar from '../Components/SolarCalculations.js'

import { MatrixUtils, makeVector,
  positiveModulo,
} from '../Common/Math.js'
import * as math from 'mathjs'
import { IACCalculator } from '../Components/IACCalculator.js'
import { findMaxLoadForMonth, findMaxLoadInMatrix, } from './LoadUtils.js'

import {
  ResultsNode,
  ResultsNodeType,
  LineChartNode,
  SunburstNode,
} from './ResultsTree.js'

let DoorAlphaMap = {
  [DoorColor.Light]: 0.30,
  [DoorColor.Medium]: 0.60,
  [DoorColor.Dark]: 0.90,
};

export function reverseAboutStart(arr) {
  let copy = math.clone(arr)
  for (let i = 0; i < arr.length; ++i) {
    copy[i] = arr[positiveModulo(-i, arr.length)]
  }
  return copy
}

let RadiantLoadType = makeEnum({
  Solar: 'Solar',
  NonSolar: 'NonSolar',
})

export class TimeSeriesUtils {
  static transformVec(ctx, vecToTransform, timeSeriesVec) {
    let numHrs = vecToTransform.length
    let reversedTimeSeriesVec = reverseAboutStart(timeSeriesVec)
    let res = makeVector(numHrs)
    for (let hr = 0; hr < numHrs; ++hr) {
      let adjustmentVec = makeVector(numHrs)
      for (let j = 0; j < numHrs; ++j) {
        adjustmentVec[(hr + j) % numHrs] = reversedTimeSeriesVec[j]
      }
      res[hr] = math.dot(vecToTransform, adjustmentVec)
    }
    return res
  }

  static transformMatrix(ctx, matrixToTransform, timeSeriesVec) {
    ctx.startSection("timeSeriesTransformMatrix")
    let numMonths = matrixToTransform.size()[0]
    let numHrs = matrixToTransform.size()[1]
    ctx.res = math.zeros(numMonths, numHrs)
    for (let i = 0; i < numMonths; i++) {
      let row = MatrixUtils.getRow(matrixToTransform, i)
      let adjustedVec = this.transformVec(ctx, row, timeSeriesVec)
      MatrixUtils.setRow(ctx.res, i, adjustedVec)
    }
    let res = ctx.res
    ctx.logLoadMatrix('res', res)
    ctx.endSection()
    return res
  }
}

export class SpaceCoolingCalculator {
  constructor(space, ctx) {
    this.space = space
    this.ctx = ctx
  }

  getSummerIndoorTemp(hourIndex) {
    let ctx = this.ctx;
    // Note - we used to use the occupancy schedule for this, but now we just use
    // a fixed value.
    return ctx.t_i_summer;
  }

  getSummerOutdoorTemp(monthIndex, hourIndex) {
    // Retrieve the summer outdoor temp from the design data
    let ctx = this.ctx;
    return ctx.designTemps.getCoolingDryBulbOut(this.ctx, monthIndex, hourIndex);
  }

  getSummerOutdoorWetBulbTemp(monthIndex, hourIndex) {
    // Retrieve the summer outdoor temp from the design data
    let ctx = this.ctx;
    return ctx.designTemps.getCoolingWetBulbOut(this.ctx, monthIndex, hourIndex);
  }

  async calcInfiltrationLatentLoads() {
    let ctx = this.ctx
    ctx.setProgressText("Calculating infiltration latent loads")
    console.log("Calc infiltration latent loads")
    ctx.startSection("Infiltration Latent Loads")
    ctx.I_lat = math.zeros(12, 24)
    ctx.C_l = ctx.call(adjustForAltitude, 4840, ctx.P_loc)
    let onlyDuringOccupiedHours = this.space.infiltrationHours.value == InfiltrationHours.OccupiedHours;
    ctx.log(`Only during occupied hours: ${onlyDuringOccupiedHours}`)
    if (onlyDuringOccupiedHours) {
      ctx.occupancySchedule = this.space.internals.people.getSchedule().getData();
    }
    for (let month = 0; month < 12; month++) {
      ctx.startLocalSection(`Month ${month}`)
      ctx.Q_inf = this.space.calcInfiltrationFlowRate(ctx, getSeasonOfMonth(month)).Q_inf;
      for (let hour = 0; hour < 24; hour++) {
        //console.log(`Month ${month}, Hour ${hour}`)
        ctx.setProgressText(`Infiltration latent loads - Month ${month}, Hour ${hour}`)
        await ctx.briefWait()
        ctx.startLocalSection(`Hour ${hour}`)
        if (onlyDuringOccupiedHours && ctx.occupancySchedule[hour] == 0) {
          ctx.log("Unoccupied hour")
          ctx.I_lat_item = 0;
        } else {
          ctx.t_i = this.getSummerIndoorTemp(hour)
          ctx.W_in = ctx.call(CalcPsychrometrics, ctx.t_i, ctx.altitude,
            PsyCalcMethod.CalcWithRelativeHumidity, {
              RH: ctx.summerIndoorRH
            }
          ).W;
          ctx.t_out_db = this.getSummerOutdoorTemp(month, hour)
          ctx.t_out_wb = this.getSummerOutdoorWetBulbTemp(month, hour)
          ctx.W_out = ctx.call(CalcPsychrometrics, ctx.t_out_db, ctx.altitude,
            PsyCalcMethod.CalcWithWetBulbTemp, {
              t_wb_F: ctx.t_out_wb,
            }
          ).W;
          ctx.I_lat_item = ctx.eval('Q_inf*C_l*(W_out - W_in)', {}, 'I_lat_item')
        }
        ctx.I_lat.set([month, hour], ctx.I_lat_item)
        ctx.endSection()
      }
      ctx.endSection()
    }

    ctx.logLoadMatrix('I_lat', ctx.I_lat)
    let res = {
      LatentLoadsMatrix: ctx.I_lat,
    }

    ctx.endSection()
    return res
  }

  async calcApplianceLatentLoads() {
    let ctx = this.ctx;
    ctx.setProgressText("Calculating appliance latent loads")
    let applianceLoads = this.space.internals.appliances;
    ctx.startSection(`Appliance Latent Loads`)
    ctx.A_lat_row = new Array(24).fill(0);
    for (let i = 0; i < applianceLoads.appliances.length; i++) {
      ctx.startLocalSection(`Appliance ${i}`)
      let app = applianceLoads.appliances[i];
      ctx.quantity = app.getQuantity();
      ctx.A_lat_app = app.getLatentLoad(ctx);
      ctx.appSched = app.getSchedule().getData();
      for (let hr = 0; hr < 24; hr++) {
        ctx.startLocalSection(`Hour ${hr}`)
        ctx.Load = ctx.eval('N*A_lat_app*schedFactor*D', {
          N: ctx.quantity,
          schedFactor: ctx.appSched[hr],
          D: app.getDiversityFactor(),
        }, 'Load')
        ctx.A_lat_row[hr] += ctx.Load;
        ctx.endSection()
      }
      ctx.endSection()
    }
    // Same for each month:
    ctx.A_lat = math.zeros(12, 24)
    for (let i = 0; i < 12; i++) {
      MatrixUtils.setRow(ctx.A_lat, i, ctx.A_lat_row)
    }
    ctx.logLoadMatrix('A_lat', ctx.A_lat)

    let res = {
      LatentLoadsMatrix: ctx.A_lat,
    }

    ctx.endSection()
    return res;
  }

  async calcPeopleLatentLoads() {
    let ctx = this.ctx;
    ctx.setProgressText("Calculating people latent loads")
    ctx.startSection(`People Latent Loads`)
    let people = this.space.internals.people;
    ctx.sched = people.getSchedule().getData();
    ctx.D = people.getDiversityFactor();
    ctx.M = people.getNumOccupants().result;
    ctx.activityLevel = people.activityLevel.value;
    let activityLevelData = people.getActivityLevelData(ctx);
    ctx.L = activityLevelData.Latent_Heat;
    ctx.P_lat_mo0 = ctx.eval(`sched * M * L * D`, {}, 'P_lat')
    // Same for each month:
    ctx.P_lat = math.zeros(12, 24)
    for (let i = 0; i < 12; i++) {
      MatrixUtils.setRow(ctx.P_lat, i, ctx.P_lat_mo0)
    }
    ctx.logLoadMatrix('P_lat', ctx.P_lat)
    let res = {
      LatentLoadsMatrix: ctx.P_lat,
    }
    ctx.endSection()
    return res
  }

  async calcMiscLatentLoads() {
    let ctx = this.ctx;
    ctx.setProgressText("Calculating misc latent loads")
    ctx.startSection(`Misc Latent Loads`)
    ctx.M_lat_row = new Array(24).fill(0);

    // Note: there is only one misc load entry for now
    let miscLoads = [this.space.internals.miscLoads];
    for (let i = 0; i < miscLoads.length; i++) {
      let misc = miscLoads[i];
      ctx.log(`Misc ${i}:`)
      ctx.M_entry = misc.getLatentLoad(ctx);
      ctx.D = misc.getDiversityFactor();
      ctx.entrySched = misc.getSchedule().getData();
      ctx.M_lat_row = ctx.eval('M_entry * entrySched * D', {}, 'M_lat_row')
    }

    // Same for each month
    ctx.M_lat = math.zeros(12, 24)
    for (let i = 0; i < 12; i++) {
      MatrixUtils.setRow(ctx.M_lat, i, ctx.M_lat_row)
    }

    ctx.logLoadMatrix('M_lat', ctx.M_lat)
    let res = {
      LatentLoadsMatrix: ctx.M_lat,
    }

    ctx.endSection()
    return res
  }

  /**
   * 
   * Returns the result of calc_E_t, which is {E_t, E_t_b, E_t_d, E_t_r}
   */
  calc_E_t(wall, monthIndex, hrIndex) {
    let ctx = this.ctx;
    ctx.startSection("Calc E_t")
    let locData = ctx.toplevelData.locationData;
    ctx.wallDir = wall.direction.value
    ctx.groundReflectance = ctx.buildingAndEnv.getReflectance(monthIndex, ctx.wallDir)
    ctx.dayOfYear = ctx.eval('getDayOfYear(monthIndex, dayOfMonth)', {
      getDayOfYear: solar.getDayOfYear,
      monthIndex,
      dayOfMonth: ctx.toplevelData.dayOfMonth,
    }, 'dayOfYear')
    ctx.tau_b = locData.tauBValuesByMonth[monthIndex]
    ctx.tau_d = locData.tauDValuesByMonth[monthIndex]
    ctx.E_t_data = ctx.call(solar.calc_E_t,
      locData.timezone,
      hrIndex,
      ctx.dayOfYear,
      locData.latitude,
      locData.longitude, 
      ctx.tau_b,
      ctx.tau_d,
      wall.direction.value,
      wall.getTiltAngleDegs(),
      ctx.groundReflectance,
    );
    let res = ctx.E_t_data
    ctx.endSection()
    return res
  }

  calc_t_e(wall, moIndex, hrIndex, t_out, alpha) {
    let ctx = this.ctx;
    ctx.startSection("Calc t_e")
    ctx.E_t = this.calc_E_t(wall, moIndex, hrIndex).E_t
    ctx.t_e = ctx.eval('t_out + alpha*E_t/h_o + epsilon*deltaR/h_o', {
      t_out: t_out,
      alpha: alpha,
      h_o: 3.0,
      epsilon: 1,
      deltaR: !wall.isRoof() ? 0 : 20,
    }, 't_e')
    let res = ctx.t_e
    ctx.endSection()
    return res
  }

  applyRTSTransform(matrix, radiantLoadType) {
    let ctx = this.ctx;
    ctx.startSection(`Apply RTS transform - ${radiantLoadType}`)
    ctx.logLoadMatrix('Before transform', matrix)
    let timeSeriesData = ctx.tablesCache.getTable('TimeSeriesData');
    if (radiantLoadType == RadiantLoadType.Solar) {
      ctx.rtsVec = timeSeriesData.getSolarRTSValues(this.space.getRTSTransformArgs())
    } else {
      ctx.rtsVec = timeSeriesData.getNonSolarRTSValues(this.space.getRTSTransformArgs())
    }
    ctx.transformedMatrix = TimeSeriesUtils.transformMatrix(ctx, matrix, ctx.rtsVec)
    ctx.logLoadMatrix('After transform', ctx.transformedMatrix)
    let res = ctx.transformedMatrix
    ctx.endSection()
    return res
  }

  /**
   * 
   * isWall - true if wall, false if roof
   */
  async calcWallLoads(wall) {
    let ctx = this.ctx
    ctx.startSection(`${wall.isRoof() ? 'Roof' : 'Wall'} Loads`)
    ctx.U = 1.0 / wall.getWallType().getRValue()
    ctx.alpha = wall.getWallType().getAbsorptance()
    ctx.A = wall.getStrictlyWallArea()
    ctx.W_loads = math.zeros(12, 24)
    for (let monthIndex = 0; monthIndex < 12; ++monthIndex) {
      ctx.startLocalSection(`Month ${monthIndex}`)
      for (let hrIndex = 0; hrIndex < 24; ++hrIndex) {
        ctx.startLocalSection(`Hour ${hrIndex}`)
        ctx.setProgressText(`Calculating ${wall.isRoof() ? 'roof' : 'wall'} loads - Month ${monthIndex}, Hour ${hrIndex}`)
        await ctx.briefWait()
        ctx.t_in = this.getSummerIndoorTemp(hrIndex)
        ctx.t_out = this.getSummerOutdoorTemp(monthIndex, hrIndex)
        ctx.t_e = this.calc_t_e(wall, monthIndex, hrIndex, ctx.t_out, ctx.alpha)
        ctx.W_mo_hr = ctx.eval('U*A*(t_e - t_in)', {}, 'W_mo_hr')
        ctx.W_loads.set([monthIndex, hrIndex], ctx.W_mo_hr)
        ctx.endSection()
      }
      ctx.endSection()
    }
    let res = ctx.W_loads
    ctx.logLoadMatrix('W_loads', ctx.W_loads)
    ctx.endSection()
    return res
  }

  /**
   * 
   * isWall: true if wall, false if roof
   */
  async calcWallSensibleLoads(isWall) {
    let ctx = this.ctx;
    ctx.startSection(isWall ? "Walls" : "Roofs")

    let timeSeriesData = ctx.tablesCache.getTable('TimeSeriesData');
    let walls = isWall ? this.space.walls : this.space.roofs;
    ctx.Walls_conv = math.zeros(12, 24)
    ctx.Walls_rad_nonsolar = math.zeros(12, 24)
    ctx.Walls_plenum = math.zeros(12, 24)
    for (let i = 0; i < walls.length; ++i) {
      let wall = walls[i];
      ctx.startLocalSection(`${isWall ? 'Wall' : 'Roof'} ${i}`)
      ctx.F_plenum = wall.getPlenumLoadFraction();
      ctx.Wall_loads = await this.calcWallLoads(wall)

      if (isWall) {
        ctx.wallCTSVec = timeSeriesData.getCTSValuesForWallType(wall.getWallType())
      } else {
        ctx.wallCTSVec = timeSeriesData.getCTSValuesForRoofType(wall.getRoofType())
      }
      ctx.Wall_CTS = TimeSeriesUtils.transformMatrix(ctx, ctx.Wall_loads, ctx.wallCTSVec)

      ctx.F_conv_wall = isWall ? 0.54 : 0.40
      ctx.Wall_conv = ctx.eval('(1 - F_plenum)*Wall_CTS*F_conv_wall', {
      }, 'W_conv')
      ctx.Walls_conv = math.add(ctx.Walls_conv, ctx.Wall_conv)

      ctx.Wall_rad_nonsolar = ctx.eval('(1 - F_plenum)*Wall_CTS*(1 - F_conv_wall)', {
      }, 'W_rad_nonsolar')
      ctx.Walls_rad_nonsolar = math.add(ctx.Walls_rad_nonsolar, ctx.Wall_rad_nonsolar)

      ctx.Wall_plenum = ctx.eval('F_plenum*Wall_CTS', {}, 'W_plenum')
      ctx.Walls_plenum = math.add(ctx.Walls_plenum, ctx.Wall_plenum)

      ctx.endSection()
    }

    ctx.Walls_rad_nonsolar = this.applyRTSTransform(ctx.Walls_rad_nonsolar, RadiantLoadType.NonSolar)
    // Note: the plenum loads are kept separate from the sensible loads
    ctx.Walls_sensible = MatrixUtils.sumOfMatrices([ctx.Walls_conv, ctx.Walls_rad_nonsolar])

    ctx.logLoadMatrix('Walls_conv', ctx.Walls_conv)
    ctx.logLoadMatrix('Walls_rad_nonsolar', ctx.Walls_rad_nonsolar)
    ctx.logLoadMatrix('Walls_plenum', ctx.Walls_plenum)
    ctx.logLoadMatrix('Walls_sensible', ctx.Walls_sensible)

    let res = {
      SensibleLoadsMatrix: ctx.Walls_sensible,
      PlenumLoadsMatrix: ctx.Walls_plenum,
    }

    ctx.endSection()
    return res
  }

  calcWindowShadedAreas(windowOrDoor, solarAngles, hr) {
    let ctx = this.ctx;
    ctx.startSection(`${windowOrDoor.getTypeName()} Shaded Areas`)

    let windowType = windowOrDoor.getWindowType()
    let optInShadingType = windowOrDoor.getInteriorShadingType()
    let optInShadingSchedule = windowOrDoor.getInteriorShadingSchedule()
    let optExShadingType = windowOrDoor.getExteriorShadingType()

    ctx.log(`Window type: ${windowType.name.value}`)
    ctx.log(`Internal shading type: ${optInShadingType ? optInShadingType.name.value : 'None'}`)
    ctx.log(`External shading type: ${optExShadingType ? optExShadingType.name.value : 'None'}`)

    ctx.W = windowType.width.value
    ctx.H = windowType.height.value

    if (!optExShadingType) {
      // No external shading
      ctx.log(`No external shading. Setting H_SL = H, W_SL = W`)
      ctx.H_SL = ctx.H
      ctx.W_SL = ctx.W
    } else {
      ctx.log(`Calculating H_SL and W_SL for external shading`)
      // Height of sunlight portion of the window
      ctx.P_H = optExShadingType.horizontalFinDepth.getValueInUnits(Units.ft)
      ctx.R_H = optExShadingType.horizontalFinDist.getValueInUnits(Units.ft)

      // Calculate Omega_V_rads
      ctx.Omega_V_rads = ctx.eval('arctan(tan(beta_rads) / cos(gamma_rads))', {
        arctan: Math.atan,
        beta_rads: solarAngles.beta_rads,
        gamma_rads: solarAngles.gamma_rads,
      }, 'Omega_V_rads')
      ctx.S_H = ctx.eval('P_H*tan(Omega_V_rads)', {
      }, 'S_H')
      ctx.H_SL = ctx.eval('max(0, min(H, H - (S_H - R_H)))', {
      }, 'H_SL')
      // Width of sunlit portion of the window
      if (solarAngles.gamma_rads <= 0) {
        // Use right fin
        ctx.log(`Using right fin - gamma_rads (${solarAngles.gamma_rads}) <= 0`)
        ctx.P_W = optExShadingType.rightFinDepth.getValueInUnits(Units.ft)
        ctx.R_W = optExShadingType.rightFinDist.getValueInUnits(Units.ft)
      } else {
        // Use left fin
        ctx.log(`Using left fin - gamma_rads (${solarAngles.gamma_rads}) > 0`)
        ctx.P_W = optExShadingType.leftFinDepth.getValueInUnits(Units.ft)
        ctx.R_W = optExShadingType.leftFinDist.getValueInUnits(Units.ft)
      }
      ctx.S_W = ctx.eval('P_W*abs(tan(gamma_rads))', {
        gamma_rads: solarAngles.gamma_rads,
      }, 'S_W')
      ctx.W_SL = ctx.eval('max(0, min(W, W - (S_W - R_W)))', {
      }, 'W_SL')
    }

    // Calc the 4 result areas
    let isHorizontal = optInShadingType !== null && optInShadingType.orientation.value === ShadesOrientation.Horizontal
    if (optInShadingType !== null && optInShadingSchedule !== null) {
      ctx.log(`Using internal shading schedule`)
      ctx.full_sched = optInShadingSchedule.getData();
      ctx.sched_hr = ctx.full_sched[hr]
    } else {
      ctx.log("No schedule, using value 1.0 (always on)")
      ctx.sched_hr = 1.0;
    }
    if (isHorizontal) {
      ctx.log("Calculating shaded areas - horizontal internal shading (or none)")
      ctx.H_clear = ctx.eval('H*(1.0 - sched_hr)', {
      }, 'H_clear')
      ctx.A_clear_light = ctx.eval('min(H_clear, H_SL)*W_SL', {
      }, 'A_clear_light')
      ctx.A_clear_shade = ctx.eval('max(0, H_clear - H_SL)*W + min(H_clear,H_SL)*(W - W_SL)', {
      }, 'A_clear_shade')
      ctx.A_shade_light = ctx.eval('max(0, H_SL-H_clear)*W_SL', {
      }, 'A_shade_light')
      ctx.A_shade_shade = ctx.eval('(H - max(H_SL,H_clear))*W + max(0, H_SL - H_clear)*(W - W_SL)', {
      }, 'A_shade_shade')
    } else {
      ctx.log("Calculating shaded areas - vertical internal shading")
      ctx.W_clear = ctx.eval('W*(1.0 - sched_hr)', {
      }, 'W_clear')
      ctx.A_clear_light = ctx.eval('H_SL*(W_SL - 0.5*(W - W_clear) - max(0, 0.5*(W - W_clear) - (W - W_SL)))', {
      }, 'A_clear_light')
      ctx.A_clear_shade = ctx.eval('(H - H_SL)*(W_SL - 0.5*(W - W_clear) - ' +
        'max(0, 0.5*(W - W_clear) - (W - W_SL))) + ' +
        'H*(max(0, (W - W_SL) - 0.5*(W - W_clear)))', {
      }, 'A_clear_shade')
      ctx.A_shade_light = ctx.eval('H_SL*(0.5*(W - W_clear) + max(0, 0.5*(W - W_clear) - (W - W_SL)))', {
      }, 'A_shade_light')
      ctx.A_shade_shade = ctx.eval('H*min(0.5*(W - W_clear), W - W_SL) + ' + 
        '(H - H_SL)*(0.5*(W - W_clear)+max(0, 0.5*(W - W_clear) - (W - W_SL)))', {
      }, 'A_shade_shade')
    }

    let fracGlass = windowOrDoor.getPercentGlass() / 100.0
    if (fracGlass < 1) {
      ctx.log("Fraction of glass < 1. Adjusting areas")
      ctx.fracGlass = fracGlass
      ctx.A_clear_light *= ctx.fracGlass
      ctx.A_clear_shade *= ctx.fracGlass
      ctx.A_shade_light *= ctx.fracGlass
      ctx.A_shade_shade *= ctx.fracGlass
    }

    let res = {
      A_clear_light: ctx.A_clear_light,
      A_shade_light: ctx.A_shade_light,
      A_clear_shade: ctx.A_clear_shade,
      A_shade_shade: ctx.A_shade_shade,
    }
    ctx.endSection()
    return res
  }

  async calcWindowNonSolarLoad(wall, window) {
    /**
     * The nonsolar loads are split into convective and radiative loads.
     * Return the two matrices.
     */
    let ctx = this.ctx;
    ctx.startSection("Window Nonsolar Load")

    ctx.Window_loads_conv = math.zeros(12, 24)
    ctx.Window_loads_rad = math.zeros(12, 24)

    let windowType = window.getWindowType()
    ctx.U = windowType.computeUValue().uValue
    ctx.A = windowType.getArea()
    ctx.SHGC = windowType.computeShgc()
    for (let mo = 0; mo < 12; ++mo) {
      ctx.startLocalSection(`Month ${mo}`)
      for (let hr = 0; hr < 24; ++hr) {
        ctx.startLocalSection(`Hour ${hr}`)
        ctx.setProgressText(`Calculating window nonsolar loads - Month ${mo}, Hour ${hr}`)
        await ctx.briefWait()

        ctx.t_in = this.getSummerIndoorTemp(hr)
        ctx.t_out = this.getSummerOutdoorTemp(mo, hr)
        ctx.E_t_data = this.calc_E_t(wall, mo, hr)
        ctx.SHGC_angle = this.calcSHGCForAngle(ctx.SHGC, ctx.E_t_data.theta_degs)
        ctx.F_conv_window = ctx.SHGC_angle > 0.5 ? 0.67 : 0.54
        ctx.Window_conv_mo_hr = ctx.eval('N*U*A*(t_out - t_in)*F_conv_window', {
          N: window.quantity.value,
        }, 'Window_conv_mo_hr')
        ctx.Window_rad_mo_hr = ctx.eval('N*U*A*(t_out - t_in)*(1 - F_conv_window)', {
          N: window.quantity.value,
        }, 'Window_rad_mo_hr')
        ctx.Window_loads_conv.set([mo, hr], ctx.Window_conv_mo_hr)
        ctx.Window_loads_rad.set([mo, hr], ctx.Window_rad_mo_hr);
        ctx.endSection()
      }
      ctx.endSection()
    }

    let res = {
      Window_nonsolar_conv: ctx.Window_loads_conv,
      Window_nonsolar_rad: ctx.Window_loads_rad,
    }
    ctx.logLoadMatrix('Nonsolar convective loads', ctx.Window_loads_conv)
    ctx.logLoadMatrix('Nonsolar rdiative loads', ctx.Window_loads_rad)

    ctx.endSection()
    return res
  }

  /**
   * Used to calc Window solar loads.
   * This function is also used for door solar loads.
   * Can be passed a WallWindow or WallDoor
   */
  async calcWindowSolarLoad(wall, windowOrDoor) {
    let ctx = this.ctx;
    ctx.startSection(`${windowOrDoor.getTypeName()} Solar Load`)

    ctx.Window_loads = math.zeros(12, 24)
    let windowType = windowOrDoor.getWindowType()
    let optInShadingType = windowOrDoor.getInteriorShadingType()
    let iacCalculator = new IACCalculator(
      windowType,
      optInShadingType,
      gApp.proj().windowsData.iacValues,
    )
    ctx.SHGC = windowOrDoor.computeShgc()
    for (let mo = 0; mo < 12; ++mo) {
      ctx.startLocalSection(`Month ${mo}`)
      for (let hr = 0; hr < 24; ++hr) {
        ctx.startLocalSection(`Hour ${hr}`)
        //console.log(`Calculating ${windowOrDoor.getTypeName()} solar loads - Month ${mo}, Hour ${hr}`)
        ctx.setProgressText(`Calculating ${windowOrDoor.getTypeName()} solar loads - Month ${mo}, Hour ${hr}`)
        await ctx.briefWait()
        ctx.E_t_data = this.calc_E_t(wall, mo, hr)
        ctx.SHGC_angle = this.calcSHGCForAngle(ctx.SHGC, ctx.E_t_data.theta_degs)
        let solarAngles = {
          beta_rads: ctx.E_t_data.beta_rads,
          gamma_rads: ctx.E_t_data.gamma_rads,
          theta_degs: ctx.E_t_data.theta_degs,
        };
        if (optInShadingType !== null) {
          ctx.IAC_res = iacCalculator.computeIACForSolarPosition(ctx, solarAngles)
        } else {
          ctx.IAC_res = {iac: 1, iacDiff: 1}
        }
        let shadedAreas = this.calcWindowShadedAreas(windowOrDoor, solarAngles, hr)
        ctx.Window_mo_hr = ctx.eval(
          'N*(E_t*SHGC_angle*(A_clear_light + A_shade_light*IAC)' +
          ' + (E_t_d+E_t_r)*SHGC_D*(A_clear_shade+A_shade_shade*IAC_D))', {
            N: windowOrDoor.quantity.value,
            E_t: ctx.E_t_data.E_t,
            E_t_d: ctx.E_t_data.E_t_d,
            E_t_r: ctx.E_t_data.E_t_r,
            SHGC_D: ctx.SHGC.Diffuse,
            IAC: ctx.IAC_res.iac,
            IAC_D: ctx.IAC_res.iacDiff,
            A_clear_light: shadedAreas.A_clear_light,
            A_shade_light: shadedAreas.A_shade_light,
            A_clear_shade: shadedAreas.A_clear_shade,
            A_shade_shade: shadedAreas.A_shade_shade,
        }, 'Window_mo_hr')
        ctx.Window_loads.set([mo, hr], ctx.Window_mo_hr)
        ctx.endSection()
      }
      ctx.endSection()
    }

    let res = ctx.Window_loads
    ctx.logLoadMatrix('Solar loads', ctx.Window_loads)

    ctx.endSection()
    return res
  }

  async calcWindowSensibleLoads() {
    console.log("Calculating window sensible loads")
    let ctx = this.ctx;
    ctx.startSection("Windows")

    ctx.Windows_conv = math.zeros(12, 24)
    ctx.Windows_rad_nonsolar = math.zeros(12, 24)
    ctx.Windows_rad_solar = math.zeros(12, 24)

    let walls = this.space.walls;
    for (let wallIndex = 0; wallIndex < walls.length; ++wallIndex) {
      let wall = walls[wallIndex];
      for (let winIndex = 0; winIndex < wall.windows.length; ++winIndex) {
        let window = wall.windows[winIndex];
        ctx.startLocalSection(`Wall ${wallIndex} - Window ${winIndex}`)

        // Nonsolar
        let nonSolarLoadsRes = await this.calcWindowNonSolarLoad(wall, window);
        ctx.Window_conv = nonSolarLoadsRes.Window_nonsolar_conv;
        ctx.Window_rad_nonsolar = nonSolarLoadsRes.Window_nonsolar_rad;

        ctx.Windows_conv = math.add(ctx.Windows_conv, ctx.Window_conv)
        ctx.Windows_rad_nonsolar = math.add(ctx.Windows_rad_nonsolar, ctx.Window_rad_nonsolar)

        // Solar
        ctx.Window_rad_solar = await this.calcWindowSolarLoad(wall, window)
        ctx.Windows_rad_solar = math.add(ctx.Windows_rad_solar, ctx.Window_rad_solar)

        ctx.endSection()
      }
    }

    ctx.Windows_rad_nonsolar = this.applyRTSTransform(ctx.Windows_rad_nonsolar, RadiantLoadType.NonSolar)
    ctx.Windows_rad_solar = this.applyRTSTransform(ctx.Windows_rad_solar, RadiantLoadType.Solar)
    ctx.Windows_sensible = MatrixUtils.sumOfMatrices(
      [ctx.Windows_conv, ctx.Windows_rad_nonsolar, ctx.Windows_rad_solar])

    ctx.log("Results:")
    ctx.logLoadMatrix('Windows_conv', ctx.Windows_conv)
    ctx.logLoadMatrix('Windows_rad_nonsolar', ctx.Windows_rad_nonsolar)
    ctx.logLoadMatrix('Windows_rad_solar', ctx.Windows_rad_solar);
    ctx.logLoadMatrix('Windows_sensible', ctx.Windows_sensible)

    let res = {
      SensibleLoadsMatrix: ctx.Windows_sensible,
    }

    ctx.endSection()
    return res
  }

  async calcDoorNonSolarLoad(wall, door) {
    let ctx = this.ctx;
    ctx.startSection("Door Nonsolar Load")

    ctx.Door_loads_conv = math.zeros(12, 24)
    ctx.Door_loads_rad = math.zeros(12, 24)

    let doorType = door.getDoorType()
    let uValue = doorType.computeUValue()
    ctx.U_glass = uValue.uValueGlass
    ctx.U_opaq = uValue.uValueDoor
    ctx.A_glass = doorType.getGlassArea()
    ctx.A_opaq = doorType.getOpaqueArea()
    ctx.SHGC = doorType.computeShgc();
    ctx.doorAlpha = lookupData(DoorAlphaMap, [doorType.colour.value])
    for (let mo = 0; mo < 12; ++mo) {
      ctx.startLocalSection(`Month ${mo}`)
      for (let hr = 0; hr < 24; ++hr) {
        ctx.startLocalSection(`Hour ${hr}`)
        ctx.setProgressText(`Calculating door nonsolar loads - Month ${mo}, Hour ${hr}`)
        await ctx.briefWait()
        ctx.t_in = this.getSummerIndoorTemp(hr)
        ctx.t_out = this.getSummerOutdoorTemp(mo, hr)
        ctx.t_e = this.calc_t_e(wall, mo, hr, ctx.t_out, ctx.doorAlpha)
        ctx.E_t_data = this.calc_E_t(wall, mo, hr)

        ctx.F_conv_wall = 0.54
        ctx.Door_mo_hr_glass = ctx.eval('N*U_glass*A_glass*(t_out - t_in)', {
          N: door.quantity.value,
        }, 'Door_mo_hr_glass')
        ctx.Door_mo_hr_opaq = ctx.eval('N*U_opaq*A_opaq*(t_e - t_in)', {
          N: door.quantity.value,
        }, 'Door_mo_hr_opaq')

        // Note: the door does not have a separate SHGC for glass and frame.
        ctx.SHGC_angle = this.calcSHGCForAngle(ctx.SHGC, ctx.E_t_data.theta_degs)
        ctx.F_conv_glass = ctx.SHGC_angle > 0.5 ? 0.67 : 0.54;
        ctx.Door_conv_mo_hr = ctx.eval('Door_mo_hr_glass*F_conv_glass + Door_mo_hr_opaq*F_conv_wall', {
        }, 'Door_conv_mo_hr')
        ctx.Door_rad_nonsolar_mo_hr = ctx.eval('Door_mo_hr_glass*(1.0-F_conv_glass) + Door_mo_hr_opaq*(1.0-F_conv_wall)', {
        }, 'Door_rad_nonsolar_mo_hr')

        ctx.Door_loads_conv.set([mo, hr], ctx.Door_conv_mo_hr)
        ctx.Door_loads_rad.set([mo, hr], ctx.Door_rad_nonsolar_mo_hr)

        ctx.endSection()
      }
      ctx.endSection()
    }

    let res = {
      Door_nonsolar_conv: ctx.Door_loads_conv,
      Door_nonsolar_rad: ctx.Door_loads_rad,
    }
    ctx.logLoadMatrix('Nonsolar convective loads', ctx.Door_loads_conv)
    ctx.logLoadMatrix('Nonsolar radiative loads', ctx.Door_loads_rad)

    ctx.endSection()
    return res
  }

  async calcDoorSolarLoad(wall, door) {
    let ctx = this.ctx;
    ctx.startSection("Door Solar Load")

    let res = null
    let doorType = door.getDoorType()
    let percentGlass = doorType.getPercentGlass()
    if (percentGlass > 0) {
      res = await this.calcWindowSolarLoad(wall, door)
    } else {
      // No glass, skip calculations
      res = math.zeros(12, 24)
    }

    ctx.endSection()
    return res
  }

  async calcDoorSensibleLoads() {
    console.log("Calculating door sensible loads")
    let ctx = this.ctx;
    ctx.startSection("Doors")

    ctx.Doors_conv = math.zeros(12, 24)
    ctx.Doors_rad_nonsolar = math.zeros(12, 24)
    ctx.Doors_rad_solar = math.zeros(12, 24)

    let walls = this.space.walls;
    for (let wallIndex = 0; wallIndex < walls.length; ++wallIndex) {
      let wall = walls[wallIndex];
      for (let doorIndex = 0; doorIndex < wall.doors.length; ++doorIndex) {
        let door = wall.doors[doorIndex];
        ctx.startLocalSection(`Wall ${wallIndex} - Door ${doorIndex}`)

        // Nonsolar
        let nonSolarLoadsRes = await this.calcDoorNonSolarLoad(wall, door)
        ctx.Door_conv = nonSolarLoadsRes.Door_nonsolar_conv;
        ctx.Door_rad_nonsolar = nonSolarLoadsRes.Door_nonsolar_rad;

        ctx.Doors_conv = math.add(ctx.Doors_conv, ctx.Door_conv)
        ctx.Doors_rad_nonsolar = math.add(ctx.Doors_rad_nonsolar, ctx.Door_rad_nonsolar)

        // Solar
        ctx.Door_rad_solar = await this.calcDoorSolarLoad(wall, door)
        ctx.Doors_rad_solar = math.add(ctx.Doors_rad_solar, ctx.Door_rad_solar)

        ctx.endSection()
      }
    }

    ctx.Doors_rad_nonsolar = this.applyRTSTransform(ctx.Doors_rad_nonsolar, RadiantLoadType.NonSolar)
    ctx.Doors_rad_solar = this.applyRTSTransform(ctx.Doors_rad_solar, RadiantLoadType.Solar)
    ctx.Doors_sensible = MatrixUtils.sumOfMatrices([ctx.Doors_conv, ctx.Doors_rad_nonsolar, ctx.Doors_rad_solar])

    ctx.log("Results:")
    ctx.logLoadMatrix('Doors_conv', ctx.Doors_conv)
    ctx.logLoadMatrix('Doors_rad_nonsolar', ctx.Doors_rad_nonsolar)
    ctx.logLoadMatrix('Doors_rad_solar', ctx.Doors_rad_solar);
    ctx.logLoadMatrix('Doors_sensible', ctx.Doors_sensible)

    let res = {
      SensibleLoadsMatrix: ctx.Doors_sensible,
    }

    ctx.endSection()
    return res
  }

  async calcSkylightNonSolarLoad(roof, skylight) {
    let ctx = this.ctx;
    ctx.startSection("Skylight Nonsolar Load")

    ctx.Skylight_loads_conv = math.zeros(12, 24)
    ctx.Skylight_loads_rad = math.zeros(12, 24)

    let skylightType = skylight.getSkylightType()
    ctx.U = skylightType.computeUValue().uValue
    ctx.A = skylightType.getArea()
    ctx.SHGC = skylightType.computeShgc();
    for (let mo = 0; mo < 12; ++mo) {
      ctx.startLocalSection(`Month ${mo}`)
      for (let hr = 0; hr < 24; ++hr) {
        ctx.startLocalSection(`Hour ${hr}`)
        ctx.setProgressText(`Calculating skylight nonsolar loads - Month ${mo}, Hour ${hr}`)
        await ctx.briefWait()

        ctx.t_in = this.getSummerIndoorTemp(hr)
        ctx.t_out = this.getSummerOutdoorTemp(mo, hr)
        ctx.E_t_data = this.calc_E_t(roof, mo, hr)
        ctx.SHGC_angle = this.calcSHGCForAngle(ctx.SHGC, ctx.E_t_data.theta_degs)
        ctx.F_conv_window = ctx.SHGC_angle > 0.5 ? 0.67 : 0.54

        ctx.Skylight_conv_mo_hr = ctx.eval('N*U*A*(t_out - t_in)*F_conv_window', {
          N: skylight.quantity.value,
        }, 'Skylight_conv_mo_hr')
        ctx.Skylight_rad_mo_hr = ctx.eval('N*U*A*(t_out - t_in)*(1 - F_conv_window)', {
          N: skylight.quantity.value,
        }, 'Skylight_rad_mo_hr')

        ctx.Skylight_loads_conv.set([mo, hr], ctx.Skylight_conv_mo_hr)
        ctx.Skylight_loads_rad.set([mo, hr], ctx.Skylight_rad_mo_hr)
        ctx.endSection()
      }
      ctx.endSection()
    }

    let res = {
      Skylight_nonsolar_conv: ctx.Skylight_loads_conv,
      Skylight_nonsolar_rad: ctx.Skylight_loads_rad,
    };
    ctx.logLoadMatrix('Nonsolar convective loads', ctx.Skylight_loads_conv)
    ctx.logLoadMatrix('Nonsolar radiative loads', ctx.Skylight_loads_rad)

    ctx.endSection()
    return res
  }

  calcSHGCForAngle(shgcs, theta_degs) {
    /**
     * Given a theta for some mo, hr (computed from solar calculations), lerp to get
     * a SHGC value for that angle.
     */
    let ctx = this.ctx;
    ctx.startSection(`SHGC for time`)
    if (shgcs.isNA) {
      ctx.log("SHGC is NA. Returning 0")
      ctx.endSection()
      return 0;
    }
    ctx.log(`Lerping SHGC for angle ${theta_degs}`)
    ctx.assert(theta_degs >= 0 && theta_degs <= 360, `Invalid theta: ${theta_degs} (expected 0-360)`)
    if (theta_degs >= 90) {
      ctx.log("SHGC is 0 for theta >= 90")
      ctx.SHGC_angle = 0;
    } else {
      ctx.lerpMap = {
        0: shgcs.Deg0,
        40: shgcs.Deg40,
        50: shgcs.Deg50,
        60: shgcs.Deg60,
        70: shgcs.Deg70,
        80: shgcs.Deg80,
        90: 0.0,
      };
      ctx.SHGC_angle = interpolateInMap(ctx.lerpMap, theta_degs)
    }
    let res = ctx.SHGC_angle
    ctx.endSection()
    return res
  }

  async calcSkylightSolarLoad(roof, skylight) {
    let ctx = this.ctx;
    ctx.startSection("Skylight Solar Load")

    ctx.Skylight_loads = math.zeros(12, 24)
    let skylightType = skylight.getSkylightType()
    ctx.SHGC = skylightType.computeShgc()
    for (let mo = 0; mo < 12; ++mo) {
      ctx.startLocalSection(`Month ${mo}`)
      for (let hr = 0; hr < 24; ++hr) {
        ctx.startLocalSection(`Hour ${hr}`)
        ctx.setProgressText(`Calculating skylight solar loads - Month ${mo}, Hour ${hr}`)
        await ctx.briefWait()
        ctx.E_t_data = this.calc_E_t(roof, mo, hr)
        ctx.SHGC_angle = this.calcSHGCForAngle(ctx.SHGC, ctx.E_t_data.theta_degs)
        ctx.Skylight_mo_hr = ctx.eval('N*E_t*SHGC_angle', {
          N: skylight.quantity.value,
          E_t: ctx.E_t_data.E_t,
        }, 'Skylight_mo_hr')
        ctx.Skylight_loads.set([mo, hr], ctx.Skylight_mo_hr)
        ctx.endSection()
      }
      ctx.endSection()
    }

    let res = ctx.Skylight_loads
    ctx.logLoadMatrix('Solar loads', ctx.Skylight_loads)

    ctx.endSection()
    return res
  }

  async calcSkylightSensibleLoads() {
    console.log("Calculating skylight sensible loads")
    let ctx = this.ctx;
    ctx.startSection("Skylights")

    ctx.Skylights_conv = math.zeros(12, 24)
    ctx.Skylights_rad_nonsolar = math.zeros(12, 24)
    ctx.Skylights_rad_solar = math.zeros(12, 24)

    let roofs = this.space.roofs;
    for (let roofIndex = 0; roofIndex < roofs.length; ++roofIndex) {
      let roof = roofs[roofIndex];
      for (let skylightIndex = 0; skylightIndex < roof.skylights.length; ++skylightIndex) {
        let skylight = roof.skylights[skylightIndex];
        ctx.startLocalSection(`Roof ${roofIndex} - Skylight ${skylightIndex}`)

        // Nonsolar
        let nonSolarLoadsRes = await this.calcSkylightNonSolarLoad(roof, skylight)
        ctx.Skylight_conv = nonSolarLoadsRes.Skylight_nonsolar_conv;
        ctx.Skylight_rad_nonsolar = nonSolarLoadsRes.Skylight_nonsolar_rad;

        ctx.Skylights_conv = math.add(ctx.Skylights_conv, ctx.Skylight_conv)
        ctx.Skylights_rad_nonsolar = math.add(ctx.Skylights_rad_nonsolar, ctx.Skylight_rad_nonsolar)

        // Solar
        ctx.Skylight_rad_solar = await this.calcSkylightSolarLoad(roof, skylight)
        ctx.Skylights_rad_solar = math.add(ctx.Skylights_rad_solar, ctx.Skylight_rad_solar)

        ctx.endSection()
      }
    }

    ctx.Skylights_rad_nonsolar = this.applyRTSTransform(ctx.Skylights_rad_nonsolar, RadiantLoadType.NonSolar)
    ctx.Skylights_rad_solar = this.applyRTSTransform(ctx.Skylights_rad_solar, RadiantLoadType.Solar)
    ctx.Skylights_sensible = MatrixUtils.sumOfMatrices(
      [ctx.Skylights_conv, ctx.Skylights_rad_nonsolar, ctx.Skylights_rad_solar])

    ctx.log("Results:")
    ctx.logLoadMatrix('Skylights_conv', ctx.Skylights_conv)
    ctx.logLoadMatrix('Skylights_rad_nonsolar', ctx.Skylights_rad_nonsolar)
    ctx.logLoadMatrix('Skylights_rad_solar', ctx.Skylights_rad_solar);
    ctx.logLoadMatrix('Skylights_sensible', ctx.Skylights_sensible)

    let res = {
      SensibleLoadsMatrix: ctx.Skylights_sensible,
    }

    ctx.endSection()
    return res
  }

  async calcApplianceSensibleLoads() {
    let ctx = this.ctx;
    ctx.setProgressText("Calculating appliance sensible loads")
    ctx.startSection(`Appliance Sensible Loads`)

    let appliances = this.space.internals.appliances;

    ctx.A_conv_appliances = math.zeros(12, 24)
    ctx.A_rad_appliances = math.zeros(12, 24)

    ctx.A_conv_appliances_row = makeVector(24)
    ctx.A_rad_appliances_row = makeVector(24)

    for (let i = 0; i < appliances.appliances.length; ++i) {
      let appliance = appliances.appliances[i];
      ctx.startLocalSection(`Appliance ${i}`)
      let applianceData = appliance.getApplianceData(ctx)
      ctx.S_app = applianceData.S_app
      ctx.F_conv = applianceData.F_conv
      ctx.F_rad = applianceData.F_rad
      ctx.appSched = appliance.getSchedule().getData()
      ctx.D = appliance.getDiversityFactor()
      ctx.N = appliance.quantity.value
      for (let hr = 0; hr < 24; hr++) {
        ctx.startLocalSection(`Hour ${hr}`)
        ctx.conv_appliance = ctx.eval('N*S_app*sched*D*F_conv', {
          sched: ctx.appSched[hr],
        }, 'conv_appliance')
        ctx.rad_appliance = ctx.eval('N*S_app*sched*D*F_rad', {
          sched: ctx.appSched[hr],
        }, 'rad_appliance')

        ctx.A_conv_appliances_row[hr] += ctx.conv_appliance
        ctx.A_rad_appliances_row[hr] += ctx.rad_appliance

        ctx.endSection()
      }
      ctx.endSection()
    }

    for (let mo = 0; mo < 12; mo++) {
      MatrixUtils.setRow(ctx.A_conv_appliances, mo, ctx.A_conv_appliances_row)
      MatrixUtils.setRow(ctx.A_rad_appliances, mo, ctx.A_rad_appliances_row)
    } 

    ctx.A_rad_appliances = this.applyRTSTransform(ctx.A_rad_appliances, RadiantLoadType.NonSolar)
    ctx.A_sensible = MatrixUtils.sumOfMatrices([ctx.A_conv_appliances, ctx.A_rad_appliances])

    ctx.log("Results:")
    ctx.logLoadMatrix('A_conv_appliances', ctx.A_conv_appliances)
    ctx.logLoadMatrix('A_rad_appliances', ctx.A_rad_appliances)
    ctx.logLoadMatrix('A_sensible', ctx.A_sensible)

    let res = {
      SensibleLoadsMatrix: ctx.A_sensible,  
    }

    ctx.endSection()
    return res
  }

  async calcLightingSensibleLoads() {
    let ctx = this.ctx;
    ctx.setProgressText("Calculating lighting sensible loads")
    ctx.startSection(`Lighting Sensible Loads`)
    let lighting = this.space.internals.lighting;

    ctx.A_conv_lights = math.zeros(12, 24)
    ctx.A_rad_lights = math.zeros(12, 24)
    ctx.A_plenum_lights = math.zeros(12, 24)

    ctx.A_conv_lights_row = makeVector(24)
    ctx.A_rad_lights_row = makeVector(24)
    ctx.A_plenum_lights_row = makeVector(24)

    ctx.L = lighting.getPower()
    ctx.D = lighting.getDiversityFactor()
    ctx.luminaireType = lighting.getLuminaireType()
    let luminaireData = lighting.getLuminaireData(ctx)
    ctx.F_rad = luminaireData.F_rad
    ctx.F_space = luminaireData.F_space
    let sched = lighting.getSchedule().getData()
    for (let hr = 0; hr < 24; hr++) {
      ctx.startLocalSection(`Hour ${hr}`)
      ctx.conv_load = ctx.eval('L*sched*D*(1-F_rad)*F_space', {
        sched: sched[hr],
      }, 'conv_load')
      ctx.rad_load = ctx.eval('L*sched*D*F_rad*F_space', {
        sched: sched[hr],
      }, 'rad_load')
      ctx.plenum_load = ctx.eval('L*sched*D*(1-F_space)', {
        sched: sched[hr],
      }, 'plenum_load')
      ctx.A_conv_lights_row[hr] = ctx.conv_load
      ctx.A_rad_lights_row[hr] = ctx.rad_load
      ctx.A_plenum_lights_row[hr] = ctx.plenum_load
      ctx.endSection()
    }

    for (let mo = 0; mo < 12; mo++) {
      MatrixUtils.setRow(ctx.A_conv_lights, mo, ctx.A_conv_lights_row)
      MatrixUtils.setRow(ctx.A_rad_lights, mo, ctx.A_rad_lights_row)
      MatrixUtils.setRow(ctx.A_plenum_lights, mo, ctx.A_plenum_lights_row)
    }

    ctx.A_rad_lights = this.applyRTSTransform(ctx.A_rad_lights, RadiantLoadType.NonSolar)
    // Note: the plenum loads are kept separate from the sensible loads
    ctx.A_sensible = MatrixUtils.sumOfMatrices([ctx.A_conv_lights, ctx.A_rad_lights])

    ctx.log("Results:")
    ctx.logLoadMatrix('A_conv_lights', ctx.A_conv_lights)
    ctx.logLoadMatrix('A_rad_lights', ctx.A_rad_lights)
    ctx.logLoadMatrix('A_plenum_lights', ctx.A_plenum_lights)
    ctx.logLoadMatrix('A_sensible', ctx.A_sensible)

    let res = {
      SensibleLoadsMatrix: ctx.A_sensible,
      PlenumLoadsMatrix: ctx.A_plenum_lights,
    }

    ctx.endSection()
    return res
  }

  async calcMotorSensibleLoads() {
    let ctx = this.ctx;
    ctx.setProgressText("Calculating motor sensible loads")
    ctx.startSection(`Motor Sensible Loads`)

    let motorsInput = this.space.internals.motors;

    ctx.Motor_conv_row = makeVector(24)
    ctx.Motor_rad_row = makeVector(24)

    for (let i = 0; i < motorsInput.motors.length; ++i) {
      let motor = motorsInput.motors[i];
      ctx.startLocalSection(`Motor ${i}`)
      ctx.SensibleLoad = motor.calcSensibleLoad(ctx)
      ctx.F_rad = motor.getRadiantFraction()
      ctx.D = motor.getDiversityFactor()
      let sched = motor.getSchedule().getData()
      for (let hr = 0; hr < 24; hr++) {
        ctx.startLocalSection(`Hour ${hr}`)
        ctx.Motor_load = ctx.eval('SensibleLoad*sched*D', {
          sched: sched[hr],
        }, 'Motor_load')
        ctx.conv_load = ctx.eval('Motor_load*(1.0 - F_rad)', {
        }, 'conv_load')
        ctx.rad_load = ctx.eval('Motor_load*F_rad', {
        }, 'rad_load')
        ctx.Motor_conv_row[hr] = ctx.conv_load
        ctx.Motor_rad_row[hr] = ctx.rad_load
        ctx.endSection()
      }
      ctx.endSection()
    }

    ctx.Motor_conv = math.zeros(12, 24)
    ctx.Motor_rad = math.zeros(12, 24)
    for (let mo = 0; mo < 12; mo++) {
      MatrixUtils.setRow(ctx.Motor_conv, mo, ctx.Motor_conv_row)
      MatrixUtils.setRow(ctx.Motor_rad, mo, ctx.Motor_rad_row)
    }

    ctx.Motor_rad = this.applyRTSTransform(ctx.Motor_rad, RadiantLoadType.NonSolar)
    ctx.Motor_sensible = MatrixUtils.sumOfMatrices([ctx.Motor_conv, ctx.Motor_rad])

    ctx.log("Results:")
    ctx.logLoadMatrix('Motor_conv', ctx.Motor_conv)
    ctx.logLoadMatrix('Motor_rad', ctx.Motor_rad)
    ctx.logLoadMatrix('Motor_sensible', ctx.Motor_sensible)

    let res = {
      SensibleLoadsMatrix: ctx.Motor_sensible,
    }

    ctx.endSection()
    return res
  }

  async calcPeopleSensibleLoads() {
    let ctx = this.ctx;
    ctx.setProgressText("Calculating people sensible loads")
    ctx.startSection(`People Sensible Loads`)
    let people = this.space.internals.people;

    ctx.A_conv_people_row = makeVector(24)
    ctx.A_rad_people_row = makeVector(24)

    ctx.M = people.getNumOccupants().result
    ctx.activityLevel = people.getActivityLevel()
    let activityLevelData = people.getActivityLevelData(ctx)
    ctx.S_P = activityLevelData.S_P
    ctx.F_rad = activityLevelData.F_rad
    let sched = people.getSchedule().getData()
    ctx.D = people.getDiversityFactor()
    for (let hr = 0; hr < 24; hr++) {
      ctx.startLocalSection(`Hour ${hr}`)
      ctx.conv_load = ctx.eval('S_P*sched*M*(1.0 - F_rad)*D', {
        sched: sched[hr],
      }, 'conv_load')
      ctx.rad_load = ctx.eval('S_P*sched*M*F_rad*D', {
        sched: sched[hr],
      }, 'rad_load')
      ctx.A_conv_people_row[hr] = ctx.conv_load
      ctx.A_rad_people_row[hr] = ctx.rad_load
      ctx.endSection()
    }

    ctx.A_conv_people = math.zeros(12, 24)
    ctx.A_rad_people = math.zeros(12, 24)
    for (let mo = 0; mo < 12; mo++) {
      MatrixUtils.setRow(ctx.A_conv_people, mo, ctx.A_conv_people_row)
      MatrixUtils.setRow(ctx.A_rad_people, mo, ctx.A_rad_people_row)
    }

    ctx.A_rad_people = this.applyRTSTransform(ctx.A_rad_people, RadiantLoadType.NonSolar)
    ctx.A_sensible = MatrixUtils.sumOfMatrices([ctx.A_conv_people, ctx.A_rad_people])

    ctx.log("Results:")
    ctx.logLoadMatrix('A_conv_people', ctx.A_conv_people)
    ctx.logLoadMatrix('A_rad_people', ctx.A_rad_people)
    ctx.logLoadMatrix('A_sensible', ctx.A_sensible)

    let res = {
      SensibleLoadsMatrix: ctx.A_sensible,
    }

    ctx.endSection()
    return res
  }

  async calcMiscSensibleLoads() {
    let ctx = this.ctx;
    ctx.setProgressText("Calculating miscellaneous sensible loads")
    ctx.startSection(`Miscellaneous Sensible Loads`)

    let miscLoads = this.space.internals.miscLoads;

    let M_sens_rad = math.zeros(12, 24)
    let M_sens_conv = math.zeros(12, 24)

    let M_sens_rad_row = makeVector(24)
    let M_sens_conv_row = makeVector(24)

    ctx.M_sens = miscLoads.getSensibleLoad(ctx)
    ctx.F_rad = miscLoads.getSensibleLoadRadiantFraction()
    let sched = miscLoads.getSchedule().getData()
    ctx.D = miscLoads.getDiversityFactor()
    for (let hr = 0; hr < 24; hr++) {
      ctx.startLocalSection(`Hour ${hr}`)
      ctx.sens_rad = ctx.eval('M_sens*F_rad*sched*D', {
        sched: sched[hr],
      }, 'sens_rad')
      ctx.sens_conv = ctx.eval('M_sens*(1 - F_rad)*sched*D', {
        sched: sched[hr],
      }, 'sens_conv')
      M_sens_rad_row[hr] = ctx.sens_rad
      M_sens_conv_row[hr] = ctx.sens_conv
      ctx.endSection()
    }

    for (let mo = 0; mo < 12; mo++) {
      MatrixUtils.setRow(M_sens_rad, mo, M_sens_rad_row)
      MatrixUtils.setRow(M_sens_conv, mo, M_sens_conv_row)
    }

    ctx.M_sens_rad = this.applyRTSTransform(M_sens_rad, RadiantLoadType.NonSolar)
    ctx.M_sensible = MatrixUtils.sumOfMatrices([M_sens_rad, M_sens_conv])

    ctx.log("Results:")
    ctx.logLoadMatrix('M_sens_rad', M_sens_rad)
    ctx.logLoadMatrix('M_sens_conv', M_sens_conv)
    ctx.logLoadMatrix('M_sensible', ctx.M_sensible)

    let res = {
      SensibleLoadsMatrix: ctx.M_sensible,
    }

    ctx.endSection()
    return res
  }

  async calcInfiltrationSensibleLoads() {
    let ctx = this.ctx;
    ctx.setProgressText("Calculating infiltration sensible loads")
    ctx.startSection(`Infiltration Sensible Loads`)

    ctx.A_sens_inf = math.zeros(12, 24)

    let onlyDuringOccupiedHours = this.space.infiltrationHours.value == InfiltrationHours.OccupiedHours;
    ctx.log(`Only during occupied hours: ${onlyDuringOccupiedHours}`)
    if (onlyDuringOccupiedHours) {
      ctx.occupancySchedule = this.space.internals.people.getSchedule().getData();
    } else {
      ctx.occupancySchedule = makeVector(24).fill(1.0);
    }
    ctx.C_s = ctx.call(adjustForAltitude, 1.08, ctx.P_loc)
    for (let mo = 0; mo < 12; mo++) {
      ctx.startLocalSection(`Month ${mo}`)
      ctx.Q_inf = this.space.calcInfiltrationFlowRate(ctx, getSeasonOfMonth(mo)).Q_inf;
      for (let hr = 0; hr < 24; hr++) {
        ctx.startLocalSection(`Hour ${hr}`)
        ctx.t_in = this.getSummerIndoorTemp(hr)
        ctx.t_out = this.getSummerOutdoorTemp(mo, hr)
        ctx.sched = ctx.occupancySchedule[hr] > 0 ? 1 : 0
        ctx.sens_inf = ctx.eval('Q_inf*sched*C_s*(t_out - t_in)', {
        }, 'sens_inf')
        ctx.A_sens_inf.set([mo, hr], ctx.sens_inf)
        ctx.endSection()
      }
      ctx.endSection()
    }

    // Note - do not apply RTS transform here. Loads are purely convective.
    ctx.log("Results:")
    ctx.logLoadMatrix('A_sens_inf', ctx.A_sens_inf)

    let res = {
      SensibleLoadsMatrix: ctx.A_sens_inf,
    }

    ctx.endSection()
    return res
  }

  async calcPartitionNonSolarLoads(partition) {
    let ctx = this.ctx;
    ctx.startSection("Partition Nonsolar Loads")

    ctx.Partition_nonsolar_conv = math.zeros(12, 24)
    ctx.Partition_nonsolar_rad = math.zeros(12, 24)

    for (let mo = 0; mo < 12; ++mo) {
      ctx.startLocalSection(`Month ${mo}`)
      for (let hr = 0; hr < 24; ++hr) {
        ctx.startLocalSection(`Hour ${hr}`)
        ctx.setProgressText(`Calculating partition nonsolar loads - Month ${mo}, Hour ${hr}`)
        await ctx.briefWait()

        ctx.log("Using F_conv for walls")
        ctx.F_conv = 0.54
        ctx.t_i = this.getSummerIndoorTemp(hr)
        ctx.t_o = this.getSummerOutdoorTemp(mo, hr)
        ctx.Partition_load_mo_hr = partition._calcLoads(ctx, false, {t_i: ctx.t_i, t_o: ctx.t_o})
        ctx.nonsolar_conv_mo_hr = ctx.eval('Partition_load_mo_hr*F_conv', {
        }, 'nonsolar_conv_mo_hr')
        ctx.nonsolar_rad_mo_hr = ctx.eval('Partition_load_mo_hr*(1.0 - F_conv)', {
        }, 'nonsolar_rad_mo_hr')
        ctx.Partition_nonsolar_conv.set([mo, hr], ctx.nonsolar_conv_mo_hr)
        ctx.Partition_nonsolar_rad.set([mo, hr], ctx.nonsolar_rad_mo_hr)

        ctx.endSection()
      }
      ctx.endSection()
    }

    ctx.logLoadMatrix('Partition nonsolar convective loads', ctx.Partition_nonsolar_conv)
    ctx.logLoadMatrix('Partition nonsolar radiative loads', ctx.Partition_nonsolar_rad)

    let res = {
      Partition_nonsolar_conv: ctx.Partition_nonsolar_conv,
      Partition_nonsolar_rad: ctx.Partition_nonsolar_rad,
    }
    ctx.endSection()
    return res
  }

  async calcPartitionSensibleLoads() {
    let ctx = this.ctx;
    ctx.setProgressText("Calculating partition sensible loads")
    ctx.startSection(`Partition Sensible Loads`)

    ctx.Partition_nonsolar_conv = math.zeros(12, 24)
    ctx.Partition_nonsolar_rad = math.zeros(12, 24)

    let partitions = this.space.partitions;
    for (let i = 0; i < partitions.length; ++i) {
      let partition = partitions[i];
      ctx.startLocalSection(`Partition ${i}`)
      let wallTypeName = partition.getWallType().name.value;
      let bufferSpaceTypeName = partition.getBufferSpaceType().name.value;
      ctx.log(`Wall type: ${wallTypeName}, Buffer space type: ${bufferSpaceTypeName}`)

      let nonSolarLoadsRes = await this.calcPartitionNonSolarLoads(partition)
      ctx.Partition_conv = nonSolarLoadsRes.Partition_nonsolar_conv;
      ctx.Partition_rad = nonSolarLoadsRes.Partition_nonsolar_rad;

      ctx.Partition_nonsolar_conv = math.add(ctx.Partition_nonsolar_conv, ctx.Partition_conv)
      ctx.Partition_nonsolar_rad = math.add(ctx.Partition_nonsolar_rad, ctx.Partition_rad)

      ctx.endSection()
    }

    ctx.Partition_nonsolar_rad = this.applyRTSTransform(ctx.Partition_nonsolar_rad, RadiantLoadType.NonSolar)
    ctx.Partition_sensible = MatrixUtils.sumOfMatrices([ctx.Partition_nonsolar_conv, ctx.Partition_nonsolar_rad])

    ctx.logLoadMatrix('Partition nonsolar convective loads', ctx.Partition_nonsolar_conv)
    ctx.logLoadMatrix('Partition nonsolar radiative loads', ctx.Partition_nonsolar_rad)
    ctx.logLoadMatrix('Partition sensible loads', ctx.Partition_sensible)

    let res = {
      SensibleLoadsMatrix: ctx.Partition_sensible,
    }

    ctx.endSection()
    return res
  }

  calcFloorLoad(floor, mo, hr) {
    let ctx = this.ctx;
    ctx.startSection("Floor load")
    ctx.t_i = this.getSummerIndoorTemp(hr)
    ctx.t_o = this.getSummerOutdoorTemp(mo, hr)
    // Calculations are already handled by the Floor class
    ctx.Floor_load_mo_hr = floor._calcLoads(ctx, false, {
      t_i: ctx.t_i, t_o: ctx.t_o
    }).q;
    let res = ctx.Floor_load_mo_hr
    ctx.endSection()
    return res
  }

  async calcFloorNonSolarLoads(floor) {
    let ctx = this.ctx;
    ctx.startSection("Floor Nonsolar Loads")

    ctx.Floor_nonsolar_conv = math.zeros(12, 24)
    ctx.Floor_nonsolar_rad = math.zeros(12, 24)

    for (let mo = 0; mo < 12; ++mo) {
      ctx.startLocalSection(`Month ${mo}`)
      for (let hr = 0; hr < 24; ++hr) {
        ctx.startLocalSection(`Hour ${hr}`)
        ctx.setProgressText(`Calculating floor nonsolar loads - Month ${mo}, Hour ${hr}`)
        await ctx.briefWait()

        ctx.log("Using F_conv for walls")
        ctx.F_conv = 0.54
        ctx.Floor_load_mo_hr = this.calcFloorLoad(floor, mo, hr)

        ctx.nonsolar_conv_mo_hr = ctx.eval('Floor_load_mo_hr*F_conv', {
        }, 'nonsolar_conv_mo_hr')
        ctx.nonsolar_rad_mo_hr = ctx.eval('Floor_load_mo_hr*(1.0 - F_conv)', {
        }, 'nonsolar_rad_mo_hr')
        ctx.Floor_nonsolar_conv.set([mo, hr], ctx.nonsolar_conv_mo_hr)
        ctx.Floor_nonsolar_rad.set([mo, hr], ctx.nonsolar_rad_mo_hr)

        ctx.endSection()
      }
      ctx.endSection()
    }

    ctx.logLoadMatrix('Floor nonsolar convective loads', ctx.Floor_nonsolar_conv)
    ctx.logLoadMatrix('Floor nonsolar radiative loads', ctx.Floor_nonsolar_rad)

    let res = {
      Floor_nonsolar_conv: ctx.Floor_nonsolar_conv,
      Floor_nonsolar_rad: ctx.Floor_nonsolar_rad,
    }
    ctx.endSection()
    return res
  }

  async calcFloorSensibleLoads() {
    let ctx = this.ctx;
    ctx.setProgressText("Calculating floor sensible loads")
    ctx.startSection(`Floor Sensible Loads`)

    ctx.Floor_nonSolar_conv = math.zeros(12, 24)
    ctx.Floor_nonSolar_rad = math.zeros(12, 24)

    let floors = this.space.floors;
    for (let i = 0; i < floors.length; ++i) {
      let floor = floors[i];
      let floorType = floor.floorType.value;
      ctx.startLocalSection(`Floor ${i} - ${floorType}`)
      if (!(floorType == FloorType.FloorAboveCrawlSpace ||
        floorType == FloorType.FloorRaisedOffGround)) {
        ctx.log(`Skipping floor - type ${floorType} does not have cooling loads.`)
        ctx.endSection();
        continue;
      }

      let nonSolarLoadsRes = await this.calcFloorNonSolarLoads(floor)
      ctx.Floor_conv = nonSolarLoadsRes.Floor_nonsolar_conv;
      ctx.Floor_rad = nonSolarLoadsRes.Floor_nonsolar_rad;

      ctx.Floor_nonSolar_conv = math.add(ctx.Floor_nonSolar_conv, ctx.Floor_conv)
      ctx.Floor_nonSolar_rad = math.add(ctx.Floor_nonSolar_rad, ctx.Floor_rad)

      ctx.endSection()
    }

    ctx.Floor_nonSolar_rad = this.applyRTSTransform(ctx.Floor_nonSolar_rad, RadiantLoadType.NonSolar)
    ctx.Floor_sensible = MatrixUtils.sumOfMatrices([ctx.Floor_nonSolar_conv, ctx.Floor_nonSolar_rad])

    ctx.logLoadMatrix('Floor nonsolar convective loads', ctx.Floor_nonSolar_conv)
    ctx.logLoadMatrix('Floor nonsolar radiative loads', ctx.Floor_nonSolar_rad)
    ctx.logLoadMatrix('Floor sensible loads', ctx.Floor_sensible)

    let res = {
      SensibleLoadsMatrix: ctx.Floor_sensible,
    }

    ctx.endSection()
    return res
  }

  async calcLatentLoads() {
    console.log("Calculating latent loads")
    let ctx = this.ctx;
    ctx.startSection("Latent Loads")
    ctx.startProgressSection("Latent loads", {
      "Infiltration": {},
      "Appliances": {},
      "People": {},
      "Misc": {},
    })
    ctx.setProgress("Infiltration")
    let infiltrationLoads = await this.calcInfiltrationLatentLoads()
    await ctx.briefWait()
    ctx.setProgress("Appliances")
    let applianceLoads = await this.calcApplianceLatentLoads()
    await ctx.briefWait()
    ctx.setProgress("People")
    let peopleLoads = await this.calcPeopleLatentLoads()
    await ctx.briefWait()
    ctx.setProgress("Misc")
    let miscLoads = await this.calcMiscLatentLoads()
    await ctx.briefWait()

    let totalLoads = MatrixUtils.sumOfMatrices([
      infiltrationLoads.LatentLoadsMatrix,
      applianceLoads.LatentLoadsMatrix,
      peopleLoads.LatentLoadsMatrix,
      miscLoads.LatentLoadsMatrix,
    ])
    ctx.logLoadMatrix('Total latent loads', totalLoads)

    let res = {
      totalLoads: totalLoads,

      infiltrationLoads: infiltrationLoads,
      applianceLoads: applianceLoads,
      peopleLoads: peopleLoads,
      miscLoads: miscLoads,
    }

    ctx.endProgressSection();
    ctx.endSection()
    return res
  }

  async calcSensibleLoads() {
    let ctx = this.ctx;
    ctx.startSection("Sensible Loads")
    ctx.startProgressSection("Sensible loads", {
      "Walls": {},
      "Roofs": {},
      "Windows": {},
      "Doors": {},
      "Skylights": {},
      "Partitions": {},
      "Floors": {},
      "Appliances": {},
      "Lighting": {},
      "Motors": {},
      "People": {},
      "Misc": {},
      "Infiltration": {},
    })
    ctx.setProgress("Walls")
    let wallLoads = await this.calcWallSensibleLoads(true)
    await ctx.briefWait()
    ctx.setProgress("Roofs")  
    let roofLoads = await this.calcWallSensibleLoads(false)
    await ctx.briefWait()
    ctx.setProgress("Windows")
    let windowLoads = await this.calcWindowSensibleLoads()
    await ctx.briefWait()
    ctx.setProgress("Doors")
    let doorLoads = await this.calcDoorSensibleLoads()
    await ctx.briefWait()
    ctx.setProgress("Skylights")
    let skylightLoads = await this.calcSkylightSensibleLoads()
    await ctx.briefWait()
    ctx.setProgress("Partitions")
    let partitionLoads = await this.calcPartitionSensibleLoads()
    await ctx.briefWait()
    ctx.setProgress("Floors")
    let floorLoads = await this.calcFloorSensibleLoads();
    await ctx.briefWait()

    ctx.setProgress("Appliances")
    let applianceLoads = await this.calcApplianceSensibleLoads()
    await ctx.briefWait()
    ctx.setProgress("Lighting")
    let lightingLoads = await this.calcLightingSensibleLoads()
    await ctx.briefWait()
    ctx.setProgress("Motors")
    let motorLoads = await this.calcMotorSensibleLoads()
    await ctx.briefWait()
    ctx.setProgress("People")
    let peopleLoads = await this.calcPeopleSensibleLoads()
    await ctx.briefWait()
    ctx.setProgress("Misc")
    let miscLoads = await this.calcMiscSensibleLoads()
    await ctx.briefWait()
    ctx.setProgress("Infiltration")
    let infiltrationLoads = await this.calcInfiltrationSensibleLoads()
    await ctx.briefWait()

    let totalLoads = MatrixUtils.sumOfMatrices([
      wallLoads.SensibleLoadsMatrix,
      roofLoads.SensibleLoadsMatrix,
      windowLoads.SensibleLoadsMatrix,
      doorLoads.SensibleLoadsMatrix,
      skylightLoads.SensibleLoadsMatrix,
      partitionLoads.SensibleLoadsMatrix,
      floorLoads.SensibleLoadsMatrix,
      applianceLoads.SensibleLoadsMatrix,
      lightingLoads.SensibleLoadsMatrix,
      motorLoads.SensibleLoadsMatrix,
      peopleLoads.SensibleLoadsMatrix,
      miscLoads.SensibleLoadsMatrix,
      infiltrationLoads.SensibleLoadsMatrix,
    ])
    ctx.logLoadMatrix('Total sensible loads', totalLoads)

    // Note: the plenum loads are kept separate from the rest of
    // the sensible loads
    let totalPlenumLoads = MatrixUtils.sumOfMatrices([
      wallLoads.PlenumLoadsMatrix,
      roofLoads.PlenumLoadsMatrix,
      lightingLoads.PlenumLoadsMatrix,
    ])
    ctx.logLoadMatrix('Total plenum loads', totalPlenumLoads)

    let res = {
      totalLoads: totalLoads,
      totalPlenumLoads: totalPlenumLoads,

      wallLoads: wallLoads,
      roofLoads: roofLoads,
      windowLoads: windowLoads,
      doorLoads: doorLoads,
      skylightLoads: skylightLoads,
      partitionLoads: partitionLoads,
      floorLoads: floorLoads,
      applianceLoads: applianceLoads,
      lightingLoads: lightingLoads,
      motorLoads: motorLoads,
      peopleLoads: peopleLoads,
      miscLoads: miscLoads,
      infiltrationLoads: infiltrationLoads,
    }

    ctx.endProgressSection()
    ctx.endSection()
    return res
  }

  _getLoadResultsNodeForMonth(loadResults, monthIndex) {
    let hourLabels = Array.from({length: 24}, (_, i) => getHourString(i));
    let values = []
    for (let hr = 0; hr < 24; ++hr) {
      let value = loadResults.q_total.get([monthIndex, hr])
      values.push(Math.round(value))
    }
    let byHourChartData = {
      title: `Hourly Cooling Loads - ${getShortMonthName(monthIndex)} ${loadResults.Inputs.dayOfMonth}`,
      units: Units.Load,
      type: 'Line',
      labelX: 'Hour',
      labelY: `Load (${getLabel(Units.Load)})`,
      labels: hourLabels,
      values: values,
      maxWidth: 800,
      height: 500,
      highlightPeak: true,
    }
    let hourGraphNode = new LineChartNode(byHourChartData)
    hourGraphNode.data.helpText = `This chart shows the cooling loads for each hour of the test day for the given month.`
    return hourGraphNode
  }

  _getLoadResultsNodeForHour(loadResults, monthIndex, hourIndex) {
    let sensibleLoadKeys = {
      'wallLoads': {name: 'Walls'},
      'roofLoads': {name: 'Roofs'},
      'windowLoads': {name: 'Windows'},
      'doorLoads': {name: 'Doors'},
      'skylightLoads': {name: 'Skylights'},
      'partitionLoads': {name: 'Partitions'},
      'floorLoads': {name: 'Floors'},
      'applianceLoads': {name: 'Appliances'},
      'lightingLoads': {name: 'Lighting'},
      'motorLoads': {name: 'Motors'},
      'peopleLoads': {name: 'People'},
      'miscLoads': {name: 'Misc'},
      'infiltrationLoads': {name: 'Infiltration'},
    }
    let latentLoadKeys = {
      'infiltrationLoads': {name: 'Infiltration'},
      'applianceLoads': {name: 'Appliances'},
      'peopleLoads': {name: 'People'},
      'miscLoads': {name: 'Misc'},
    }
    let sensibleLoadItems = {}
    for (const key in sensibleLoadKeys) {
      let item = loadResults.SensibleLoads[key]
      sensibleLoadItems[key] = {
        label: sensibleLoadKeys[key].name,
        value: Math.round(item.SensibleLoadsMatrix.get([monthIndex, hourIndex])),
      }
    }
    let latentLoadItems = {}
    for (const key in latentLoadKeys) {
      let item = loadResults.LatentLoads[key]
      latentLoadItems[key] = {
        label: latentLoadKeys[key].name,
        value: Math.round(item.LatentLoadsMatrix.get([monthIndex, hourIndex])),
      }
    }

    let title = `Cooling Loads - ${getShortMonthName(monthIndex)} ${loadResults.Inputs.dayOfMonth}, ${getHourString(hourIndex)}`
    let sunburstData = {
      title: title,
      units: Units.Load,
      includeTable: true,
      data: {
        totalSensibleLoad: {
          label: 'Sensible Loads',
          value: Math.round(loadResults.q_sensible.get([monthIndex, hourIndex])),
          children: sensibleLoadItems,
        },
        totalLatentLoad: {
          label: 'Latent Loads',
          value: Math.round(loadResults.q_latent.get([monthIndex, hourIndex])),
          children: latentLoadItems,
        },
      }
    }
    let sunburstNode = new SunburstNode(sunburstData)
    sunburstNode.data.helpText = `This chart shows the breakdown of the cooling loads during the given hour.`;
    return sunburstNode
  }

  _addResultsNode(loadResults) {
    let rootNode = new ResultsNode(ResultsNodeType.VerticalSplit, 'Space Cooling');
    rootNode.title = "Cooling Loads"
    rootNode.helpText = `This section shows the cooling loads for your space. Cooling loads are calculated ` +
      `for one day of each month of the year (typically the 21st). We provide extra detail for the month and hour that experience the highest load.`

    let monthLabels = Array.from({length: 12}, (_, i) => getShortMonthName(i));
    let peakValues = []
    for (let monthIndex = 0; monthIndex < 12; ++monthIndex) {
      let peakValue = findMaxLoadForMonth(loadResults.q_total, monthIndex)
      peakValues.push(Math.round(peakValue.value))
    }
    //console.log("PEAK VALUES: ", peakValues)
    let monthChartData = {
      title: 'Peak Cooling Loads',
      units: Units.Load,
      type: 'Bar',
      labelX: 'Month',
      labelY: `Peak Load (${getLabel(Units.Load)})`,
      labels: monthLabels,
      values: peakValues,
      maxWidth: 800,
      height: 500,
      highlightPeak: true,
    }
    let monthGraphNode = new LineChartNode(monthChartData)
    monthGraphNode.data.helpText = `This chart shows the peak cooling load for each month. The peak load is the ` +
      `maximum cooling load of any hour on the test day for that month (typically the 21st).`
    rootNode.children.push(monthGraphNode)

    let peakValue = findMaxLoadInMatrix(loadResults.q_total)
    let peakTime = peakValue.time

    let sampleMonthNode = this._getLoadResultsNodeForMonth(loadResults, peakTime[0])
    rootNode.children.push(sampleMonthNode)

    let sampleHourNode = this._getLoadResultsNodeForHour(loadResults, peakTime[0], peakTime[1])
    rootNode.children.push(sampleHourNode)

    loadResults.resultsNode = rootNode;
  }

  async calcOutputs() {
    let ctx = this.ctx;
    ctx.startSection('Space Cooling')
    ctx.startProgressSection("Space cooling", {
      "Latent loads": {},
      "Sensible loads": {},
    })

    let latentLoadsRes = null
    if (valOr(ctx.debugOptions.Calc_latent_loads, true)) {
      ctx.setProgress("Latent loads")
      latentLoadsRes = await this.calcLatentLoads()
    }
    let sensibleLoadsRes = null
    if (valOr(ctx.debugOptions.Calc_sensible_loads, true)) {
      ctx.setProgress("Sensible loads")
      sensibleLoadsRes = await this.calcSensibleLoads()
    }

    if (!latentLoadsRes || !sensibleLoadsRes) {
      throw new Error("Aborting early - latent or sensible loads not calculated (debug mode)")
    }

    let inputs = {
      dayOfMonth: ctx.toplevelData.dayOfMonth,
    }
    let res = {
      q_sensible: sensibleLoadsRes.totalLoads,
      q_latent: latentLoadsRes.totalLoads,
      q_total: math.add(sensibleLoadsRes.totalLoads, latentLoadsRes.totalLoads),
      q_plenum: sensibleLoadsRes.totalPlenumLoads,

      SensibleLoads: sensibleLoadsRes,
      LatentLoads: latentLoadsRes,

      Inputs: inputs,
    }
    this._addResultsNode(res);

    ctx.endProgressSection()
    ctx.endSection()
    return res
  }
};
