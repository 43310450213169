<script setup>
import { ref } from 'vue';

const props = defineProps({
  selected: {
    type: Boolean,
    default: false,
  },
});
let emits = defineEmits(['onSelect']);

function select() {
  if (props.selected) {
    return;
  }
  emits('onSelect');
}

</script>

<template>
  <div class="radio-button" :class="{ 'is-on': selected }" @click="select">
    <input type="radio" :checked="selected" />
    <!--<label></label>-->
  </div>
</template>

<style scoped>
.radio-button {
  display: inline;
  cursor: pointer;
}

.radio-button input {
  cursor: pointer;
}

/*
.radio-button label {
  margin-left: 8px;
}
.radio-button.is-on label {
  color: green;
}
.radio-button:not(.is-on) label {
  color: red;
}
*/
</style>