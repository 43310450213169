import { ref, reactive } from 'vue'
import { valOr, prettyJson, } from '../SharedUtils.js'

/*
Serializer Utils
*/

function hasProp(obj, propName) {
  // return obj !== null && typeof obj == 'object' && obj.hasOwnProperty(propName);
  let hasProp = obj !== null && (typeof obj == 'object') && (propName in obj);
  // console.log(`hasProp ${propName}: ${hasProp}`)
  return hasProp;
}

export function staticField(name, value) {
  return {name: name, type: 'Static', value: value}
}

export function arrayField(name, elemCtor) {
  return {name: name, type: 'ObjArray', elemCtor: elemCtor}
}

export function genericObjectField(name, typeList) {
  return {name: name, type: 'GenericObject', typeList: typeList}  
}

export function dateField(name) {
  return {name: name, type: 'Date'}
}

/*
let kTypeMap = {
  'Date': {
    writeToJson: (obj) => {
      return obj.toJSON();
    },
    createFromJson: (data) => {
      return new Date(data);
    }
  }
}
*/

function logIf(condition, ...args) {
  if (condition) {
    console.log(...args);
  }
}

function addPath(opts, fieldName) {
  let newOpts = {...opts};
  newOpts.path = newOpts.path + `/${fieldName}`;
  return newOpts;
}

export function writeToJson(obj, opts) {
  opts = valOr(opts, {})
  opts.path = opts.path || 'root';
  logIf(opts.debug, `Writing ${opts.path}`);
  let result = null;
  if (obj === undefined) {
    throw new Error(`writeToJson failed: obj is undefined (path: ${opts.path})`);
  }
  if (hasProp(obj, 'writeToJson')) {
    logIf(obj.debug, "Using 'writeToJson' func")
    result = obj.writeToJson(opts);
  } else if (hasProp(obj, 'serFields')) {
    logIf(obj.debug, "Using 'serFields'")
    let data = {};
    for (const field of obj.serFields) {
      if (typeof field == 'string') {
        data[field] = writeToJson(obj[field], addPath(opts, field));
      } else {
        if (field.type == 'Static') {
          data[field.name] = field.value;
        } else if (field.type == "ObjArray") {
          let arr = [];
          for (let i = 0; i < obj[field.name].length; ++i) {
            let elem = obj[field.name][i];
            let elemData = writeToJson(elem, addPath(opts, `${field.name}[${i}]`));
            arr.push(elemData);
          }
          data[field.name] = arr;
        } else if (field.type == 'GenericObject') {
          let newOpts = addPath(opts, field.name);
          newOpts.typeList = field.typeList;
          data[field.name] = writeGenericObjectToJson(obj[field.name], newOpts);
        } else if (field.type == "Date") {
          logIf(opts.debug, `Writing '${opts.path + '/' + field.name}' (Date)`)
          data[field.name] = obj[field.name].toJSON();
        } else {
          throw new Error("Invalid serField: ", prettyJson(field));
        }
      }
    }
    result = data;
  } else {
    // logIf(opts.debug, "Using value");
    /*
    if (obj.constructor.name in kTypeMap) {
      console.log(`Using custom writer for ${obj.constructor.name}`);
      return kTypeMap[typeof obj].writeToJson();
    } else {
      return obj;
    }
    */
    result = obj;
  }
  // Optional hook for post-write processing
  if (hasProp(obj, 'postWriteToJson')) {
    obj.postWriteToJson(result, opts);
  }
  return result;
}

export function readFromJson(targetObj, data, opts) {
  opts = valOr(opts, {})
  let debug = false;
  opts.path = opts.path || 'root';
  logIf(debug, `${opts.path}`);
  if (hasProp(targetObj, 'serUtilPreTransformJson')) {
    data = targetObj.serUtilPreTransformJson(data, opts);
  }
  if (hasProp(targetObj, 'readFromJson')) {
    logIf(debug, `Using readFromJson`)
    targetObj.readFromJson(data, opts);
  } else if (hasProp(targetObj, 'serFields')) {
    for (const field of targetObj.serFields) {
      if (typeof field == 'string') {
        logIf(debug, `- ${field}`);

        // Simple serField item (ex. 'title')
        if (!hasProp(data, field)) {
          // The targetObj wants a field that's not in the data. Keep the default.
          continue;
        }
        if (hasProp(targetObj[field], 'readFromJson') || hasProp(targetObj[field], 'serFields')) {
          // Complex object. Recurse
          readFromJson(targetObj[field], data[field], addPath(opts, field));
        } else {
          // Plain object
          logIf(debug, `-- ${data[field]}`);
          targetObj[field] = data[field];
        }
      } else {
        // Complex serField item (ex. {name: 'posts', type: 'ObjArray', elemCtor: Post})
        if (!hasProp(data, field.name)) {
          // The targetObj wants a field that's not in the data. Keep the default.
          continue;
        }
        if (field.type == 'Static') {
          // We can skip static fields. They aren't fields on the targetObj
          continue;
        } else if (field.type == 'GenericObject') {
          logIf(debug, `- ${field.name} (GenericObject)`);
          let newOpts = addPath(opts, field.name);
          newOpts.typeList = field.typeList;
          targetObj[field.name] = readGenericObjectFromJson(data[field.name], newOpts);
        } else if (field.type == 'ObjArray') {
          // Clear cur array and append (do not invalidate existing array references)
          logIf(debug, `- ${field.name} (ObjArray)`);
          let arr = targetObj[field.name];
          arr.length = 0;
          for (let i = 0; i < data[field.name].length; ++i) {
            let elemData = data[field.name][i];
            let elem = field.elemCtor();
            readFromJson(elem, elemData, addPath(opts, `${field.name}[${i}]`));
            arr.push(elem);
          }
        } else if (field.type == 'Date') {
          logIf(debug, `- ${field.name} (Date)`);
          targetObj[field.name] = new Date(data[field.name]);
        } else {
          throw new Error("Invalid serField: " + prettyJson(field));
        }
      }
    }
  } else {
    throw new Error("No way to readFromJson for the given obj: " + prettyJson(targetObj) + ", " + prettyJson(data));
  }
  // Optional hook for post-read processing
  if (hasProp(targetObj, 'postReadFromJson')) {
    targetObj.postReadFromJson(data, opts);
  }
}

/*
function makeReviverFromTypeList(typeList) {
}

export function writeGenericObjectToJson(obj, opts) {
  // We depend on the built-in 
  return JSON.parse(JSON.stringify(obj));
}

export function readGenericObjectFromJson(data, opts) {
  let reviver = makeReviverFromTypeList(opts.typeList);
  return JSON.parse(JSON.stringify(data), reviver);
}
*/

export function writeGenericObjectToJson(obj, opts) {
  opts = valOr(opts, {})

  if (obj === null) {
    return null
  } else if (typeof obj === 'object') {
    if (hasProp(obj, 'serFields') || hasProp(obj, 'writeToJson')) {
      // Custom object
      let res = writeToJson(obj, opts);
      return res;
    } else if (Array.isArray(obj)) {
      // Array
      let res = []
      for (let i = 0; i < obj.length; ++i) {
        let childValue = obj[i];
        let childRes = writeGenericObjectToJson(childValue, addPath(opts, i));
        res.push(childRes);
      }
      return res;
    } else {
      // Plain object
      let res = {}
      for (const key in obj) {
        let childValue = obj[key];
        let childRes = writeGenericObjectToJson(childValue, addPath(opts, key));
        res[key] = childRes;
      }
      return res;
    }
  } else {
    // Primitive
    return obj;
  }
}

function makeTypeMap(typeList) {
  let typeMap = {}
  for (const type of typeList) {
    let ctor = type.serInfo.ctor;
    if (ctor === undefined) {
      ctor = () => { return new type(); }
    }
    typeMap[type.serInfo.type] = {
      ctor: ctor,
    }
  }
  return typeMap;
}

export function readGenericObjectFromJson(data, opts) {
  opts = valOr(opts, {})

  if (data === null) {
    return null
  } else if (typeof data === 'object') {
    if (hasProp(data, '_type')) {
      // Custom object
      let typeMap = makeTypeMap(opts.typeList);
      let typeName = data._type;
      if (!(typeName in typeMap)) {
        throw new Error(`Could not find type in typeMap: ${typeName}`);
      }
      let newObj = typeMap[typeName].ctor();
      readFromJson(newObj, data, opts);
      return newObj;
    } else if (Array.isArray(data)) {
      // Array
      let res = []
      for (let i = 0; i < data.length; ++i) {
        let childData = data[i];
        let childRes = readGenericObjectFromJson(childData, addPath(opts, i));
        res.push(childRes);
      }
      return res;
    } else {
      // Plain object
      let res = {}
      for (const key in data) {
        let childData = data[key];
        let childRes = readGenericObjectFromJson(childData, addPath(opts, key));
        res[key] = childRes;
      }
      return res;
    }
  } else {
    // Primitive
    return data;
  }
}

export class TypeRegistry {
  constructor(classes) {
    this.types = {}
    if (classes) {
      this.addTypes(classes);
    }
  }

  addTypes(classes) {
    for (const classType of classes) {
      this.addType(classType);
    }
  }

  addType(classType) {
    let serInfo = classType.serInfo;
    this.types[serInfo.type] = serInfo.ctor;
  }

  create(typeName) {
    let ctor = this.types[typeName];
    if (!ctor) {
      throw new Error(`Could not find type in registry: ${typeName}`);
    }
    return ctor();
  }
}

export class Serializer {
  constructor() {
    this.typeRegistry = new TypeRegistry();
  }

  addTypes(classes) {
    this.typeRegistry.addTypes(classes);
  }

  writeToJson(obj, opts) {
    return writeToJson(obj, opts);
  }

  readFromJson(targetObj, data, opts) {
    readFromJson(targetObj, data, opts);
  }

  writeGenericObjectToJson(obj, opts) {
    return writeGenericObjectToJson(obj, opts);
  }

  readGenericObjectFromJson(data, opts) {
    readGenericObjectFromJson(data, opts);
  }
}