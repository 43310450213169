<script setup>
import { ref, onMounted, watch, computed, } from 'vue'
import { gApp } from '../State.js'
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth'
import { prettyJson } from '../SharedUtils.js'
import { useRoute } from "vue-router"
import BasicModal from './utils/BasicModal.vue'

const route = useRoute();

const props = defineProps({
  signIn: Boolean
})

let username = ref("");
let password = ref("");
let retypePassword = ref("");

let errorMsg = ref("");
let statusMsg = ref("");

let passwordResetModal = ref(null);

function getAuthErrorMsg(error) {
  console.log(`Auth error: ${prettyJson(error)}`)
  let msgMap = {
    "auth/credential-already-in-use": `There is already an account with that email.`,
    "auth/email-already-in-use": `There is already an account with that email.`,
    "auth/invalid-email": `That email is invalid.`,
    "auth/invalid-credential": `Invalid username or password.`,
    "auth/wrong-password": `That password is incorrect.`,
    "auth/quota-exceeded": `Too many attempts. Access temporarily disabled. Please try again later.`,
    "auth/too-many-requests": `Too many attempts. Access temporarily disabled. Please try again later.`,
    "auth/weak-password": `Please choose a stronger password.`,
  };
  if (error.code in msgMap) {
    return msgMap[error.code];
  } else {
    return `An error occurred (${error.code}). Please contact support@heatwise-hvac.com`
  }
}

// Note - currently unused
async function startSignInWithGoogle() {
  try {
    console.log("Trying to sign in with google");
    let userCredential = await signInWithPopup(gApp.auth, new GoogleAuthProvider());
    console.log("Signed in with google");
  } catch (err) {
    console.log("Error signing in with google:", err);
    errorMsg.value = getAuthErrorMsg(err);
  }
}

async function completeSignIn() {
  if (props.signIn) {
    try {
      console.log("Trying to sign in");
      if (!username.value) {
        errorMsg.value = "Please enter a valid email";
        return;
      }
      if (!password.value) {
        errorMsg.value = "Please enter a password";
        return;
      }
      errorMsg.value = "";
      statusMsg.value = "Signing in...";
      let userCredential = await signInWithEmailAndPassword(gApp.auth, username.value, password.value);
      console.log("Signed in");
      errorMsg.value = "";
    } catch (err) {
      console.log("Error signing in:", err);
      // errorMsg.value = `Could not sign in. Please check you entered the right username and password.`;
      errorMsg.value = getAuthErrorMsg(err);
    } finally {
      statusMsg.value = "";
    }
  } else {
    try {
      if (!username.value) {
        errorMsg.value = "Please enter a valid email";
        return;
      }
      if (!password.value) {
        errorMsg.value = "Please enter a password";
        return;
      }
      if (password.value !== retypePassword.value) {
        errorMsg.value = "Passwords must match."
        return;
      }
      errorMsg.value = "";
      statusMsg.value = "Creating account...";
      let userCredential = await createUserWithEmailAndPassword(gApp.auth, username.value, password.value);
      console.log("Created account!");
      errorMsg.value = "";
    } catch (err) {
      console.log("Error signing up:", err);
      // errorMsg.value = `Please check you entered a valid username and password.`;
      errorMsg.value = getAuthErrorMsg(err);
    } finally {
      statusMsg.value = "";
    }
  }
}

function reset() {
  errorMsg.value = null;
  username.value = "";
  password.value = "";
  retypePassword.value = "";
}

function changeToSignUp() {
  gApp.router.push({name: 'signup'})
}

function changeToSignIn() {
  gApp.router.push({name: 'signin'})
}

function startPasswordReset() {
  passwordResetModal.value.showModal();
}

function submitPasswordReset() {
  if (!username.value) {
    gApp.toast(`Please enter a valid email.`, {type: 'error'});
    return;
  }
  sendPasswordResetEmail(gApp.auth, username.value)
  .then(() => {
    gApp.toast(`Password reset email sent to ${username.value}`);
  })
  .catch((err) => {
    gApp.toast(`Error sending password reset email: ${err.message}`, {type: 'error'});
  })
}

watch(gApp.user, (newUser) => {
  if (newUser) {
    gApp.router.replace('/');
  }
}, {immediate: true});

watch(() => {
  return route.fullPath;
},
(newPath) => {
  reset();
}, {immediate: true})

</script>

<template>
  <div class="OuterContainer">
    <div class="FloatingShape shape1"></div>
    <div class="FloatingShape shape2"></div>
    <div class="FloatingShape shape3"></div>
    <div class="FloatingShape shape4"></div>
    <div class="SignIn FlexCol GapXS AlignCenter">
      <div class="FlexCol GapXS mb-l">
        <div class="Flex AppLogoDiv AlignCenter mb-l">
          <img class="AppLogo mr-xs" src="../assets/cpu-fan-cooling-icon.png"/>
          <h1 class="AppTitle">HeatWise</h1>
        </div>
        <h1 class="TextAlignCenter MainHeader">{{ signIn ? 'Sign In' : 'Sign Up'}}</h1>
        <p class="MuteText TextAlignCenter" :class="{'EnhancedText': true}">{{ signIn ? "Sign in to access your HeatWise projects." : 'Sign up and start creating HeatWise projects.'}}</p>
      </div>
      <p v-if="errorMsg" class="TextAlignCenter"><i class="bi-exclamation-circle mr-xs delete-red"></i>{{ errorMsg }}</p>
      <p v-if="statusMsg" class="TextAlignCenter StatusMsg"><i class="bi-hourglass-split mr-xs"></i>{{ statusMsg }}</p>
      <div class="W100 mb-s">
        <p class="FieldLabel">Email</p>
        <div class="InputWrapper">
          <i class="bi-envelope InputIcon"></i>
          <input class="TextInput Block BigInput W100" placeholder="Enter your email" v-model="username" />
        </div>
      </div>
      <div class="W100">
        <p class="FieldLabel">Password</p>
        <div class="InputWrapper mb-xxs">
          <i class="bi-lock InputIcon"></i>
          <input class="TextInput Block BigInput W100" type="password" placeholder="Enter your password" v-model="password" />
        </div>
        <div v-if="!signIn" class="InputWrapper">
          <i class="bi-shield-lock InputIcon"></i>
          <input class="TextInput Block BigInput W100" type="password" placeholder="Confirm your password" v-model="retypePassword" />
        </div>
      </div>
      <!-- Currently not used -->
      <!--<button @click="startSignInWithGoogle">Sign in with google</button>-->
      <button class="BigBtn MarginBotXS W100 CreateBtn mt-m" @click="completeSignIn">
        <i class="bi-box-arrow-in-right mr-xs" v-if="signIn"></i>
        <i class="bi-person-plus mr-xs" v-else></i>
        {{ signIn ? 'Sign In' : 'Sign Up' }}
      </button>
      <div v-if="signIn" class="TextAlignCenter">
        <button class="TextBtn mt-s" @click="startPasswordReset">Forgot password?</button>
      </div>
      <div class="Divider mt-m mb-m">
        <span>OR</span>
      </div>
      <p class="Flex GapXS JustifyCenter">
        {{signIn ? "Don't have an account?" : 'Already have an account?'}}
        <button class="ToggleBtn LinkBtn" @click="signIn ? changeToSignUp() :  changeToSignIn()">{{signIn ? "Sign up" : 'Sign in' }}</button>
      </p>
    </div>
  </div>
  <BasicModal ref="passwordResetModal" title="Reset Password" doneText="Send Email" @onDone="submitPasswordReset">
    <p class="mb-s">Enter your email address to reset your password.</p>
    <input class="TextInput Block BigInput W100" placeholder="Email" v-model="username" />
  </BasicModal>
</template>

<style scoped>
.InputWrapper {
  position: relative;
  width: 100%;
}

.InputIcon {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #777;
  font-size: 18px;
  /* Remove transition to prevent animation issues */
  z-index: 2; /* Ensure icon stays above input */
}

.InputWrapper .BigInput {
  padding-left: 42px;
}

.JustifyCenter {
  justify-content: center;
}

.Divider {
  display: flex;
  align-items: center;
  text-align: center;
  color: #777;
  margin: var(--m) 0;
}

.Divider::before,
.Divider::after {
  content: '';
  flex: 1;
  height: 1px;
  background: linear-gradient(to right, transparent, #ddd, transparent);
}

.Divider span {
  padding: 0 16px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  color: #999;
}
.AppLogoDiv {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AppLogo {
  width: 48px;
  transition: all 0.5s ease;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
}

.AppLogo:hover {
  transform: rotate(25deg) scale(1.1);
  filter: drop-shadow(0 6px 12px rgba(79, 140, 255, 0.3));
}

.AppTitle {
  font-size: 36px;
  font-weight: 700;
  background: linear-gradient(135deg, var(--pc), #4f8cff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 8px;
  text-shadow: 0 2px 10px rgba(79, 140, 255, 0.2);
  letter-spacing: -0.5px;
}

.OuterContainer {
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #e0f7fa, #bbdefb, #c5cae9);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  padding-top: var(--xl);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
}

.OuterContainer::before,
.OuterContainer::after {
  content: "";
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  background: linear-gradient(135deg, rgba(79, 140, 255, 0.15), rgba(62, 85, 204, 0.1));
  animation: morphing 15s ease-in-out infinite;
  z-index: 0;
}

.OuterContainer::before {
  top: -100px;
  right: -100px;
  animation-delay: 0s;
}

.OuterContainer::after {
  bottom: -100px;
  left: -100px;
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    transform: rotate(0deg) scale(1);
  }
  25% {
    border-radius: 70% 30% 30% 70% / 70% 70% 30% 30%;
  }
  50% {
    border-radius: 30% 70% 70% 30% / 70% 70% 30% 30%;
    transform: rotate(180deg) scale(1.1);
  }
  75% {
    border-radius: 70% 30% 30% 70% / 30% 30% 70% 70%;
  }
  100% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    transform: rotate(360deg) scale(1);
  }
}

@keyframes gradientBG {
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
}

.SignIn {
  margin: 0 auto;
  max-width: 460px;
  width: 100%;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: var(--xl) var(--xl);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.15), 0 0 20px rgba(79, 140, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  animation: fadeIn 0.6s ease-out;
  z-index: 1;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.SignIn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background: linear-gradient(90deg, var(--pc), #4f8cff);
}

.FieldLabel {
  font-weight: 600;
  padding: 0;
  font-size: var(--p-size);
  margin-bottom: 4px;
  color: var(--text-color-secondary, #555);
}

.BigInput {
  padding: 14px 16px;
  border: 1px solid var(--b-20);
  border-radius: 10px;
  font-size: 16px;
  transition: all 0.3s ease;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

.BigInput:focus {
  border-color: var(--pc);
  box-shadow: 0 0 0 3px rgba(62, 85, 204, 0.25), inset 0 2px 4px rgba(0, 0, 0, 0);
  outline: none;
  /*background-color: white;*/
  /*transform: translateY(-1px);*/
  /* Ensure input focus doesn't affect other elements */
  /*z-index: 1;*/
}

.BigBtn {
  padding: 16px;
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
  background: linear-gradient(135deg, var(--pc), #4f8cff);
  background-size: 200% auto;
  color: white;
  border: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.BigBtn:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(79, 140, 255, 0.3);
  background-position: right center;
}

.BigBtn:active {
  transform: translateY(0);
  box-shadow: 0 5px 10px rgba(79, 140, 255, 0.2);
}

.ToggleBtn {
  text-transform: none;
  font-size: var(--p-size);
  color: var(--pc);
  font-weight: 700;
  transition: color 0.3s ease;
  padding: 0 4px;
}

.ToggleBtn:hover {
  color: #4f8cff;
}

.MainHeader {
  line-height: 1.1;
  font-weight: 700;
  font-size: 32px;
  color: var(--text-color-primary, #333);
}

.StatusMsg {
  color: var(--primary);
  font-weight: 500;
  margin-bottom: var(--s);
  display: flex;
  align-items: center;
  justify-content: center;
}

.TextBtn {
  color: var(--pc);
  transition: all 0.3s ease;
  position: relative;
  padding: 2px 4px;
  border-radius: 4px;
}

.TextBtn:hover {
  color: #4f8cff;
  background-color: rgba(79, 140, 255, 0.1);
}

.MuteText {
  color: var(--text-color-tertiary, #777);
  font-size: 14px;
}

.EnhancedText {
  font-size: 17px;
  font-weight: 500;
  color: var(--pc-med);
  letter-spacing: 0.01em;
  line-height: 1.4;
}

.delete-red {
  color: #ff4757;
}

.FloatingShape {
  position: absolute;
  border-radius: 50%;
  opacity: 0.6;
  z-index: 0;
  filter: blur(10px);
}

.shape1 {
  width: 150px;
  height: 150px;
  background: linear-gradient(45deg, rgba(79, 140, 255, 0.4), rgba(62, 85, 204, 0.2));
  top: 15%;
  left: 15%;
  animation: float 20s ease-in-out infinite;
}

.shape2 {
  width: 100px;
  height: 100px;
  background: linear-gradient(45deg, rgba(79, 140, 255, 0.3), rgba(125, 200, 255, 0.2));
  top: 60%;
  right: 20%;
  animation: float 15s ease-in-out infinite reverse;
}

.shape3 {
  width: 80px;
  height: 80px;
  background: linear-gradient(45deg, rgba(62, 85, 204, 0.3), rgba(125, 200, 255, 0.2));
  bottom: 10%;
  left: 25%;
  animation: float 18s ease-in-out infinite 2s;
}

.shape4 {
  width: 120px;
  height: 120px;
  background: linear-gradient(45deg, rgba(125, 200, 255, 0.3), rgba(62, 85, 204, 0.2));
  top: 25%;
  right: 10%;
  animation: float 25s ease-in-out infinite 5s;
}

@keyframes float {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(-20px, 20px) rotate(5deg);
  }
  50% {
    transform: translate(20px, -20px) rotate(10deg);
  }
  75% {
    transform: translate(-20px, -20px) rotate(5deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}
</style>
