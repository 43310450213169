<script setup>
import { ref, computed, onMounted, nextTick, } from 'vue'
import { gApp } from '../State.js'
import { PleaseContactStr } from '../Base.js'
import FieldInput from './FieldInput.vue'
import SimpleCollapse from './SimpleCollapse.vue'
import CalcLogItem from './CalcLogItem.vue'
import InputsSummary from './InputsSummary.vue'
import ProjectErrorsSummary from './ProjectErrorsSummary.vue'
import LoadBreakdownTable from './LoadBreakdownTable.vue'
// Dynamically import jspdf to avoid bloating the bundle
const JSPDF = () => import('jspdf')

console.log("Setting HouseResults helper vars");

let pdfPageOne = ref(null);
let pdfPageTwo = ref(null);
let datePdfGenerated = ref(null);

let proj = computed(() => {
  return gApp.proj();
})

// Helper for accessing the load results
// Only valid when results are ready
let results = computed(() => {
  let proj = gApp.proj();
  if (!proj.resultsReady()) {
    return null;
  }
  return proj.getResults();
})

function logLocData() {
  // console.log("LocObj: ", gApp.proj().toplevelData.locationData.locObj);
}

async function exportPdf() {
  // Default export is a4 paper, portrait, using millimeters for units
  let jsPDF = (await JSPDF()).default;
  const doc = new jsPDF();

  let pageHeight = doc.internal.pageSize.height;

  let marginX = 15;
  let marginY = 15;
  let nextY = marginY;

  await doc.html(pdfPageOne.value, {
      callback: function(doc) {
          return doc;
      },
      x: marginX,
      y: nextY,
      width: 170,
      windowWidth: 650,
  });

  doc.addPage();
  nextY = pageHeight + marginY;
  await doc.html(pdfPageTwo.value, {
      callback: function(doc) {
          return doc;
      },
      x: marginX,
      y: nextY,
      width: 170,
      windowWidth: 650,
  });

  let projName = gApp.proj().getUiName().replace(' ', '_');
  doc.save(`${projName}.pdf`);
}

async function downloadAsPdf() {
  //gApp.toast('Feature not ready yet. Coming soon.', {type: 'success'});
  //let dateStr = new Date().toISOString().slice(0, 10)
  datePdfGenerated.value = new Date().toLocaleDateString();
  nextTick(() => {
    exportPdf();
  })
}

</script>

<template>
  <div class="BorderedSection">
    <button v-if="proj.resultsReady() && results.noErrors()" class="DownloadBtn CreateBtn" @click="downloadAsPdf">Download as PDF</button>
    <div class="mb-m">
      <h3 class=""><i class="bi-bar-chart-line mr-xs"></i>Results</h3>
      <p class="help-text">View your house's load results.</p>
    </div>
    <div class="Section">
      <div v-if="!proj.resultsReady()">
        <p>Calculating, please wait...</p>
      </div>
      <div v-else>
        <div v-if="results.getProjectErrors()" class="">
          <p><b>Found Problems</b></p>
          <p class="mb-xs"><i class="bi-exclamation-circle mr-xs"></i>You must fix these problems before seeing load results:</p>
          <ProjectErrorsSummary :errors="results.getProjectErrors()" />
        </div>
        <div v-else>
          <div v-if="proj.debugOn && results.outputCtx">
            <SimpleCollapse v-if="proj.debugOn" title="Debug Log" class="MarginBotXXS">
              <p class="DebugLog">{{results.getCalcContext().getLogStr()}}</p>
            </SimpleCollapse>
            <SimpleCollapse v-if="proj.debugOn" title="Debug Log (Dict)" class="MarginBotM">
              <CalcLogItem :item="results.getCalcContext().getLogDict()" />
            </SimpleCollapse>
          </div>
          <div v-if="results.getError()">
            <h4>Unexpected Error:</h4>
            <p>An unexpected error occurred while calculating the load results. {{PleaseContactStr}}</p>
            <p class="DebugLog ResultsErrorLog">{{results.getError()}}</p>
          </div>
          <div v-else>
            <!-- Hidden element used for pdf generation -->
            <div v-show="false" class="PdfOutput ToplevelPdf">
              <div ref="pdfPageOne">
                <h1>{{ proj.getUiName() }}</h1>
                <div class="MarginBotS PdfOutput">
                  <p>Location: {{gApp.proj().toplevelData.locationData.locPath.fullName}}</p>
                  <p>Heating Design Temp: {{proj.designTempInputs.heatingDesignTemp.getSelectLabelStr()}}</p>
                  <p>Cooling Design Temp: {{proj.designTempInputs.coolingDesignTemp.getSelectLabelStr()}}</p>
                  <p>Generated on {{datePdfGenerated}}</p>
                </div>
                <LoadBreakdownTable ref="loadsTable" :results="results"/>
              </div>
              <div ref="pdfPageTwo">
                <!-- <h2>Inputs Summary</h2> -->
                <InputsSummary ref="inputsSummary" :results="results" class="PdfOutput"/>
              </div>
            </div>
            <div class="MarginBotM" title="Load Breakdown" :styles="{Emphasis:true}">
              <!-- <h3>Final Load Outputs</h3> -->
              <div class="mb-s">
                <FieldInput v-model="proj.designTempInputs.heatingDesignTemp" />
                <FieldInput v-model="proj.designTempInputs.coolingDesignTemp" />
              </div>
              <div class="w-80 mb-m">
                <p class="mute-header">Disclaimer</p>
                <p class="help-text">
                  Please double-check all results when using them in a professional setting.
                  Contact support@heatwise-hvac.com for any questions or issues.
                </p>
              </div>
              <LoadBreakdownTable :results="results"/>
            </div>
            <SimpleCollapse title="Inputs Summary">
              <InputsSummary :results="results"/>
            </SimpleCollapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.DebugLog {
  white-space: pre-wrap;
  font-family: monospace;
  font-size: 16px;
  line-height: 1.25;
}

.ResultsErrorLog {
  padding: 8px;
  background-color: lightgrey;
}

.ErrorIcon {
  color: var(--delete-red);
}

.ErrorDiv {
  /* border: 1px solid var(--b-20); */
  width: 800px;
  border: 1px solid var(--grey-60);
  /* background-color: var(--grey-90); */
  /* background-color: var(--delete-red); */
  border-radius: var(--br-s);
  padding: var(--xs);
}

.ToplevelPdf table {
  border-right: none;
  border-bottom: none;
}

.PdfOutput {
  font-family: sans-serif;
  font-size: 16px;
}

.DownloadBtn {
  float: right;
}

</style>
