import { makeEnum, makeOptions,
  setupClass, lookupData, 
  interpolateInMap,
} from '../Base.js'
import { InputComponent } from '../Common/InputComponent.js'
import { CalcContext } from '../Common/CalcContext.js'

import { kFloorCoverings } from '../MaterialData/FloorCoverings.js'
import { GroundSurfaceTempAmplitudes } from '../Data/GroundSurfaceTempAmplitudes.js'
import * as calc from './ResidentialCalculations.js'

import { Units } from '../Common/Units.js'

import {
  FieldType,
  Field,
  FieldGroup,
} from '../Common/Field.js'

import {
  Season,
  ManualOrAutomatic,
  ManualOrSelectFromList,
 } from './Common.js'

import { gApp, DebugOn } from '../Globals.js'


export let FloorType = makeEnum({
  SlabOnGrade: 'Slab on grade',
  SlabBelowGrade: 'Slab below grade (basement)',
  FloorAboveCrawlSpace: 'Floor above crawl space or unconditioned space',
  FloorAboveConditionedSpace: 'Floor above conditioned space',
  FloorRaisedOffGround: 'Floor raised off ground',
})

let OuterWallConstruction = makeEnum({
  EightInchBlockWall: '8" block wall, brick facing',
  FourInchBlockWall: '4" block wall, brick facing',
  MetalStudWall: 'Metal stud wall with stucco and dry wall',
  PouredConcreteWall: 'Poured concrete wall',
})

let PerimeterInsulation = makeEnum({
  Insulated: 'Insulated',
  Uninsulated: 'Uninsulated',
})

let FpTable = {
  [OuterWallConstruction.EightInchBlockWall]: {
    [PerimeterInsulation.Uninsulated]: 0.68,
    [PerimeterInsulation.Insulated]: 0.50,
  },
  [OuterWallConstruction.FourInchBlockWall]: {
    [PerimeterInsulation.Uninsulated]: 0.84,
    [PerimeterInsulation.Insulated]: 0.49,
  },
  [OuterWallConstruction.MetalStudWall]: {
    [PerimeterInsulation.Uninsulated]: 1.20,
    [PerimeterInsulation.Insulated]: 0.53,
  },
  [OuterWallConstruction.PouredConcreteWall]: {
    [PerimeterInsulation.Uninsulated]: 2.12,
    [PerimeterInsulation.Insulated]: 0.72,
  }
};

export class Floor extends InputComponent {
  init() {
    this.floorType = new Field({
      name: 'Floor Type',
      type: FieldType.Select,
      choices: makeOptions(FloorType),
      bold: true,
    })

    this.area = new Field({
      name: 'Area',
      type: FieldType.Area,
      requiresInput: true,
    })
    this.area.makeUpdater((field) => {
      field.visible = this.floorType.value !== FloorType.FloorAboveConditionedSpace
    })

    this.onGradeGroup = new FieldGroup([
      new Field({
        key: 'outerWall',
        name: 'Outer wall construction',
        type: FieldType.Select,
        choices: makeOptions(OuterWallConstruction)
      }),
      new Field({
        key: 'perimeterInsulation',
        name: 'Perimeter Insulation',
        type: FieldType.Select,
        choices: makeOptions(PerimeterInsulation),
      }),
      new Field({
        key: 'exposedPerimeter',
        name: 'Exposed perimeter',
        type: FieldType.Length,
        min: 0,
        allowMin: true,
      })
    ])
    this.onGradeGroup.setVisibility(() => {
      return this.floorType.value == FloorType.SlabOnGrade;
    })

    this.raisedGroup = new FieldGroup([
      new Field({
        key: 'floorRValue',
        name: 'Floor R-value',
        type: FieldType.RValue,
        requiresInput: true,
      })
    ])
    this.raisedGroup.setVisibility(() => {
      return this.floorType.value == FloorType.FloorRaisedOffGround;
    });

    this.belowGradeGroup = new FieldGroup([
      new Field({
        key: 'depth',
        name: 'Depth',
        type: FieldType.Length,
        requiresInput: true,
      }),
      new Field({
        key: 'floorInsulationRValue',
        name: 'R-value of Floor Insulation',
        type: FieldType.RValue,
        requiresInput: true,
      }),
      new Field({
        key: 'wallInsulationRValue',
        name: 'R-value of Wall Insulation',
        type: FieldType.RValue,
        requiresInput: true,
      }),
      new Field({
        key: 'shortestWidth',
        name: 'Shortest Width of Basement',
        type: FieldType.Length,
        requiresInput: true,
      }),
      new Field({
        key: 'belowGradeWallArea',
        name: 'Below-grade wall area',
        type: FieldType.Area,
        requiresInput: true,
      }),
      new Field({
        key: 'tempVariationEntryType',
        name: 'Amplitude of ground surface temp variation',
        type: FieldType.Select,
        choices: makeOptions(ManualOrAutomatic, [ManualOrAutomatic.Automatic, ManualOrAutomatic.Manual]),
        bold: true,
      }),
      new Field({
        key: 'tempVariation',
        name: '',
        type: FieldType.Temp,
      }),
      new Field({
        key: 'avgGroundTempEntryType',
        name: 'Average ground temp',
        type: FieldType.Select,
        choices: makeOptions(ManualOrAutomatic, [ManualOrAutomatic.Automatic, ManualOrAutomatic.Manual]),
        bold:  true,
      }),
      new Field({
        key: 'avgGroundTemp',
        name: '',
        type: FieldType.Temp,
      }),
    ])
    this.belowGradeGroup.setVisibility(() => {
      return this.floorType.value == FloorType.SlabBelowGrade;
    })
    this.belowGradeGroup.getField('tempVariation').makeUpdater((field) => {
      let entryType = this.belowGradeGroup.getField('tempVariationEntryType').value;
      if (entryType == ManualOrAutomatic.Manual) {
        field.isOutput = false;
      } else {
        let locationData = gApp.proj().getLocationData();
        if (locationData.isLocationSet()) {
          let weatherData = locationData.getOutputs();
          field.isOutput = true;
          field.value = GroundSurfaceTempAmplitudes.lookupValue(weatherData.latitude, weatherData.longitude);
          field.setEntryErrorMsg(null);
        } else {
          field.isOutput = true;
          field.value = 0;
          field.setEntryErrorMsg("Must set the location.")
        }
      }
    })
    this.belowGradeGroup.getField('avgGroundTemp').makeUpdater((field) => {
      let entryType = this.belowGradeGroup.getField('avgGroundTempEntryType').value;
      if (entryType == ManualOrAutomatic.Manual) {
        field.isOutput = false;
      } else {
        let locationData = gApp.proj().getLocationData();
        if (locationData.isLocationSet()) {
          let weatherData = locationData.getOutputs();
          field.isOutput = true;
          field.value = weatherData.avgAnnualTemp;
          field.setEntryErrorMsg(null);
        } else {
          field.isOutput = true;
          field.value = 0;
          field.setEntryErrorMsg("Must set the location.")
        }
      }
    })

    this.aboveCrawlSpaceGroup = new FieldGroup([
      new Field({
        key: 'floorRValue',
        name: 'Floor R-value',
        type: FieldType.RValue,
        requiresInput: true,
      }),
      new Field({
        key: 'winterTemp',
        name: 'Winter temperature of space',
        type: FieldType.Temperature,
        requiresInput: true,
      }),
      new Field({
        key: 'summerTemp',
        name: 'Summer temperature of space',
        type: FieldType.Temperature,
        requiresInput: true,
      }),
      // TODO - implement the temperature calculator
    ])
    this.aboveCrawlSpaceGroup.setVisibility(() => {
      return this.floorType.value == FloorType.FloorAboveCrawlSpace;
    })

    this.additionalCoveringOption = new Field({
      name: 'Entry type',
      type: FieldType.Select,
      choices: makeOptions(ManualOrSelectFromList),
      bold: true,
    })
    this.manualCovering = new Field({
      name: 'R-value',
      type: FieldType.RValue,
      requiresInput: true,
      // For the covering, R-value=0 is actually valid.
      // Never a divide-by-zero issue b/c U=1.0/(R_floor+R_covering)
      allowMin: true,
    })
    this.listCovering = new Field({
      name: 'Material types',
      type: FieldType.Select,
      choices: Floor.getFloorCoveringOptions(),
    })
    this.updater.addWatchEffect('show-covering', () => {
      // TODO - this visibility logic is super gross but fine for now.
      let showCovering = this.hasOptionalFloorCovering();
      if (showCovering) {
        this.additionalCoveringOption.visible = true;
        if (this.additionalCoveringOption.value == ManualOrSelectFromList.Manual) {
          this.manualCovering.visible = true;
          this.listCovering.visible = false;
        } else {
          this.manualCovering.visible = false;
          this.listCovering.visible = true;
        }
      } else {
        this.additionalCoveringOption.visible = false;
        this.manualCovering.visible = false;
        this.listCovering.visible = false;
      }
    })

    this.heatingLoadHelpInfo = null;
    this.coolingLoadHelpInfo = null;
    this.updater.addWatchEffect('floor-calc-help', () => {
      // TODO - only commercial for now
      if (!gApp.proj().isCommercial()) {
        return;
      }
      try {
        let ctx = CalcContext.create();
        let locationData = gApp.proj().buildingAndEnv.getLocationData();
        ctx.toplevelData = {
          locationData: locationData.getOutputs(),
          dayOfMonth: 21,
        }
        let sampleTemps = locationData.getSampleTemps();
        let heatingResult = this._calcLoads(ctx, true, {
            t_i: sampleTemps.t_i_winter, t_o: sampleTemps.t_o_winter});
        let coolingResult = this._calcLoads(ctx, false, {
            t_i: sampleTemps.t_i_summer, t_o: sampleTemps.t_o_summer});
        this.heatingLoadHelpInfo = heatingResult.helpInfo;
        this.coolingLoadHelpInfo = coolingResult.helpInfo;
      } catch (err) {
        // Ignore exception - likely just fields need to be filled out.
        console.log("Error calculating floor loads: ", err);
        this.heatingLoadHelpInfo = {
          helpText: "Please fill out all fields correctly to see load previews.",
        }
        this.coolingLoadHelpInfo = null;
      }
    })

    this.serFields = [
      'floorType',
      'area',
      'onGradeGroup',
      'raisedGroup',
      'belowGradeGroup',
      'aboveCrawlSpaceGroup', 
      'additionalCoveringOption',
      'manualCovering',
      'listCovering',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Floor',
    }
  }

  getFloorType() {
    return this.floorType.value;
  }

  hasOptionalFloorCovering() {
    return this.floorType.value !== FloorType.FloorAboveConditionedSpace;
  }

  isFloorRaised() {
    return this.floorType.value == FloorType.FloorRaisedOffGround;
  }

  getArea() {
    return this.area.value;
  }

  static getFloorCoveringOptions() {
    let options = []
    for (const coveringName in kFloorCoverings) {
      let option = {
        label: `${coveringName} (R-value=${kFloorCoverings[coveringName]})`,
        value: coveringName,
      }
      options.push(option);
    }
    return options;
  }

  getManualRValue() {
    if (this.floorType.value == FloorType.FloorAboveCrawlSpace) {
      return this.aboveCrawlSpaceGroup.getField('floorRValue').value;
    } else if (this.floorType.value == FloorType.FloorRaisedOffGround) {
      return this.raisedGroup.getField('floorRValue').value;
    }
    throw new Error(`Cannot get manual R-value for floor of type ${this.floorType.value}`);
  }

  getFloorCoveringRValue() {
    if (this.additionalCoveringOption.value == ManualOrSelectFromList.Manual) {
      return this.manualCovering.value;
    } else {
      let coveringName = this.listCovering.value;
      if (!(coveringName in kFloorCoverings)) {
        throw new Error(`Unknown floor covering: ${coveringName}`);
      }
      return kFloorCoverings[coveringName]
    }
  }

  getCrawlSpaceTemp(season) {
    if (this.floorType.value !== FloorType.FloorAboveCrawlSpace) {
      throw new Error(`Cannot get crawl space temp for floor type ${this.floorType.value}`);
    }
    return season == Season.Winter ?
      this.aboveCrawlSpaceGroup.getField('winterTemp').value :
      this.aboveCrawlSpaceGroup.getField('summerTemp').value;
  }

  _calcLoads(ctx, isHeating, optArgs) {
    ctx.startSection(`Floor loads - ${isHeating ? 'Heating' : 'Cooling'}`);

    let helpInfo = null;
    let weatherData = ctx.toplevelData.locationData;
    ctx.floorType = this.floorType.value;
    if (gApp.proj().isResidential()) {
      ctx.t_i = isHeating ? ctx.toplevelData.indoorWinterTemp
        : ctx.toplevelData.indoorSummerTemp;
      ctx.t_o = isHeating? ctx.designTemps.heating : ctx.designTemps.cooling;
    } else {
      // For commercial, t_i and t_o must be given in the optArgs
      ctx.log("For commercial projects, using t_i and t_o given in optArgs...")
      ctx.assert(optArgs, 'optArgs must be given for commercial projects');
      ctx.assert(optArgs.t_i !== undefined, 'optArgs.t_i must be given for commercial projects');
      ctx.assert(optArgs.t_o !== undefined, 'optArgs.t_o must be given for commercial projects');
      ctx.t_i = optArgs.t_i;
      ctx.t_o = optArgs.t_o;
    }

    ctx.A = this.area.value;
    ctx.R_cvr = this.getFloorCoveringRValue();
    if (this.floorType.value == FloorType.SlabOnGrade) {
      if (isHeating) {
        ctx.wallConstruction = this.onGradeGroup.getField('outerWall').value;
        ctx.perimeterInsulation = this.onGradeGroup.getField('perimeterInsulation').value;
        ctx.P = this.onGradeGroup.getField('exposedPerimeter').value;
        ctx.F_p = lookupData(FpTable, [ctx.wallConstruction, ctx.perimeterInsulation]);
        // TODO - should t_o be t_gr here?
        ctx.q = ctx.eval('F_p*(t_i - t_o)*P', {}, 'q')
        helpInfo = {
          helpText: `The heating load for a slab-on-grade floor is calculated as: `
                  + `<b>q = F_p*(t_i - t_o)*P</b>, where F_p is the perimeter factor, t_i is the indoor temperature, `
                  + `t_o is the outdoor temperature, and P is the exposed perimeter.`,
          result: {
            label: 'Heating load',
            value: ctx.q,
            units: Units.Load,
          },
          relatedValues: {
            F_p: {
              label: 'Perimeter factor (F_p)',
              value: ctx.F_p,
              units: Units.None,
            },
            t_i: {
              label: 'Indoor temperature',
              value: ctx.t_i,
              units: Units.F,
            },
            t_o: {
              label: 'Outdoor temperature',
              value: ctx.t_o,
              units: Units.F,
            },
          }
        }
      } else {
        ctx.q = 0;
        helpInfo = {
          helpText: "Slab-on-grade floors have no cooling load.",
          result: {
            label: 'Cooling load',
            value: 0,
            units: Units.Load,
          }
        }
      }
    } else if (this.floorType.value == FloorType.SlabBelowGrade) {
      if (isHeating) {
        ctx.groundTempEntryType = this.belowGradeGroup.getField('avgGroundTempEntryType').value; 
        ctx.t_gr_avg = this.belowGradeGroup.getField('avgGroundTemp').value;
        ctx.A_gr = this.belowGradeGroup.getField('tempVariation').value;
        ctx.t_gr = ctx.eval('t_gr_avg - A_gr', {}, 't_gr');

        ctx.k_soil = weatherData.k_soil;
        ctx.d = this.belowGradeGroup.getField('depth').value;
        ctx.R_other = ctx.eval('R_wall_insul + 1.47', {
          R_wall_insul: this.belowGradeGroup.getField('wallInsulationRValue').value
        }, 'R_other');

        // Wall
        ctx.U_avg_bw = ctx.eval(
          '((2*k_soil)/(pi*d))*(ln(d + 2*k_soil*R_other/pi) - ln(2*k_soil*R_other/pi))',
          {pi: Math.PI, ln: Math.log,}, 'U_avg_bw')
        ctx.A_wall = this.belowGradeGroup.getField('belowGradeWallArea').value;
        ctx.q_bw = ctx.eval('U_avg_bw*(t_i - t_gr)*A_wall', {}, 'q_bw')

        // Floor
        ctx.w_b = this.belowGradeGroup.getField('shortestWidth').value;
        ctx.R_other = ctx.eval('R_floor_insul + R_cvr + 1.47', {
          R_floor_insul: this.belowGradeGroup.getField('floorInsulationRValue').value,
        }, 'R_other')
        ctx.U_avg_bf = ctx.eval(
          '(2*k_soil/(pi*w_b))*(ln(w_b/2 + d/2 + k_soil*R_other/pi) - ln(d/2 + k_soil*R_other/pi))',
          {pi: Math.PI, ln: Math.log,}, 'U_avg_bf')
        ctx.q_bf = ctx.eval('U_avg_bf*(t_i - t_gr)*A', {}, 'q_bf')

        ctx.q = ctx.q_bw + ctx.q_bf;
        
        helpInfo = {
          helpText: `The heating load is calculated as the sum of wall and floor heat transfer: `
                    + `<b>q = U_wall*(t_i - t_gr)*A_wall + U_floor*(t_i - t_gr)*A_floor</b>, `
                    + `where t_i is the indoor temp, t_gr is the ground temp, and U-values are calculated from the inputs `
                    + `and soil conductivity.`,
          result: {
            label: 'Heating load',
            value: ctx.q,
            units: Units.Load,
          },
          relatedValues: {
            q_bw: {
              label: 'Wall heat transfer',
              value: ctx.q_bw,
              units: Units.Load,
            },
            q_bf: {
              label: 'Floor heat transfer',
              value: ctx.q_bf,
              units: Units.Load,
            },
            t_gr: {
              label: 'Ground temperature',
              value: ctx.t_gr,
              units: Units.F,
            },
            t_i: {
              label: 'Indoor temperature',
              value: ctx.t_i,
              units: Units.F,
            },
            U_wall: {
              label: 'Wall U-value',
              value: ctx.U_avg_bw,
              units: Units.UValue,
            },
            A_wall: {
              label: 'Wall area',
              value: ctx.A_wall,
              units: Units.ft2,
            },
            U_floor: {
              label: 'Floor U-value',
              value: ctx.U_avg_bf,
              units: Units.UValue,
            },
            A_floor: {
              label: 'Floor area',
              value: ctx.A,
              units: Units.ft2,
            },
          }
        }
      } else {
        ctx.q = 0;
        helpInfo = {
          helpText: "Below-grade floors have no cooling load.",
          result: {
            label: 'Cooling load',
            value: 0,
            units: Units.Load,
          }
        }
      }
    } else if (this.floorType.value == FloorType.FloorAboveCrawlSpace) {
      // Treat as a partition
      ctx.t_b = isHeating ? this.aboveCrawlSpaceGroup.getField('winterTemp').value
        : this.aboveCrawlSpaceGroup.getField('summerTemp').value;
      ctx.R = ctx.eval('R_floor + R_cvr', {
        R_floor: this.aboveCrawlSpaceGroup.getField('floorRValue').value,
      }, 'R')
      ctx.U = 1.0 / ctx.R;
      if (isHeating) {
        // TODO - see Calculation note about if t_b > t_i
        ctx.q = ctx.eval('A*U*(t_i - t_b)', {
        }, 'q');
        helpInfo = {
          helpText: `Floor above crawl space heating load is calculated using the temperature difference between indoor and crawl space temperatures: `
                    + `<b>q = U*(t_i - t_b)*A</b>, where t_i is the indoor temperature, and t_b is the crawl space temperature.`,
          result: {
            label: 'Heating load',
            value: ctx.q,
            units: Units.Load,
          },
          relatedValues: {
            R: {
              label: 'Total R-value',
              value: ctx.R,
              units: Units.RValue,
            },
            U: {
              label: 'U-value',
              value: ctx.U,
              units: Units.UValue,
            },
            t_i: {
              label: 'Indoor temperature',
              value: ctx.t_i,
              units: Units.F,
            },
            t_b: {
              label: 'Crawl space temperature',
              value: ctx.t_b,
              units: Units.F,
            }
          }
        }
      } else {
        ctx.q = ctx.eval('A*U*(t_b - t_i)', {
        }, 'q');
        helpInfo = {
          helpText: `Floor above crawl space cooling load is calculated using the temperature difference between crawl space and indoor temperatures: `
                    + `<b>q = U*(t_b - t_i)*A</b>, where t_i is the indoor temperature, and t_b is the crawl space temperature.`,
          result: {
            label: 'Cooling load',
            value: ctx.q,
            units: Units.Load,
          },
          relatedValues: {
            R: {
              label: 'Total R-value',
              value: ctx.R,
              units: Units.RValue,
            },
            U: {
              label: 'U-value',
              value: ctx.U,
              units: Units.UValue,
            },
            t_i: {
              label: 'Indoor temperature',
              value: ctx.t_i,
              units: Units.F,
            },
            t_b: {
              label: 'Crawl space temperature',
              value: ctx.t_b,
              units: Units.F,
            }
          }
        }
      }
    } else if (this.floorType.value == FloorType.FloorAboveConditionedSpace) {
      // No heating/cooling load
      ctx.q = 0;
      helpInfo = {
        helpText: `Floors above conditioned spaces have no ${isHeating ? 'heating' : 'cooling'} load.`,
        result: {
          label: isHeating ? 'Heating load' : 'Cooling load',
          value: 0,
          units: Units.Load,
        }
      }
    } else if (this.floorType.value == FloorType.FloorRaisedOffGround) {
      // Treat as a wall
      ctx.R = ctx.eval('R_floor + R_cvr', {
        R_floor: this.raisedGroup.getField('floorRValue').value,
      }, 'R');
      ctx.U = 1.0 / ctx.R;
      let surfaceType = 'FloorRaisedOffGround';
      if (isHeating) {
        ctx.q = ctx.eval('A*U*(t_i - t_o)', {}, 'q');
        helpInfo = {
          helpText: `Raised floor heating load is calculated using the temperature difference between indoor and outdoor temperatures: `
                  + `<b>q = U*(t_i - t_o)*A</b>, where t_i is the indoor temperature, and t_o is the outdoor temperature.`,
          result: {
            label: 'Heating load',
            value: ctx.q,
            units: Units.Load,
          },
          relatedValues: {
            R: {
              label: 'Total R-value',
              value: ctx.R,
              units: Units.RValue,
            },
            U: {
              label: 'U-value',
              value: ctx.U,
              units: Units.UValue,
            },
            t_i: {
              label: 'Indoor temperature',
              value: ctx.t_i,
              units: Units.F,
            },
            t_o: {
              label: 'Outdoor temperature',
              value: ctx.t_o,
              units: Units.F,
            }
          }
        }
      } else {
        if (gApp.proj().isResidential()) {
          // Note: we keep these different for now because the residential app uses some adjustment factors
          // internally.
          // TODO - verify that those are actually needed / desired - would be better to standardize on one way.
          ctx.q = calc.calcOpaqueQ(ctx, ctx.A,
            ctx.U, ctx.t_i, ctx.t_o, null,
            isHeating, surfaceType, weatherData);
        } else {
          ctx.q = ctx.eval('A*U*(t_o - t_i)', {}, 'q');
        }
        helpInfo = {
          helpText: `Raised floor cooling load is calculated using the temperature difference between outdoor and indoor temperatures: `
                  + `<b>q = U*(t_o - t_i)*A</b>, where t_i is the indoor temperature, and t_o is the outdoor temperature. For houses, `
                  + `some adjustment factors are also applied.`,
          result: {
            label: 'Cooling load',
            value: ctx.q,
            units: Units.Load,
          },
          relatedValues: {
            R: {
              label: 'Total R-value',
              value: ctx.R,
              units: Units.RValue,
            },
            U: {
              label: 'U-value',
              value: ctx.U,
              units: Units.UValue,
            },
            t_i: {
              label: 'Indoor temperature',
              value: ctx.t_i,
              units: Units.F,
            },
            t_o: {
              label: 'Outdoor temperature',
              value: ctx.t_o,
              units: Units.F,
            }
          }
        }
      }
    } else {
      throw new Error("Unknown FloorType: " + this.floorType.value);
    }

    let q = ctx.q;
    ctx.endSection();
    return {
      q: q,
      helpInfo: helpInfo,
    };
  }

  calcOutputs(ctx) {
    let result = {};
    result.q_heating = this._calcLoads(ctx, true).q;
    result.q_cooling = this._calcLoads(ctx, false).q;
    return result;
  }
}
setupClass(Floor)
