import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
} from '../Base.js'
import { getFullHourString,
  generateItemName,
  sortItemsByName,
} from '../SharedUtils.js'

import { Field, FieldType, FieldGroup, } from '../Common/Field.js'

import { gApp, DebugOn } from '../Globals.js'

export class Schedule {
  init(name, makeId) {
    this.name = Field.makeName(`Schedule Name`, name)
    this.id = makeId ? gApp.proj().makeId('Schedule') : 0;
    this.editable = true;

    let fields = {}
    for (let i = 0; i < 24; i++) {
      fields[`${i}`] = new Field({
        name: getFullHourString(i),
        type: FieldType.Percent,
        defaultValue: 100.0,
      });
    }
    this.hourFields = FieldGroup.fromDict(fields)

    this.serFields = [
      'name',
      'id',
      'editable',
      'hourFields',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _uniqueName: true,
      _name: () => {
        return this.name.value;
      }
    }
  }

  static getTypeMetadata() {
    return {
      name: 'Schedule',
      createType: Schedule.createType,
      openEditor: Schedule.openEditor,
      customEditor: true,
    }
  }

  static createType() {
    let type = Schedule.create(generateItemName('Schedule', gApp.proj().schedules, 'TYPE_NAME-CTR'), true)
    gApp.proj().schedules.push(type);
    sortItemsByName(gApp.proj().schedules);
    return type
  }

  static openEditor(type) {
    let pathName = 'schedules'
    gApp.proj().editorState.selectedSchedule = type;
    gApp.router.push({name: pathName});
  }

  isEditable() {
    return this.editable;
  }

  setEditable(editable) {
    this.editable = editable;
  }

  dumpValues() {
    console.log("Schedule values:");
    let fields = this.hourFields.fields.slice(12);
    for (const field of fields) {
      console.log(`${field.name} (${field.key}): ${field.value}`);
    }
  }

  static createDefaultSchedules() {
    // Create the schedules that will exist by default in new projects

    let alwaysOn = Schedule.create("AlwaysOn", true);
    for (let i = 0; i < 24; i++) {
      alwaysOn.hourFields.get(`${i}`).value = 100;
    }
    alwaysOn.setEditable(false);

    let onDuringWorkHours = Schedule.create("WorkHours", true);
    for (let i = 0; i < 24; i++) {
      onDuringWorkHours.hourFields.get(`${i}`).value = 0;
    }
    for (let i = 9; i < 17; i++) {
      onDuringWorkHours.hourFields.get(`${i}`).value = 100;
    }
    // Allow editing this one
    onDuringWorkHours.setEditable(true);

    return [alwaysOn, onDuringWorkHours];
  }

  getInputPage() {
    return {
      label: `Schedules - ${this.name.value}`,
      // TODO
      path: `schedules/${this.id}`,
    };
  }

  getHour(i) {
    return this.hourFields.get(`${i}`).value;
  }

  setHour(i, value) {
    this.hourFields.get(`${i}`).value = value;
  }

  getData() {
    let data = []
    for (let i = 0; i < 24; i++) {
      data.push(this.getHour(i) / 100.0);
    }
    return data;
  }

  static getTableInfo() {
    return {
      typeName: 'Schedule',
      allowDuplicate: true,
      // Note: the duplicate should be editable
      excludeFieldsForDuplicate: ['editable'],
      columns: {
        'name': {
          label: 'Name',
        }
      }
    }
  }

  getTableData() {
    return {
      name: this.name.value,
    }
  }
}
setupClass(Schedule)
