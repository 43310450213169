<script setup>
import { ref, onMounted, reactive, computed, watch, nextTick, } from 'vue'
import GenericCollapse from './GenericCollapse.vue';

const props = defineProps({
  stepNumber: {
    type: Number,
    default: 1,
  },
  currentStep: {
    type: Number,
    default: 1,
  },
  title: {
    type: String,
    default: "",
  },
  isDone: {
    type: Boolean,
    default: false,
  },
  isReady: {
    type: Boolean,
    default: true,
  }
});
let emits = defineEmits(['onMarkDone']);

let isCurrentStep = computed(() => {
  return props.stepNumber === props.currentStep;
});

// Determine if this step is available (previous steps are completed)
let isAvailable = computed(() => {
  // Step 1 is always available
  if (props.stepNumber === 1) return true;
  
  // For other steps, check if the previous step is done
  return props.stepNumber <= props.currentStep;
});

const collapseRef = ref(null);

// Watch for changes to currentStep and automatically open/close the collapse
watch(() => props.currentStep, (newVal) => {
  if (newVal === props.stepNumber && collapseRef.value) {
    // This is the current step, open it
    collapseRef.value.open();
  } else if (collapseRef.value) {
    // This is not the current step, close it
    collapseRef.value.close();
  }
}, { immediate: true });

// Also watch on mount to ensure proper initial state
onMounted(() => {
  if (isCurrentStep.value && collapseRef.value) {
    collapseRef.value.open();
  }
});

</script>

<template>
  <div>
    <GenericCollapse ref="collapseRef" class="GuideStep" :disabled="!isAvailable" :class="{isDone: isDone, isCurrentStep: isCurrentStep, isLocked: !isAvailable}">
      <template v-slot:title>
        <div class="StepTitleContainer">
          <div class="StepNumberBadge">{{ stepNumber }}</div>
          <div class="StepTitleText">
            <span class="StepTitle">{{title}}</span>
          </div>
          <div v-if="isDone" class="StepStatusIcon">
            <i class="bi-check-circle-fill"></i>
          </div>
          <div v-else-if="!isAvailable" class="StepStatusIcon">
            <i class="bi-lock-fill"></i>
          </div>
        </div>
      </template>
      <template v-slot:content>
        <slot name="content"></slot>
        <div class="DoneSection mt-m">
          <button v-if="!isDone" class="CreateBtn MarkDoneBtn" :disabled="!isReady || !isAvailable" @click="emits('onMarkDone')">
            Mark Done
          </button>
          <p v-else class="Bold f-l pc TextAlignCenter"><i class="bi-check-lg mr-xxs"></i>Step Done!</p>
        </div>
      </template>
    </GenericCollapse>
  </div>
</template>

<style scoped>
.GuideStep {
}

.GuideStep.isCurrentStep:deep(.CollapseBtn) {
  background-color: var(--pc, #3498db);
  color: white;
  border: 2px solid var(--pc, #3498db);
  box-shadow: 0 0 8px rgba(52, 152, 219, 0.5);
  animation: pulse-border 2s infinite;
  position: relative;
  transition: all 0.3s ease;
}

@keyframes pulse-border {
  0% {
    box-shadow: 0 0 0 0 rgba(52, 152, 219, 0.7);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(52, 152, 219, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(52, 152, 219, 0);
  }
}

.GuideStep.isCurrentStep:deep(.CollapseBtn:hover) {
  background-color: var(--primary-dark, #2980b9);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(52, 152, 219, 0.6);
}

.GuideStep.isCurrentStep:deep(.StepNumberBadge) {
  background-color: white;
  color: var(--pc, #3498db);
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
}


.GuideStep.isCurrentStep:deep(.StepTitle) {
  color: white;
}

.GuideStep.isDone {
}

.GuideStep.isLocked:deep(.CollapseBtn) {
  background-color: var(--grey-80);
  color: var(--grey-40);
  cursor: not-allowed;
}

.StepTitleContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.StepNumberBadge {
  background-color: var(--grey-40);
  color: white;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: var(--s);
  flex-shrink: 0;
}

.StepTitleText {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
}

.StepTitle {
  font-size: var(--f-l);
  font-weight: bold;
  line-height: 1.2;
}

.StepStatusIcon {
  margin-left: var(--xs);
  font-size: var(--f-l);
}

.StepStatusIcon i {
  vertical-align: middle;
}

.isDone .StepStatusIcon {
  color: var(--g-30, #27ae60);
}

.isLocked .StepStatusIcon {
  color: var(--grey-50);
}

.DoneSection {
}

.MarkDoneBtn {
  /*border-radius: var(--br-m);*/
  margin: auto;
  padding: var(--s) var(--m);
}

.MarkDoneBtn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
