import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
  interpolateInMap, doubleInterpolateInMap,
  IdsMap, PleaseContactStr,
  IntervalTimer,
} from '../Base.js'
import {
  valOr, deepCopyObject, generateItemName, sortItemsByName,
  prettyJson,
} from '../SharedUtils.js'
import * as ser from '../Common/SerUtil.js'

import { Field, FieldType, FieldGroup, SelectOrManualInput, } from '../Common/Field.js'
import { Wall } from '../Components/Wall.js'
import { Roof } from '../Components/Roof.js'
import { Partition } from '../Components/Partition.js'
import { Floor } from '../Components/Floor.js'
import { Season, } from '../Components/Common.js'
import {
  SystemHeatingDesignTemps,
  SystemCoolingDesignTemps,
} from './SystemDesignTemps.js'
import { SystemDesignTemps } from './SystemDesignTempInputs.js'
import { FieldWithVariableUnits, MultiTieredSelect, } from '../Common/FieldUtils.js'
import { Units } from '../Common/Units.js'
import { InputComponent } from '../Common/InputComponent.js'

import { gApp, DebugOn } from '../Globals.js'

import { SpaceInteriorExterior, BuildingMass, SpacePercentGlass, SpaceHasCarpet } from './SpaceEnums.js'
import { SpaceTypesDataTable } from '../MaterialData/SpaceTypesDataTable.js'
import { VentilationEffectivenessData, } from '../Data/VentilationEffectivenessData.js'
import { SpaceInternals } from './SpaceInternals.js'
import { DataTable } from '../Data/DataTable.js'
import { CalcContext } from '../Common/CalcContext.js'
import * as psy from '../Components/Psychrometrics.js'

import { SpaceHeatingCalculator } from './SpaceHeatingCalculator.js'
import { SpaceCoolingCalculator } from './SpaceCoolingCalculator.js'

import { DataCache } from '../MaterialData/DataCache.js'
import * as MaterialDataTableRegistry from '../MaterialData/MaterialDataTableRegistry.js'

import * as math from 'mathjs'
import { MatrixUtils, MatrixPrototype } from '../Common/Math.js'

import {
  ResultsNode,
  ResultsNodeType,
} from './ResultsTree.js'

export let InfiltrationHours = makeEnum({
  AllHours: 'All hours',
  OccupiedHours: 'Only during occupied hours',
})

let VentilationInputType = makeEnum({
  Manual: 'Enter manually',
  Automatic: 'Enter automatically',
})

export class SpaceVentilation extends InputComponent {
  init(space) {
    this.space = space

    // Breathing zone ventilation:
    this.ventilationType = Field.makeSelect('Input type', VentilationInputType, {bold: true})
    this.ventilationHelpInfo = {
      helpText: `Enter the ventilation manually below or choose 'Enter automatically' to `
                + `estimate values from a space type.`
    }

    // For automatic selection:
    this.spaceTypeSelect = new MultiTieredSelect({
      name: 'Space Type',
      type: FieldType.Select,
      optionsMap: SpaceTypesDataTable.getInstance().getOptionsMap(),
      defaultValue: ["OfficeBuildings", "OfficeSpace"],
    })
    this.spaceTypeSelect.setVisibility(() => {
      return this.ventilationType.value == VentilationInputType.Automatic;
    })
    /*
    this.spaceTypeSelect.makeUpdater((field) => {
      console.log("New value: ", field.value);
    })
    */
 
    // Manual fields:
    this.manualVentilation = new FieldWithVariableUnits({
      name: 'Ventilation (1)',
      type: FieldType.AirFlow,
      units: Units.AirFlow,
      unitOptions: [Units.AirFlow, Units.AirFlowPerPerson, Units.AirFlowPerArea, Units.ACH],
      requiresInput: true,
    });
    this.manualVentilationB = new FieldWithVariableUnits({
      name: 'Ventilation (2)',
      type: FieldType.AirFlow,
      units: Units.AirFlow,
      unitOptions: [Units.AirFlow, Units.AirFlowPerPerson, Units.AirFlowPerArea, Units.ACH],
      defaultValue: 0,
      //requiresInput: true,
    })
    this.minimumVentilationRequirement = new Field({
      name: 'Minimum Requirement',
      type: FieldType.AirFlow,
      requiresInput: true,
    });

    this.updater.addWatchEffect('ventilation-values', () => {
      if (this.ventilationType.value == VentilationInputType.Manual) {
        this.manualVentilation.isOutput = false;
        this.manualVentilationB.isOutput = false;
        this.minimumVentilationRequirement.isOutput = false;
      } else if (this.ventilationType.value == VentilationInputType.Automatic) {
        this.manualVentilation.isOutput = true;
        this.manualVentilationB.isOutput = true;
        this.minimumVentilationRequirement.isOutput = true;
        // Set the fields to outputs, looked up from the space types.
        let ctx = CalcContext.create();
        let res = this._calcAutomaticVentilation(ctx);
        this.manualVentilation.value = res.cfm_per_person;
        this.manualVentilation.units = Units.AirFlowPerPerson;
        this.manualVentilationB.value = res.cfm_per_ft2;
        this.manualVentilationB.units = Units.AirFlowPerArea;
        this.minimumVentilationRequirement.value = res.V_bz_min;

        // Clear the required input b/c we use these values if we switch back to manual mode
        this.manualVentilation.requiresInput = false;
        this.manualVentilationB.requiresInput = false;
        this.minimumVentilationRequirement.requiresInput = false;
      } else {
        throw new Error(`Unknown ventilation type: ${this.ventilationType.value}`);
      }
    });

    this.outputTotalVentilation = new Field({
      name: 'Total Ventilation',
      type: FieldType.AirFlow,
      isOutput: true,
    })
    this.totalVentilationHelpInfo = null;
    this.outputTotalVentilation.makeUpdater((field) => {
      if (!this.space.internals) {
        // Due to initialization order, this can happen briefly. Just return until it is set.
        return;
      }
      let res = this.calcVentilation(CalcContext.create());
      field.value = res.V_bz;

      let numOccupants = this.space.internals.people.getNumOccupants().result;
      let floorArea = this.space.floorArea.value;
      let avgCeilingHeight = this.space.avgCeilingHeight.value;
      this.totalVentilationHelpInfo = {
        helpText: `Total ventilation is the sum of Ventilation (1) and Ventilation (2). Two inputs are provided in case you'd like to give `
                + `the ventilation by person and by area separately. Set Ventilation (2) to 0cfm if not needed.`,
        relatedValues: {
          V_bz_A: {
            label: 'Ventilation (1)',
            value: res.otherValues.V_bz_A,
            units: Units.AirFlow,
          },
          V_bz_B: {
            label: 'Ventilation (2)',
            value: res.otherValues.V_bz_B,
            units: Units.AirFlow,
          },
          occupants: {
            label: '# Occupants',
            value: numOccupants,
            units: Units.None,
          },
          floorArea: {
            label: 'Floor Area',
            value: floorArea,
            units: Units.ft2,
          },
          ceilingHeight: {
            label: 'Ceiling Height',
            value: avgCeilingHeight,
            units: Units.ft,
          }
        }
      }
    })

    // Effectiveness:
    this.ventilationEffectivenessCooling = new Field({
      name: 'Effectiveness (cooling) (E<sub>z</sub>)',
      type: FieldType.Ratio,
      allowMin: false,
      requiresInput: true,
    })
    this.ventilationEffectivenessHeating = new Field({
      name: 'Effectiveness (heating) (E<sub>z</sub>)',
      type: FieldType.Ratio,
      allowMin: false,
      requiresInput: true,
    })

    this.ventilationEffectivenessData = DataTable.create([
        {
          name: 'Ventilation Type',
          field: 'label',
        },
        {
          name: 'Ventilation Effectiveness (E_z)',
          field: 'value',
        }
      ], VentilationEffectivenessData)

    this.serFields = [
      'ventilationType',
      'spaceTypeSelect',
      'manualVentilation',
      'manualVentilationB',
      'minimumVentilationRequirement',
      'ventilationEffectivenessCooling',
      'ventilationEffectivenessHeating',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Ventilation',
    }
  }

  usesManualInput() {
    return this.ventilationType.value == VentilationInputType.Manual;
  }

  getManualVentilationValues(ctx) {
    ctx.assert(this.ventilationType.value == VentilationInputType.Manual,
      "Ventilation type must be manual");
    return this._calcManualVentilation(ctx);
  }

  getAutomaticVentilationValues(ctx) {
    ctx.assert(this.ventilationType.value == VentilationInputType.Automatic,
      "Ventilation type must be automatic");
    return this._calcAutomaticVentilation(ctx);
  }

  _calcAutomaticVentilation(ctx) {
    let zeroValue = {
      V_bz: 0,
      V_bz_min: 0,
      cfm_per_person: 0,
      cfm_per_ft2: 0,
      otherValues: {
        V_bz_A: 0,
        V_bz_B: 0,
      }
    }
    if (!this.space.internals) {
      // Due to initialization order, this can happen briefly. Just return zeroValue
      return zeroValue;
    }
    let data = this.getDataForSpaceType();
    if (data === null) {
      // Bad space type
      return zeroValue;
    }
    let numOccupants = this.space.internals.people.getNumOccupants().result;
    let spaceArea = this.space.floorArea.value;
    let ventilationByPerson = data.cfmPerPerson * numOccupants;
    let ventilationByArea = data.cfmPerFt2 * spaceArea;
    let V_bz = ventilationByPerson + ventilationByArea;
    let V_bz_min = ventilationByArea;
    let res = {
      V_bz,
      V_bz_min,
      cfm_per_person: data.cfmPerPerson,
      cfm_per_ft2: data.cfmPerFt2,
      otherValues: {
        V_bz_A: ventilationByPerson,
        V_bz_B: ventilationByArea,
      }
    };
    //console.log(`RES for type ${this.spaceTypeSelect.value}: ${prettyJson(res)}`);
    return res;
  }

  _calcManualVentilation(ctx) {
    let resA = this._convertVentilationFieldToAirFlow(this.manualVentilation);
    let resB = this._convertVentilationFieldToAirFlow(this.manualVentilationB);
    let V_bz_min = this.minimumVentilationRequirement.value;
    return {
      V_bz: resA.V_bz + resB.V_bz,
      V_bz_min,
      otherValues: {
        V_bz_A: resA.V_bz,
        V_bz_B: resB.V_bz,
      }
    }
  }

  _convertVentilationFieldToAirFlow(field) {
    let units = field.units;
    switch (units) {
      case Units.AirFlow: {
        let V_bz = field.value;
        return {V_bz};
      }
      case Units.AirFlowPerPerson: {
        let numOccupants = this.space.internals.people.getNumOccupants().result;
        let V_bz = field.value * numOccupants;
        return {
          V_bz,
        };
      }
      case Units.AirFlowPerArea: {
        let V_bz = field.value * this.space.floorArea.value;
        return {
          V_bz
        };
      }
      case Units.ACH: {
        let ACH = field.value;
        let spaceVolume = this.space.floorArea.value * this.space.avgCeilingHeight.value;
        let V_bz = ACH * spaceVolume / 60;
        return {V_bz};
      }
      default: {
        throw new Error(`Unknown ventilation unit: ${units}`);
      }
    }
  }

  // Aka: Return {V_bz, V_bz_min}
  calcVentilation(ctx) {
    if (this.ventilationType.value == VentilationInputType.Automatic) {
      return this._calcAutomaticVentilation(ctx);
    } else {
      return this._calcManualVentilation(ctx);
    }
  }

  calc_Ventilation_values(ctx) {
    ctx.startSection("Ventilation values")
    let ventilationValues = this.calcVentilation(ctx);
    //console.log(`CALCULATED V_BZ FOR SPACE ${this.space.name.value}: ${ventilationValues.V_bz}`)
    ctx.V_bz = ventilationValues.V_bz;
    ctx.V_bz_min = ventilationValues.V_bz_min;

    ctx.E_z_cooling = this.ventilationEffectivenessCooling.value;
    ctx.E_z_heating = this.ventilationEffectivenessHeating.value;
    ctx.assert(ctx.E_z_cooling > 0, "Ventilation effectiveness (E_z_cooling) must be > 0");
    ctx.assert(ctx.E_z_heating > 0, "Ventilation effectiveness (E_z_heating) must be > 0");

    ctx.V_oz_cooling = ctx.eval('V_bz/E_z_cooling', {
    }, 'V_oz_cooling');
    ctx.V_oz_cooling_min = ctx.eval('V_bz_min/E_z_cooling', {
    }, 'V_oz_cooling_min');
    // Minimum primary airflow rate
    ctx.V_pz_min_cooling = ctx.eval('1.5*V_oz_cooling', {
    }, 'V_pz_min_cooling');

    ctx.V_oz_heating = ctx.eval('V_bz/E_z_heating', {
    }, 'V_oz_heating');
    ctx.V_oz_heating_min = ctx.eval('V_bz_min/E_z_heating', {
    }, 'V_oz_heating_min');
    // Minimum primary airflow rate
    ctx.V_pz_min_heating = ctx.eval('1.5*V_oz_heating', {
    }, 'V_pz_min_heating');

    let res = {
      V_bz: ctx.V_bz,
      V_oz_cooling: ctx.V_oz_cooling,
      V_oz_cooling_min: ctx.V_oz_cooling_min,
      V_oz_heating: ctx.V_oz_heating,
      V_oz_heating_min: ctx.V_oz_heating_min,
      V_pz_min_cooling: ctx.V_pz_min_cooling,
      V_pz_min_heating: ctx.V_pz_min_heating,
    }
    ctx.endSection()
    return res;
  }

  getDataForSpaceType() {
    if (!this.spaceTypeSelect.hasValidPath() || this.spaceTypeSelect.isEmpty()) {
      return null;
    }
    //console.log("Getting data for space type: ", this.spaceType.value);
    let data = SpaceTypesDataTable.getInstance().getDataForSpaceType(
      this.spaceTypeSelect.value);
    return {
      cfmPerPerson: data['cfm/person'],
      cfmPerFt2: data['cfm/ft^2'],
    }
  }
};
setupClass(SpaceVentilation)

export let SpaceLoadResultsState = makeEnum({
  None: 'None',
  Calculating: 'Calculating',
  Ready: 'Ready',
})

export class SpaceLoadResults {
  init(calcContext) {
    this.calcContext = calcContext;
    this.results = {}
    this.error = null

    this.projectErrors = null;
  }
}
setupClass(SpaceLoadResults)

export class ZoneInfo {
  constructor(data) {
    this.t_i_summer = data.t_i_summer;
    this.t_i_winter = data.t_i_winter;
    this.zone_humidity = data.zone_humidity;
  }

  static createFromZone(zone) {
    let zoneInfo = new ZoneInfo({
      t_i_summer: zone.summerIndoorTemp.value,
      t_i_winter: zone.winterIndoorTemp.value,
      zone_humidity: zone.humiditySetpoint.value,
    });
    return zoneInfo;
  }
}

export class Space extends InputComponent {
  init(name, makeId) {
    this.name = Field.makeName(`Space Name`, name)
    this.id = makeId ? gApp.proj().makeId('Space') : 0;

    this.floorArea = new Field({
      name: 'Floor Area',
      type: FieldType.Area,
    })
    this.avgCeilingHeight = new Field({
      name: 'Average Ceiling Height',
      type: FieldType.Length,
    })

    // These fields are used to calculate RTS transform matrices
    this.buildingMass = Field.makeSelect('Construction Weight', BuildingMass)
    this.spaceInteriorExterior = Field.makeSelect('Interior or Exterior', SpaceInteriorExterior)
    this.percentGlass = Field.makeSelect('Percent Glass', SpacePercentGlass)
    this.hasCarpet = Field.makeSelect('Has Carpet', SpaceHasCarpet)

    this.sizeFields = [
      'floorArea',
      'avgCeilingHeight',
    ]
    this.moreInfoFields = [
      'buildingMass',
      'spaceInteriorExterior',
      'percentGlass',
      'hasCarpet',
    ]
    this.moreInfoHelp = {
      helpText: `The above fields are used to estimate the Radiant Time Series (RTS) vectors for the space. `
                + `Click the "Advanced" section below to see the vectors and get more info.`
    }

    this.ventilation = SpaceVentilation.create(this)

    let infiltrationUnitOptions = [
      Units.AirFlow,
      Units.CfmPerFt2WallArea,
      Units.CfmPerFtCrackLength,
      Units.ACH,
    ]
    this.summerInfiltration = new FieldWithVariableUnits({
      name: 'Summer Infiltration',
      type: FieldType.AirFlow,
      unitOptions: infiltrationUnitOptions,
      requiresInput: true,
    });
    this.winterInfiltration = new FieldWithVariableUnits({
      name: 'Winter Infiltration',
      type: FieldType.AirFlow,
      unitOptions: infiltrationUnitOptions,
      requiresInput: true,
    });
    this.infiltrationHours = Field.makeSelect('Infiltration Hours', InfiltrationHours)
    this.summerInfiltrationHelp = ''
    this.winterInfiltrationHelp = ''
    this.updater.addWatchEffect('infiltration-help', () => {
      let summerRes = this.calcInfiltrationFlowRate(CalcContext.create(), Season.Summer);
      let winterRes = this.calcInfiltrationFlowRate(CalcContext.create(), Season.Winter);
      this.summerInfiltrationHelp = summerRes.helpInfo;
      this.winterInfiltrationHelp = winterRes.helpInfo;
    });

    this.walls = []
    this.roofs = []
    this.floors = []
    this.partitions = []

    this.internals = SpaceInternals.create(this)

    // Used for the space load calculator, only
    this.resultsState = SpaceLoadResultsState.None;
    this.loadResults = null

    this.debugCalcOptions = {
      Calc_latent_loads: true,
      Calc_sensible_loads: true,
    }

    this.serFields = [
      'name',
      'id',
      'floorArea',
      'avgCeilingHeight',
      'buildingMass',
      'spaceInteriorExterior',
      'percentGlass',
      'hasCarpet',
      'ventilation',
      'summerInfiltration',
      'winterInfiltration',
      'infiltrationHours',
      ser.arrayField('walls', () => { return Wall.create(); }),
      ser.arrayField('roofs', () => { return Roof.create(); }),
      ser.arrayField('floors', () => { return Floor.create(); }),
      ser.arrayField('partitions', () => { return Partition.create(); }),
      'internals',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _uniqueName: true,
      _name: () => {
        return this.name.value;
      }
    }
  }

  serUtilPreTransformJson(data) {
    // Move spaceType from toplevel to ventilation section, to support old formats
    let dataCopy = deepCopyObject(data);
    if (dataCopy.spaceType) {
      dataCopy.ventilation = dataCopy.ventilation || {};
      dataCopy.ventilation.spaceTypeSelect = dataCopy.spaceType;
      delete dataCopy.spaceType;
    }
    return dataCopy;
  }

  static getTypeMetadata() {
    return {
      name: 'Space',
      createType: Space.createType,
      openEditor: Space.openEditor,
    }
  }

  static createType() {
    let type = Space.create(generateItemName('Space', gApp.proj().spaces, 'TYPE_NAME-CTR'), true)
    gApp.proj().spaces.push(type);
    sortItemsByName(gApp.proj().spaces);
    return type
  }

  static openEditor(type) {
    let pathName = 'spaces';
    gApp.proj().editorState.selectedSpace = type;
    gApp.router.push({name: pathName});
  }

  getInputPage() {
    return {
      label: `Space - ${this.name.value}`,
      path: `spaces/${this.id}`,
    }
  }

  static getTableInfo() {
    return {
      typeName: 'Space',
      allowDuplicate: true,
      columns: {
        'name': {
          label: 'Name',
        }
      }
    }
  }

  getTableData() {
    return {
      name: this.name.value
    }
  }

  getName() {
    return this.name.value;
  }

  getRTSTransformArgs() {
    return {
      spaceInteriorExterior: this.spaceInteriorExterior.value,
      buildingMass: this.buildingMass.value,
      hasCarpet: this.hasCarpet.value,
      percentGlass: this.percentGlass.value,
    }
  }

  getSolarRTSVector() {
    let args = this.getRTSTransformArgs();
    return gApp.proj().timeSeriesData.getSolarRTSValues(args);
  }

  getNonSolarRTSVector() {
    let args = this.getRTSTransformArgs();
    return gApp.proj().timeSeriesData.getNonSolarRTSValues(args);
  }

  async calculateLoadsAsync(ctx, zoneInfo, opts) {
    opts = valOr(opts, {});  

    ctx.debugOptions = this.debugCalcOptions;

    ctx.startLocalSection("Basic")

    ctx.t_i_summer = zoneInfo.t_i_summer;
    ctx.t_i_winter = zoneInfo.t_i_winter;
    ctx.zone_humidity = zoneInfo.zone_humidity;

    // TODO - are these set properly?
    ctx.require('summerIndoorRH')
    ctx.require('winterIndoorRH')
    ctx.endSection()

    ctx.startProgressSection("Space", {
      "Heating": {},
      "Cooling": {},
    })

    ctx.setProgress("Heating")
    let heatingCalculator = new SpaceHeatingCalculator(this, ctx);
    let heatingResults = heatingCalculator.calcOutputs();

    ctx.setProgress("Cooling")
    let coolingCalculator = new SpaceCoolingCalculator(this, ctx);
    let coolingResults = await coolingCalculator.calcOutputs();

    ctx.endProgressSection()

    let resultsNode = new ResultsNode(ResultsNodeType.VerticalSplit, 'Space Load Results');
    resultsNode.children.push(heatingResults.resultsNode);
    resultsNode.children.push(coolingResults.resultsNode);
    //console.log("RESULTS NODE: ", resultsNode);

    let results = {
      heating: heatingResults,
      cooling: coolingResults,
      plenum_loads: coolingResults.q_plenum,
      resultsNode: resultsNode,
    }

    return results;
  }

  _getSampleZoneInfo() {
    /*
    Get some zone info that makes sense for the Space-only calculations.
    Try to get to get from an actual Zone using this Space, but fall-back
    to some standard values if not available.
    */
   let zoneInfo = null;
    for (const system of gApp.proj().systems) {
      for (const zone of system.zones) {
        if (zone.hasSpaceWithId(this.id)) {
          zoneInfo = ZoneInfo.createFromZone(zone);
          break;
        }
      }
    }
    if (!zoneInfo) {
      // Fallback to some standard values
      zoneInfo = new ZoneInfo({
        t_i_summer: 75,
        t_i_winter: 70,
        zone_humidity: 50,
      });
    }
    return zoneInfo;
  }

  _getProjectObjectsToIgnoreForErrors() {
    /*
    When we update the project errors before running calculations, we check all Project
    objects for errors. Really, we only have to check objects needed by this Space. So
    make a list of objects to ignore.
    */
    let ignoreList = []
    let proj = gApp.proj();

    // Ignore all Systems
    for (const system of proj.systems) {
      ignoreList.push(system);
    }

    // Ignore all other Spaces
    for (const space of proj.spaces) {
      if (space.id != this.id) {
        ignoreList.push(space);
      }
    }

    // Note - should probs also ignore other stuff, but fine for now.

    return ignoreList;
  }

  async calculateDebugLoadsAsync() {
    if (this.resultsState == SpaceLoadResultsState.Calculating) {
      console.log("Already running calculations. Ignoring.");
      return;
    }
    try {
      console.log("Calculating loads for space: ", this.name.value);
      this.resultsState = SpaceLoadResultsState.Calculating;

      // Check for project errors before running any calculations
      let objectsToIgnoreForErrors = this._getProjectObjectsToIgnoreForErrors();
      console.log("Using ignore list for errors: ", objectsToIgnoreForErrors);
      let projectErrorOpts = {
        ignoreSet: new Set(objectsToIgnoreForErrors),
      }
      gApp.proj().updateProjectErrors(projectErrorOpts);
      if (gApp.proj().getProjectErrors()) {
        console.log("Project has errors, aborting calculation");
        this.loadResults = SpaceLoadResults.create(null);
        this.loadResults.projectErrors = gApp.proj().getProjectErrors();
        this.resultsState = SpaceLoadResults.Ready;
        return;
      }

      let ctx = CalcContext.create();
      this.loadResults = SpaceLoadResults.create(ctx);

      let tablesCache = new DataCache();
      MaterialDataTableRegistry.registerCommercialTables(tablesCache);
      ctx.tablesCache = tablesCache;

      let proj = gApp.proj();
      let locationData = proj.buildingAndEnv.getLocationData();
      ctx.startLocalSection("Proj values")
      console.log("Location data: ", locationData);
      ctx.toplevelData = {
        locationData: locationData.getOutputs(),
        dayOfMonth: 21,
      }
      ctx.buildingAndEnv = proj.buildingAndEnv.getOutputs();
      console.log("toplevel data: ", ctx.toplevelData);
      ctx.altitude  = ctx.toplevelData.locationData.elevation;
      ctx.P_loc = ctx.call(psy.calcP_loc, ctx.altitude)
      ctx.endSection()

      // We have to set some placeholder values, which would normally come from the System or Zone
      // inputs.
      ctx.startLocalSection("Dummy system values")
      ctx.log("Using placeholder values...")

      ctx.designTemps = new SystemDesignTemps(
        SystemHeatingDesignTemps.Temp99p6,
        SystemCoolingDesignTemps.Temp0p4,
        ctx.toplevelData.locationData
      )
      let sampleZoneInfo = this._getSampleZoneInfo();
      ctx.logValue("Sample zone info", sampleZoneInfo);

      ctx.summerIndoorRH = 0.50;
      ctx.winterIndoorRH = 0.30;

      ctx.endSection()

      this.loadResults.results = await this.calculateLoadsAsync(ctx, sampleZoneInfo, {
        debugMode: true
      });
    } catch (err) {
      console.log("Error calculating Space loads: ", err);
      ctx.logFatalError(err);
      this.loadResults.error = err;
    } finally {
      this.resultsState = SpaceLoadResultsState.Ready;
    }
    return this.loadResults;
  }

  getDummyLoadResults(ctx, opts) {
    opts = valOr(opts, {});
    ctx.startSection("Space - calculate dummy load results")
    let heatingResults = {
      q_sensible: 100,
      q_latent: 200,
      q_total: 300,
    }
    let q_sens_cooling = math.zeros(12, 24);
    let q_latent_cooling = math.zeros(12, 24);
    for (let mo = 0; mo < 12; ++mo) {
      for (let hr = 0; hr < 24; ++hr) {
        q_sens_cooling.set([mo, hr], 100*mo + hr);
        q_latent_cooling.set([mo, hr], 200*mo + hr);
      }
    }
    let coolingResults = {
      q_sensible: q_sens_cooling,
      q_latent: q_latent_cooling,
      q_total: math.add(q_sens_cooling, q_latent_cooling),
    }

    let plenum_loads = math.zeros(12, 24);
    for (let mo = 0; mo < 12; ++mo) {
      for (let hr = 0; hr < 24; ++hr) {
        plenum_loads.set([mo, hr], 300*mo + hr);
      }
    }

    ctx.results = {
      heating: heatingResults,
      cooling: coolingResults,
      plenum_loads: plenum_loads,
    }
    let results = ctx.results;

    ctx.endSection()
    return results;
  }

  static writeWorkerResultsToJson(results) {
    if (!results) {
      return results;
    }
    return ser.writeGenericObjectToJson(results);
  }

  static readWorkerResultsFromJson(data) {
    if (!data) {
      return null;
    }
    return ser.readGenericObjectFromJson(data, {
      typeList: [MatrixPrototype],
    });
  }

  getNumOccupants() {
    return this.internals.people.getNumOccupants().result;
  }

  isOccupied(hr) {
    return this.internals.people.getSchedule().getData()[hr] > 0;
  }

  getOccupancySchedule() {
    return this.internals.people.getSchedule();
  }

  getNumOccupantsAtHour(hourIndex) {
    let schedData = this.getOccupancySchedule().getData();
    return Math.ceil(schedData[hourIndex]*this.getNumOccupants());
  }

  getExposedArea(ctx) {
    let A_exp_walls = 0;
    for (let i = 0; i < this.walls.length; i++) {
      let wall = this.walls[i];
      A_exp_walls += wall.getArea();
    }
    let A_exp_roofs = 0;
    for (let i = 0; i < this.roofs.length; i++) {
      let roof = this.roofs[i];
      A_exp_roofs += roof.getArea();
    }
    let A_exp_floors = 0;
    for (let i = 0; i < this.floors.length; i++) {
      let floor = this.floors[i];
      if (floor.isFloorRaised()) {
        A_exp_floors += floor.getArea();
      }
    }
    return ctx.eval('A_exp_walls + A_exp_roofs + A_exp_floors', {
      A_exp_walls: A_exp_walls,
      A_exp_roofs: A_exp_roofs,
      A_exp_floors: A_exp_floors,
    }, 'A_exp');
  }

  getTotalCrackLength(ctx) {
    let L_crack_windows = 0;
    let L_crack_doors = 0;
    for (let i = 0; i < this.walls.length; i++) {
      let wall = this.walls[i];
      for (let j = 0; j < wall.windows.length; j++) {
        let window = wall.windows[j];
        let windowType = window.getWindowType();
        L_crack_windows += window.quantity.value * windowType.getPerimeter();
      }
      for (let j = 0; j < wall.doors.length; j++) {
        let door = wall.doors[j];
        let doorType = door.getDoorType();
        L_crack_doors += door.quantity.value * doorType.getPerimeter();
      }
    }
    let L_crack_skylights = 0;
    for (let i = 0; i < this.roofs.length; i++) {
      let roof = this.roofs[i];
      for (let j = 0; j < roof.skylights.length; j++) {
        let skylight = roof.skylights[j];
        let skylightType = skylight.getSkylightType();
        L_crack_skylights += skylight.quantity.value * skylightType.getPerimeter();
      }
    }
    return ctx.eval('L_crack_windows + L_crack_doors + L_crack_skylights', {
      L_crack_windows: L_crack_windows,
      L_crack_doors: L_crack_doors,
      L_crack_skylights: L_crack_skylights,
    }, 'L_crack');
  }

  calcInfiltrationFlowRate(ctx, season) {
    let infiltrationField = season == Season.Summer ? this.summerInfiltration : this.winterInfiltration;
    ctx.Inp = infiltrationField.value;
    ctx.InpUnits = infiltrationField.units;
    let helpInfo = null;

    switch (ctx.InpUnits) {
      case Units.AirFlow: {
        ctx.Q_inf = ctx.eval('Inp', {}, 'Q_inf');
        let helpText = `You may also enter infiltration based on wall area, crack length, or ACH by clicking the units dropdown.`
        helpInfo = {
          helpText: helpText,
        };
        break;
      }
      case Units.CfmPerFt2WallArea: {
        ctx.A_exp = this.getExposedArea(ctx);
        ctx.Q_inf = ctx.eval('Inp*A_exp', {}, 'Q_inf');
        let helpText = `Here, infiltration is calculated as <b>Input × ExposedArea</b>. The exposed `
          + `area is the sum of the wall, roof, and raised floor areas of the space.`;
        helpInfo = {
          helpText: helpText,
          result: {
            label: 'Infiltration',
            value: ctx.Q_inf,
            units: Units.AirFlow,
          },
          relatedValues: {
            A_exp: {
              label: 'Exposed Area',
              value: ctx.A_exp,
              units: Units.ft2,
            },
          }
        }
        break;
      }
      case Units.CfmPerFtCrackLength: {
        ctx.L_crack = this.getTotalCrackLength(ctx);
        ctx.Q_inf = ctx.eval('Inp*L_crack', {}, 'Q_inf');
        let helpText = `Here, infiltration is calculated as <b>Input × TotalCrackLength</b>. The total `
          + `crack length is the sum of the perimeters of all windows, doors, and skylights in `
          + `the space.`;
        helpInfo = {
          helpText: helpText,
          result: {
            label: 'Infiltration',
            value: ctx.Q_inf,
            units: Units.AirFlow,
          },
          relatedValues: {
            L_crack: {
              label: 'Total Crack Length',
              value: ctx.L_crack,
              units: Units.ft,
            },
          }
        }
        break;
      }
      case Units.ACH: {
        // Note: we use the user input here for floor area, instead of summing
        // the area of all floors.
        ctx.A_floor = this.floorArea.value;
        ctx.H_c = this.avgCeilingHeight.value;
        ctx.Q_inf = ctx.eval('Inp*A_floor*H_c/60.0', {
        }, 'Q_inf');
        let helpText = `Here, infiltration is calculated as <b>Input × FloorArea × AvgCeilingHeight / 60.0</b>`
          + ` (to convert from ACH to cfm).`
        helpInfo = {
          helpText: helpText,
          result: {
            label: 'Infiltration',
            value: ctx.Q_inf,
            units: Units.AirFlow,
          },
          relatedValues: {
            A_floor: {
              label: 'Floor Area',
              value: ctx.A_floor,
              units: Units.ft2,
            },
            H_c: {
              label: 'Ceiling Height',
              value: ctx.H_c,
              units: Units.ft,
            },
          }
        }
        break;
      }
      default:
        throw new Error(`Unknown infiltration unit: ${this.winterInfiltration.units}`);
    }
    return {
      Q_inf: ctx.Q_inf,
      helpInfo: helpInfo,
    }
  }

  getTotalWallArea() {
    let A_walls = 0;
    for (let i = 0; i < this.walls.length; i++) {
      let wall = this.walls[i];
      A_walls += wall.getArea();
    }
    return A_walls;
  }

  getTotalRoofArea() {
    let A_roofs = 0;
    for (let i = 0; i < this.roofs.length; i++) {
      let roof = this.roofs[i];
      A_roofs += roof.getArea();
    }
    return A_roofs;
  }

  getTotalWindowArea() {
    let A_windows = 0;
    for (let i = 0; i < this.walls.length; i++) {
      let wall = this.walls[i];
      A_windows += wall.getWindowArea();
    }
    return A_windows;
  }

  getTotalDoorArea() {
    let A_doors = 0;
    for (let i = 0; i < this.walls.length; i++) {
      let wall = this.walls[i];
      A_doors += wall.getDoorArea();
    }
    return A_doors;
  }

  getTotalSkylightArea() {
    let A_skylights = 0;
    for (let i = 0; i < this.roofs.length; i++) {
      let roof = this.roofs[i];
      A_skylights += roof.getSkylightArea();
    }
    return A_skylights;
  }

  getWinterInfiltration() {
    let res = this.calcInfiltrationFlowRate(CalcContext.create(), Season.Winter);
    return res.Q_inf;
  }

  getNumAppliances() {
    return this.internals.getNumAppliances();
  }

  getMiscLoad() {
    return this.internals.getMiscLoad();
  }
}
setupClass(Space)
