<script setup>
import { ref, onMounted, reactive, computed, defineEmits, } from 'vue'
import { getColorFromUsername } from '../../SharedUtils'

let props = defineProps({
  username: String,
})

function getFirstLetter(str) {
  return str[0];
}

</script>

<template>
  <div class="ProfileBtn" 
       :title="username" 
       :data-username="username || '?'"
       :style="{ backgroundColor: getColorFromUsername(username) }">
    <p class="upper white f-xs">{{getFirstLetter(username || '?')}}</p>
  </div>
</template>

<style scoped>
.ProfileBtn {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 700;
  text-align: center;
  position: relative;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
}

.ProfileBtn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.ProfileBtn::after {
  content: attr(data-username);
  position: absolute;
  bottom: 105%; /* Position above the element */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: var(--xxs) var(--xs);
  border-radius: 3px;
  font-size: var(--f-s);
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
  z-index: 100;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.ProfileBtn:hover::after {
  opacity: 1;
  visibility: visible;
}

</style>
