import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
  interpolateInMap, doubleInterpolateInMap,
  IdsMap, PleaseContactStr,
  IntervalTimer,
} from '../Base.js'
import {
  secsToDurationStr,
  generateItemName,
} from '../SharedUtils.js'
import * as ser from '../Common/SerUtil.js'
import { OutputValue } from '../Common/OutputValue.js'
import { InputComponent } from '../Common/InputComponent.js'

export { Units } from '../Common/Units.js'

import { RecoveryType, } from '../Components/HouseVentilationInfiltration.js'
import { makeNoYesField, makeYesNoField, YesNo, } from '../Components/Common.js'
import { Field, FieldGroup, FieldType, } from '../Common/Field.js'

import { Zone } from './Zone.js'
import { Schedule } from './Schedule.js'

import { CalcContext } from '../Common/CalcContext.js'

import { SystemLoadCalculator } from './SystemLoadCalculator.js'
import { SystemDesignTempInputs } from './SystemDesignTempInputs.js'
import { SystemHeatingDesignTemps, SystemCoolingDesignTemps } from './SystemDesignTemps.js'

import { gApp, DebugOn, IsTestEnv, } from '../Globals.js'

export class SystemFan {
  init() {
    this.power = new Field({
      name: 'Power',
      type: FieldType.Power,
      requiresInput: true,
      allowMin: false,
    })
    this.motorIsInAirStream = makeYesNoField('Motor is in air stream')
    this.externalStaticPressure = new Field({
      name: 'External Static Pressure',
      type: FieldType.SmallPressure,
      requiresInput: true,
      allowMin: false,
    })
    this.fanEfficiency = new Field({
      name: 'Fan Efficiency',
      type: FieldType.Percent,
      requiresInput: true,
      allowMin: false,
    })
    this.motorEfficiency = new Field({
      name: 'Motor Efficiency',
      type: FieldType.Percent,
      requiresInput: true,
      allowMin: false,
    })

    this.fields = [
      'power',
      'motorIsInAirStream',
      'externalStaticPressure',
      'fanEfficiency',
      'motorEfficiency',
    ]

    this.serFields = [
      ...this.fields,
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'SystemFan',
    }
  }
}
setupClass(SystemFan)

class SystemFans extends InputComponent {
  init() {
    this.useSupplyFan = makeYesNoField('Use supply fan', {bold: true})
    this.supplyFan = SystemFan.create()
    this.updater.setEnabledWhen('supply-fan', this.supplyFan, () => {
      return this.useSupplyFan.value == YesNo.Yes;
    });

    this.useReturnFan = makeYesNoField('Use exhaust fan', {
      bold: true,
      defaultValue: YesNo.No,
    })
    this.returnFan = SystemFan.create()
    this.updater.setEnabledWhen('return-fan', this.returnFan, () => {
      return this.useReturnFan.value == YesNo.Yes;
    });

    this.serFields = [
      'useSupplyFan',
      'supplyFan',
      'useReturnFan',
      'returnFan',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'SystemFans',
    }
  }

  usingSupplyFan() {
    return this.useSupplyFan.value == YesNo.Yes;
  }

  usingReturnFan() {
    return this.useReturnFan.value == YesNo.Yes;
  }
}
setupClass(SystemFans)

let ExhaustAirType = makeEnum({
  MatchSupplyFlowThroughUnit: `Match supply flow through unit`,
  SpecifiedPortionOfSupplyFlow: `Specified portion of supply flow`,
  ManuallySetExhaustFlow: `Manually set exhaust flow`,
})

export class SystemHeatRecovery {
  init() {
    this.recoveryType = Field.makeSelect('Recovery Type', RecoveryType, {bold: true})

    this.exhaustAir = FieldGroup.fromDict({
      'entryType': Field.makeSelect('Exhaust Air', ExhaustAirType),
      'portionOfSupplyFlow': new Field({
        name: 'Portion of Supply Flow',
        type: FieldType.Percent,
      }),
      'manualValue': new Field({
        name: 'Flow',
        type: FieldType.AirFlow,
        defaultValue: 100,
        min: 0,
      }),
    })
    this.exhaustAir.get('entryType').setVisibility(() => {
      return this.recoveryType.value != RecoveryType.None;
    })
    this.exhaustAir.get('portionOfSupplyFlow').setVisibility(() => {
      return this.recoveryType.value != RecoveryType.None && this.exhaustAir.get('entryType').value == ExhaustAirType.SpecifiedPortionOfSupplyFlow;
    });
    this.exhaustAir.get('manualValue').setVisibility(() => {
      return this.recoveryType.value != RecoveryType.None && this.exhaustAir.get('entryType').value == ExhaustAirType.ManuallySetExhaustFlow;
    });

    this.hrvGroup = new FieldGroup([
      new Field({
        key: 'summerEfficiency',
        name: 'Summer Efficiency',
        type: FieldType.Percent,
        defaultValue: 55,
      }),
      new Field({
        key: 'winterEfficiency',
        name: 'Winter Efficiency',
        type: FieldType.Percent,
        defaultValue: 75,
      })
    ]);
    this.hrvGroup.setVisibility(() => {
      return this.recoveryType.value == RecoveryType.HRV;
    })

    this.ervGroup = new FieldGroup([
      new Field({
        key: 'summerSensibleEfficiency',
        name: 'Summer sensible efficiency',
        type: FieldType.Percent,
        defaultValue: 55,
      }),
      new Field({
        key: 'summerTotalEfficiency',
        name: 'Summer total efficiency',
        type: FieldType.Percent,
        defaultValue: 60,
      }),
      new Field({
        key: 'winterSensibleEfficiency',
        name: 'Winter sensible efficiency',
        type: FieldType.Percent,
        defaultValue: 75,
      }),
      new Field({
        key: 'winterTotalEfficiency',
        name: 'Winter total efficiency',
        type: FieldType.Percent,
        defaultValue: 80,
      }),
    ]);
    this.ervGroup.setVisibility(() => {
      return this.recoveryType.value == RecoveryType.ERV;
    })

    this.serFields = [
      'recoveryType',
      'exhaustAir',
      'hrvGroup',
      'ervGroup',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'HeatRecovery',
    }
  }

  calc_Q_exhaust(ctx, V_ot) {
    let entryType = this.exhaustAir.get('entryType').value;
    ctx.exhaustEntryType = entryType;
    if (entryType == ExhaustAirType.MatchSupplyFlowThroughUnit) {
      return V_ot;
    } else if (entryType == ExhaustAirType.SpecifiedPortionOfSupplyFlow) {
      return V_ot * this.exhaustAir.get('portionOfSupplyFlow').value / 100.0;
    } else if (entryType == ExhaustAirType.ManuallySetExhaustFlow) {
      return this.exhaustAir.get('manualValue').value;
    } else {
      throw new Error(`Unknown entry type: ${entryType}`);
    }
  }
}
setupClass(SystemHeatRecovery)

// Right now, we only support CAV and VAV systems
export let SystemType = makeEnum({
  CAV: 'CAV',
  VAV: 'VAV',
  // Unsupported
  RadiantInductionOrChilledBeam: 'Radiant, induction, or chilled beam',
  // Unsupported
  Unknown: 'Unknown',
})

let ReliefAirOptions = makeEnum({
  PercentageOfVentilationAir: 'Percentage of ventilation air',
  Manual: 'Manual',
})

export let ZoneAirflowCalcMethod = makeEnum({
  SumOfPeaks: 'Sum of peaks',
  TotalPeak: 'Total peak',
})

function checkHeatingSupplyTempSupportsZones(field, system) {
  if (system.zones.length == 0) {
    return;
  }
  let heatingSupplyTemp = field.value;
  let maxWinterTemp = null;
  let unitsLabel = system.zones[0].winterIndoorTemp.getUnitsLabel();
  for (const zone of system.zones) {
    let zoneTargetTemp = zone.winterIndoorTemp.value;
    maxWinterTemp = maxWinterTemp == null ? zoneTargetTemp : Math.max(maxWinterTemp, zoneTargetTemp);
  }
  if (heatingSupplyTemp <= maxWinterTemp) {
    field.setEntryErrorMsg(`This temperature must be >${maxWinterTemp}${unitsLabel} in order to heat` +
      ` all zones to their desired winter indoor temperatures.`);
    return;
  }
  field.setEntryErrorMsg(null)
}

function checkCoolingSupplyTempSupportsZones(field, system) {
  if (system.zones.length == 0) {
    return;
  }
  let coolingSupplyTemp = field.value;
  let minSummerTemp = null;
  let unitsLabel = system.zones[0].summerIndoorTemp.getUnitsLabel();
  for (const zone of system.zones) {
    let zoneTargetTemp = zone.summerIndoorTemp.value;
    minSummerTemp = minSummerTemp == null ? zoneTargetTemp : Math.min(minSummerTemp, zoneTargetTemp);
  }
  if (coolingSupplyTemp >= minSummerTemp) {
    field.setEntryErrorMsg(`This temperature must be <${minSummerTemp}${unitsLabel} in order to cool` +
      ` all zones to their desired summer indoor temperatures.`);
    return;
  }
  field.setEntryErrorMsg(null)
}

class CAVSystemInputs {
  init(system) {
    this.system = system;

    this.heatingSupplyTemp = new Field({
      name: 'Heating Supply Temp',
      type: FieldType.Temp,
      requiresInput: true,
    })
    this.heatingSupplyTemp.makeUpdater((field) => {
      checkHeatingSupplyTempSupportsZones(field, this.system);
    })
    this.coolingSupplyTemp = new Field({
      name: 'Cooling Supply Temp',
      type: FieldType.Temp,
      requiresInput: true,
    })
    this.coolingSupplyTemp.makeUpdater((field) => {
      checkCoolingSupplyTempSupportsZones(field, this.system);
    })

    this.includeZoneTerminalReheat = makeYesNoField('Include zone terminal reheat')
    this.zoneAirflowCalcMethod = Field.makeSelect('Zone Airflow Calculation Method', ZoneAirflowCalcMethod)

    this.fields = [
      'heatingSupplyTemp',
      'coolingSupplyTemp',
      'includeZoneTerminalReheat',
      'zoneAirflowCalcMethod',
    ]

    this.serFields = [
      ...this.fields
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: "CAV Details",
    }
  }
}
setupClass(CAVSystemInputs)

class VAVSystemInputs {
  init(system) {
    this.system = system;

    this.heatingSupplyTemp = new Field({
      name: 'Heating Supply Temp',
      type: FieldType.Temp,
      requiresInput: true,
    })
    this.heatingSupplyTemp.makeUpdater((field) => {
      checkHeatingSupplyTempSupportsZones(field, this.system);
    })
    this.coolingSupplyTemp = new Field({
      name: 'Cooling Supply Temp',
      type: FieldType.Temp,
      requiresInput: true,
    })
    this.coolingSupplyTemp.makeUpdater((field) => {
      checkCoolingSupplyTempSupportsZones(field, this.system);
    })
    this.includeZoneTerminalReheat = makeYesNoField('Include zone terminal reheat')

    this.fields = [
      'heatingSupplyTemp',
      'coolingSupplyTemp',
      'includeZoneTerminalReheat',
    ]

    this.serFields = [
      ...this.fields
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: "VAV Details",
    }
  }
}
setupClass(VAVSystemInputs)

let PrimaryAirCoolingEntry = makeEnum({
  Airflow: 'Airflow',
  WetBulbTemp: 'Supply Wet Bulb Temp',
  DewPointTemp: 'Supply Dew Point Temp',
  RelativeHumidity: 'Supply Relative Humidity',
})

/*
TODO - currently unused
*/
class RadiantInductionSystemInputs {
  init() {
    this.primaryAirHeatingSupplyTemp = new Field({
      name: 'Primary Air Heating Supply Temp',
      type: FieldType.Temp,
    })
    this.primaryAirCoolingSupplyTemp = new Field({
      name: 'Primary Air Cooling Supply Temp',
      type: FieldType.Temp,
    })
    this.primaryAirCooling = Field.makeSelect('Primary Air Cooling', PrimaryAirCoolingEntry)

    this.airflow = new Field({
      name: 'Airflow',
      type: FieldType.Flow,
    })
    this.airflow.setVisibility(() => {
      return this.primaryAirCooling.value == PrimaryAirCoolingEntry.Airflow;
    })
    this.supplyWetBulbTemp = new Field({
      name: 'Supply Wet Bulb Temp',
      type: FieldType.Temp,
    })
    this.supplyWetBulbTemp.setVisibility(() => {
      return this.primaryAirCooling.value == PrimaryAirCoolingEntry.WetBulbTemp;
    })
    this.supplyDewPointTemp = new Field({
      name: 'Supply Dew Point Temp',
      type: FieldType.Temp,
    })
    this.supplyDewPointTemp.setVisibility(() => {
      return this.primaryAirCooling.value == PrimaryAirCoolingEntry.DewPointTemp;
    })
    this.supplyRelativeHumidity = new Field({
      name: 'Supply Relative Humidity',
      type: FieldType.Percent,
    })
    this.supplyRelativeHumidity.setVisibility(() => {
      return this.primaryAirCooling.value == PrimaryAirCoolingEntry.RelativeHumidity;
    })

    this.fields = [
      'primaryAirHeatingSupplyTemp',
      'primaryAirCoolingSupplyTemp',
      'primaryAirCooling',
      'airflow',
      'supplyWetBulbTemp',
      'supplyDewPointTemp',
      'supplyRelativeHumidity',
    ]

    this.serFields = [
      ...this.fields
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: "Radiant Induction System Details",
    }
  }
}
setupClass(RadiantInductionSystemInputs)

export let SystemResultsState = makeEnum({
  None: 'None',
  Calculating: 'Calculating',
  Ready: 'Ready',
})

export class SystemLoadResults {
  init(calcContext) {
    this.calcContext = calcContext
    this.heatingDesignTemp = null
    this.coolingDesignTemp = null

    this.results = null
    this.projectErrors = null
    this.error = null
    this.startTime = IsTestEnv() ? 10000 : Date.now();
    this.endTime = null;

    // Note - we don't save the calcContext, that is just for internal debugging.
    this.serFields = [
      'heatingDesignTemp',
      'coolingDesignTemp',
      ser.genericObjectField('results', [OutputValue]),
      'projectErrors',
      'error',
      'startTime',
      'endTime',
    ]
  }

  hasResults() {
    return this.results != null;
  }

  setDesignTemps(heatingDesignTemp, coolingDesignTemp) {
    this.heatingDesignTemp = heatingDesignTemp;
    this.coolingDesignTemp = coolingDesignTemp;
  }

  setProjectErrors(errors) {
    this.projectErrors = errors;
  }

  getProjectErrors() {
    return this.projectErrors;
  }

  getError() {
    return this.error;
  }

  setError(err) {
    this.error = err.toString();
    this.endTime = IsTestEnv() ? 20000 : Date.now();
  }

  setResults(results) {
    this.results = results;
    this.endTime = IsTestEnv() ? 20000 : Date.now();
  }

  resultsGood() {
    return this.error == null && this.projectErrors == null;
  }
  
  getOutputSummary() {
    return this.results.outputSummary;
  }

  getDetailedOutputBreakdown() {
    return this.results.detailedOutputBreakdown;
  }

  getSystemInputSummary() {
    return this.results.systemInputSummary;
  }

  getSpaceInputSummary() {
    return this.results.spaceInputSummary;
  }

  getProgressStr() {
    if (!this.calcContext) {
      return 'Calculation in progress...';
    }
    return this.calcContext.getProgressText();
  }

  getDetailedProgressStr() {
    if (!this.calcContext) {
      return 'Calculation in progress...';
    }
    return this.calcContext.getDetailedProgressText();
  }

  getProgressPercent() {
    if (!this.calcContext) {
      return 0;
    }
    return this.calcContext.getProgressPercent();
  }

  getTimeCalculatingStr() {
    let secsCalculating = (Date.now() - this.startTime) / 1000;
    return secsToDurationStr(secsCalculating);
  }

  getDurationStr() {
    let secsCalculating = (this.endTime - this.startTime) / 1000;
    return secsToDurationStr(secsCalculating);
  }

  getStartDateStr() {
    return new Date(this.startTime).toLocaleString();
  }

  getHeatingDesignTempStr() {
    return SystemHeatingDesignTemps.getLabel(this.heatingDesignTemp);
  }

  getCoolingDesignTempStr() {
    return SystemCoolingDesignTemps.getLabel(this.coolingDesignTemp);
  }
}
setupClass(SystemLoadResults)

export class System extends InputComponent {
  init(name, makeId) {
    this.name = Field.makeName(`System Name`, name)
    this.id = makeId ? gApp.proj().makeId('System') : 0;
    this.zones = []

    this.estimatedTotalOccupancy = new Field({
      name: 'Estimated Total Occupancy',
      type: FieldType.Count,
      requiresInput: true,
      allowMin: false,
    })

    this.overrideSystemVentilationIntake = makeNoYesField('Override system ventilation intake?', {
      bold: true,
    })
    this.minSystemVentilationIntake = new Field({
      name: 'Minimum System Ventilation Intake (V<sub>ot,min</sub>)',
      type: FieldType.AirFlow,
      allowMin: true,
      requiresInput: true,
    })
    this.maxSystemVentilationIntake = new Field({
      name: 'Maximum System Ventilation Intake (V<sub>ot,max</sub>)',
      type: FieldType.AirFlow,
      allowMin: true,
      requiresInput: true,
    })
    this.minSystemVentilationIntake.setVisibility(() => {
      return this.overrideSystemVentilationIntake.value == YesNo.Yes;
    })
    this.maxSystemVentilationIntake.setVisibility(() => {
      return this.overrideSystemVentilationIntake.value == YesNo.Yes;
    })

    this.changeVentilationForModes = makeYesNoField('Change ventilation for heating and cooling modes?', {
      bold: true,
    })
    this.demandControlledVentilation = FieldGroup.fromDict({
      'use': makeNoYesField('Use demand controlled ventilation', {bold: true}),
      'matchOccupancySchedule': makeYesNoField('Match system occupancy schedule'),
      'schedule': Field.makeTypeSelect('Schedule', gApp.proj().schedules, null, {
        errorWhenEmpty: `You must create a Schedule`,
        metadata: {
          typeClass: Schedule,
        }
      }),
    })
    this.demandControlledVentilation.get('matchOccupancySchedule').setVisibility(() => {
      return this.demandControlledVentilation.get('use').value == YesNo.Yes;
    })
    this.demandControlledVentilation.get('schedule').setVisibility(() => {
      return this.demandControlledVentilation.get('use').value == YesNo.Yes &&
        this.demandControlledVentilation.get('matchOccupancySchedule').value == YesNo.No;
    });

    this.heatRecovery = SystemHeatRecovery.create()

    this.economizer = FieldGroup.fromDict({
      'use': makeNoYesField('Uses economizer', {bold: true}),
    })

    this.addWinterHumidification = makeNoYesField('Add winter humidification', {bold: true})
    this.winterHumiditySetpoint = new Field({
      name: 'Winter Humidity Setpoint',
      type: FieldType.Percent,
      requiresInput: true,
      allowMin: false,
    })
    this.winterHumiditySetpoint.setVisibility(() => {
      return this.addWinterHumidification.value == YesNo.Yes;
    })
    this.winterHumidityFields = [
      'addWinterHumidification',
      'winterHumiditySetpoint',
    ]

    this.systemFans = SystemFans.create()

    // Note: we only support CAV and VAV for now
    this.systemType = new Field({
      name: 'System Type',
      type: FieldType.Select,
      choices: makeOptions(SystemType, [SystemType.CAV, SystemType.VAV]),
      bold: true,
    })
    this.separateAirflow = makeYesNoField('Separate airflow for heating and cooling?')
    this.detailFields = [
      'systemType',
      'separateAirflow',
    ]
    this.cavSystemInputs = CAVSystemInputs.create(this)
    this.updater.setEnabledWhen('cav-inputs', this.cavSystemInputs, () => {
      return this.systemType.value == SystemType.CAV;
    });

    this.vavSystemInputs = VAVSystemInputs.create(this)
    this.updater.setEnabledWhen('vav-inputs', this.vavSystemInputs, () => {
      return this.systemType.value == SystemType.VAV;
    });

    this.designTempInputs = SystemDesignTempInputs.create()

    this.resultsState = SystemResultsState.None;
    this.loadResults = SystemLoadResults.create(null);
    this.debugCalcOptions = {
      recordCalculationLog: true,
      calcFirstHourOnly: false,
      useDummySpaceLoads: false,
    }

    this.serFields = [
        'name',
        'id',
        ser.arrayField('zones', () => { return Zone.create(); }),
        'estimatedTotalOccupancy',
        'overrideSystemVentilationIntake',
        'minSystemVentilationIntake',
        'maxSystemVentilationIntake',
        'changeVentilationForModes',
        'demandControlledVentilation',
        'heatRecovery',
        'economizer',
        ...this.winterHumidityFields,
        ...this.detailFields,
        'systemFans',
        'cavSystemInputs',
        'vavSystemInputs',
        'designTempInputs',

        'resultsState',
        'loadResults',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _uniqueName: true,
      _name: () => {
        return this.name.value;
      }
    }
  }

  getObjErrors() {
    let errors = [];
    this.updater.addErrors(errors);
    if (this.zones.length === 0) {
      errors.push({
        msg: 'You must add at least one Zone to the System.',
        showInComponentErrorsList: false,
      });
    }
    return errors;
  }

  postReadFromJson(jsonData, opts) {
    // If we read that the load results were calculating, we have to set to None, since we can't
    // resume them here.
    console.log("On postReadFromJson");
    if (this.resultsState == SystemResultsState.Calculating) {
      console.log("Resetting results state to None (was Calculating on save)");
      this.resultsState = SystemResultsState.None;
      this.loadResults = SystemLoadResults.create(null);
    }
  }

  getName() {
    return this.name.value;
  }

  static getTableInfo() {
    return {
      typeName: 'System',
      allowDuplicate: true,
      excludeFieldsForDuplicate: ['resultsState', 'loadResults'],
      columns: {
        'name': {
          label: 'Name',
        }
      }
    }
  }

  getTableData() {
    return {
      name: this.name.value,
    }
  }

  getInputPage() {
    return {
      label: `Systems - ${this.getName()}`,
      path: `systems/${this.id}`,
    }
  }

  getResultsState() {
    return this.resultsState;
  }

  getResultsStatusStr() {
    if (this.resultsState == SystemResultsState.None) {
      return 'No results yet';
    } else if (this.resultsState == SystemResultsState.Calculating) {
      return 'Calculating...';
    } else if (this.resultsState == SystemResultsState.Ready) {
      if (this.loadResults.error != null) {
        return 'Error calculating loads';
      } else {
        return 'Results ready!';
      }
    }
  }

  getResultsReadyAndSuccessful() {
    return this.resultsState == SystemResultsState.Ready &&
      this.loadResults.resultsGood();
  }

  getResultsProgressStr() {
    if (this.resultsState == SystemResultsState.Calculating) {
      return this.loadResults.getProgressStr();
    }
    return 'No calculation in progress';
  }

  getResultsDetailedProgressStr() {
    if (this.resultsState == SystemResultsState.Calculating) {
      return this.loadResults.getDetailedProgressStr();
    }
    return '';
  }

  getResultsProgressPercent() {
    if (this.resultsState == SystemResultsState.Calculating) {
      return this.loadResults.calcContext.getProgressPercent();
    }
    return 0;
  }

  getResultsTimeCalculating() {
    if (this.resultsState == SystemResultsState.Calculating) {
      return this.loadResults.getTimeCalculatingStr();
    }
    return '';
  }

  getSystemType() {
    return this.systemType.value;
  }

  getSystemName() {
    if (this.systemType.value == SystemType.CAV) {
      return 'CAV';
    } else if (this.systemType.value == SystemType.VAV) {
      return 'VAV';
    } else if (this.systemType.value == SystemType.RadiantInductionOrChilledBeam) {
      return 'Radiant Induction or Chilled Beam';
    } else {
      throw new Error(`Could not get name for system type: ${this.systemType.value}`);
    }
  }

  usingDemandControlledVentilation() {
    return this.demandControlledVentilation.get('use').value == YesNo.Yes;
  }

  getDemandControlledVentilationSchedule(occupancyScheduleData) {
    let matchOccupancySchedule = this.demandControlledVentilation.get('matchOccupancySchedule').value;
    if (matchOccupancySchedule == YesNo.Yes) {
      return occupancyScheduleData;
    } else {
      let schedule = this.demandControlledVentilation.get('schedule').lookupValue();
      return schedule.getData();
    }
  }

  getHeatingSupplyTemp() {
    if (this.systemType.value == SystemType.CAV) {
      return this.cavSystemInputs.heatingSupplyTemp.value;
    } else if (this.systemType.value == SystemType.VAV) {
      return this.vavSystemInputs.heatingSupplyTemp.value;
    } else {
      throw new Error(`Unsupported system type: ${this.systemType.value}`);
    }
  }

  getCoolingSupplyTemp() {
    if (this.systemType.value == SystemType.CAV) {
      return this.cavSystemInputs.coolingSupplyTemp.value;
    } else if (this.systemType.value == SystemType.VAV) {
      return this.vavSystemInputs.coolingSupplyTemp.value;
    } else {
      throw new Error(`Unsupported system type: ${this.systemType.value}`);
    }
  }
  
  isUsingEconomizer() {
    return this.economizer.get('use').value == YesNo.Yes;
  }

  usingSeparateAirflowsForHeatingCooling() {
    return this.separateAirflow.value == YesNo.Yes;
  }

  overridesSystemVentilationIntake() {
    return this.overrideSystemVentilationIntake.value == YesNo.Yes;
  }

  get_V_ot_max_override() {
    return this.maxSystemVentilationIntake.value;
  }

  get_V_ot_min_override() {
    return this.minSystemVentilationIntake.value;
  }

  getRecoveryType() {
    return this.heatRecovery.recoveryType.value;
  }

  createZone() {
    let zone = Zone.create(generateItemName('Zone', this.zones, 'TYPE_NAME-CTR'))
    this.zones.push(zone);
    return zone;
  }

  getZoneAtIndex(zoneIndex) {
    return this.zones[zoneIndex];
  }

  forEachZone(func) {
    for (let i = 0; i < this.zones.length; i++) {
      func({zoneIndex: i, zone: this.zones[i]});
    }
  }

  forEachSpaceInZone(zoneIndex, func) {
    let zone = this.zones[zoneIndex];
    for (let spaceIndex = 0; spaceIndex < zone.spaces.length; spaceIndex++) {
      let zoneSpace = zone.spaces[spaceIndex];
      func({
        zoneIndex,
        zone,
        spaceIndex,
        quantity: zoneSpace.quantity.value,
        spaceType: zoneSpace.getSpaceType()
      });
    }
  }

  forEachSpace(func) {
    for (let zoneIndex = 0; zoneIndex < this.zones.length; zoneIndex++) {
      let zone = this.zones[zoneIndex];
      for (let spaceIndex = 0; spaceIndex < zone.spaces.length; spaceIndex++) {
        let zoneSpace = zone.spaces[spaceIndex];
        func({
          zoneIndex,
          zone,
          spaceIndex,
          quantity: zoneSpace.quantity.value,
          spaceType: zoneSpace.getSpaceType()
        });
      }
    }
  }

  isOccupied(hr) {
    // The system is occupied if any zone is occupied
    let occupied = false;
    this.forEachZone((zone) => {
      if (zone.zone.isOccupied(hr)) {
        occupied = true;
      }
    })
    return occupied;
  }

  getFracOccupied(hr) {
    /**
     * Estimate occupancy based on occupied spaces
     */
    let totalOccupants = 0;
    this.forEachSpace((space) => {
      let numOccupants = space.spaceType.getNumOccupantsAtHour(hr);
      totalOccupants += numOccupants * space.quantity;
    })
    return Math.min(1.0, totalOccupants / this.estimatedTotalOccupancy.value);
  }

  _getProjectObjectsToIgnoreForErrors() {
    /*
    When we update the project errors before running calculations, we check all Project
    objects for errors. Really, we only have to check objects needed by this System. So
    make a list of objects to ignore.
    */
    let ignoreList = []
    let proj = gApp.proj();

    // Ignore other Systems
    for (const system of proj.systems) {
      if (system.id != this.id) {
        //console.log(`Ignoring system: ${system.name.value}`);
        ignoreList.push(system);
      }
    }

    // Ignore Spaces not used by this system
    let spaceTypesUsedBySystem = new Set();
    this.forEachSpace((space) => {
      spaceTypesUsedBySystem.add(space.spaceType);
    })
    for (const spaceType of proj.spaces) {
      if (!spaceTypesUsedBySystem.has(spaceType)) {
        //console.log(`Ignoring space: ${spaceType.name.value}`);
        ignoreList.push(spaceType);
      }
    }

    return ignoreList;
  }

  async calculateLoadsAsync() {
    if (this.resultsState == SystemResultsState.Calculating) {
      console.log("Already running calculations. Ignoring");
      return;
    }
    console.log("Calculating loads for system: ", this.name.value);
    this.resultsState = SystemResultsState.Calculating;

    // Check for project errors before running any calculations
    let objectsToIgnoreForErrors = this._getProjectObjectsToIgnoreForErrors();
    console.log("Using ignore list for errors: ", objectsToIgnoreForErrors);
    let projectErrorOpts = {
      ignoreSet: new Set(objectsToIgnoreForErrors),
    }
    gApp.proj().updateProjectErrors(projectErrorOpts);
    if (gApp.proj().getProjectErrors()) {
      console.log("Project has errors, aborting calculation");
      this.loadResults = SystemLoadResults.create(null);
      this.loadResults.setProjectErrors(gApp.proj().getProjectErrors());
      this.resultsState = SystemResultsState.Ready;
      return;
    }

    let ctx = CalcContext.create({
      recordLog: this.debugCalcOptions.recordCalculationLog
    });
    this.loadResults = SystemLoadResults.create(ctx);
    this.loadResults.setProjectErrors(gApp.proj().getProjectErrors());
    this.loadResults.setDesignTemps(
      this.designTempInputs.heatingDesignTemp.value,
      this.designTempInputs.coolingDesignTemp.value);
    try {
      ctx.debugOptions = this.debugCalcOptions;
      ctx.startDateStr = this.loadResults.getStartDateStr();
      let loadCalculator = new SystemLoadCalculator(this, gApp.proj(), ctx, this.debugCalcOptions);
      let results = await loadCalculator.calcLoads();
      this.loadResults.setResults(results);
    } catch (err) {
      console.log(`Error calculating loads for system ${this.name.value}:\n${err}\nTRACE:\n${err.stack}`);
      ctx.logFatalError(err);
      this.loadResults.setError(err);
    } finally {
      this.resultsState = SystemResultsState.Ready;
    }
  }
}
setupClass(System)
