import { reactive, ref } from 'vue'
import { GenericCache } from './Common/GenericCache';

export function logIf(condition, ...args) {
  if (condition) {
    console.log(...args);
  }
}

/*
It is actually -very- slow to create a new Intl.NumberFormat object for each number (or use
toLocateString() - see docs). So cache them here and reuse.
*/
class NumberFormatCache extends GenericCache {
  static _instance = null;

  static instance() {
    if (!this._instance) {
      this._instance = new NumberFormatCache();
    }
    return this._instance;
  }

  _createEntry(opts) {
    return new Intl.NumberFormat('en-US', opts);
  }
}

export function formatNum(num, opts) {
  opts = valOr(opts, {});
  if (typeof num == 'number' && !Number.isFinite(num)) {
    // Handles NaN, Infinity, -Infinity
    return '<Invalid>';
  }
  let formatter = NumberFormatCache.instance().getOrCreate(opts);
  return formatter.format(num);
}

export function formatNumBrief(num) {
  // Note: the '+' converts the string back to a number, which drops
  // any unneeded decimal places
  //return +Number.parseFloat(num).toFixed(2);
  return formatNum(num, {maximumFractionDigits: 2});
}

export function StrictParseNumberOr(numStr, backupVal) {
  if (typeof numStr === 'number') {
    return numStr;
  } else if (typeof numStr !== 'string' || numStr === '') {
    return backupVal;
  }
  // We remove any commas (Number class does not handle them)
  let num = Number(numStr.replace(/,/g, ''));
  if (isNaN(num)) {
    return backupVal;
  }
  return num;
}

export function StrictParseNumber(numStr) {
  let num = StrictParseNumberOr(numStr, null);
  if (num === null) {
    throw new Error(`Could not parse number: ${numStr}`);
  }
  return num;
}

export function IsNumberString(numStr) {
  let num = StrictParseNumberOr(numStr, null);
  return num !== null;
}

export function addElem(array, elem, index=null) {
  if (index !== null) {
    array.splice(index, 0, elem);
  } else {
    array.push(elem);
  }
}


export function addElemUniq(array, elem) {
  if (!elemIn(elem, array)) {
    addElem(array, elem);
  }
}

export function removeElem(array, elem) {
  const index = array.indexOf(elem);
  if (index > -1) {
    array.splice(index, 1);
  }
}

export function extendArray(array, arrB) {
  for (const elem of arrB) {
    array.push(elem);
  }
}

export function clearArray(array) {
  array.length = 0;
}

export function replaceArray(array, newValues) {
  array.splice(0, array.length, ...newValues);
}

export function arraysEqual(arrA, arrB) {
  if (arrA.length != arrB.length) {
    return false;
  }
  for (let i = 0; i < arrA.length; ++i) {
    if (arrA[i] !== arrB[i]) {
      return false;
    }
  }
  return true;
}

export function getReversed(arr) {
  let copy = math.clone(arr)
  copy.reverse()
  return copy
}

export function extendMap(map, newEntries) {
  for (const key in newEntries) {
    map[key] = newEntries[key];
  }
}

export function elemIn(elem, arr) {
  for (const item of arr) {
    if (elem === item) {
      return true;
    }
  }
  return false;
}

// Takes dict {a:valA, b:valB, ...} and returns
// {a:mapFunc(a, valA), b:mapFunc(b, valB), ...}
export function mapDict(dict, mapFunc) {
  let newDict = {};
  for (const key in dict) {
    newDict[key] = mapFunc(key, dict[key]);
  }
  return newDict;
}

export function curTimeSecs() {
  return (new Date()).getTime() / 1000.0;
}

export function prettyJson(obj) {
  return JSON.stringify(obj, null, 2);
}

export function countToHumanStr(count) {
  if (count > 1000*1000*1000) {
    return Math.floor(count / (1000*1000*1000)) + "B";
  } else if (count > 1000*1000) {
    return Math.floor(count / (1000*1000)) + "M";
  } else if (count > 10*1000) {
    return Math.floor(count / (1000)) + "K";
  }
  return String(count);
}

export function deepCopyObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function isObject(obj) {
  return typeof obj == 'object' && obj !== null;
}

export function valOr(val, defaultVal) {
  return typeof val !== 'undefined' ? val : defaultVal;
}

export function deepCopyArray(arr, startInc, endExc) {
  startInc = valOr(startInc, 0);
  endExc = valOr(endExc, arr.length);
  let res = [];
  for (let i = startInc; i < endExc; ++i) {
    res.push(deepCopyObject(arr[i]));
  }
  return res;
}

export function writeObjToJson(obj) {
  return deepCopyObject(obj);
}

export function readObjFromJson(obj) {
  return deepCopyObject(obj);
}

/**
 * See: https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
 *
 * Calculate a 32 bit FNV-1a hash
 * Found here: https://gist.github.com/vaiorabbit/5657561
 * Ref.: http://isthe.com/chongo/tech/comp/fnv/
 *
 * @param {string} str the input value
 * @param {boolean} [asString=false] set to true to return the hash value as 
 *     8-digit hex string instead of an integer
 * @param {integer} [seed] optionally pass the hash of the previous chunk
 * @returns {integer | string}
 */
export function hashFnv32a(str, asString, seed) {
    /*jshint bitwise:false */
    var i, l,
        hval = (seed === undefined) ? 0x811c9dc5 : seed;

    for (i = 0, l = str.length; i < l; i++) {
        hval ^= str.charCodeAt(i);
        hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }
    if( asString ){
        // Convert to 8 digit hex string
        return ("0000000" + (hval >>> 0).toString(16)).substr(-8);
    }
    return hval >>> 0;
}

export function hashString(str) {
  return hashFnv32a(str);
}

/*
export function hash64(str) {
    var h1 = hash32(str);  // returns 32 bit (as 8 byte hex string)
    return h1 + hash32(h1 + str);  // 64 bit (as 16 byte hex string)
}
*/

export function waitMillis(numMillis) {
  return new Promise(resolve => setTimeout(resolve, numMillis));
}

export async function runAfterDelay(delaySecs, func) {
  await waitMillis(delaySecs*1000);
  await func();
}

export function isValidUrl(urlString) {
  try {
    return Boolean(new URL(urlString));
  } catch (error) {
    return false;
  }
}

export function downloadTextFile(contents, filename) {
  // See: https://web.dev/patterns/files/save-a-file/
  const blob = new Blob([contents], { type: 'text/plain' });
  const blobURL = URL.createObjectURL(blob);
  // Create invisible link element and trigger
  const a = document.createElement('a');
  a.href = blobURL;
  a.download = filename;
  a.style.display = 'none';
  document.body.append(a);
  a.click();

  // Revoke the blob URL and remove the element.
  setTimeout(() => {
    URL.revokeObjectURL(blobURL);
    a.remove();
  }, 1000);
}

// Returns null on error.
export async function asyncFetchText(url, options) {
  let textStr = null;
  try {
    let response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`Failed to get: ${url}. ${reponse.status} ${response.statusText}`);
    }
    textStr = await response.text();
  } catch (error) {
    console.error(`Fetch for "${url}" failed with error: `, error);
    return null;
  }
  return textStr;
}

export function fatalAssert(condition, message) {
  if (!condition) {
    throw Error('Assert failed: ' + (message || ''));
  }
};

export function AssertThrow(condition, message) {
  if (!condition) {
    throw Error('Assert failed: ' + (message || ''));
  }
}

export async function copyToClipboard(text) {
  return navigator.clipboard.writeText(text).then(() => {
    console.log("Copied!");
  }).catch((error) => {
    console.error("Failed to copy", error);
  });
}

// Returns `error` on error, otherwise null
export function readFromJsonWithRollback(obj, jsonText) {
  let origState = obj.writeToJson();
  try {
    obj.readFromJson(jsonText, ...extraArgs);
  } catch (error) {
    console.error("Failed to read from json. Rolling back to original state.");
    obj.readFromJson(origState);
    return error;
  }
  return null;
}

export function safeParseJson(jsonStr) {
  try {
    return JSON.parse(jsonStr);
  } catch (error) {
    console.error("Error parsing json.", error);
    return null;
  }
}

// Ex. pluralStr(1, "day") -> "day", pluralStr(2, "day") -> "days"
function pluralStr(number, str) {
  if (number == 1) {
    return str;
  }
  return str + "s";
}

function quantityStr(number, unitStr) {
  return `${number} ${pluralStr(number, unitStr)}`
}

export function getTimeAgoStr(date, opts)  {
  opts = valOr(opts, {});
  let curDate = new Date();    
  let hoursDiff = (curDate.getTime() - date.getTime()) / (1000.0*60*60);
  hoursDiff = Math.floor(hoursDiff);
  let res = null;
  if (hoursDiff > 24) {
    let daysAgo = Math.floor(hoursDiff / 24.0);
    res = quantityStr(daysAgo, "day") + " ago";
  } else if (hoursDiff >= 1) {
    res = quantityStr(hoursDiff, "hr") + " ago";
  } else {
    // hoursDiff == 0
    if (valOr(opts.enableMins, false)) {
      let minsDiff = (curDate.getTime() - date.getTime()) / (1000.0*60);
      minsDiff = Math.floor(minsDiff);
      res = quantityStr(minsDiff, "min") + " ago";
    } else {
      res = "0 hrs ago";
    }
  }
  return res;
}

export function secsToDurationStr(secs) {
  let hours = Math.floor(secs / 3600);
  let mins = Math.floor((secs % 3600) / 60);
  let secsRemain = Math.floor(secs % 60);
  let res = "";
  if (hours > 0) {
    res += `${hours}hr `;
  }
  if (mins > 0) {
    res += `${mins}min `;
  }
  res += `${secsRemain}s`;
  return res;
}

export function secsSinceDate(date) {
  let curDate = new Date();
  return (curDate - date) / (1000.0);
}

export function getRandInt(maxValExclusive) {
  return Math.floor(Math.random() * maxValExclusive);
}

export function getElemNames(elems) {
  return elems.map((elem) => {
    return elem.name.value;
  });
}

export function getElemWithNameValue(elems, name) {
  for (const elem of elems) {
    if (elem.name.value == name) {
      return elem;
    }
  }
  throw new Error(`Could not find elem with name: ${name}`);
}

export function protectedFunc(func, errorMsg) {
  try {
    return func();
  } catch (err) {
    console.error("Caught error in protected func: ", err);
    return errorMsg;
  }
}

export function resOr(func, backupValue) {
  try {
    return func();
  } catch (err) {
    console.error("Caught error in protected func: ", err);
    return backupValue;
  }
}

// Javascript Array.sort() does alphabetic sort by default, beware!
// Use arr.sort(compareNums) for numerical sort.
export let compareNums = (a, b) => (a - b);

export async function awaitAll(list, asyncFunc) {
  let promises = [];
  list.forEach((elem) => {
    promises.push(asyncFunc(elem));
  })
  return Promise.all(promises);
}

export function isDupName(name, existingItems) {
  let numFound = 0;
  for (const item of existingItems) {
    if (item.name.value == name) {
      numFound++;
      if (numFound > 1) {
        return true;
      }
    }
  }
  return false;
}

export function isNameTaken(name, existingItems) {
  for (const item of existingItems) {
    if (item.name.value == name) {
      return true;
    }
  }
  return false;
}

export function generateItemName(typeName, existingItems, optNameTemplate) {
  let i = existingItems.length + 1;
  let name = "";
  while (true) {
    let nameTemplate = valOr(optNameTemplate, 'TYPE_NAME-CTR')
    name = nameTemplate.replace('TYPE_NAME', typeName).replace('CTR', i);
    if (!isNameTaken(name, existingItems)) {
      break;
    }
    ++i;
  }
  return name;
}

export function sortItemsByName(items) {
  items.sort((a, b) => {
    return a.name.value.localeCompare(b.name.value);
  });
}

export function valueOrFuncRes(value) {
  if (typeof value === 'function') {
    return value();
  } else {
    return value;
  }
}

export function applyIndent(str, numSpaces) {
  let indentStr = " ".repeat(numSpaces);
  return str.split('\n').map((line) => {
    return indentStr + line;
  }).join('\n');
}


export function getMonthName(monthIndex) {
  let months =  [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  return months[monthIndex]
}

export function getShortMonthName(monthIndex) {
  return getMonthName(monthIndex).slice(0, 3);
}

export function getHourString(hrIndex) {
  // Return a time string in the format "12pm"
  let hr = hrIndex % 12;
  if (hr == 0) {
    hr = 12;
  }
  let ampm = hrIndex < 12 ? 'am' : 'pm';
  return `${hr}${ampm}`;
}

export function getFullHourString(hrIndex) {
  // Exs: 0 -> "12:00am", 1 -> "1:00am", 12 -> "12:00pm", 13 -> "1:00pm"
  let hr = hrIndex % 12;
  if (hr == 0) {
    hr = 12;
  }
  let ampm = hrIndex < 12 ? 'am' : 'pm';
  return `${hr}:00${ampm}`;
}

export function getMonthHourString(monthIndex, hrIndex) {
  let monthName = getShortMonthName(monthIndex);
  let hourString = getHourString(hrIndex);
  return `${monthName}, ${hourString}`;
}

export function safeDivide(num, denom) {
  return denom === 0 ? 0 : num / denom;
}

export function hashObject(obj) {
  return hashString(JSON.stringify(obj));
}

export function getColorFromUsername(username) {
  if (!username) return 'var(--pc)';
  
  // Generate a consistent color based on the username
  const hash = username.split('').reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  
  // Use the hash to generate a hue value (0-360)
  const hue = Math.abs(hash % 360);
  
  // Return a vibrant but not too light color
  return `hsl(${hue}, 70%, 45%)`;
}

export function applyEllipse(str, maxLength) {
  if (str.length <= maxLength) {
    return str;
  }
  return str.slice(0, maxLength) + '...';
}

export class GraphNode {
  constructor(id, data) {
    this.id = valOr(id, 0);
    this.data = valOr(data, null);
    this.children = [];
  }

  addChild(child) {
    this.children.push(child);
  }

  getChildren() {
    return this.children;
  }

  hasChildren() {
    return this.children.length > 0;
  }

  isLeaf() {
    return !this.hasChildren();
  }

  getChildWithId(id) {
    for (const child of this.children) {
      if (child.id == id) {
        return child;
      }
    }
    return null;
  }

  hasChildWithId(id) {
    return this.getChildWithId(id) !== null;
  }

  static makeTreeRecursive(rootNode, getChildrenFunc) {
    let children = getChildrenFunc(rootNode);
    for (const childNode of children) {
      rootNode.addChild(childNode)
      GraphNode.makeTreeRecursive(childNode, getChildrenFunc);
    }
  }

  static makeTreeFromPaths(paths) {
    let rootNode = new GraphNode(0);
    for (const path of paths) {
      let parentNode = rootNode;
      for (let nodeIndex = 0; nodeIndex < path.length; ++nodeIndex) {
        // For path [{id: A, ...}, {id: B, ...}, ...], add an edge from A to B.
        // Create required nodes as we go.
        let curData = path[nodeIndex];
        let curNode = null;
        if (parentNode.hasChildWithId(curData.id)) {
          curNode = parentNode.getChildWithId(curData.id);
        } else {
          curNode = new GraphNode(curData.id, curData);
          parentNode.addChild(curNode);
        }
        //console.log("Cur node: ", curNode);
        parentNode = curNode;
      }
    }
    return rootNode;
  }
}

export class DFSHelper {
  constructor() {
  }

  _doDFSRecursive(node, visitFunc, resultsMap, visitedMap) {
    visitedMap[node.id] = true;
    if (node.children) {
      for (const child of node.children) {
        if (visitedMap[child.id]) {
          continue;
        }
        this._doDFSRecursive(child, visitFunc, resultsMap, visitedMap);
      }
    }
    let res = visitFunc(node, resultsMap);
    resultsMap[node.id] = res;
  }

  doDFS(root, visitFunc) {
    let resultsMap = {}
    let visitedMap = {}
    this._doDFSRecursive(root, visitFunc, resultsMap, visitedMap);
    return resultsMap[root.id];
  }
}

/*
Helper for returning a result or an error.
Either result or error will be null.
*/
export class Result {
  constructor(result, error) {
    this.result = result;
    this.error = error;
  }

  static success(result) {
    return new Result(result, null);
  }

  static error(error) {
    return new Result(null, error);
  }

  getResult() {
    return this.result;
  }

  getError() {
    return this.error;
  }

  isError() {
    return this.error !== null;
  }

  isSuccess() {
    return this.result !== null;
  }
}
