<script setup>
import { gApp } from '../Globals.js'
import { ref, onUnmounted } from 'vue'
import BasicModal from './utils/BasicModal.vue'
import CloseButton from './utils/CloseButton.vue'
import { getColorFromUsername } from '../SharedUtils'

let showProfileInfo = ref(false);

let sendFeedbackModal = ref(null);
let getHelpModal = ref(null);
let feedbackText = ref("");
let helpText = ref("");

async function signOutUser() {
  showProfileInfo.value = false;
  await gApp.signOutUser();
}

let feedbackTemplate = `I wish HeatWise had these features:


I hit these bugs / problems:


Other feedback:

`

let helpTemplate = ``

// Note - cannot be used directly in the template
const version = __APP_VERSION__

const isStaging = import.meta.env.MODE === 'staging';

function openSendFeedback() {
  feedbackText.value = feedbackTemplate;
  sendFeedbackModal.value.showModal();
}

function openGetHelp() {
  helpText.value = helpTemplate;
  getHelpModal.value.showModal();
}

function sendFeedback() {
  gApp.sendDeveloperFeedback(feedbackText.value);
  feedbackText.value = "";
  gApp.toast('Feedback sent!');
}

function sendHelpRequest() {
  gApp.sendDeveloperFeedback(helpText.value);
  helpText.value = "";
  gApp.toast('Help request sent!');
}

onUnmounted(() => {
})

</script>

<template>
  <div class="App">
    <div class="AppHeader Flex AlignCenter">
      <div class="LogoContainer">
        <img class="AppLogo mr-xs cursor-ptr" src="../assets/cpu-fan-cooling-icon.png" @click="gApp.router.push('/')"/>
      </div>
      <div class="TitleContainer Flex AlignBaseline">
        <h3 class="AppTitle cursor-ptr glow-text" @click="gApp.router.push('/')">HeatWise</h3>
        <div class="VersionBadge ml-xs">
          <p class="f-xs VersionNum">v{{version}}</p>
        </div>
        <div v-if="isStaging" class="StagingBadge ml-xs">
          <p class="f-m">STAGING</p>
        </div>
      </div>
      <p class="CurProjTitle Bold cursor-ptr" @click="gApp.router.push(gApp.proj().getBaseRoute())" v-if="gApp.proj()">
        <i class="bi-chevron-right white mr-s"></i>
        <i class="bi-box-fill pc mr-xs"></i>
        {{gApp.proj().getUiName()}}
      </p>
      <div class="TopBtns Flex GapXS AlignBaseline">
        <!-- <button v-if="gApp.proj()" @click="gApp.saveAll()">Save Project</button> -->
        <template v-if="gApp.authState.value == 'SignedIn'">
          <!-- <i class="bi-person-circle"></i> -->
          <!-- <p class="f-s MRXS">{{gApp.user.value.email}}</p> -->
          <button class="CreateBtn HelpBtn" @click="openGetHelp"><i class="bi-asterisk mr-xxs"></i>Get Help</button>
          <button class="CreateBtn FeedbackBtn mr-xxs" @click="openSendFeedback"><i class="bi-send mr-xxs"></i>Send Feedback</button>
          <div class="pos-rel">
            <div class="ProfileBtn text-btn MRXS upper hover-grow" 
                 :class="{grown: showProfileInfo}" 
                 :style="{ backgroundColor: getColorFromUsername(gApp.user.value.email) }"
                 @click="showProfileInfo = !showProfileInfo">
              <p class="upper black f-xs">{{gApp.user.value.email[0]}}</p>
            </div>
            <div v-if="showProfileInfo" class="ProfileDropdown pos-abs right-0 pane mt-xs shad-m">
              <CloseButton @click="showProfileInfo=false" class="float-r"/>
              <div class="profile-header">
                <div class="profile-icon-large" :style="{ backgroundColor: getColorFromUsername(gApp.user.value.email) }">
                  <p class="upper f-m">{{gApp.user.value.email[0]}}</p>
                </div>
                <div class="profile-info">
                  <p class="profile-title">Profile</p>
                  <p class="profile-email">{{gApp.user.value.email}}</p>
                </div>
              </div>
              <div class="profile-actions">
                <button v-if="gApp.authState.value == 'SignedIn'" class="profile-signout-btn" @click="signOutUser">
                  <i class="bi-box-arrow-right mr-xs"></i>Sign Out
                </button>
              </div>
            </div>
          </div>
          <!-- <button v-if="gApp.authState.value == 'SignedIn'" class="LinkBtn SignOutBtn w-text f-xs" @click="signOutUser">Sign Out</button> -->
        </template>
      </div>
    </div>
    <div class="BodyContainer">
      <router-view></router-view>
    </div>
    <BasicModal ref="sendFeedbackModal" title="Send Feedback" doneText="Send" @onDone="sendFeedback">
      <p class="mute-header">Feedback</p>
      <p class="help-text mb-s">Fill in this form to send feedback to the developers.</p>
      <textarea v-model="feedbackText" class="mb-s w-100 FeedbackArea"/>
    </BasicModal>
    <BasicModal ref="getHelpModal" title="Get Help" doneText="Send" @onDone="sendHelpRequest">
      <p class="mute-header">Help Request</p>
      <p class="help-text mb-s">Describe what you need help with and we'll get back to you as soon as possible.</p>
      <textarea v-model="helpText" class="mb-s w-100 FeedbackArea"/>
    </BasicModal>
  </div>
</template>

<style scoped>
.App {
  /* max-width: 1200px; */
  width: 100%;
  height: 100%;
  /* padding: var(--space-m); */
  background-color: var(--main-bg);
}

.AppHeader {
  height: var(--header-height);
  background: var(--header-color);
  padding: 0 var(--space-m);
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  color: var(--w-text);
  width: 100%;
  position: fixed;
  z-index: 2;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.LogoContainer {
  display: flex;
  align-items: center;
  position: relative;
}

.TitleContainer {
  position: relative;
  display: flex;
  align-items: baseline;
}

.CurProjTitle {
  float: left;
  /*margin-left: calc(var(--sidebar-w) - 150px);*/
  margin-left: var(--l);
  transition: transform 0.3s ease;
}

.CurProjTitle:hover {
  transform: translateY(-2px);
}

.TopBtns {
  margin-left: auto;
}

.AppLogo {
  height: 32px;
  filter: brightness(0) invert(1);
  transition: all 0.3s ease;
}


.AppTitle {
  color: var(--title-color);
  font-size: var(--f-l);
  font-weight: 700;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
}

.glow-text:hover {
  transform: translateY(-2px);
}

.VersionBadge {
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 12px;
  padding: 2px 8px;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
}

.VersionBadge:hover {
  background-color: rgba(255, 255, 255, 0.25);
  transform: translateY(-2px);
}

.StagingBadge {
  background-color: rgba(255, 87, 51, 0.8);
  border-radius: 12px;
  padding: 2px 8px;
  font-weight: bold;
  box-shadow: 0 1px 2px rgba(255, 87, 51, 0.3);
}

.BodyContainer {
  padding-top: var(--header-height);
  position: relative;
  z-index: 1;
  height: 100%;
}

.ProfileBtn {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  text-align: center;
  position: relative;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.ProfileBtn:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.ProfileBtn p {
  font-weight: 700;
  font-size: var(--f-m);
}

.ProfileDropdown {
  color: var(--main-text);
  z-index: 1000;
  border-radius: var(--br-l);
  min-width: 360px;
  padding: var(--m);
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  animation: fadeInDown 0.3s ease-out;
}

.profile-header {
  display: flex;
  align-items: center;
  margin-bottom: var(--m);
  padding-bottom: var(--s);
  border-bottom: 1px solid rgba(62, 85, 204, 0.1);
}

.profile-icon-large {
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: var(--m);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  color: var(--w-text);
  font-size: var(--f-l);
  font-weight: 700;
}

.profile-info {
  flex: 1;
}

.profile-title {
  font-size: var(--f-m);
  font-weight: 700;
  color: var(--pc);
  margin-bottom: var(--xxs);
}

.profile-email {
  font-size: var(--f-s);
  color: var(--grey-40);
  word-break: break-all;
}

.profile-actions {
  margin-top: var(--m);
}

.profile-signout-btn {
  display: flex;
  align-items: center;
  padding: var(--xs) var(--s);
  background-color: rgba(62, 85, 204, 0.05);
  color: var(--pc);
  border: 1px solid rgba(62, 85, 204, 0.2);
  border-radius: var(--br-m);
  font-weight: 600;
  transition: all 0.2s ease;
  width: 100%;
}

.profile-signout-btn:hover {
  background-color: rgba(62, 85, 204, 0.1);
  transform: translateY(-2px);
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.FeedbackBtn, .HelpBtn {
  padding: var(--xs);
  font-size: var(--f-xxs);
  text-transform: none;
  border-radius: var(--br-l);
}

.HelpBtn {
  padding: var(--xs) var(--s);
}

.FeedbackArea {
  resize: vertical;
  height: 200px;
  font-size: var(--f-xs);
}

.VersionNum {
  color: white;
  font-weight: 600;
}

</style>
