<script setup>
import { ref, onMounted, reactive, computed } from 'vue'
import { makeOptions } from '../Base.js'
import { replaceArray, valOr } from '../SharedUtils.js'
import BasicModal from './utils/BasicModal.vue'
import BasicSelector from './utils/BasicSelector.vue'
import CustomTypeEditor from './CustomTypeEditor.vue'

const props = defineProps({
  field: Object,
})

let modal = ref(null);

function open() {
  modal.value.showModal();
}

let selectChoices = computed(() => {
  // console.log("Recomputing select choices for: " + props.field.name);
  return props.field.getChoices();
})

let isNoneValue = computed(() => {
  return props.field.value === 'None' ||
    props.field.value === 'Manual';
})

function getTypeData() {
  if ('typeClass' in props.field.metadata) {
    return props.field.metadata.typeClass.getTypeMetadata();
  } else {
    return null;
  }
}
function hasTypeData() {
  return getTypeData() !== null;
}

function createNewType() {
  // Create the type, select it, then navigate to the editor
  let typeData = getTypeData();
  /*
  let newType = typeData.createType();
  props.field.value = newType.id;
  */
  modal.value.closeModal();
  typeData.openEditor(null);
}

function onValueChanged(newVal) {
  props.field.value = newVal;
}

let labelString = computed(() => {
  if (selectChoices.value.length === 0) {
    return 'Select';
  } else if (props.field.isSelectValueValid()) {
    return props.field.getSelectLabelStr();
  } else {
    // For convenience, just use the 'Select Value' msg here, too
    //return 'Invalid Value';
    return 'Select';
  }
})

function getSelectorClasses() {
  if (props.field.bold) {
    return {
      Bold: true
    }
  } else {
    return {}
  }
}

let typeName = computed(() => {
  let typeData = getTypeData();
  return typeData ? typeData.name : 'Type';
})

let lowercaseTypeName = computed(() => {
  return typeName.value.toLowerCase();
})

let useCustomEditor = computed(() => {
  let typeData = getTypeData();
  if (!typeData) {
    return false;
  }
  return valOr(typeData.customEditor, false);
})

let modalClasses = computed(() => {
  let classes = {
    TypeSelectorModal: true
  }
  if (useCustomEditor.value) {
    classes.CustomEditor = true;
  }
  return classes;
})

let modalTitle = computed(() => {
  if (useCustomEditor.value) {
    // Don't show modal title if using custom editor
    return '';
  }
  return 'Select ' + typeName.value;
})

function onDone() {
  modal.value.closeModal();
}

</script>

<template>
  <button class="TypeSelectBtn" :class="{IsError: field.getEntryErrorMsg() !== null, IsNone: isNoneValue}" @click="open">
    {{ labelString }}
  </button>
  <BasicModal ref="modal" :title="modalTitle"
    :extraClasses="modalClasses"
    :showFooter="!useCustomEditor">
    <div v-if="useCustomEditor">
      <CustomTypeEditor :typeName="typeName" :field="field" @onDone="onDone" />
    </div>
    <div v-else>
      <div v-if="selectChoices.length === 0">
        <p class="mb-xs">You haven't created any <b>{{typeName}}s</b> yet.</p>
        <button v-if="hasTypeData()" @click="createNewType" class="CreateBtn">Create {{typeName}}</button>
      </div>
      <div v-else>
        <p class="help-text">Select a <b>{{ typeName }}</b>:</p>
        <BasicSelector
          :value="field.value" :options="selectChoices"
          @change="onValueChanged" class="Selector mb-s" :class="getSelectorClasses()"/>
        <button v-if="hasTypeData()" @click="createNewType" class="CreateNewTypeBtn TextBtn">Create <b>{{typeName}}s</b> <i class="bi-arrow-up-right"></i></button>
      </div>
    </div>
  </BasicModal>
</template>

<style>
.TypeSelectorModal {
}

.TypeSelectorModal.CustomEditor {
  width: 1000px !important;
  padding: var(--l);
}
</style>

<style scoped>
.TypeSelectBtn {
  border-radius: var(--br-s);
  border: 1px solid var(--pc-dark);
  padding: var(--xxs) var(--s);
  background-color: var(--pc);
  /*background-color: var(--main-bg);*/
  font-weight: normal;
  font-size: var(--f-s);
  color: var(--w-text);
  /*color: var(--main-text);*/
  text-transform: inherit;
  text-decoration: none;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;

  /*width: 100%;*/
  /*max-width: 120px;*/
}

.TypeSelectBtn:hover {
  /*background-color: var(--pc-highlight);*/
  /*transform: scale(1.05);*/
  background-color: var(--pc-highlight);
}

.TypeSelectBtn.IsError {
  background-color: var(--warning-orange);
  border-color: var(--warning-orange);
}

.TypeSelectBtn.IsError:hover {
  background-color: var(--pc-highlight);
}

.TypeSelectBtn.IsNone {
  background-color: var(--pc-med2);
  border-color: var(--pc-med2);
}

.TypeSelectBtn.IsNone:hover {
  background-color: var(--pc-highlight);
}

.Selector {
}

.Selector:deep(.Select) {
  padding: var(--xs) var(--xs);
  font-size: var(--f-m);
  border-radius: var(--br-m);
}

.CreateNewTypeBtn {
  font-size: var(--f-m);
}

.BigIcon {
  font-size: var(--f-m);
}

</style>
