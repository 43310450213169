<script setup>
import { ref, onMounted, reactive, computed, watch, nextTick, } from 'vue'
import { gApp } from '../State.js'
import { CommercialHelpTable } from '../BuildingComponents/CommercialQuickHelp/CommercialHelpTable.js'

// Import marked async, to reduce bundle size
const marked = () => import('marked');
const markedKatex = () => import('marked-katex-extension');

const props = defineProps({
  id: {
    type: String,
    required: true,
  }
});

let markedInstance = ref(null);

// Hack to prevent Teleporting before the target is rendered
let hasTicked = ref(false);

let commercialHtml = computed(() => {
  if (!markedInstance.value) {
    return '<p>Loading...</p>';
  }
  if (!(props.id in CommercialHelpTable)) {
    return null;
  }
  let markdownText = CommercialHelpTable[props.id];
  const renderer = new markedInstance.value.Renderer();
  // Override the default image rendering
  renderer.image = (image) => {
    // Prepend "QuickHelp/" to the image source
    const modifiedHref = `QuickHelp/${image.href}`;
    return `<img src="${modifiedHref}" alt="${image.text}" ${image.title ? `title="${image.title}"` : ""} />`;
  };
  return markedInstance.value(markdownText, { renderer });
});

async function setupMarkedLibrary() {
  const options = {
    output: 'mathml',
    throwOnError: true,
  }
  let instance = (await marked()).marked;
  let markedKatexInstance = (await markedKatex()).default;
  instance.use(markedKatexInstance(options));
  markedInstance.value = instance;
}

onMounted(() => {
  setupMarkedLibrary();
  nextTick(() => {
    hasTicked.value = true;
  })
})

</script>

<template>
  <Teleport v-if="hasTicked" to="#HelpContent">
    <div v-if="gApp.proj().helpPaneId == id" class="Paragraphs">
      <div v-if="gApp.proj().isCommercial()">
        <div v-if="commercialHtml">
          <div class="Markdown" v-html="commercialHtml"></div>
        </div>
        <div v-else>
          <p>No help found for topic {{ id }}. Use the 'Get Help' button in the top-right if you need assistance.</p>
        </div>
      </div>
      <div v-else>
        <!-- Legacy residential way, using slot. Eventually change to use markdown -->
        <slot></slot>
      </div>
    </div>
  </Teleport>
</template>

<style scoped>

.Markdown:deep() {
}

.Markdown:deep(h1) {
  font-size: var(--f-xl);
  margin-bottom: var(--space-m);
}

.Markdown:deep(h2) {
  font-size: var(--f-l);
  margin-top: var(--space-l);
  margin-bottom: var(--space-s);
}

.Markdown:deep(h3) {
  font-size: var(--f-m);
}

.Markdown:deep(p) {
  margin-bottom: var(--space-s);
}

.Markdown:deep(img) {
  max-width: 100%;
}

.Markdown:deep(table) {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid var(--b-20);
  margin-bottom: var(--space-s);
}

.Markdown:deep(th),
.Markdown:deep(td) {
  border: 1px solid var(--b-20);
  padding: var(--space-xxs);
}

</style>
