<script setup>
import { ref, watch, onMounted, onUnmounted } from 'vue'
import { gApp, } from '../State.js'
import { useRoute } from "vue-router"

const route = useRoute();

let projectLoadError = ref(null);

async function fetchData(newId) {
  projectLoadError.value = null;
  let canOpen = await gApp.canOpenProj(newId);
  if (!canOpen) {
    // If do not have access to this project, return to home
    console.log("Project is locked by someone else! Returning home.");
    gApp.router.replace('/');
    return;
  }
  let projResult = await gApp.setCurProj(newId);
  if (projResult.isError()) {
    projectLoadError.value = projResult.getError();
    return;
  }
  projectLoadError.value = null;
  updateHelpIdForPath(route.fullPath);
}

function updateHelpIdForPath(newPath) {
  //console.log("New path: ", newPath);
  let helpIds = {
    '': 'home',
    'build': 'build',
    'walls': 'WallsView',
    'roofs': 'RoofsView',
    'windows': 'WindowsView',
    'doors': 'DoorsView',
    'skylights': 'SkylightsView',
    'internalshadings': 'InteriorShadingsView',
    'externalshadings': 'ExteriorShadingsView',
    'bufferspaces': 'BufferSpacesView',
    'schedules': 'SchedulesView',
    'location': 'BuildingAndEnv',
    'systems': 'SystemsView',
    'spaces': 'SpacesView',
  }
  let pathParts = route.fullPath.split('/');
  let pageId = pathParts.length >= 4 ? pathParts[3] : '';
  if (gApp.proj()) {
    if (pageId in helpIds) {
      gApp.proj().setHelpId(helpIds[pageId]);
      // console.log("Set help id to " + helpIds[pageId]);
    } else {
      gApp.proj().setHelpId(null)
      gApp.proj().setHelpPaneVisible(false);
    }
  }
}

watch(() => {
    return route.params.id;
  },
  (newId) => {
    fetchData(newId);
  }, {immediate: true});

watch(() => {
  return route.fullPath;
},
(newPath) => {
  updateHelpIdForPath(newPath);
}, {immediate: true})

onMounted(() => {
  console.log("Mounting ProjectView");
})

onUnmounted(() => {
  console.log("Unmounting ProjectView!");
  // Save + release the project (no longer editing)
  if (gApp.proj()) {
    gApp.proj().save();
  }
})

</script>

<template>
  <div class="ProjectView">
    <div class="Sidebar PaddingL">
      <template v-if="gApp.proj() !== null">
        <router-link to="/" class="MenuLink Block BackToHomeBtn">
          <i class="bi-arrow-left MRXS"></i>
          <span class="BackBtnText">All Projects</span>
        </router-link>

        <p class="mute-text small-upper SidebarHeader">
          {{gApp.proj().isCommercial() ? 'Building' : 'House'}}
        </p>
        <router-link :to="{path: `${gApp.proj().getBaseRoute()}`}" class="MenuLink MainLink Block">
          <span class="TextSpan">
            <i class="bi-asterisk MRXS"></i>{{ gApp.proj().isCommercial() ? 'Guide' : 'Project' }}
          </span>
        </router-link>
        <template v-if="gApp.proj().isCommercial()">
          <router-link :to="{path: `${gApp.proj().getBaseRoute()}/location`}" class="MenuLink Block"><i class="bi-geo-alt MRXS"></i>Location</router-link>
          <router-link :to="{path: `${gApp.proj().getBaseRoute()}/spaces`}" class="MenuLink Block"><i class="bi-box MRXS"></i>Spaces</router-link>
          <router-link :to="{path: `${gApp.proj().getBaseRoute()}/systems`}" class="MenuLink Block"><i class="bi-grid MRXS"></i>Systems</router-link>
          <router-link :to="{path: `${gApp.proj().getBaseRoute()}/results`}" class="MenuLink Block"><i class="bi-bar-chart-line MRXS"></i>Results</router-link>
        </template>
        <template v-else>
          <router-link :to="{path: `${gApp.proj().getBaseRoute()}/build`}" class="MenuLink Block"><i class="bi-tools MRXS"></i>Build House</router-link>
        </template>

        <p class="mute-text small-upper pl-m SidebarHeader">Components</p>
        <router-link :to="{path: `${gApp.proj().getBaseRoute()}/walls`}" class="MenuLink Block"><i class="bi-bricks MRXS"></i>Walls</router-link>
        <router-link :to="{path: `${gApp.proj().getBaseRoute()}/roofs`}" class="MenuLink Block"><i class="bi-house-fill MRXS"></i>Roofs</router-link>
        <router-link :to="{path: `${gApp.proj().getBaseRoute()}/windows`}" class="MenuLink Block"><i class="bi-grid-fill MRXS"></i>Windows</router-link>
        <router-link :to="{path: `${gApp.proj().getBaseRoute()}/doors`}" class="MenuLink Block"><i class="bi-door-closed-fill MRXS"></i>Doors</router-link>
        <router-link :to="{path: `${gApp.proj().getBaseRoute()}/skylights`}" class="MenuLink Block"><i class="bi-brightness-high-fill MRXS"></i>Skylights</router-link>
        <router-link :to="{path: `${gApp.proj().getBaseRoute()}/internalshadings`}" class="MenuLink Block"><i class="bi-shadows MRXS"></i>Internal Shadings</router-link>
        <router-link :to="{path: `${gApp.proj().getBaseRoute()}/externalshadings`}" class="MenuLink Block"><i class="bi-shadows MRXS"></i>External Shadings</router-link>
        <router-link :to="{path: `${gApp.proj().getBaseRoute()}/bufferspaces`}" class="MenuLink Block"><i class="bi-distribute-horizontal MRXS"></i>Buffer Spaces</router-link>
        <template v-if="gApp.proj().isCommercial()">
          <router-link :to="{path: `${gApp.proj().getBaseRoute()}/schedules`}" class="MenuLink Block"><i class="bi-alarm MRXS"></i>Schedules</router-link>
        </template>

        <p class="mute-text small-upper pl-m SidebarHeader mt-s">Settings</p>
        <router-link :to="{path: `${gApp.proj().getBaseRoute()}/settings`}" class="MenuLink Block"><i class="bi-gear MRXS"></i>Settings</router-link>
      </template>
    </div>
    <div class="RightSidebar" v-show="gApp.proj() && gApp.proj().helpPaneVisible">
      <div class="HelpHeader">
        <!-- <button class="LinkBtn float-r" @click="gApp.proj().setHelpPaneVisible(false)">Hide</button> -->
        <button class="float-r LinkBtn CloseX" @click="gApp.proj().setHelpPaneVisible(false)">&#x2715;</button>
        <h3 class=""><i class="mr-xxs bi-lightning-charge"></i>Quick Help</h3>
      </div>
      <!-- Teleport target -->
      <div id="HelpContent">
      </div>
    </div>
    <div class="Main PaddingM">
      <button class="QuickHelpOpener CreateBtn" @click="gApp.proj().setHelpPaneVisible(true)"><i class="bi-lightning-charge mr-xxs"></i>Quick Help</button>
      <div v-if="gApp.proj() !== null">
        <!-- <router-view></router-view> -->
        <!-- This keeps the page alive when changing pages -->
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </div>
      <div v-else>
        <div v-if="!projectLoadError" class="project-loading-container">
          <div class="project-loading-spinner"></div>
          <h4 class="project-loading-text">Loading project<span class="dot-1">.</span><span class="dot-2">.</span><span class="dot-3">.</span></h4>
        </div>
        <div v-else>
          <h4 class="upper mb-m">Error loading project</h4>
          <p class="w-60 mb-m">{{projectLoadError}}</p>
          <p class="mb-l">Please verify that this project exists and you have access to it.</p>
          <p class=""><i>Return to <router-link to="/">Projects</router-link></i></p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/*
.router-link-active {
  background-color: red;
}
*/

.ProjectView {
  /* display: grid; */
  /* grid-template-columns: var(--sidebar-w) 1fr; */
  /* gap: var(--space-m); */
  height: 100%;
}

.Sidebar {
  width: var(--sidebar-w);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  height: 100%;
  padding: var(--space-xs) 0 80px 0;
  background: var(--sidebar-bg);
  color: var(--sidebar-color);
  position: fixed;
  float: left;
  font-size: var(--f-m);
  overflow-y: auto;
  scrollbar-width: thin;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

/* webkit browsers(safari, chrome) */
/* 
*::-webkit-scrollbar {
  border: none;
  background-color: transparent;
} 
*/
/* firefox */
/*
*{
  scrollbar-color: #404040b3 transparent;
}
*/

.RightSidebar {
  position: fixed;
  right: 0;
  top: var(--header-height);
  /*width: 400px;*/
  width: 500px;
  max-width: 25%;
  height: calc(100vh - var(--header-height));
  background-color: var(--main-bg);
  /* padding: var(--s); */
  border-left: 1px solid grey;
}

.HelpHeader {
  border-bottom: 1px solid grey;
  padding: var(--s);
}

#HelpContent {
  padding: var(--s);
  padding-bottom: var(--xxl);
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.MenuLink {
  font-weight: bold;
  font-size: var(--f-s);
  color: var(--pc-lightest);
  width: 100%;
  padding: var(--xs) var(--s) var(--xs) var(--m);
  margin: 2px 0;
  border-radius: 0 8px 8px 0;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
}

.MenuLink::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 3px;
  background: linear-gradient(to bottom, #4e7cff, #6a5acd);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.MenuLink:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  transform: translateX(5px);
  padding-left: calc(var(--m) + 5px);
}

.MenuLink:hover::before {
  opacity: 1;
}

.MenuLink i {
  margin-right: var(--space-xs);
  font-size: 1.1em;
  transition: transform 0.2s ease;
}

.MenuLink:hover i {
  transform: scale(1.2);
}

.MainLink {
}

.MainLink .TextSpan {
}

.SidebarHeader {
  color: var(--pc-med2);
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: var(--m);
  margin-bottom: var(--xs);
  padding-left: var(--m);
}

.QuickHelpOpener {
  float: right;
}

.router-link-exact-active {
  background: linear-gradient(to right, var(--pc), rgba(62, 85, 204, 0.7));
  color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.router-link-exact-active::before {
  opacity: 1;
}

.router-link-exact-active i {
  color: white;
}

.Main {
  width: 100%;
  padding-left: calc(var(--sidebar-w) + var(--space-l));
  /* padding-left: var(--space-xl); */
}

.BackToHomeBtn {
  margin-top: var(--xs);
  margin-bottom: var(--m);
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 8px;
  padding: var(--xs) var(--s);
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid rgba(255, 255, 255, 0.08);
}

.BackToHomeBtn:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
  border-color: rgba(255, 255, 255, 0.15);
}

.BackToHomeBtn i {
  font-size: 1.3em;
  transition: all 0.2s ease;
  color: rgba(255, 255, 255, 0.9);
}

.BackToHomeBtn:hover i {
  transform: scale(1.1);
  color: white;
}

.BackBtnText {
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: all 0.2s ease;
}

.BackToHomeBtn:hover .BackBtnText {
  color: white;
}

.project-loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--xl);
  margin: var(--m) 0;
  min-height: 250px;
  border-radius: var(--br-m);
}

.project-loading-spinner {
  width: 60px;
  height: 60px;
  border: 6px solid rgba(62, 85, 204, 0.3);
  border-radius: 50%;
  border-top-color: var(--pc);
  border-left-color: var(--pc);
  animation: spin 1s ease-in-out infinite;
  margin-bottom: var(--s);
}

.project-loading-text {
  font-size: var(--f-m);
  color: var(--pc);
  font-weight: 600;
  animation: pulse 2s infinite;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

.dot-1, .dot-2, .dot-3 {
  animation: dotPulse 1.5s infinite;
  display: inline-block;
}

.dot-1 { animation-delay: 0s; }
.dot-2 { animation-delay: 0.3s; }
.dot-3 { animation-delay: 0.6s; }

@keyframes dotPulse {
  0% { opacity: 0; transform: translateY(0); }
  50% { opacity: 1; transform: translateY(-5px); }
  100% { opacity: 0; transform: translateY(0); }
}

</style>
