import { makeEnum } from '../Base.js'

export let ResultsNodeType = makeEnum({
  VerticalSplit: 'VerticalSplit',
  LineChart: 'LineChart',
  Sunburst: 'Sunburst',
})

/*
For graphable calculation results
*/
export class ResultsNode {
  constructor(type, componentName) {
    this.type = type;
    this.componentName = componentName;
    this.children = [];
  }
}

export class LineChartNode extends ResultsNode {
  constructor(data) {
    super(ResultsNodeType.LineChart, 'LineChart');
    this.data = data;
  }
}

export class SunburstNode extends ResultsNode {
  constructor(data) {
    super(ResultsNodeType.Sunburst, 'Sunburst');
    
    // Sort the data recursively from largest to smallest
    if (data.data) {
      this._sortDataRecursively(data.data);
    } else {
      this._sortDataRecursively(data);
    }
    
    this.data = data;
  }
  
  _sortDataRecursively(data) {
    // Skip special properties that start with underscore
    const items = [];
    for (const key in data) {
      if (!key.startsWith('_')) {
        items.push({
          key: key,
          value: data[key].value,
          item: data[key]
        });
      }
    }
    
    // Sort items by value in descending order
    items.sort((a, b) => b.value - a.value);
    
    // Create a new sorted object
    const sortedData = {};
    for (const specialKey in data) {
      if (specialKey.startsWith('_')) {
        sortedData[specialKey] = data[specialKey];
      }
    }
    
    // Add sorted items
    for (const item of items) {
      sortedData[item.key] = item.item;
      
      // Recursively sort children if they exist
      if (item.item.children) {
        this._sortDataRecursively(item.item.children);
      }
    }
    
    // Replace the original data with sorted data
    Object.keys(data).forEach(key => {
      delete data[key];
    });
    
    Object.keys(sortedData).forEach(key => {
      data[key] = sortedData[key];
    });
  }
}
