<script setup>
import { ref, onMounted, reactive, computed, watch } from 'vue'

const props = defineProps({
  defaultOpen: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

let isOpen = ref(props.defaultOpen);

function toggleOpen() {
  // Don't toggle if disabled
  if (props.disabled) return;
  
  if (isOpen.value) {
    isOpen.value = false;
  } else {
    isOpen.value = true;
  }
}

function open() {
  isOpen.value = true;
}

function close() {
  isOpen.value = false;
}

// Expose methods to parent components
defineExpose({
  open,
  close,
  toggleOpen
});

</script>

<template>
  <div class="CollapseDiv">
    <button class="CollapseBtn" @click="toggleOpen" :class="{IsOpen: isOpen}">
      <slot name="title">Default title</slot>
    </button>
    <div v-show="isOpen" class="Content">
      <slot name="content">Default body</slot>
    </div>
  </div>
</template>

<style scoped>
.CollapseDiv {
  max-width: 1000px;
}

.CollapseBtn {
  /* background-color: #777; */
  /* color: white; */
  /* padding: var(--space-xs); */

  background-color: var(--grey-90);
  width: 100%;

  border: 1px solid var(--grey-60);
  border-radius: 4px;
  padding: 12px 16px;

  /* border: none; */
  /* outline: none; */

  text-align: left;
  /* font-size: var(--f-m2); */
  font-size: var(--f-m);
  transition: all 0.2s ease;
}

.CollapseBtn:hover {
  /* background-color: #555; */
  background-color: var(--grey-70);
}

.Content {
  padding: var(--space-s);
  /* background-color: #f1f1f1; */
  border: 1px solid var(--grey-60);
  border-top: none;
}
</style>
