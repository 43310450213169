<script setup>
import { ref, computed } from 'vue'
import { makeValStr } from '../../Common/Units.js'

const props = defineProps({
  title: {
    type: String,
    default: 'Help'
  },
  helpInfo: {
    type: Object,
    default: null
  }
})

const hasRelatedValues = computed(() => {
  return props.helpInfo && props.helpInfo.relatedValues && Object.keys(props.helpInfo.relatedValues).length > 0
})

const hasResult = computed(() => {
  return props.helpInfo && props.helpInfo.result
})
</script>

<template>
  <div v-if="helpInfo" class="input-help-container">
    <p v-if="helpInfo.helpText" class="help-text CharW">
      <i class="bi-info-circle mr-xxs"></i>
      <span v-html="helpInfo.helpText"></span>
    </p>
    
    <div v-if="hasResult" class="result-value">
      <span class="result-label mr-xs">{{ helpInfo.result.label }}:</span> 
      <span class="result-value-text">{{ makeValStr(helpInfo.result.value, helpInfo.result.units) }}</span>
    </div>
    
    <div v-if="hasRelatedValues" class="related-values">
      <div v-for="(value, key) in helpInfo.relatedValues" :key="key" class="related-value-item">
        <div class="related-value-label mr-xs">
          {{ value.label }}:
        </div>
        <div class="related-value-text">
          {{ makeValStr(value.value, value.units) }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.input-help-container {
  margin-top: var(--space-xxs);
  margin-bottom: var(--space-s);
  font-size: 0.85rem;
  color: var(--text-muted, #6c757d);
  background-color: var(--bg-light, #f8f9fa);
  border-left: 3px solid var(--info-color, #17a2b8);
  padding: var(--space-xs) var(--space-xs) var(--space-xs) var(--space-s);
  border-radius: 0 var(--space-xxs) var(--space-xxs) 0;
}

.help-text {
  margin-bottom: var(--space-xs);
  line-height: 1.4;
}

.result-value {
  margin-bottom: var(--space-xs);
  padding: var(--space-xxs) 0;
}

.result-label {
  font-weight: 500;
}

.result-value-text {
  font-weight: 600;
  color: var(--info-color, #17a2b8);
}

.related-values {
  border-top: 1px dotted var(--border-color, #dee2e6);
  padding-top: var(--space-xxs);
  margin-top: var(--space-xxs);
}

.related-value-item {
  display: flex;
  align-items: baseline;
  margin-bottom: var(--space-xxs);
}

.related-value-label {
  /*width: 200px;*/
}

.related-value-text {
  font-weight: 500;
}
</style>
