/**
 * Load calc utility functions 
 */

export function findMaxLoadInMatrix(loadsMatrix) {
  let maxTime = null;
  let maxValue = null;
  for (let mo = 0; mo < 12; ++mo) {
    for (let hr = 0; hr < 24; ++hr) {
      let value = loadsMatrix.get([mo, hr])
      if (maxValue == null || value > maxValue) {
        maxValue = value
        maxTime = [mo, hr]
      }
    }
  }
  return {
    value: maxValue,
    time: maxTime,
  }
}

export function findMaxLoadForMonth(loadsMatrix, monthIndex) {
  let maxValue = null;
  let maxHour = null;
  for (let hr = 0; hr < 24; ++hr) {
    let value = loadsMatrix.get([monthIndex, hr])
    if (maxValue == null || value > maxValue) {
      maxValue = value
      maxHour = hr
    }
  }
  return {
    value: maxValue,
    hour: maxHour,
  }
}

export function findMaxValueInArray(arr) {
  let maxValue = null;
  let maxIndex = null;
  for (let i = 0; i < arr.length; ++i) {
    if (maxValue == null || arr[i] > maxValue) {
      maxValue = arr[i]
      maxIndex = i
    }
  }
  return {
    value: maxValue,
    index: maxIndex,
  }
}