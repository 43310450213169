import { 
  setupClass, 
} from '../Base.js'

import { Season,  } from '../Components/Common.js'
import { Units } from '../Common/Units.js'

import { CalcPsychrometrics, PsyCalcMethod, adjustForAltitude, } from '../Components/Psychrometrics.js'
import { scalarSum } from '../Common/Math.js'
import {
  ResultsNode,
  ResultsNodeType,
  SunburstNode,
} from './ResultsTree.js'

export class SpaceHeatingCalculator {
  constructor(space, ctx) {
    this.space = space
    this.ctx = ctx
  }

  calcWallLoads() {
    let ctx = this.ctx
    ctx.startSection("Wall loads")
    let wallLoads = []
    let breakdown = {}
    for (let i = 0; i < this.space.walls.length; i++) {
      let wall = this.space.walls[i];
      let wallName = wall.getWallType().name.value;
      ctx.log(`Calculating q_sens_wall for wall: ${i + 1} (type ${wallName})`)
      //let q_sens_wall = wall.calculateSensibleLoad(ctx);
      ctx.q_sens_wall = ctx.eval('1.0/R*A*(t_i - t_o)', {
        R: wall.getRValue(),
        A: wall.getStrictlyWallArea(),
      }, 'q_sens_wall');
      wallLoads.push({q_sens_wall: ctx.q_sens_wall});
      breakdown[`Wall${i + 1}`] = {
        label: `Wall ${i + 1}: ${wallName}`,
        value: Math.round(ctx.q_sens_wall),
      }
    }
    ctx.log("Summing wall sensible loads...")
    ctx.q_sens_walls = ctx.evalSum('q_sens_wall', wallLoads, 'q_sens_walls')

    let res = {
      SensibleLoadValue: ctx.q_sens_walls,
      Breakdown: breakdown,
    }

    ctx.endSection();
    return res;
  }

  calcWindowLoads() {
    let ctx = this.ctx;
    ctx.startSection("Window loads")
    let windowLoads = []
    let breakdown = {}
    for (let wallIndex = 0; wallIndex < this.space.walls.length; wallIndex++) {
      let wall = this.space.walls[wallIndex];
      ctx.log(`Summing windows on wall ${wallIndex + 1} (type ${wall.getWallType().name.value})`)
      for (let winIndex = 0; winIndex < wall.windows.length; winIndex++) {
        let window = wall.windows[winIndex];
        let windowType = window.getWindowType();
        ctx.log(`Window ${winIndex + 1} (type ${windowType.name.value}) on wall ${wallIndex + 1}`)
        ctx.q_sens_window = ctx.eval('n*U*A*(t_i - t_o)', {
          n: window.quantity.value,
          U: windowType.computeUValue().uValue,
          A: windowType.getArea(),
        }, 'q_sens_window');
        windowLoads.push({q_sens_window: ctx.q_sens_window});
        
        // Add to breakdown
        let key = `Window${wallIndex+1}_${winIndex+1}`;
        let quantityStr = window.quantity.value > 1 ? ` x${window.quantity.value}` : '';
        breakdown[key] = {
          label: `Wall ${wallIndex + 1}, Window ${winIndex + 1}: ${windowType.name.value}${quantityStr}`,
          value: Math.round(ctx.q_sens_window),
        }
      }
    }
    ctx.log("Summing window sensible loads...")
    ctx.q_sens_windows = ctx.evalSum('q_sens_window', windowLoads, 'q_sens_windows')

    let res = {
      SensibleLoadValue: ctx.q_sens_windows,
      Breakdown: breakdown,
    }

    ctx.endSection();
    return res;
  }

  calcDoorLoads() {
    let ctx = this.ctx;
    ctx.startSection("Door loads")
    let doorLoads = []
    let breakdown = {}
    for (let wallIndex = 0; wallIndex < this.space.walls.length; wallIndex++) {
      let wall = this.space.walls[wallIndex];
      ctx.log(`Summing doors on wall ${wallIndex + 1} (type ${wall.getWallType().name.value})`)
      for (let doorIndex = 0; doorIndex < wall.doors.length; doorIndex++) {
        let door = wall.doors[doorIndex];
        let doorType = door.getDoorType();
        ctx.log(`Door ${doorIndex + 1} (type ${doorType.name.value}) on wall ${wallIndex + 1}`)
        let uValue = doorType.computeUValue();
        // TODO - are the U-values here correct?
        ctx.q_sens_door = ctx.eval('n*((U_glass*A_glass + U_opaq*A_opaq)*(t_i - t_o))', {
          n: door.quantity.value,
          U_glass: uValue.uValueGlass,
          A_glass: doorType.getGlassArea(),
          U_opaq: uValue.uValueDoor,
          A_opaq: doorType.getOpaqueArea(),
        }, 'q_sens_door');
        doorLoads.push({q_sens_door: ctx.q_sens_door});
        
        // Add to breakdown
        let key = `Door${wallIndex+1}_${doorIndex+1}`;
        let quantityStr = door.quantity.value > 1 ? ` x${door.quantity.value}` : '';
        breakdown[key] = {
          label: `Wall ${wallIndex + 1}, Door ${doorIndex + 1}: ${doorType.name.value}${quantityStr}`,
          value: Math.round(ctx.q_sens_door),
        }
      }
    }

    ctx.log("Summing door sensible loads...")
    ctx.q_sens_doors = ctx.evalSum('q_sens_door', doorLoads, 'q_sens_doors')

    let res = {
      SensibleLoadValue: ctx.q_sens_doors,
      Breakdown: breakdown,
    }

    ctx.endSection();
    return res;
  }

  calcRoofLoads() {
    let ctx = this.ctx;
    ctx.startSection("Roof loads")
    let roofLoads = []
    let breakdown = {}
    for (let i = 0; i < this.space.roofs.length; i++) {
      let roof = this.space.roofs[i];
      let roofType = roof.getRoofType();
      ctx.log(`Calculating q_sens_roof for roof: ${i + 1} (type ${roofType.name.value})`)
      //let q_sens_roof = roof.calculateSensibleLoad(ctx);
      ctx.q_sens_roof = ctx.eval('1.0/R*A*(t_i - t_o)', {
        R: roof.getRValue(),
        A: roof.getStrictlyRoofArea(),
      }, 'q_sens_roof');
      roofLoads.push({q_sens_roof: ctx.q_sens_roof});
      
      // Add to breakdown
      let key = `Roof${i+1}`;
      breakdown[key] = {
        label: `Roof ${i + 1}: ${roofType.name.value}`,
        value: Math.round(ctx.q_sens_roof),
      }
    }
    ctx.log("Summing roof sensible loads...")
    ctx.q_sens_roofs = ctx.evalSum('q_sens_roof', roofLoads, 'q_sens_roofs')

    let res = {
      SensibleLoadValue: ctx.q_sens_roofs,
      Breakdown: breakdown,
    }

    ctx.endSection();
    return res;
  }

  calcSkylightLoads() {
    let ctx = this.ctx;
    ctx.startSection("Skylight loads")
    let skylightLoads = []
    let breakdown = {}
    for (let i = 0; i < this.space.roofs.length; i++) {
      let roof = this.space.roofs[i];
      let roofType = roof.getRoofType();
      ctx.log(`Summing skylights on roof ${i + 1} (type ${roofType.name.value})`)
      for (let skylightIndex = 0; skylightIndex < roof.skylights.length; skylightIndex++) {
        let skylight = roof.skylights[skylightIndex];
        let skylightType = skylight.getSkylightType();
        ctx.log(`Skylight ${skylightIndex + 1} (type ${skylightType.name.value}) on roof ${i + 1}`)
        ctx.q_sens_skylight = ctx.eval('n*U*A*(t_i - t_o)', {
          n: skylight.quantity.value,
          U: skylightType.computeUValue().uValue,
          A: skylightType.getArea(),
        }, 'q_sens_skylight');
        skylightLoads.push({q_sens_skylight: ctx.q_sens_skylight});
        
        // Add to breakdown
        let key = `Skylight${i+1}_${skylightIndex+1}`;
        let quantityStr = skylight.quantity.value > 1 ? ` x${skylight.quantity.value}` : '';
        breakdown[key] = {
          label: `Roof ${i + 1}, Skylight ${skylightIndex + 1}: ${skylightType.name.value}${quantityStr}`,
          value: Math.round(ctx.q_sens_skylight),
        }
      }
    }
    ctx.log("Summing skylight sensible loads...")
    ctx.q_sens_skylights = ctx.evalSum('q_sens_skylight', skylightLoads, 'q_sens_skylights')

    let res = {
      SensibleLoadValue: ctx.q_sens_skylights,
      Breakdown: breakdown,
    }

    ctx.endSection();
    return res;
  }

  calcFloorLoads() {
    let ctx = this.ctx;
    ctx.startSection("Floor loads")
    let floorLoads = []
    let breakdown = {}
    for (let i = 0; i < this.space.floors.length; i++) {
      ctx.startLocalSection(`Floor ${i + 1}`)
      let floor = this.space.floors[i];
      ctx.q_sens_floor = floor._calcLoads(ctx, true, {t_i: ctx.t_i, t_o: ctx.t_o}).q;
      floorLoads.push({q_sens_floor: ctx.q_sens_floor});
      
      // Add to breakdown
      let key = `Floor${i+1}`;
      breakdown[key] = {
        label: `Floor ${i + 1}`,
        value: Math.round(ctx.q_sens_floor),
      }
      ctx.endSection()
    }
    ctx.log("Summing floor sensible loads...")
    ctx.q_sens_floors = ctx.evalSum('q_sens_floor', floorLoads, 'q_sens_floors')

    let res = {
      SensibleLoadValue: ctx.q_sens_floors,
      Breakdown: breakdown,
    }

    ctx.endSection();
    return res;
  }

  calcPartitionLoads() {
    let ctx = this.ctx;
    ctx.startSection("Partition loads")
    let partitionLoads = []
    let breakdown = {}
    for (let i = 0; i < this.space.partitions.length; i++) {
      ctx.startLocalSection(`Partition ${i + 1}`)
      let partition = this.space.partitions[i];
      ctx.q_sens_partition = partition._calcLoads(ctx, true, {t_i: ctx.t_i, t_o: ctx.t_o});
      partitionLoads.push({q_sens_partition: ctx.q_sens_partition});
      
      // Add to breakdown
      let key = `Partition${i+1}`;
      breakdown[key] = {
        label: `Partition ${i + 1}`,
        value: Math.round(ctx.q_sens_partition),
      }
      ctx.endSection()
    }
    ctx.log("Summing partition sensible loads...")
    ctx.q_sens_partitions = ctx.evalSum('q_sens_partition', partitionLoads, 'q_sens_partitions')

    let res = {
      SensibleLoadValue: ctx.q_sens_partitions,
      Breakdown: breakdown,
    }

    ctx.endSection();
    return res;
  }

  calcInfiltrationSensibleLoads() { 
    let ctx = this.ctx;
    ctx.startSection("Infiltration sensible load")
    ctx.Q_inf = this.space.calcInfiltrationFlowRate(ctx, Season.Winter).Q_inf;
    ctx.C_s = ctx.call(adjustForAltitude, 1.08, ctx.P_loc);
    ctx.q_sens_inf = ctx.eval('Q_inf*C_s*(t_i - t_o)', {
    }, 'q_sens_inf');

    let breakdown = {
      "Infiltration": {
        label: "Infiltration Sensible Load",
        value: Math.round(ctx.q_sens_inf),
      }
    };

    let res = {
      SensibleLoadValue: ctx.q_sens_inf,
      Breakdown: breakdown,
    }

    ctx.endSection();
    return res;
  }

  calcInfiltrationLatentLoads() {
    let ctx = this.ctx;
    ctx.startSection("Infiltration latent load")
    ctx.Q_inf = this.space.calcInfiltrationFlowRate(ctx, Season.Winter).Q_inf;

    ctx.C_l = ctx.call(adjustForAltitude, 4840, ctx.P_loc);
    ctx.W_out = ctx.call(CalcPsychrometrics, ctx.t_o, ctx.altitude,
      PsyCalcMethod.CalcWithRelativeHumidity, {
        RH: 0.50
      }
    ).W;
    ctx.W_in = ctx.call(CalcPsychrometrics, ctx.t_i, ctx.altitude,
      PsyCalcMethod.CalcWithRelativeHumidity, {
        RH: ctx.winterIndoorRH
      }
    ).W;
    ctx.q_lat_inf = ctx.eval('Q_inf*C_l*(W_in - W_out)', {
    }, 'q_lat_inf');

    let breakdown = {
      "Infiltration": {
        label: "Infiltration Latent Load",
        value: Math.round(ctx.q_lat_inf),
      }
    };

    let res = {
      LatentLoadValue: ctx.q_lat_inf,
      Breakdown: breakdown,
    }

    ctx.endSection();
    return res;
  }

  _addResultsNode(loadResults) {
    let rootNode = new ResultsNode(ResultsNodeType.VerticalSplit, 'Space Heating');
    rootNode.title = "Space Heating"
    rootNode.helpText = `This section shows the heating loads for your space. Heating loads are ` +
      `calculated based on the heating 99.6% dry bulb temperature for your location.`
    
    let sunburstData = {
      title: 'Heating Loads',
      units: Units.Load,
      includeTable: true,
      data: {
        totalSensibleLoad: {
          label: 'Sensible Loads',
          value: Math.round(loadResults.q_sensible),
          children: {
            wallLoads: {
              label: 'Wall Loads',
              value: Math.round(loadResults.SensibleLoads.wallLoads.SensibleLoadValue),
              children: loadResults.SensibleLoads.wallLoads.Breakdown,
            },
            windowLoads: {
              label: 'Window Loads',
              value: Math.round(loadResults.SensibleLoads.windowLoads.SensibleLoadValue),
              children: loadResults.SensibleLoads.windowLoads.Breakdown,
            },
            doorLoads: {
              label: 'Door Loads',
              value: Math.round(loadResults.SensibleLoads.doorLoads.SensibleLoadValue),
              children: loadResults.SensibleLoads.doorLoads.Breakdown,
            },
            roofLoads: {
              label: 'Roof Loads',
              value: Math.round(loadResults.SensibleLoads.roofLoads.SensibleLoadValue),
              children: loadResults.SensibleLoads.roofLoads.Breakdown,
            },
            skylightLoads: {
              label: 'Skylight Loads',
              value: Math.round(loadResults.SensibleLoads.skylightLoads.SensibleLoadValue),
              children: loadResults.SensibleLoads.skylightLoads.Breakdown,
            },
            floorLoads: {
              label: 'Floor Loads',
              value: Math.round(loadResults.SensibleLoads.floorLoads.SensibleLoadValue),
              children: loadResults.SensibleLoads.floorLoads.Breakdown,
            },
            partitionLoads: {
              label: 'Partition Loads',
              value: Math.round(loadResults.SensibleLoads.partitionLoads.SensibleLoadValue),
              children: loadResults.SensibleLoads.partitionLoads.Breakdown,
            },
            infiltrationLoads: {
              label: 'Infiltration Loads',
              value: Math.round(loadResults.SensibleLoads.infiltrationLoads.SensibleLoadValue),
              //children: loadResults.SensibleLoads.infiltrationLoads.Breakdown,
            }
          }
        },
        totalLatentLoad: {
          label: 'Latent Loads',
          value: Math.round(loadResults.q_latent),
          children: {
            infiltrationLoads: {
              label: 'Infiltration Loads',
              value: Math.round(loadResults.LatentLoads.infiltrationLoads.LatentLoadValue),
              //children: loadResults.LatentLoads.infiltrationLoads.Breakdown,
            }
          }
        }
      }
    }
    let sunburstNode = new SunburstNode(sunburstData)
    rootNode.children.push(sunburstNode)

    loadResults.resultsNode = rootNode;
  }

  calcOutputs() {
    let ctx = this.ctx;
    ctx.startSection('Space Heating')

    ctx.log("Setting t_i to the winter indoor temp")
    ctx.t_i = ctx.eval('t_i_winter', {}, 't_i');
    ctx.log("Setting t_o to the heating dry bulb design temp")
    ctx.t_o = ctx.designTemps.getHeatingDryBulbOut();

    let wallLoads = this.calcWallLoads();
    let windowLoads = this.calcWindowLoads();
    let doorLoads = this.calcDoorLoads();
    let roofLoads = this.calcRoofLoads();
    let skylightLoads = this.calcSkylightLoads();
    let floorLoads = this.calcFloorLoads();
    let partitionLoads = this.calcPartitionLoads();
    let infiltrationSensibleLoads = this.calcInfiltrationSensibleLoads();
    let infiltrationLatentLoads = this.calcInfiltrationLatentLoads();

    let totalSensibleLoad = scalarSum([
      wallLoads.SensibleLoadValue,
      windowLoads.SensibleLoadValue,
      doorLoads.SensibleLoadValue,
      roofLoads.SensibleLoadValue,
      skylightLoads.SensibleLoadValue,
      floorLoads.SensibleLoadValue,
      partitionLoads.SensibleLoadValue,
      infiltrationSensibleLoads.SensibleLoadValue,
    ]);
    let totalLatentLoad = scalarSum([
      infiltrationLatentLoads.LatentLoadValue,
    ]);
    ctx.logValue('Total sensible load', totalSensibleLoad)
    ctx.logValue('Total latent load', totalLatentLoad)

    let SensibleLoads = {
      totalLoad: totalSensibleLoad,

      wallLoads: wallLoads,
      windowLoads: windowLoads,
      doorLoads: doorLoads,
      roofLoads: roofLoads,
      skylightLoads: skylightLoads,
      floorLoads: floorLoads,
      partitionLoads: partitionLoads,
      infiltrationLoads: infiltrationSensibleLoads,
    }
    let LatentLoads = {
      totalLoad: totalLatentLoad,

      infiltrationLoads: infiltrationLatentLoads,
    }

    let res = {
      q_sensible: totalSensibleLoad,
      q_latent: totalLatentLoad,
      q_total: totalSensibleLoad + totalLatentLoad,

      SensibleLoads: SensibleLoads,
      LatentLoads: LatentLoads,
    }
    this._addResultsNode(res)

    ctx.endSection()
    return res
  }
}
