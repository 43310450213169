import { makeEnum, makeOptions,
  setupClass, lookupData, 
  interpolateInMap,
} from '../Base.js'
import { InputComponent } from '../Common/InputComponent.js'

import { Units } from '../Common/Units.js'

import { prettyJson, valOr,
  addElem, removeElem, elemIn,
  extendMap,
  downloadTextFile,
  generateItemName,
  sortItemsByName,
  formatNum,
} from '../SharedUtils.js'

import {
  FieldType,
  Field,
  FieldGroup,
} from '../Common/Field.js'

import {
  kDirectionChoices, YesNo,
  makeYesNoField, makeNoYesField,
  InstallationSealing,
 } from './Common.js'

 import {
  NumPanes,
  FrameStyle,
  Glazing,
  PanesAppliedTo,
  Tint,
  PaneThickness,
  GapType,
 } from './WindowsCommon.js'

 import {
  WindowType
 } from './WindowType.js'


import { gApp, DebugOn } from '../Globals.js'

export let SkylightStyle = makeEnum({
  ManufacturedSkylight: 'Manufactured skylight',
  SiteAssembledSlopedSlashOverheadGlazing: 'Site-assembled sloped/overhead glazing',
  SlopedCurtainWall: 'Sloped curtain wall (> 20° from horizontal)',
  DomedSkylight: 'Domed skylight',
  TubularDaylightingDevice: 'Tubular daylighting device',
})

export let SkylightInputType = makeEnum({
  Manual: 'Manual',
  BuildSkylight: 'Build skylight',
})

let DomedSkylightOpacity = makeEnum({
  Clear: 'Clear',
  ClearWithLightDiffuser: 'Clear with light diffuser',
  MildyTranslucent: 'Mildy translucent',
  VeryTranslucent: 'Very translucent',
})

let DomedSkylightGlazing = makeEnum({
  Single: 'Single',
  Double: 'Double',
  DoubleLowE: 'Double, low-e',
  Triple: 'Triple',
  TripleLowE: 'Triple, low-e',
})

let DomedSkylightShgcs = {
  [DomedSkylightOpacity.ClearWithLightDiffuser]: {
    [0]: 0.53,
    [9]: 0.5,
    [12]: 0.44,
  },
  [DomedSkylightOpacity.Clear]: {
    [0]: 0.86,
    [9]: 0.77,
    [12]: 0.7,
  },
  [DomedSkylightOpacity.MildyTranslucent]: {
    [0]: 0.5,
    [12]: 0.4,
  },
  [DomedSkylightOpacity.VeryTranslucent]: {
    [0]: 0.3,
    [9]: 0.26,
    [12]: 0.24,
  }
};

let TubularSkylightCollectorLayers = makeEnum({
  N1: '1',
  N2: '2',
})

let TubularSkylightDiffuserLayers = makeEnum({
  N1: '1',
  N2: '2',
  N3: '3',
})

let RoofInsulationLocation = makeEnum({
  BelowRoof: 'Below roof',
  AboveCeiling: 'Above ceiling',
})

let TubularSkylightData = {
  [TubularSkylightCollectorLayers.N1]: {
    [TubularSkylightDiffuserLayers.N1]: {
      [RoofInsulationLocation.AboveCeiling]: {uValue: 0.62, shgc: 0.32},
      [RoofInsulationLocation.BelowRoof]: {uValue: 1.34, shgc: 0.34},
    },
    [TubularSkylightDiffuserLayers.N2]: {
      [RoofInsulationLocation.AboveCeiling]: {uValue: 0.38, shgc: 0.28},
      [RoofInsulationLocation.BelowRoof]: {uValue: 1.34, shgc: 0.35},
    },
    [TubularSkylightDiffuserLayers.N3]: {
      [RoofInsulationLocation.AboveCeiling]: {uValue: 0.27, shgc: 0.24},
      [RoofInsulationLocation.BelowRoof]: {uValue: 1.33, shgc: 0.34},
    },
  },
  [TubularSkylightCollectorLayers.N2]: {
    [TubularSkylightDiffuserLayers.N1]: {
      [RoofInsulationLocation.AboveCeiling]: {uValue: 0.62, shgc: 0.27},
      [RoofInsulationLocation.BelowRoof]: {uValue: 0.83, shgc: 0.32},
    },
    [TubularSkylightDiffuserLayers.N2]: {
      [RoofInsulationLocation.AboveCeiling]: {uValue: 0.38, shgc: 0.24},
      [RoofInsulationLocation.BelowRoof]: {uValue: 0.83, shgc: 0.38},
    },
    [TubularSkylightDiffuserLayers.N3]: {
      [RoofInsulationLocation.AboveCeiling]: {uValue: 0.27, shgc: 0.24},
      [RoofInsulationLocation.BelowRoof]: {uValue: 1.33, shgc: 0.34},
    },
  },
}

export class SkylightType extends InputComponent {
  init(name, makeId) {
    this.id = makeId ? gApp.proj().makeId('SkylightType') : 0;

    this.name = Field.makeName('Name', name)
    this.height = new Field({
      name: 'Length',
      type: FieldType.Length,
      requiresInput: true,
    })
    this.width = new Field({
      name: 'Width',
      type: FieldType.Length,
      requiresInput: true,
    })
    this.curbHeight = new Field({
      name: 'Curb Height',
      type: FieldType.SmallLength,
      min: 0,
      allowMin: true,
    })
    this.unusualShape = makeNoYesField('Non-rectangular shape', {visible: false})
    this.unusualShapeArea = new Field({
      name: 'Shape Area',
      type: FieldType.Area,
    })
    this.unusualShapePerimeter = new Field({
      name: 'Shape Perimeter',
      type: FieldType.Length,
    })
    this.updater.addWatchEffect('unusual-shape', () => {
      let unusualShape = this.unusualShape.value == YesNo.Yes;
      this.height.visible = !unusualShape;
      this.width.visible = !unusualShape;
      this.unusualShapeArea.visible = unusualShape;
      this.unusualShapePerimeter.visible = unusualShape;
    })

    this.style = Field.makeSelect('Style', SkylightStyle)
    this.installationSealing = Field.makeSelect('Installation sealing',
      InstallationSealing)
    this.inputType = Field.makeSelect('Input type', SkylightInputType, {bold: true})

    this.curbHeight.makeUpdater((field) => {
      if (this.style.value == SkylightStyle.SlopedCurtainWall) {
        field.isOutput = true;
        field.value = 0;
      } else {
        field.isOutput = false;
      }
    })

    this.manualUValue = new Field({
      name: 'U-Value',
      type: FieldType.UValue,
      min: 0,
      allowMin: false,
      requiresInput: true,
    })
    this.manualShgc = new Field({
      name: 'SHGC (total window)',
      type: FieldType.Ratio,
      requiresInput: true,
    })
    this.manualInputFields = ['manualUValue', 'manualShgc']
    this.updater.setFieldsEnabled('manual', this, this.manualInputFields, () => {
      return this.inputType.value == SkylightInputType.Manual;
    })

    this.domedSkylightInputs = new FieldGroup([
      Field.makeSelect('Dome Opacity', DomedSkylightOpacity,
        {key: 'domeOpacity'}),
      new Field({
        key: 'frameStyle',
        name: 'Frame Style',
        type: FieldType.Select,
        choices: makeOptions(FrameStyle, [
          FrameStyle.AluminumWithoutThermalBreak,
          FrameStyle.AluminumWithThermalBreak,
          FrameStyle.ReinforcedVinylSlashAluminumCladWood,
          FrameStyle.WoodSlashVinyl,
        ])
      }),
      Field.makeSelect('Glazing', DomedSkylightGlazing, {key: 'glazing'}),
    ])
    this.domedSkylightInputs.setVisibility(() => {
      return this.inputType.value == SkylightInputType.BuildSkylight &&
        this.style.value == SkylightStyle.DomedSkylight;
    })

    this.tubularSkylightInputs = new FieldGroup([
      Field.makeSelect('# of glazing layers on collector',
        TubularSkylightCollectorLayers, {key: 'collectorLayers'}),
      Field.makeSelect('# of glazing layers on diffuser',
        TubularSkylightDiffuserLayers, {key: 'diffuserLayers'}),
      Field.makeSelect('Approximate location of roof insulation',
        RoofInsulationLocation, {key: 'insulationLocation'}),
    ])
    this.tubularSkylightInputs.setVisibility(() => {
      return this.inputType.value == SkylightInputType.BuildSkylight &&
        this.style.value == SkylightStyle.TubularDaylightingDevice;
    })

    /*
    TODO - add notes to the user
    ***Note to the user: if unknown, select “Below roof”
    ***Note to the user: thermal performance will be greatly improved if the insulation is located above the ceiling.
    ***Note to the user: only one layer on the diffuser will result in poor thermal performance.
    */
    
    // Other styles: {
    let isOtherStyle = () => {
      return !elemIn(this.style.value, [
        SkylightStyle.DomedSkylight, SkylightStyle.TubularDaylightingDevice])
    }
    this.frameStyle = new Field({
      name: 'Frame Style',
      type: FieldType.Select,
      choices: makeOptions(FrameStyle, [
        FrameStyle.AluminumWithoutThermalBreak,
        FrameStyle.AluminumWithThermalBreak,
        FrameStyle.ReinforcedVinylSlashAluminumCladWood,
        FrameStyle.WoodSlashVinyl,
      ])
    })
    this.frameStyle.makeChoicesUpdater((field) => {
      if (!isOtherStyle()) {
        return [];
      }
      if (this.style.value == SkylightStyle.ManufacturedSkylight) {
        return makeOptions(FrameStyle, [
          FrameStyle.AluminumWithoutThermalBreak,
          FrameStyle.AluminumWithThermalBreak,
          FrameStyle.ReinforcedVinylSlashAluminumCladWood,
          FrameStyle.WoodSlashVinyl,
        ])
      } else if (this.style.value == SkylightStyle.SiteAssembledSlopedSlashOverheadGlazing) {
        return makeOptions(FrameStyle, [
          FrameStyle.AluminumWithoutThermalBreak,
          FrameStyle.AluminumWithThermalBreak,
          FrameStyle.StructuralGlazing,
        ])
      } else if (this.style.value == SkylightStyle.SlopedCurtainWall) {
        return makeOptions(FrameStyle, [
          FrameStyle.AluminumWithoutThermalBreak,
          FrameStyle.AluminumWithThermalBreak,
          FrameStyle.StructuralGlazing,
        ])
      }
    })
    this.useDefaultFrameWidth = Field.makeSelect('Use default frame width', YesNo)
    this.frameWidth = new Field({
      name: 'Frame width',
      type: FieldType.SmallLength,
    })
    this.frameWidth.makeUpdater((field) => {
      if (!isOtherStyle()) {
        return;
      }
      if (this.useDefaultFrameWidth.value == YesNo.Yes) {
        field.isOutput = true;
        field.value = lookupData(WindowType._getStdFrameWidthMap(), [
          this.style.value,
          this.frameStyle.value,
        ])
      } else {
        field.isOutput = false;
      }
    })
    this.numPanes = Field.makeSelect('Number of panes', NumPanes)
    this.glazing = Field.makeSelect('Glazing', Glazing)
    this.glazing.makeChoicesUpdater(() => {
      if (!isOtherStyle()) {
        return [];
      }
      if (this.numPanes.value == NumPanes.N1) {
        return makeOptions(Glazing, [Glazing.None])
      } else if (this.numPanes.value == NumPanes.N2) {
        return makeOptions(Glazing)
      } else {
        return makeOptions(Glazing, [
          Glazing.None,
          Glazing.E0_2,
          Glazing.E0_1,
          Glazing.E0_05,
        ])
      }
    })
    this.panesAppliedTo = Field.makeSelect('Panes applied to', PanesAppliedTo)
    this.panesAppliedTo.makeChoicesUpdater((field) => {
      if (!isOtherStyle()) {
        return [];
      }
      if (this.numPanes.value == NumPanes.N1) {
        return [];
      } else if (this.numPanes.value == NumPanes.N2) {
        if (this.glazing.value == Glazing.None) {
          return [];
        } else if (elemIn(this.glazing.value, [Glazing.E0_6])) {
          return makeOptions(PanesAppliedTo, [PanesAppliedTo.Surface2or3])
        } else {
          return makeOptions(PanesAppliedTo, [
            PanesAppliedTo.Surface2,
            PanesAppliedTo.Surface3,
          ])
        }
      } else {
        if (this.glazing.value == Glazing.None) {
          return [];
        } else if (this.glazing.value == Glazing.E0_2) {
          return makeOptions(PanesAppliedTo, [
            PanesAppliedTo.Surface2or3,
            PanesAppliedTo.Surface4or5,
            PanesAppliedTo.Surface2or3and4or5,
          ])
        } else {
          return makeOptions(PanesAppliedTo, [
            PanesAppliedTo.Surface2or3and4or5
          ])
        }
      }
    })
    this.tint = Field.makeSelect('Tint / reflective coating', Tint)
    this.tint.makeChoicesUpdater(() => {
      if (!isOtherStyle()) {
        return []
      }
      if (this.numPanes.value == NumPanes.N1) {
        return makeOptions(Tint, [
          Tint.Clear,
          Tint.Bronze,
          Tint.Green,
          Tint.Grey,
          Tint.BlueGreen,
          Tint.StainlessSteelOnClear8p,
          Tint.StainlessSteelOnClear14p,
          Tint.StainlessSteelOnGreen14p,
          Tint.StainlessSteelOnClear20p,
          Tint.TitaniumOnClear20p,
          Tint.TitaniumOnClear30p,
        ])
      } else if (this.numPanes.value == NumPanes.N2) {
        if (elemIn(this.glazing.value, [Glazing.None, Glazing.E0_6])) {
          return makeOptions(Tint)
        } else if (elemIn(this.glazing.value, [Glazing.E0_4, Glazing.E0_2, Glazing.E0_1])) {
          if (this.panesAppliedTo.value == PanesAppliedTo.Surface2) {
            return makeOptions(Tint, [Tint.Clear])
          } else {
            return makeOptions(Tint, [
              Tint.Clear,
              Tint.Bronze,
              Tint.Green,
              Tint.Grey,
              Tint.BlueGreen,
              Tint.HighPerfGreen,
            ])
          }
        } else if (this.glazing.value == Glazing.E0_05) {
          return makeOptions(Tint, [
            Tint.Clear,
            Tint.Bronze,
            Tint.Green,
            Tint.Grey,
            Tint.BlueGreen,
            Tint.HighPerfGreen,
          ])
        }
      } else {
        if (this.glazing.value == Glazing.None) {
          return makeOptions(Tint, [Tint.Clear, Tint.HighPerfGreen])
        } else {
          return makeOptions(Tint, [Tint.Clear])
        }
      }
    })
    this.paneThickness = Field.makeSelect('Pane thickness', PaneThickness)
    this.paneThickness.makeChoicesUpdater(() => {
      if (!isOtherStyle()) {
        return []
      }
      if (elemIn(this.tint.value, [Tint.Clear, Tint.Bronze, Tint.Green, Tint.Grey])) {
        return makeOptions(PaneThickness)
      } else {
        return makeOptions(PaneThickness, [PaneThickness.QuarterInch])
      }
    })
    this.gapType = Field.makeSelect('Gap type', GapType)
    this.gapType.makeChoicesUpdater(() => {
      if (!isOtherStyle()) {
        return [];
      }
      if (this.numPanes.value == NumPanes.N1) {
        return makeOptions(GapType, [GapType.None])
      } else if (elemIn(this.numPanes.value, [NumPanes.N2, NumPanes.N3])) {
        return makeOptions(GapType, [
          GapType.QuarterInchAir,
          GapType.HalfInchAir,
          GapType.QuarterInchArgon,
          GapType.HalfInchArgon,
        ])
      } else {
        return makeOptions(GapType, [
          GapType.QuarterInchAir,
          GapType.HalfInchAir,
          GapType.QuarterInchArgon,
          GapType.HalfInchArgon,
          GapType.QuarterInchKrypton,
        ])
      }
    })
    this.builderFields = [
      'frameStyle',
      'useDefaultFrameWidth',
      'frameWidth',
      'numPanes',
      'glazing',
      'panesAppliedTo',
      'tint',
      'paneThickness',
      'gapType',
    ]
    this.updater.setFieldsEnabled('builder-fields', this, this.builderFields, () => {
      return this.inputType.value == SkylightInputType.BuildSkylight && isOtherStyle();
    })
    // }

    this.outputUValue = new Field({
      name: 'Output U-Value',
      type: FieldType.UValue,
      isOutput: true,
      allowMin: false,
    })
    this.outputUValue.makeUpdater((field) => {
      let uValue = this.computeUValue();
      field.value = uValue.uValue;
      field.debugOutput = DebugOn() ? prettyJson(uValue) : null;
    })
    this.outputShgc = new Field({
      name: 'Output SHGC',
      type: FieldType.Ratio,
      isOutput: true,
      allowMin: false,
    })
    this.outputShgc.makeUpdater((field) => {
      let shgcs = this.computeShgc();
      field.value = shgcs.shgc;
      field.debugOutput = DebugOn() ? prettyJson(shgcs) : null;
    })

    this.serFields = [
      'id',
      'name',
      'height',
      'width',
      'curbHeight',
      'unusualShape',
      'unusualShapeArea',
      'unusualShapePerimeter',
      'style',
      'installationSealing',
      'inputType',
      'manualUValue',
      'manualShgc',
      'domedSkylightInputs',
      'tubularSkylightInputs',
      'frameStyle',
      'useDefaultFrameWidth',
      'frameWidth',
      'numPanes',
      'glazing',
      'panesAppliedTo',
      'tint',
      'paneThickness',
      'gapType',
    ];
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Skylight',
    }
  }

  static getTypeMetadata() {
    return {
      name: 'Skylight',
      createType: SkylightType.createType,
      openEditor: SkylightType.openEditor,
      customEditor: true,
    }
  }

  static createType() {
    let type = SkylightType.create(generateItemName('Skylight', gApp.proj().skylightTypes), true)
    gApp.proj().skylightTypes.push(type);
    sortItemsByName(gApp.proj().skylightTypes);
    return type
  }

  static openEditor(skylightType) {
    let pathName = gApp.proj().isCommercial() ? 'building-skylights' : 'skylights';
    gApp.proj().editorState.selectedSkylight = skylightType;
    gApp.router.push({name: pathName});
  }

  static getTableInfo() {
    return {
      typeName: 'Skylight',
      allowDuplicate: true,
      columns: {
        'name': {
          label: 'Name',
        },
        /*
        'style': {
          label: 'Style',
        },
        */
        height: {
          label: 'Height',
        },
        width: {
          label: 'Width',
        },
        uValue: {
          label: 'U-Value',
        },
        shgc0: {
          label: 'SHGC (0 deg)',
        },
        shgcDiff: {
          label: 'SHGC (Diffuse)',
        },
      }
    }
  }

  getTableData() {
    let shgcs = this.computeShgc();
    let shgc0 = shgcs.Deg0;
    let shgcDiff = shgcs.Diffuse;
    return {
      name: this.name.value,
      //style: SkylightStyle._labels[this.style.value],
      height: this.height.getValueStr(Units.ft),
      width: this.width.getValueStr(Units.ft),
      uValue: this.outputUValue.getValueStr(),
      shgc0: formatNum(shgc0),
      shgcDiff: formatNum(shgcDiff),
    }
  }

  getInputPage() {
    return {
      label: `Skylights - ${this.name.value}`,
      // TODO
      path: `skylights/${this.id}`,
    };
  }

  getArea() {
    // This is the area of the glass part
    if (this.unusualShape.value == YesNo.No) {
      return this.height.value * this.width.value;
    } else {
      return this.unusualShapeArea.value;
    }
  }

  getPerimeter() {
    if (this.unusualShape.value == YesNo.No) {
      return 2*this.height.value + 2*this.width.value;
    } else {
      return this.unusualShapePerimeter.value;
    }
  }

  getCurbArea() {
    // Area of the opaque curb
    let A_FC = 2*this.curbHeight.getValueInUnits(Units.ft)*(this.height.value + this.width.value)
    return A_FC;
  }

  /*
  isSpecialStyle() {
    return elemIn(this.style.value, [
      SkylightStyle.DomedSkylight
      SkylightStyle.TubularDaylightingDevice,
    ])
  }
  */

  computeUValue() {
    if (this.inputType.value == SkylightInputType.Manual) {
      return {
        uValue: this.manualUValue.value,
      }
    } else {
      if (this.style.value == SkylightStyle.DomedSkylight) {
        // Treated the same as a ManufacturedSkylight but the area ratio is different
        let inputData = {
          width: this.width.getValueInUnits(Units.ft),
          height: this.height.getValueInUnits(Units.ft),
          frameWidth: this.frameWidth.getValueInUnits(Units.ft),
          numPanes: this.numPanes.value,
          glazing: this.glazing.value,
          panesAppliedTo: this.panesAppliedTo.value,
          gapType: this.gapType.value,
          windowStyle: SkylightStyle.ManufacturedSkylight,
          frameStyle: this.frameStyle.value,
          windowsData: gApp.proj().windowsData,
          isSkylight: true,
          curbHeight: this.curbHeight.getValueInUnits(Units.ft),
        }
        return WindowType.computeUValue(inputData);

      } else if (this.style.value == SkylightStyle.TubularDaylightingDevice) {
        let uValue = lookupData(TubularSkylightData, [
          this.tubularSkylightInputs.getField('collectorLayers').value,
          this.tubularSkylightInputs.getField('diffuserLayers').value,
          this.tubularSkylightInputs.getField('insulationLocation').value,
          'uValue',
        ])
        return {
          uValue: uValue,
        }
      } else {
        // Similar method to windows.
        let inputData = {
          width: this.width.getValueInUnits(Units.ft),
          height: this.height.getValueInUnits(Units.ft),
          frameWidth: this.frameWidth.getValueInUnits(Units.ft),
          numPanes: this.numPanes.value,
          glazing: this.glazing.value,
          panesAppliedTo: this.panesAppliedTo.value,
          gapType: this.gapType.value,
          // Note: we pass in the skylight style here. The func handles this fine.
          windowStyle: this.style.value,
          frameStyle: this.frameStyle.value,
          windowsData: gApp.proj().windowsData,
          isSkylight: true,
          curbHeight: this.curbHeight.getValueInUnits(Units.ft),
        }
        return WindowType.computeUValue(inputData);
      }
    }
  }

  computeShgc() {
    let uValue = this.computeUValue().uValue;
    if (this.inputType.value == SkylightInputType.Manual) {
      return WindowType._estimateSkylightShgcs(this.manualShgc.value);
    } else {
      if (this.style.value == SkylightStyle.DomedSkylight) {
        let opacity = this.domedSkylightInputs.getField('domeOpacity').value;
        let curbHeight = this.curbHeight.getValueInUnits(Units.inches);
        let interpMap = lookupData(DomedSkylightShgcs, [opacity]);
        let shgc = interpolateInMap(interpMap, curbHeight);
        return WindowType._estimateSkylightShgcs(shgc);
      } else if (this.style.value == SkylightStyle.TubularDaylightingDevice) {
        let shgc = lookupData(TubularSkylightData, [
          this.tubularSkylightInputs.getField('collectorLayers').value,
          this.tubularSkylightInputs.getField('diffuserLayers').value,
          this.tubularSkylightInputs.getField('insulationLocation').value,
          'shgc',
        ])
        return WindowType._estimateSkylightShgcs(shgc);
      } else {
        let inputData = {
          width: this.width.getValueInUnits(Units.ft),
          height: this.height.getValueInUnits(Units.ft),
          frameWidth: this.frameWidth.getValueInUnits(Units.ft),
          numPanes: this.numPanes.value,
          glazing: this.glazing.value,
          panesAppliedTo: this.panesAppliedTo.value,
          gapType: this.gapType.value,
          paneThickness: this.paneThickness.value,
          tint: this.tint.value,
          windowStyle: this.style.value,
          frameStyle: this.frameStyle.value,
          windowsData: gApp.proj().windowsData,
          isSkylight: true,
          curbHeight: this.curbHeight.getValueInUnits(Units.ft),
        }
        return WindowType.computeShgc(inputData);
      }
    }
  }
}
setupClass(SkylightType)
