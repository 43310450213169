<script setup>
import { ref, onMounted, reactive, computed, defineEmits, } from 'vue'

const emit = defineEmits(['click'])
</script>

<template>
  <button class="LinkBtn CloseX" @click="emit('click')" tabindex="-1" aria-label="Close">&#x2715;</button>
</template>

<style scoped>
.CloseX {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: transparent;
  color: #333;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0;
  line-height: 1;
}

.CloseX:hover {
  background-color: rgba(0, 0, 0, 0.1);
  transform: scale(1.1);
}

.CloseX:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

</style>
