import { createApp, } from 'vue'
import './style/main.css'
import './style/utils.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import App from './ui/App.vue'
import { initGlobalApp } from './State.js'

import { createRouter, createWebHashHistory } from 'vue-router'
import * as Sentry from "@sentry/vue";

import ToastPlugin from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css';

import Tracker from '@openreplay/tracker';

// Note: we load some components up-front and async load others
import HomeView from './ui/HomeView.vue'
import ProjectView from './ui/ProjectView.vue'
import SignInView from './ui/SignInView.vue'

import HouseHomeView from './ui/HouseHomeView.vue'
const BuildHouseView = () => import('./ui/BuildHouseView.vue');
const WallsView = () => import('./ui/WallsView.vue');
const WindowsView = () => import('./ui/WindowsView.vue');
const DoorsView = () => import('./ui/DoorsView.vue');
const SkylightsView = () => import('./ui/SkylightsView.vue');
const ExternalShadingsView = () => import('./ui/ExternalShadingsView.vue');
const InternalShadingsView = () => import('./ui/InternalShadingsView.vue');
const BufferSpacesView = () => import('./ui/BufferSpacesView.vue');

import BuildingHomeView from './ui/BuildingHomeView.vue'
const BuildingAndEnvView = () => import('./ui/BuildingAndEnvView.vue');
const SystemsView = () => import('./ui/SystemsView.vue');
const SpacesView = () => import('./ui/SpacesView.vue');
const SchedulesView = () => import('./ui/SchedulesView.vue');
const BuildingResultsView = () => import('./ui/BuildingResultsView.vue');
const ProjectSettingsView = () => import('./ui/ProjectSettingsView.vue');

// Firebase Setup:
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Import for automatic page reload
import { registerSW } from 'virtual:pwa-register'

console.log(`Starting app, in mode: ${import.meta.env.MODE}`);

// Setup PWA automatic page reload.
// This causes the service worker to reload the page as soon as it is registered.
// Note - this doesn't currently do an auto-reload every X secs.
// See here to set that up: https://vite-pwa-org.netlify.app/guide/periodic-sw-updates
registerSW({ immediate: true })

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBkvN-t-cop25ALRWlW454tHQuJhMKT52Q",
  //authDomain: "buildingcalc.firebaseapp.com",
  authDomain: "app.heatwise-hvac.com",
  projectId: "buildingcalc",
  storageBucket: "buildingcalc.appspot.com",
  messagingSenderId: "815475876429",
  appId: "1:815475876429:web:cbfba3ce60e6d8807bf204",
  measurementId: "G-WTW9LP6FZ4"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// OpenReplay Setup:
const tracker = new Tracker({
  projectKey: "tNYwTZk4biWSIOouGaZs",  
  // Make sure we can see input values, for debugging/support
  obscureTextEmails: false,
  obscureTextNumbers: false,
  obscureInputEmails: false,
  obscureInputNumbers: false,
  obscureInputDates: false,
  defaultInputMode: 0,
});
// If we aren't on localhost, start the tracker
if (window.location.hostname !== 'localhost') {
  tracker.start()
}

const routes = [
  {
    path: '/', name: 'home', component: HomeView,
  },
  {
    path: '/signin', name: 'signin', component: SignInView,
    props: {signIn: true},
  },
  {
    path: '/signup', name: 'signup', component: SignInView,
    props: {signIn: false},
  },
  {
    path: '/house/:id', component: ProjectView,
    name: 'house-project',
    children: [
      {path: '', name: 'house', component: HouseHomeView,},
      {path: 'build', name: 'build', component: BuildHouseView,},
      {path: 'walls', name: 'walls', component: WallsView, props: {isRoof: false}},
      {path: 'roofs', name: 'roofs', component: WallsView, props: {isRoof: true}},
      {path: 'windows', name: 'windows', component: WindowsView,},
      {path: 'doors', name: 'doors', component: DoorsView,},
      {path: 'skylights', name: 'skylights', component: SkylightsView,},
      {path: 'externalshadings', name: 'externalshadings', component: ExternalShadingsView,},
      {path: 'internalshadings', name: 'internalshadings', component: InternalShadingsView,},
      {path: 'bufferspaces', name: 'bufferspaces', component: BufferSpacesView,},
      {path: 'settings', name: 'settings', component: ProjectSettingsView},
    ]
  },
  {
    path: '/building/:id', component: ProjectView,
    name: 'building-project',
    // Note: 'name' must be unique across all routes, even for nested routes
    children: [
      {path: '', name: 'building', component: BuildingHomeView,},
      {path: 'location', name: 'location', component: BuildingAndEnvView,},
      {path: 'systems', name: 'systems', component: SystemsView},
      {path: 'spaces', name: 'spaces', component: SpacesView},
      {path: 'results', name: 'building-results', component: BuildingResultsView},
      {path: 'schedules', name: 'schedules', component: SchedulesView},
      {path: 'walls', name: 'building-walls', component: WallsView, props: {isRoof: false}},
      {path: 'roofs', name: 'building-roofs', component: WallsView, props: {isRoof: true}},
      {path: 'windows', name: 'building-windows', component: WindowsView,},
      {path: 'doors', name: 'building-doors', component: DoorsView,},
      {path: 'skylights', name: 'building-skylights', component: SkylightsView,},
      {path: 'externalshadings', name: 'building-externalshadings', component: ExternalShadingsView,},
      {path: 'internalshadings', name: 'building-internalshadings', component: InternalShadingsView,},
      {path: 'bufferspaces', name: 'building-bufferspaces', component: BufferSpacesView,},
      {path: 'settings', name: 'building-settings', component: ProjectSettingsView},
    ]
  },
  {
    path: '/psychrometrics', name: 'psychrometrics', component: () => import('./ui/PsychrometricsCalculatorView.vue'),
  },
  {
    path: '/solar-calculator', name: 'solar-calculator', component: () => import('./ui/SolarCalculatorView.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
})

const app = createApp(App)

/*
Note: Sentry works fine on Chrome, but does not seem to work on Firefox Mac (requests are blocked due to CORS).
*/
Sentry.init({
  app,
  dsn: "https://78554c998cc6f60838bd2a4ef56f066b@o4507087356821504.ingest.us.sentry.io/4508490908499968",
  integrations: [
    /*
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
    */
  ],
  // Performance Monitoring
  /*
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  */
  debug: true,
  enabled: window.location.hostname !== 'localhost',
});

app.use(router)
app.use(ToastPlugin)
// app.component(VueFeather.name, VueFeather)

let builderApp = initGlobalApp(firebaseApp, router,
  app.config.globalProperties.$toast);
builderApp.setOpenReplayTracker(tracker);
builderApp.run();

let curErrorHandler = app.config.errorHandler;
if (!curErrorHandler) {
  curErrorHandler = (err, instance, info) => {
  }
}
app.config.errorHandler = (err, instance, info) => {
  builderApp.onGlobalError(err, instance, info);
  return curErrorHandler(err, instance, info);
}

app.mount('#app')

