<script setup>
import { ref, onMounted, reactive, computed, defineEmits, } from 'vue'
import GuideStep from './GuideStep.vue';
import { gApp } from '../Globals.js'

let proj = computed(() => {
  return gApp.proj();
})

let currentStep = computed(() => {
  return proj.value.guide.currentStep;
})

let props = defineProps({
  name: {
    type: String,
    default: 'Back',
  }
})

const emit = defineEmits(['click'])

function onMarkDone(step) {
  if (step === 1) {
    proj.value.guide.setLocationDone = true;
    // Move to next step
    if (proj.value.guide.currentStep === 1) {
      proj.value.guide.currentStep = 2;
    }
  } else if (step === 2) {
    proj.value.guide.createSpacesDone = true;
    // Move to next step
    if (proj.value.guide.currentStep === 2) {
      proj.value.guide.currentStep = 3;
    }
  } else if (step === 3) {
    proj.value.guide.createSystemsDone = true;
    // Move to next step
    if (proj.value.guide.currentStep === 3) {
      proj.value.guide.currentStep = 4;
    }
  } else if (step === 4) {
    proj.value.guide.runCalculationsDone = true;
    // No next step after this (but increment currentStep so that the guide is marked as done)
    if (proj.value.guide.currentStep === 4) {
      proj.value.guide.currentStep = 5;
    }
  }
}

function hasSetLocation() {
  return proj.value.guide.setLocationDone;
}

function hasCreatedSpaces() {
  return proj.value.guide.createSpacesDone;
}

function hasCreatedSystems() {
  return proj.value.guide.createSystemsDone;
}

function hasRunCalculations() {
  return proj.value.guide.runCalculationsDone;
}

let currentLocation = computed(() => {
  let locationData = proj.value.getLocationData();
  if (!locationData) {
    return "Not set";
  }
  return locationData.locPath ? locationData.locPath.fullName : "Not set";
})

function getSystemsWithResults(systems) {
  return systems.filter(system => system.getResultsReadyAndSuccessful());
}

</script>

<template>
  <div>
    <div class="mb-l GuideHeader">
      <div class="GuideHeaderIcon"><i class="bi-clipboard-check"></i></div>
      <div class="GuideHeaderContent">
        <h2 class="GuideTitle">Project Guide</h2>
        <p class="Bold GuideSubtitle">Follow this guide to complete your project</p>
      </div>
    </div>
    <GuideStep :stepNumber="1" :currentStep="currentStep" title="Set Location" :isDone="hasSetLocation()" :isReady="true" class="mb-xs" @onMarkDone="onMarkDone(1)">
      <template v-slot:content>
        <p class="mb-m CharW">
          Go to the <b>Location</b> tab on the left and set your location. By default, it will be set to Winnipeg, MB.
        </p>
        <div class="MinorWarningSection">
          <p class="Bold">
            Getting Help
          </p>
          <p>
            If you ever want more details, click 'Quick Help' in the top-right to open our built-in manual.
            You can also click the 'Get Help' button in the top-right to contact support.
          </p>
        </div>
        <p class="Italic TextAlignCenter mt-l">
          When you're ready, mark this step done.
        </p>
      </template>
    </GuideStep>
    <GuideStep :stepNumber="2" :currentStep="currentStep" title="Create Spaces" :isDone="hasCreatedSpaces()" :isReady="proj.spaces.length > 0" class="mb-xs" @onMarkDone="onMarkDone(2)">
      <template v-slot:content>
        <p v-if="currentStep < 2" class="mb-s LockedStepMessage">
          <i class="bi-lock-fill mr-xxs"></i> Complete the previous step first.
        </p>
        <p v-else class="mb-s">
          Go to the <b>Spaces</b> tab on the left and create your building's spaces. In Step 3, you will create
          Systems that will be used to condition these spaces.
        </p>
        <div v-if="proj.spaces.length > 0">
          <p>You have created these spaces:</p>
          <p v-for="space in proj.spaces">+ {{ space.name.value }}</p>
          <p class="Italic mt-m TextAlignCenter">
            You are ready to create Systems. Mark this step done.
          </p>
        </div>
      </template>
    </GuideStep>
    <GuideStep :stepNumber="3" :currentStep="currentStep" title="Create Systems" :isDone="hasCreatedSystems()" :isReady="proj.systems.length > 0" class="mb-xs" @onMarkDone="onMarkDone(3)">
      <template v-slot:content>
        <p v-if="currentStep < 3" class="mb-s LockedStepMessage">
          <i class="bi-lock-fill mr-xxs"></i> Complete the previous steps first.
        </p>
        <p v-else class="mb-s">
          Go to the <b>Systems</b> tab on the left and create your building's systems. Each system will have a list of zones, which contain spaces.
        </p>
        <div v-if="proj.systems.length > 0">
          <p>You have created these systems:</p>
          <p v-for="system in proj.systems">+ {{ system.name.value }}</p>
          <p class="Italic mt-m TextAlignCenter">
            You are ready to run calculations. Mark this step done.
          </p>
        </div>
      </template>
    </GuideStep>
    <GuideStep :stepNumber="4" :currentStep="currentStep" title="Run Calculations" :isDone="hasRunCalculations()" :isReady="getSystemsWithResults(proj.systems).length > 0" class="mb-s" @onMarkDone="onMarkDone(4)">
      <template v-slot:content>
        <p v-if="currentStep < 4" class="mb-s LockedStepMessage">
          <i class="bi-lock-fill mr-xxs"></i> Complete the previous steps first.
        </p>
        <p v-else class="mb-s">
          Go to the <b>Results</b> tab on the left. Click a System to open its results page, then run a calculation.
        </p>
        <div class="mb-s MinorWarningSection">
          <p class="Bold">
            You may be told that there are project errors.
          </p>
          <p>This is normal. You may have forgot to fill in some values. Resolve the errors then re-run the calculation. </p>
        </div>
        <div v-if="getSystemsWithResults(proj.systems).length > 0">
          <p>You have successfully calculated loads for:</p>
          <p v-for="system in getSystemsWithResults(proj.systems)">+ {{ system.name.value }}</p>
          <p class="Italic mt-m TextAlignCenter">
            You are done the guide. Mark this step done.
          </p>
        </div>
      </template>
    </GuideStep>
    <div class="mt-m" v-if="proj.guide.allDone()">
      <p class="Bold pc CharW f-l">
        You are done the guide.
      </p>
      <p>
        From here, you can modify any part of your project then re-run calculations for updated results.
      </p>
    </div>
    <div v-if="gApp.isAdminUser()" class="mt-m">
      <p>ADMIN SECTION</p>
      <button @click="proj.guide.reset()">Reset Guide</button>
    </div>
  </div>
</template>

<style scoped>
.MinorWarningSection {
  /*
  border: 1px solid var(--b-20);
  padding: var(--s);
  border-radius: var(--br-s);
  */
  border-left: 4px solid var(--b-20);
  padding-left: var(--xs);
  /*padding-top: var(--xs);*/
  padding-bottom: var(--xs);
}

.LockedStepMessage {
  color: var(--grey-40);
  font-style: italic;
}

.GuideHeader {
  background-color: var(--pc-10);
  padding: var(--m);
  border-radius: var(--br-s);
  border-left: 6px solid var(--pc);
  display: flex;
  align-items: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.GuideHeaderIcon {
  font-size: 2rem;
  color: var(--pc);
  margin-right: var(--m);
  display: flex;
  align-items: center;
  justify-content: center;
}

.GuideHeaderContent {
  flex: 1;
}

.GuideTitle {
  /*color: var(--pc);*/
  font-size: 1.8rem;
  margin-bottom: var(--xxs);
  line-height: 1.2;
}

.GuideSubtitle {
  font-size: 1.1rem;
  margin: 0;
  color: var(--grey-40);
  /*animation: fadeIn 0.8s ease-in-out;*/
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}
</style>
