import { ref, reactive, } from 'vue'
import { getFirestore, connectFirestoreEmulator, runTransaction,  } from "firebase/firestore";
import { getAuth, onAuthStateChanged, connectAuthEmulator, signOut, sendEmailVerification, } from "firebase/auth";
import { getFunctions, httpsCallable, connectFunctionsEmulator, } from "firebase/functions";
import { getAnalytics, } from "firebase/analytics";
import { collection, query, where, or, doc, getDoc,
  getDocs, setDoc, addDoc, updateDoc, deleteDoc, } from "firebase/firestore";
import * as ser from './Common/SerUtil.js'
import {
  PleaseContactStr,
  IntervalTimer,
  YesNoOrChecking,
} from './Base.js'
import { makePromiseObj } from './Common/ReqMap.js'
import * as Sentry from "@sentry/vue";
import { PsychrometricsCalculator } from './Components/PsychrometricsCalculator.js';
import { SolarCalculator } from './Components/SolarCalculator.js';

import { prettyJson, clearArray, valOr, getElemNames,
  addElem, removeElem, elemIn,
  extendArray, extendMap, isObject,
  runAfterDelay, waitMillis, awaitAll,
  Result,
} from './SharedUtils.js'

import { ResidentialProject } from './Components/ResidentialProject.js'
import { CommercialProject } from './BuildingComponents/CommercialProject.js';
import { ProjectTypes } from './Project.js';
export { ResidentialProject } from './Components/ResidentialProject.js'

import { ProjectLock } from './Common/ProjectLock.js'

export { Units } from './Common/Units.js'

export * from './Common/Field.js'

import { gApp, setGlobalApp, DebugOn, } from './Globals.js'
export { gApp, DebugOn, } from './Globals.js'

const kAppVersion = __APP_VERSION__

const kAdminUsers = [
  'mgriley97@gmail.com',
  'madsaurgames@gmail.com',
  'magnus.johnson@heatwise-hvac.com',
  'magnusjohnson49@gmail.com',
];

const kBetaUsers = [
  'mgriley97@gmail.com',
  'madsaurgames@gmail.com',
  'magnus.johnson@heatwise-hvac.com',
  'magnusjohnson49@gmail.com',

  'mike.lund@tweetgarot.com',
  'dan.rehbein@tweetgarot.com',
]

/*
Notes:
For several classes we use the ctor + init pattern because in the ctor, 'this' is not
reactive, which is a problem if we pass them to lambdas.
*/

// const kServerAddr = 'https://'

// We only actually save to backend when a change is detected, so it's fine to do this often
const kAutosaveIntervalSecs = 1;

export class BuilderApp {
  constructor(firebaseApp, router, toaster) {
    this.firebaseApp = firebaseApp;
    this.router = router;
    this.toaster = toaster;
    this.openReplayTracker = null;

    this.useEmulator = location.hostname == "localhost";
    console.log("Using Firebase emulators: " + this.useEmulator);
    this.db = getFirestore(firebaseApp);
    // Note: I found that I couldn't actually see any data in the firebase emulator UI, so sort of useless.
    /*
    if (this.useEmulator) {
      connectFirestoreEmulator(this.db, "127.0.0.1", 8080);
    }
    */
    this.auth = getAuth(firebaseApp);
    /*
    if (this.useEmulator) {
      connectAuthEmulator(this.auth, "http://127.0.0.1:9099");
    }
    */
    this.analytics = getAnalytics(firebaseApp);
    this.functions = getFunctions(firebaseApp);
    this.functionsEndpoint = 'https://us-central1-buildingcalc.cloudfunctions.net'
    if (this.useEmulator) {
      connectFunctionsEmulator(this.functions, "127.0.0.1", 5001);
      this.functionsEndpoint = 'http://127.0.0.1:5001/buildingcalc/us-central1'
    }

    this.authState = ref("Checking");
    this.authPromiseObj = makePromiseObj()
    this.user = ref(null);

    this.reloadingProjects = ref(false);
    this.projects = reactive([]);
    this.curProj = ref(null);

    // This is used to display a welcome message if they have no projects yet
    this.userHasProjects = ref(YesNoOrChecking.Checking);

    this.psychrometricsCalculator = PsychrometricsCalculator.create();
    this.solarCalculator = SolarCalculator.create();

    this.savingAll = false;
    this.appStartTime = new Date();
    this.autosaveTimer = new IntervalTimer(() => {
      this.doAutosave();
    }, kAutosaveIntervalSecs, {onlyWhenVisible: true});
    this.checkNotifsTimer = new IntervalTimer(() => {
      this.checkUserNotifs();
      this.updateProjLocks();
    }, 3, {onlyWhenVisible: true})
  }

  isAdminUser() {
    if (!this.user.value) {
      return false;
    }
    return elemIn(this.user.value.email, kAdminUsers)
  }

  isBetaUser() {
    if (!this.user.value) {
      return false;
    }
    return elemIn(this.user.value.email, kBetaUsers)
  }

  getOpenReplayTracker() {
    return this.openReplayTracker;
  }

  setOpenReplayTracker(tracker) {
    this.openReplayTracker = tracker;
  }

  run() {
    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        console.log(`User signed in (id: ${user.uid})`, user);
        this.user.value = user;
        this.authState.value = "SignedIn";
        Sentry.setUser({
          email: user.email,
          app_version: kAppVersion,
        })
        if (this.openReplayTracker) {
          this.openReplayTracker.setUserID(user.email);
        }
        this.userHasProjects.value = YesNoOrChecking.Checking;
        this.reloadProjects();
        console.log("Resolving auth promise");
        this.authPromiseObj.resolve();
        runAfterDelay(1.0, () => {
          this.checkNotifsTimer.runNow();
        });
        if (!this.user.value.emailVerified) {
          this.tryVerifyEmail();
        }
      } else {
        console.log("User signed out");
        this.user.value = null;
        this.authState.value = "SignedOut";
        Sentry.setUser(null);
        this.userHasProjects.value = YesNoOrChecking.Checking;
        let currentRouteName = this.router.currentRoute.value.name;
        if (!(currentRouteName == 'signin' || currentRouteName == 'signup')) {
          console.log("Not signed in. Going to signin page");
          this.router.replace('/signin');
        }
        clearArray(this.projects);
      }
    });

    /*
    window.addEventListener("beforeunload", (evt) => {
      evt.preventDefault();
      // For legacy support
      event.returnValue = true;
    });
    */

    // Note: the visibilitychange handler function cannot be async
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState == 'hidden') {
        this.onEnterBackground();
      } else if (document.visibilityState == 'visible') {
        this.onEnterForeground();
      }
    });
    this.onEnterForeground();
  }

  getUser() {
    return this.user.value;
  }

  getUserEmail() {
    return this.user.value ? this.user.value.email : null;
  }

  async signOutUser() {
    await this.closeProject();
    await signOut(this.auth);
  }

  async tryVerifyEmail() {
    // Send a verification email if we haven't already
    const docRef = doc(this.db, 'userdata', this.user.value.uid);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists() || !docSnap.data().sentEmailVerif) {
      console.log("Email verification not yet sent. Sending...");
      // Delay to avoid 'auth/too-many-requests'.
      setTimeout(() => {
        this.sendEmailVerification();
      }, 1000);
      if (docSnap.exists()) {
        await updateDoc(docRef, {
          sentEmailVerif: true
        }) 
      } else {
        await setDoc(docRef, {
          sentEmailVerif: true
        }) 
      }
    } else {
      console.log("Already sent an email verif");
    }
  }

  async sendEmailVerification(doToast) {
    console.log("Sending email verification");
    await sendEmailVerification(this.user.value, {
      // continue-url
      url: window.location.href,
    });
    if (doToast) {
      gApp.toast("Sent!");
    }
    console.log("Sent!");
  }

  getAppTimeSecs() {
    let curTime = new Date();
    return (curTime - this.appStartTime) / 1000.0;
  }

  onEnterForeground() {
    console.log("Entering foreground");
    this.autosaveTimer.start();

    this.checkNotifsTimer.start();
    this.checkNotifsTimer.runNow();

    // If our email is unverified, reload and check if it's verified now
    // TODO - this does not work as expected. Better to just use a continue-link for now.
    /*
    if (this.auth.currentUser && !this.auth.currentUser.emailVerified) {
      this.reloadUser();
    }
    */
  }

  async reloadUser() {
    /*
    console.log("Reloading auth to check for email verification");
    await this.auth.currentUser.reload();
    this.user.value = this.auth.currentUser;
    */
  }

  onEnterBackground() {
    console.log("Entering background");

    // TODO - runNow, too?
    this.autosaveTimer.stop();

    this.checkNotifsTimer.stop();
  }

  doAutosave() {
    let curAppTime = Math.round(this.getAppTimeSecs());
    // console.log(`Autosaving... (${curAppTime}s)`);
    this.saveAll();
  }

  async saveAll() {
    if (this.savingAll) {
      console.log("Already saving all");
      return;
    }
    this.savingAll = true;
    try {
      // Save the current project, if editing
      if (this.proj()) {
        await this.proj().save();
      }
    } finally {
      this.savingAll = false;
    }
  }

  proj() {
    return this.curProj.value;
  }

  async _loadProjectData(projId) {
    /*
    Returns Result<projectData>
    */
    try {
      await this.authPromiseObj.wait();
      console.log(`Loading project data for ID=${projId}`)
      const docRef = doc(this.db, 'projects', projId);
      console.log("DocRef: ", docRef);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        console.log("Proj does not exist");
        return Result.error(`Could not find this Project (ID ${projId}). ` +
          `If you think this is an error, please contact support@heatwise-hvac.com.`)
      }
      // console.log("Found project: ", docSnap);
      console.log("Found project: ", docSnap.data());
      return Result.success(docSnap.data());
    } catch (err) {
      console.warn(`Error loading Project (${projId}): ${err.message}`);
      return Result.error(`Error loading Project (with ID ${projId}): ${err.message}. If you think this is an error, please contact `
          + `support@heatwise-hvac.com.`)
    }
  }

  async setCurProj(projId, opts) {
    /*
    Sets the current project to the given projId.
    Be very careful with error handling here. We don't want a situation where
    we fail to completely read the new project, then save back a blank proj (resulting in
    data loss).

    Returns Result<Project>
    */
    opts = valOr(opts, {});
    let isNew = valOr(opts.isNew, false);

    console.log(`Setting curProj to ${projId}`);
    if (this.curProj.value) {
      if (this.curProj.value.id == projId) {
        console.log(`Already have proj ${projId} open. Returning`);
        return Result.success(this.curProj.value);
      }
      // Have open some other project. Close it.
      await this.closeProject();
      this.curProj.value = null;
    }

    let projDataResult = await this._loadProjectData(projId);
    if (projDataResult.isError()) {
      console.log(`Could not find proj: ${projId}`);
      this.reportError(new Error(`Error loading project ${projId}: ` +projDataResult.getError()));
      return projDataResult;
    }
    console.log(`Found proj: ${projId}. Deserializing.`);

    try {
      let proj = null;
      let projData = projDataResult.getResult();
      if (!projData.type || projData.type == ProjectTypes.Residential) {
        proj = ResidentialProject.create(projId);
      } else {
        proj = CommercialProject.create(projId);
      }
      // Note: set curProj here so that created objects can reference it.
      // Note: we split Project init into init() and setup() b/c setup() requires
      // gApp.proj() to be set. Messy but fine for now.
      console.log(`Setting gApp.proj() to ${projId}`);
      gApp.curProj.value = proj;
      console.log("Running proj.setup()");
      proj.setup();
      console.log("Deserializing proj data");
      // Note: deserialization may fail if data is malformed. Make sure exceptions
      // are handled here so that we don't end up with a blank project that gets
      // saved (overwriting the original data).
      ser.readFromJson(proj, projData);
      console.log("Done deserializing proj data");
      console.log("Running proj.onPostReadFromJson()");
      await proj.onPostReadFromJson();

      // No need to wait
      proj.updateLastOpenedTime();

      if (isNew) {
        await proj.setupNewProject();
      }

      // Try to acquire the write lock. Fallback to read-only mode
      // TODO - this really should be a lock transaction, but whatever
      let lockOwner = await ProjectLock.getLockOwner(projId);
      if (!lockOwner) {
        await ProjectLock.acquireLock(projId);
        proj.lockedBy = this.user.value.email;
      }

      // Project is now done loading and ready for use
      proj.setDoneLoading();

      return Result.success(proj);
    } catch (err) {
      console.error(`Error setting curProj to ${projId}: ${err.message}`);
      this.reportError(new Error(`Error setting curProj to ${projId}: ${err.message}`));
      gApp.curProj.value = null;
      return Result.error(`Error setting project to ${projId}: ${err.message}`);
    }
  }

  async canOpenProj(projId) {
    return await ProjectLock.canOpen(projId);
  }

  async stealProjectLock(projId) {
    await ProjectLock.forceAcquireLock(projId);
  }

  async createProject(name, projType) {
    // Add the doc to the firebase now so that firebase creates its uid
    console.log("Uid: " + this.user.value.uid);
    const projDoc = doc(collection(this.db, 'projects'));
    await setDoc(projDoc, {
      userId: this.user.value.uid,
      userEmail: this.user.value.email,
      name: name,
      type: projType,
      sharedUsers: [],
      creationTime: new Date().getTime(),
      lastOpenedTime: new Date().getTime(),
    });
    console.log(`Created new project: id=${projDoc.id}, type=${projDoc.type}`)
    let projResult = await this.setCurProj(projDoc.id, {isNew: true});
    if (projResult.isError()) {
      this.toastError(`Error creating project: ${projResult.getError()}`);
      let developerFeedbackMsg = `The user ${this.user.value.email} encountered an error creating a project: ` +
        `${projResult.getError()}`;
      this.sendDeveloperFeedback(developerFeedbackMsg);
      return;
    }
    this.router.push({path: projResult.getResult().getBaseRoute()});
  }

  async deleteCurProj() {
    let proj = this.curProj.value;
    console.log(`Deleting proj: ${proj.name}`);
    await deleteDoc(doc(this.db, 'projects', proj.id));
    this.router.push({name: 'home'});
    this.curProj.value = null;
    await waitMillis(500);
    this.toast(`"${proj.name}" deleted`);
  }

  async closeProject() {
    if (this.curProj.value) {
      let proj = this.curProj.value;
      await proj.close();
      // Set to null after closing, so that if any last effect handlers run
      // we still have gApp.proj() set.
      this.curProj.value = null;
    }
  }

  isReloadingProjects() {
    return this.reloadingProjects.value;
  }

  async reloadProjects() {
    if (this.reloadingProjects.value) {
      return;
    }
    this.reloadingProjects.value = true;
    try {
      //console.log("Reloading projects...");
      await this.authPromiseObj.wait();
      //console.log("Auth promise resolved");
      let queryCondition = null;
      if (this.user.value.emailVerified) {
        queryCondition = or(
          where("userId", "==", this.user.value.uid),
          where("sharedUsers", "array-contains", this.user.value.email));
      } else {
        queryCondition = where("userId", "==", this.user.value.uid);
      }
      const q = query(collection(this.db, "projects"), queryCondition);
      const querySnapshot = await getDocs(q);
      let newProjects = []
      querySnapshot.forEach((doc) => {
        let projData = doc.data(); 
        // console.log("ProjData: ", projData);
        let type = projData.type || ProjectTypes.Residential;
        let projInfo = {
          id: doc.id,
          type: type,
          route: `/${type == ProjectTypes.Residential ? 'house' : 'building'}/${doc.id}`,
          name: projData.name || "Untitled Project",
          lastOpenedTime: projData.lastOpenedTime || 0,
          userId: projData.userId,
          userEmail: projData.userEmail,
          isMine: this.user.value.uid == projData.userId,
          sharedUsers: projData.sharedUsers || [],
        }
        //console.log(`Name: ${projInfo.name}, IsMine: ${projInfo.isMine}`);
        // console.log("ProjInfo: ", prettyJson(projInfo));
        newProjects.push(projInfo);
      })
      // Sort by most recently opened/touched
      newProjects.sort((projA, projB) => {
        return projB.lastOpenedTime - projA.lastOpenedTime;
      });
      this.userHasProjects.value = newProjects.length > 0 ?
                                    YesNoOrChecking.Yes : YesNoOrChecking.No;
      // Set lock data
      awaitAll(newProjects, async (proj) => {
        proj.lockedBy = await ProjectLock.getLockOwner(proj.id);
      })
      clearArray(this.projects);
      extendArray(this.projects, newProjects);
    } catch (err) {
      console.error("Error loading projects: ", err);
      gApp.reportError(err);
    } finally {
      this.reloadingProjects.value = false;
    }
  }

  async updateProjLocks() {
    //console.log("Updating proj locks");
    awaitAll(this.projects, async (proj) => {
      proj.lockedBy = await ProjectLock.getLockOwner(proj.id);
    })
  }

  toast(msg, opts) {
    opts = valOr(opts, {})
    // console.log(`Toast msg: ${msg}`);
    // TODO - replace with Bootstrap's better toast.
    this.toaster.open({message: msg, ...opts})
  }

  toastError(msg) {
    this.toast(msg, {type: 'error', duration: 20});
  }

  async sendDeveloperFeedback(feedbackTxt) {
    console.log("Sending feedback");
    let data = {
      text: feedbackTxt
    }
    await fetch(this.functionsEndpoint + '/sendDeveloperFeedback', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data),
    });
  }

  async sendUserNotif(userEmail, data) {
    console.log("Sending user notif: ", prettyJson(data));
    const notifDoc = doc(collection(this.db, 'notifs'));
    await setDoc(notifDoc, {
      'for': userEmail,
      ...data
    });
  }

  async getUserNotifs() {
    if (!this.user.value || !this.user.value.emailVerified) {
      return [];
    }
    //console.log(`Getting user notifs for ${this.user.value.email}`);
    let queryCondition = where("for", "==", this.user.value.email);
    const q = query(collection(this.db, "notifs"), queryCondition);
    const querySnapshot = await getDocs(q);
    let notifs = [];
    querySnapshot.forEach((doc) => {
      notifs.push(doc.data());
    })
    return notifs;
  }

  async clearUserNotifs() {
    if (!this.user.value || !this.user.value.emailVerified) {
      return;
    }
    let queryCondition = where("for", "==", this.user.value.email);
    const q = query(collection(this.db, "notifs"), queryCondition);
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      deleteDoc(doc.ref);
    })
  }

  async checkUserNotifs() {
    if (!this.user.value) {
      return;
    }
    let notifs = await this.getUserNotifs();
    for (const notif of notifs) {
      if (notif.type === 'AddedToProject') {
        this.toast(`${notif.owner} added you to project ${notif.name}`, {duration: 10000});
        this.reloadProjects();
      } else if (notif.type === 'RemovedFromProject') {
        this.toast(`${notif.owner} removed you from project ${notif.name}`);
        this.reloadProjects();
      } else {
        throw new Error(`Unexpected notif type: ${notif.type}`, {duration: 10000});
      }
    }
    await this.clearUserNotifs();
  }

  reportError(err) {
    Sentry.captureException(err);
  }

  onGlobalError(err, instance, info) {
    console.error("Detected global error! ", err);
    let msg = `An internal error occurred. ${PleaseContactStr}\nDetails: ${err.message}`
    this.toastError(msg);
  }
};

export function initGlobalApp(firebaseApp, router, toaster) {
  setGlobalApp(new BuilderApp(firebaseApp, router, toaster));
  return gApp;
}

export function initGlobalMockApp(mockApp) {
  setGlobalApp(mockApp);
  return gApp;
}

