<script setup>
import { gApp } from '../State.js'
import { ref, onMounted, reactive, computed } from 'vue'
import BasicModal from './utils/BasicModal.vue'
import InputGroup from './InputGroup.vue'
import { ProjectLock } from '../Common/ProjectLock.js';

import ProfileIcon from './utils/ProfileIcon.vue'

let openProjModal = ref(null);

let projToOpen = ref({name: '', lockedBy: ''});

const props = defineProps({
  projects: Array,
})

let kMaxNumSharedUsersShown = 3;

function getTrimmedSharedUsers(proj) {
  let maxUsers = kMaxNumSharedUsersShown;
  return proj.sharedUsers.slice(0, maxUsers);
}

async function tryOpenProj(proj) {
  console.log("Trying to open proj: ", proj);
  let owner = await ProjectLock.getLockOwner(proj.id);
  if (!owner || owner === gApp.getUser().email) {
    console.log("Success - opening");
    gApp.router.push(proj.route)
  } else {
    console.log(`Failed - lock owner is ${owner}`);
    projToOpen.value = proj;
    projToOpen.value.lockedBy = owner;
    openProjModal.value.showModal();
  }
}

async function forceOpenProj(proj) {
  await gApp.stealProjectLock(proj.id);
  gApp.router.push(proj.route)
}

</script>

<template>
  <div class="TableWrapper">
    <div v-if="gApp.isReloadingProjects()" class="projects-loading-container">
      <div class="projects-loading-spinner"></div>
      <p class="projects-loading-text">Loading projects<span class="dot-1">.</span><span class="dot-2">.</span><span class="dot-3">.</span></p>
    </div>
    <div v-else>
      <p class="help-text NoProjectsYet" v-if="projects.length == 0">
        <i class="bi-box-seam mr-xxs"></i>
        Nothing here yet
      </p>
      <table v-else class="NiceTable x-Cols4 ProjectsTable w-100">
        <col style="width:25%">
        <col style="width:10%">
        <col style="width:10%">
        <col style="width:10%">
        <thead>
          <tr class="table-header-row">
            <th class="NameRow"><span class="header-cell first-header-cell"><i class="bi-file-earmark-text mr-xxs"></i>Name</span></th>
            <th><span class="header-cell"><i class="bi-person mr-xxs"></i>Owner</span></th>
            <th><span class="header-cell"><i class="bi-people mr-xxs"></i>Shared With</span></th>
            <th><span class="header-cell"><i class="bi-lock mr-xxs"></i>Locked By</span></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(project, index) in projects" :id="project.id" class="project-row" :style="{'animation-delay': index * 0.05 + 's'}">
            <td>
              <button @click="tryOpenProj(project)" class="LinkBtn OpenProjBtn project-name-btn">
                <i class="bi-box-fill pc mr-xs project-icon"></i>
                <span class="project-name">{{project.name}}</span>
              </button>
            </td>
            <td>
              <div class="Flex RowNoWrap">
                <ProfileIcon :username="project.userEmail" class="mr-xs" />
                <p class="">{{project.userEmail || 'Unknown'}}</p>
              </div>
            </td>
            <td>
              <div class="Flex">
                <div v-for="user in getTrimmedSharedUsers(project)">
                  <ProfileIcon  :username="user" class="mr-xxs" />
                </div>
                <p v-if="project.sharedUsers.length > kMaxNumSharedUsersShown">(+{{project.sharedUsers.length - kMaxNumSharedUsersShown}} more)</p>
              </div>
            </td>
            <td>
              <div v-if="project.lockedBy" class="Flex NoWrap">
                <i class="bi-lock bold-icon mr-xs"></i>
                <ProfileIcon :username="project.lockedBy" class="mr-xs" />
                <!--<p>{{project.lockedBy}}</p>-->
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <BasicModal ref="openProjModal" title="Project Locked" :showCancel="false">
      <div class="MarginBotM">
        <h4>
          {{projToOpen.lockedBy || 'Someone'}} is currently editing {{projToOpen.name}}.
        </h4>
        <p>
          Only one user can edit a project at a time. Please wait until they are done.
        </p>
      </div>
      <InputGroup title="Open anyways..." class="OpenAnyways">
        <p class="MarginBotS">
          If you believe {{projToOpen.lockedBy || 'someone'}} has left the project open, you can boot them out and open it now.
        </p>
        <button @click="forceOpenProj(projToOpen)" class="LinkBtn">Boot {{projToOpen.lockedBy}}</button>
      </InputGroup>
    </BasicModal>
  </div>
</template>

<style scoped>
.TableWrapper {
  /* width: 80%; */
  border-radius: 0 0 var(--br-m) var(--br-m);
  background: white;
  transition: all 0.3s ease;
}

.ProjectsTable {
  border-collapse: separate;
  border-spacing: 0;
}

.fancy-table {
  border-left: 1px solid rgba(var(--pc-rgb), 0.2);
  border-right: 1px solid rgba(var(--pc-rgb), 0.2);
  border-bottom: 1px solid rgba(var(--pc-rgb), 0.2);
}

.table-header-row {
  background: linear-gradient(to right, rgba(var(--pc-rgb), 0.1), rgba(var(--pc-rgb), 0.05));
  height: 50px;
}

.header-cell {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: var(--text-dark);
  text-transform: uppercase;
  font-size: var(--f-xs);
  letter-spacing: 0.5px;
}

.first-header-cell {
  padding-left: var(--s);
}

.project-row {
  transition: all 0.2s ease;
  animation: fadeIn 0.5s ease-out both;
}

.project-row td {
  border-bottom: 1px solid var(--b-20);
}

.project-row:last-child td {
  border-bottom: none;
}

.project-row:hover {
  background-color: var(--b-10);
  transform: translateX(3px);
}

.project-name-btn {
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
  padding: var(--xs) var(--s);
  /*border-radius: var(--br-s);*/
  position: relative;
  overflow: hidden;
}

.project-name-btn:hover {
  background-color: rgba(var(--pc-rgb), 0.1);
  transform: translateX(3px);
}

.project-icon {
  font-size: 1.3em;
  transition: transform 0.2s ease;
  color: var(--pc);
}

.project-name-btn:hover .project-icon {
  transform: scale(1.2);
}

.project-name {
  font-weight: 700;
  font-size: 1.1em;
  color: var(--b-text);
  transition: all 0.2s ease;
  letter-spacing: 0.02em;
}

.project-name-btn:hover .project-name {
  color: var(--pc-highlight);
  transform: translateX(2px);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.OpenAnyways {
  
}

.OpenProjBtn {
  text-decoration: none;
  text-transform: none;
  font-weight: 700;
  position: relative;
  overflow: hidden;
}

.OpenProjBtn::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--pc);
  transition: width 0.3s ease;
}

.OpenProjBtn:hover::after {
  width: 100%;
}

.bold-icon {
  font-size: 18px;
}

.NoProjectsYet {
  font-size: var(--f-m);
  padding: var(--s) var(--m);
}

.projects-loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--xl);
  margin: var(--m) 0;
  min-height: 200px;
}

.projects-loading-spinner {
  width: 50px;
  height: 50px;
  border: 4px solid var(--b-10);
  border-radius: 50%;
  border-top-color: var(--pc);
  border-left-color: var(--pc);
  animation: spin 1s ease-in-out infinite;
  margin-bottom: var(--s);
  box-shadow: 0 0 15px rgba(var(--pc-rgb), 0.2);
}

.projects-loading-text {
  font-size: var(--f-m);
  color: var(--pc);
  font-weight: 600;
  animation: pulse 2s infinite;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Reusing the same animations from HomeView */
@keyframes spin {
  to { transform: rotate(360deg); }
}

@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

.dot-1, .dot-2, .dot-3 {
  animation: dotPulse 1.5s infinite;
  display: inline-block;
}

.dot-1 { animation-delay: 0s; }
.dot-2 { animation-delay: 0.3s; }
.dot-3 { animation-delay: 0.6s; }

@keyframes dotPulse {
  0% { opacity: 0; transform: translateY(0); }
  50% { opacity: 1; transform: translateY(-5px); }
  100% { opacity: 0; transform: translateY(0); }
}

</style>
