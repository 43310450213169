<script setup>
import { ref, onMounted, watch, computed, reactive } from 'vue'
import { gApp } from '../State.js'
import { ProjectTypes } from '../Project.js'
import { waitMillis, } from '../SharedUtils.js'
import { AsyncLock, YesNoOrChecking, }  from '../Base.js'
import BasicModal from './utils/BasicModal.vue'
import ProjectsTable from './ProjectsTable.vue'
import InputGroup from './InputGroup.vue'
import RadioSelector from './RadioSelector.vue'

let createProjModal = ref(null);
let welcomeModal = ref(null);
let newProjName = ref("");
let newProjType = ref(ProjectTypes.Commercial);
let createProjectLock = new AsyncLock();

let welcomeProjectName = ref("");
let welcomeProjectType = ref(ProjectTypes.Commercial);

let debugProjects = [
  {id: '0'},
  {id: '1'},
  {id: '2'},
];

function startCreateProject() {
  newProjName.value = "";
  newProjType.value = ProjectTypes.Commercial;
  createProjModal.value.showModal();
}

async function createProject() {
  await createProjectLock.run(async () => {
    let projectName = newProjName.value || "Untitled";
    await gApp.createProject(projectName, newProjType.value);
  });
}

async function sendEmailVerification() {
  await gApp.sendEmailVerification(true);
}

let myProjects = computed(() => {
  return gApp.projects.filter((proj) => { return proj.isMine; })
})

let sharedProjects = computed(() => {
  return gApp.projects.filter((proj) => { return !proj.isMine; })
})

function getCreateProjectOptions() {
  return [
    {
      value: 'Commercial',
      label: 'Commercial Building',
      info: 'Calculate loads for a commercial building project.'
    },
    {
      value: 'Residential',
      label: 'House',
      info: 'Calculate loads for a residential house project.'
    },
  ]
}

function showWelcomeModal() {
  welcomeProjectName.value = "";
  welcomeProjectType.value = ProjectTypes.Commercial;
  welcomeModal.value.showModal();
}

async function createFirstProject() {
  welcomeModal.value.closeModal();
  await createProjectLock.run(async () => {
    let projectName = welcomeProjectName.value || "Untitled";
    await gApp.createProject(projectName, welcomeProjectType.value);
  });
}

// Watch for gApp.projects changes
watch(gApp.userHasProjects, (newVal) => {
  if (newVal == YesNoOrChecking.No) {
    showWelcomeModal();
  } else if (newVal == YesNoOrChecking.Yes) {
    // Do nothing
    welcomeModal.value.closeModal();
  }
})

onMounted(() => {
  if (gApp.user.value) {
    gApp.closeProject();
    gApp.reloadProjects(); 
  }
})

</script>

<template>
  <div class="Home PaddingM">
    <div v-if="gApp.authState.value === 'SignedIn'" class="Projects">
      <div class="TextAlignCenter">
        <h2 class="fw-normal2">Welcome to HeatWise.</h2>
        <p class="big-help-text mb-s">Create a new project to start calculating loads.</p>
        <button @click="startCreateProject" class="CreateProjBtn BigCreateBtn mlr-auto">Create Project</button>
      </div>
      <div class="mb-xl mt-xl">
        <InputGroup v-if="!gApp.user.value.emailVerified" class="EmailVerification w-80">
          <p class="mute-header mb-s">Verify Your Email</p>
          <p class="mb-xs">We sent a verification email to <b>{{gApp.user.value.email}}</b>.</p>
          <p class="help-text mb-xxs">Don't see it? Please check your spam or <button @click="sendEmailVerification"  class="TextBtn">resend</button>.</p>
        </InputGroup>
      </div>
      <div class="MarginBotM">
        <div class="mb-xl">
          <div class="">
            <div class="table-header">
              <h2 class="TableTitle">
                <i class="bi-folder-fill header-icon"></i>
                <span class="header-text">My Projects</span>
              </h2>
            </div>
            <ProjectsTable class="projects-section" :projects="myProjects" />
          </div>
        </div>
        <div class="mb-xl">
          <div class="">
            <div class="table-header">
              <h2 class="TableTitle">
                <i class="bi-people-fill header-icon"></i>
                <span class="header-text">Shared with me</span>
              </h2>
            </div>
            <ProjectsTable class="projects-section" :projects="sharedProjects" />
          </div>
        </div>
        <div v-if="gApp.isAdminUser()" class="MarginBotM">
          <h2 class="mb-xxs">Beta Features</h2>
          <p>(This section is only visible to beta users)</p>
          <div>
            <router-link to="/psychrometrics" class="LinkBtn">Psychrometrics Calculator</router-link>
          </div>
          <div>
            <router-link to="/solar-calculator" class="LinkBtn">Solar Calculator</router-link>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="gApp.authState.value === 'Checking'" class="loading-container">
      <h4 class="loading-text">Loading<span class="dot-1">.</span><span class="dot-2">.</span><span class="dot-3">.</span></h4>
      <div class="loading-spinner"></div>
    </div>
    <div v-else class="SignIn FlexCol GapXS AlignCenter">
      <h2 class="MarginBotM">You are signed out</h2>
      <button @click="gApp.router.replace('/signin')" class="">Sign in here</button>
    </div>
    <BasicModal ref="createProjModal" title="Create Project" doneText="Create" @onDone="createProject">
      <div class="mb-l">
        <p class="mute-header">Name</p>
        <input v-model="newProjName" class="BigInput mb-s" placeholder="Untitled" autofocus />
      </div>
      <div class="mb-m">
        <p class="mute-header mb-xs">Building Type</p>
        <!--<p class="mb-s">Choose building type:</p>-->
        <div>
          <RadioSelector v-model="newProjType" :options="getCreateProjectOptions()" />
        </div>
      </div>
      <div>
        <p class="mute-header mb-xxs">Disclaimer</p>
        <p class="help-text">
          Please double-check all calculation results when using them in a professional setting.
        </p>
      </div>
    </BasicModal>
    
    <BasicModal ref="welcomeModal" :showCancel="false" :showDone="false">
      <div>
        <h2 class="TextAlignCenter pc mb-s welcome-heading">Welcome to HeatWise</h2>
        <p class="TextAlignCenter Bold mb-m">
          Let's create your first project.
        </p>
      </div>
      
      <div class="project-form">
        <div class="mb-s">
          <p class="mute-header">Project Name</p>
          <input v-model="welcomeProjectName" class="BigInput mb-s" placeholder="Untitled" autofocus />
        </div>
        <div class="mb-s">
          <p class="mute-header mb-xxs">Building Type</p>
          <div>
            <RadioSelector v-model="welcomeProjectType" :options="getCreateProjectOptions()" />
          </div>
        </div>
        <div class="mb-s">
          <p class="mute-header">Disclaimer</p>
          <p class="help-text">
            Please double-check all calculation results when using them in a professional setting.
          </p>
        </div>
      </div>
      <div class="welcome-buttons">
        <button @click="createFirstProject" class="MarginAuto CreateBtn BigCreateBtn">
          Create Project
        </button>
      </div>
    </BasicModal>
  </div>
</template>

<style scoped>
.Home {
  /*background-color: #f7f9fc;*/
  height: 100%;
}

.Projects {
  margin: 0 auto;
  max-width: 1200px;
}

.CreateProjBtn {
  border-radius: var(--br-m);
  padding: var(--s) var(--m);
}

.EmailVerification {
  border-radius: var(--br-m);
  padding: var(--s);
}

.BigInput {
  padding: 12px;
  font-size: var(--f-m);
  width: 80%;
  border: 1px solid var(--b-20);
}

.TableTitle {
  font-weight: 600;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: var(--s);
  border-radius: var(--br-s) var(--br-s) 0 0;
  margin-bottom: 0;
}

.header-icon {
  color: var(--pc);
  margin-right: var(--xs);
  font-size: 1.2em;
}

.header-text {
  position: relative;
  z-index: 1;
  color: var(--text-dark);
  font-weight: 600;
  letter-spacing: 0.5px;
}

.header-accent {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background: linear-gradient(90deg, var(--pc), rgba(62, 85, 204, 0.1));
}

.table-header {
  position: relative;
  border-radius: var(--br-m) var(--br-m) 0 0;
  background-color: var(--white);
}


.feature-list {
  text-align: left;
  padding: var(--m);
  background-color: var(--b-5);
  border-radius: var(--br-m);
}

.feature-item h4 {
  margin-bottom: var(--xxs);
  font-weight: 500;
}


.welcome-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: var(--l);
}

.primary-btn {
  background-color: var(--primary);
  color: white;
  padding: var(--s) var(--m);
  border-radius: var(--br-m);
  font-weight: 500;
  border: none;
  cursor: pointer;
}

.secondary-btn {
  background-color: var(--b-10);
  color: var(--text);
  padding: var(--s) var(--m);
  border-radius: var(--br-m);
  font-weight: 500;
  border: 1px solid var(--b-20);
  cursor: pointer;
}

.note-text {
  font-size: var(--f-s);
  color: var(--text-muted);
  font-style: italic;
}

.project-form {
  text-align: left;
  border-radius: var(--br-m);
}

.welcome-heading {
  /*animation: fadeIn 0.5s ease-in-out;*/
}
.projects-section {
  margin-bottom: var(--xl);
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--b-10);
  /*box-shadow: 0 4px 12px black;*/
  border-radius: var(--br-m);
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.BigCreateBtn {
  border-radius: var(--br-m);
  padding: var(--s) var(--m);
  font-size: var(--f-m);
  box-shadow: 0 4px 8px rgba(62, 85, 204, 0.3);
  transition: all 0.2s ease;
}

.BigCreateBtn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(62, 85, 204, 0.4);
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--xl);
  margin-top: var(--xl);
}

.loading-text {
  font-size: var(--f-l);
  font-weight: 500;
  margin-bottom: var(--m);
  color: var(--primary);
  text-transform: uppercase;
  letter-spacing: 2px;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: var(--primary);
  animation: spin 1s ease-in-out infinite;
  margin-bottom: var(--m);
}

.loading-message {
  color: var(--text-muted);
  font-style: italic;
  animation: pulse 2s infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

.dot-1, .dot-2, .dot-3 {
  animation: dotPulse 1.5s infinite;
  display: inline-block;
}

.dot-1 { animation-delay: 0s; }
.dot-2 { animation-delay: 0.3s; }
.dot-3 { animation-delay: 0.6s; }

@keyframes dotPulse {
  0% { opacity: 0; transform: translateY(0); }
  50% { opacity: 1; transform: translateY(-5px); }
  100% { opacity: 0; transform: translateY(0); }
}
</style>
