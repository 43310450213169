import * as ser from '../Common/SerUtil.js'
import { makeEnum, makeOptions,
  setupClass, lookupData, 
  interpolateInMap,
} from '../Base.js'

import * as calc from './ResidentialCalculations.js'

import {
  FieldType,
  Field,
} from '../Common/Field.js'

import {
  kDirectionChoices, YesNo,
  makeYesNoField, makeNoYesField,
 } from './Common.js'

import { WallType } from './WallType.js'
import { WindowType } from './WindowType.js'
import { DoorInputType, DoorType, } from './DoorType.js'
import { InteriorShadingType } from './InteriorShadingType.js'
import { ExteriorShadingType } from './ExteriorShadingType.js'
import { Schedule } from '../BuildingComponents/Schedule.js'

import { gApp, } from '../Globals.js'
 
export class WallWindow {
  init() {
    this.windowType = Field.makeTypeSelect('Type', gApp.proj().windowTypes, null, {
      errorWhenEmpty: `You must create a Window Type`,
      metadata: {
        typeClass: WindowType,
      }
    });
    this.quantity = new Field({
      name: 'Quantity',
      type: FieldType.Count,
      defaultValue: 1,
      allowMin: false,
    })
    this.interiorShadingType = Field.makeTypeSelect('Interior Shading',
      gApp.proj().interiorShadingTypes, 'None', {
      metadata: {
        typeClass: InteriorShadingType,
      }
    })
    this.exteriorShadingType = Field.makeTypeSelect('Exterior Shading',
      gApp.proj().exteriorShadingTypes, 'None', {
      metadata: {
        typeClass: ExteriorShadingType,
      }
    })

    if (gApp.proj().isCommercial()) {
      this.interiorShadingSchedule = Field.makeTypeSelect('Interior Shading Schedule',
        gApp.proj().schedules, 'None', {
        metadata: {
          typeClass: Schedule,
        }
      })
    }

    this.serFields = [
      'windowType',
      'quantity',
      'interiorShadingType',
      'exteriorShadingType',
    ];
    if (gApp.proj().isCommercial()) {
      this.serFields.push('interiorShadingSchedule');
    }
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Window',
    }
  }

  getTypeName() {
    return "WallWindow";
  }

  getWindowType() {
    return this.windowType.lookupValue();
  }

  getInteriorShadingType() {
    return this.interiorShadingType.lookupValue();
  }

  getExteriorShadingType() {
    return this.exteriorShadingType.lookupValue();
  }

  getInteriorShadingSchedule() {
    return this.interiorShadingSchedule.lookupValue();
  }

  computeShgc() {
    return this.getWindowType().computeShgc();
  }

  getPercentGlass() {
    return 100.0
  }
}
setupClass(WallWindow)

export class WallDoor {
  init() {
    this.doorType = Field.makeTypeSelect('Type', gApp.proj().doorTypes, null, {
      errorWhenEmpty: `You must create a Door Type.`,
      metadata: {
        typeClass: DoorType,
      }
    });
    this.quantity = new Field({
      name: 'Quantity',
      type: FieldType.Count,
      defaultValue: 1,
      allowMin: false,
    })
    this.interiorShadingType = Field.makeTypeSelect(
      'Interior Shading', gApp.proj().interiorShadingTypes, 'None', {
      metadata: {
        typeClass: InteriorShadingType,
      }
    })
    this.exteriorShadingType = Field.makeTypeSelect(
      'Exterior Shading', gApp.proj().exteriorShadingTypes, 'None', {
      metadata: {
        typeClass: ExteriorShadingType
      }
    })

    if (gApp.proj().isCommercial()) {
      this.interiorShadingSchedule = Field.makeTypeSelect('Interior Shading Schedule',
        gApp.proj().schedules, 'None', {
          metadata: {
          typeClass: Schedule,
        }
      })
    }

    this.serFields = [
      'doorType',
      'quantity',
      'interiorShadingType',
      'exteriorShadingType',
    ];
    if (gApp.proj().isCommercial()) {
      this.serFields.push('interiorShadingSchedule');
    }
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Door',
    }
  }

  getTypeName() {
    return "WallDoor";
  }

  getDoorType() {
    return this.doorType.lookupValue();
  }

  // Note: a DoorType implements a similar interface to a WindowType, so allow
  // getting it like this, for compatibility
  getWindowType() {
    return this.getDoorType();
  }

  getInteriorShadingType() {
    return this.interiorShadingType.lookupValue();
  }

  getExteriorShadingType() {
    return this.exteriorShadingType.lookupValue();
  }

  getInteriorShadingSchedule() {
    return this.interiorShadingSchedule.lookupValue();
  }

  computeShgc() {
    return this.getDoorType().computeShgc();
  }

  getPercentGlass() {
    return this.getDoorType().getPercentGlass();
  }
}
setupClass(WallDoor)

export class Wall {
  init() {
    this.wallType = Field.makeTypeSelect('Type', gApp.proj().wallTypes, null, {
      errorWhenEmpty: 'You must create a Wall Type.',
      metadata: {
        typeClass: WallType,
      }
    })
    this.area = new Field({
      name: 'area',
      type: FieldType.Area,
      requiresInput: true,
    });
    this.direction = new Field({
      name: 'Direction',
      type: FieldType.Select,
      choices: kDirectionChoices,
      defaultValue: 'N',
    })
    this.isMostlyShaded = makeNoYesField("Wall is mostly shaded?");
    this.windows = [];
    this.doors = [];

    // Only relevant for commercial buildings:
    this.hasCeilingPlenum = makeYesNoField('Has ceiling plenum?', {bold: true});
    this.portionOfWallLoadToPlenum = new Field({
      name: 'Portion of wall load to plenum',
      type: FieldType.Percent,
    })
    this.portionOfWallLoadToPlenum.setVisibility(() => {
      return this.hasCeilingPlenum.value == YesNo.Yes;
    })

    this.expandedInUi = false;

    this.serFields = [
      'wallType',
      'area',
      'direction',
      'isMostlyShaded',
      ser.arrayField('windows', () => { return WallWindow.create(); }),
      ser.arrayField('doors', () => { return WallDoor.create(); }),
      'hasCeilingPlenum',
      'portionOfWallLoadToPlenum',
    ];
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Wall',
    }
  }

  isRoof() {
    return false
  }

  getNumWindows() {
    let num = 0;
    for (const win of this.windows) {
      num += win.quantity.value;
    }
    return num;
  }

  getNumDoors() {
    let num = 0;
    for (const door of this.doors) {
      num += door.quantity.value;
    }
    return num;
  }

  getWallType() {
    return this.wallType.lookupValue();
  }

  getArea() {
    return this.area.value;
  }

  getStrictlyWallArea() {
    // Wall area = total area - window area - door area
    return this.area.value - this.getWindowArea() - this.getDoorArea();
  }

  getWindowArea() {
    let totalWindowArea = 0;
    for (const win of this.windows) {
      totalWindowArea += win.getWindowType().getArea() * win.quantity.value;
    }
    return totalWindowArea;
  }

  getDoorArea() {
    let totalDoorArea = 0;
    for (const door of this.doors) {
      totalDoorArea += door.getDoorType().getArea() * door.quantity.value;
    }
    return totalDoorArea;
  }

  getTiltAngleDegs() {
    return 90.0
  }

  getRValue() {
    return this.getWallType().getRValue();
  }

  getPlenumLoadFraction() {
    return this.hasCeilingPlenum.value == YesNo.Yes ?
      this.portionOfWallLoadToPlenum.value / 100.0 : 0;
  }

  _calcLoads(ctx, isHeating) {
    ctx.startSection(`${isHeating ? 'Heating' : 'Cooling'}`);

    let weatherData = ctx.toplevelData.locationData;

    ctx.t_i = isHeating ? ctx.toplevelData.indoorWinterTemp
      : ctx.toplevelData.indoorSummerTemp;
    ctx.t_o = isHeating? ctx.designTemps.heating : ctx.designTemps.cooling;

    // Calc opaque area
    ctx.A = this.area.value;
    for (const win of this.windows) {
      ctx.A = ctx.eval('A - A_win*N_win', {
        A_win: win.getWindowType().getArea(),
        N_win: win.quantity.value,
      }, 'A');
    }
    for (const door of this.doors) {
      ctx.A = ctx.eval('A - A_door*N_door', {
        A_door: door.getDoorType().getArea(),
        N_door: door.quantity.value,
      }, 'A');
    }

    let wallType = this.getWallType();
    ctx.R = wallType.getRValue();
    ctx.alpha = wallType.getAbsorptance();
    ctx.U = ctx.eval('1.0 / R', {}, 'U');

    let surfaceType = this.isMostlyShaded.value == YesNo.Yes ?
      'WallOrDoorMostlyShaded' : 'WallOrDoorNotMostlyShaded';
    ctx.q_wall = calc.calcOpaqueQ(ctx, ctx.A,
      ctx.U, ctx.t_i, ctx.t_o, ctx.alpha,
      isHeating, surfaceType, weatherData);

    // Windows:
    let q_windows = 0;
    for (let i = 0; i < this.windows.length; ++i) {
      ctx.startLocalSection(`Win${i + 1}`)
      let wallWin = this.windows[i];
      let win = wallWin.getWindowType();

      ctx.A_win = ctx.eval('A*N', {A: win.getArea(),
        N: wallWin.quantity.value}, 'A_win');
      ctx.U_win = win.computeUValue().uValue;
      ctx.direction = this.direction.value;
      ctx.hasBugScreen = win.hasBugScreen();
      // TODO - what about non-rectangular shape?
      ctx.H = win.height.value;
      ctx.W = win.width.value;
      let shgcValues = win.computeShgc();
      let interiorShading = wallWin.getInteriorShadingType();
      let exteriorShading = wallWin.getExteriorShadingType();
      if (interiorShading) {
        ctx.iacData = WindowType.computeIAC(win, interiorShading,
            gApp.proj().windowsData.iacValues);
        ctx.IAC = ctx.iacData.iac;
      } else {
        ctx.IAC = 1;
      }
      ctx.q_win = calc.calcWindowQ(ctx, ctx.A_win, ctx.U_win,
        ctx.t_i, ctx.t_o, ctx.hasBugScreen, ctx.direction,
        ctx.H, ctx.W,
        isHeating, shgcValues, ctx.IAC, interiorShading,
        exteriorShading, weatherData, gApp.proj().windowsData);
      q_windows += ctx.q_win;

      ctx.endSection();
    }

    // Doors
    let q_doors = 0;
    for (let i = 0; i < this.doors.length; ++i) {
      ctx.startLocalSection(`Door${i + 1}`)
      let wallDoor = this.doors[i];
      let doorType = wallDoor.getDoorType();

      ctx.doorType = wallDoor.doorType.value;
      ctx.inputType = doorType.inputType.value;
      if (ctx.inputType == DoorInputType.BuildDoor) {
        ctx.buildDoorType = doorType.doorBuilder.type.value;
      }
      ctx.surfaceType = this.isMostlyShaded.value == YesNo.Yes ?
        'WallOrDoorMostlyShaded' : 'WallOrDoorNotMostlyShaded';

      ctx.percentGlass = doorType.getPercentGlass();
      ctx.pushMsg('\nOpaque part:')
      if (ctx.percentGlass < 100) {
        ctx.A_opq = ctx.eval('N*A*percentOpq',
          {N: wallDoor.quantity.value, A: doorType.getArea(),
            percentOpq: (100 - ctx.percentGlass) / 100.0}, 'A_opq');
        // Note: alpha is not used for Walls/Doors, just set to 0
        let absorptance = null;
        ctx.q_opq = calc.calcOpaqueQ(ctx, ctx.A_opq,
          doorType.computeUValue().uValueDoor, ctx.t_i, ctx.t_o, absorptance,
          isHeating, ctx.surfaceType, weatherData);
      } else {
        ctx.q_opq = 0;
      }

      ctx.pushMsg('\nGlass part:')
      if (ctx.percentGlass > 0) {
        ctx.A_win = ctx.eval('N*A*percentGlass', {
          A: doorType.getArea(), N: wallDoor.quantity.value,
          percentGlass: ctx.percentGlass / 100.0,
        }, 'A_win');
        // Note: this is the same as in the opaque case
        ctx.U_win = doorType.computeUValue().uValueGlass;
        ctx.direction = this.direction.value;
        ctx.hasBugScreen = doorType.hasScreenDoor();
        ctx.H = doorType.height.value;
        ctx.W = doorType.width.value;
        let shgcValues = doorType.computeShgc();
        let interiorShading = wallDoor.getInteriorShadingType();
        let exteriorShading = wallDoor.getExteriorShadingType();
        if (interiorShading) {
          ctx.iacData = WindowType.computeIAC(doorType, interiorShading,
              gApp.proj().windowsData.iacValues);
          ctx.IAC = ctx.iacData.iac;
        } else {
          ctx.IAC = 1;
        }
        ctx.q_glass = calc.calcWindowQ(ctx, ctx.A_win, ctx.U_win,
          ctx.t_i, ctx.t_o, ctx.hasBugScreen, ctx.direction,
          ctx.H, ctx.W,
          isHeating, shgcValues, ctx.IAC, interiorShading,
          exteriorShading, weatherData, gApp.proj().windowsData);
      } else {
        ctx.q_glass = 0;
      }

      ctx.pushMsg('\nTotal:')
      ctx.q_door = ctx.eval('q_opq + q_glass', {}, 'q_door')
      q_doors += ctx.q_door;
        
      ctx.endSection();
    }

    let res = {
      q: ctx.q_wall,
      q_windows: q_windows,
      q_doors: q_doors,
    }
    ctx.endSection();

    return res;
  }

  calcOutputs(ctx) {
    let heatingRes = this._calcLoads(ctx, true);
    let coolingRes = this._calcLoads(ctx, false);

    let outputs = {
      q_heating: heatingRes.q,
      q_cooling: coolingRes.q,
      windows: {
        q_heating: heatingRes.q_windows,
        q_cooling: coolingRes.q_windows,
      },
      doors: {
        q_heating: heatingRes.q_doors,
        q_cooling: coolingRes.q_doors,
      }
    }
    return outputs;
  }
};
setupClass(Wall)