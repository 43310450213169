<script setup>
import SingleRadioButton from './SingleRadioButton.vue';

const props = defineProps({
  modelValue: {
    type: String,
    required: true
  },
  options: {
    type: Array,
    required: true
  }
})
const emit = defineEmits(['update:modelValue'])

function selectType(type) {
    emit('update:modelValue', type);
}

</script>

<template>
  <div class="RadioSelector">
    <!--
    <p v-for="option in options" :key="option.value" class="Option mb-xxs cursor-ptr f-m" @click="selectType(option.value)">
        <i :class="{'bi-square-fill': modelValue === option.value, 'bi-square': modelValue !== option.value}" class="Box pc f-m mr-xs"></i>
        {{ option.label }}
    </p>
    -->
    <div v-for="option in options" :key="option.value"
      class="Option Flex RowNoWrap GapS mb-xxs cursor-ptr f-m"
      :class="{Selected: modelValue === option.value}"
      @click="selectType(option.value)"
    >
      <SingleRadioButton :selected="modelValue === option.value" class="mr-xs" />
      <div>
        <p class="Bold">{{option.label}}</p>
        <p v-if="option.info" class="help-text">{{option.info}}</p>
      </div>
    </div>
  </div>
</template>
  
<style scoped>
.RadioSelector {
/* Add your styles here */
}

.Option {
  border: 1px solid var(--b-20);
  border-radius: var(--br-l);
  padding: var(--s);
}

.Option.Selected {
  border-color: var(--pc);
}

.Option:hover {
  border-color: var(--pc-dark);
}

</style>