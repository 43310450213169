import { makeEnum, makeOptions,
  setupClass, lookupData, 
} from '../Base.js'
import { generateItemName, sortItemsByName, } from '../SharedUtils.js'

export { Units } from '../Common/Units.js'

import {
  FieldType,
  Field,
} from '../Common/Field.js'

import { gApp, DebugOn } from '../Globals.js'


export class ExteriorShadingType {
  init(name, makeId) {
    this.id = makeId ? gApp.proj().makeId('ExteriorShadingType') : 0;
    this.name = Field.makeName('Name', name)

    this.horizontalFinDepth = new Field({
      name: 'Depth',
      type: FieldType.Length,
      min: 0,
      allowMin: true,
      requiresInput: true,
    })
    this.horizontalFinDist = new Field({
      name: 'Distance from parallel edge',
      type: FieldType.Length,
      min: 0,
      allowMin: true,
      requiresInput: true,
    })
    this.horizontalFinFields = [
      'horizontalFinDepth',
      'horizontalFinDist',
    ]

    this.leftFinDepth = new Field({
      name: 'Depth',
      type: FieldType.Length,
      min: 0,
      allowMin: true,
      requiresInput: true,
    })
    this.leftFinDist = new Field({
      name: 'Distance from parallel edge',
      type: FieldType.Length,
      min: 0,
      allowMin: true,
      requiresInput: true,
    })
    this.leftFinFields = [
      'leftFinDepth',
      'leftFinDist',
    ]

    this.rightFinDepth = new Field({
      name: 'Depth',
      type: FieldType.Length,
      min: 0,
      allowMin: true,
      requiresInput: true,
    })
    this.rightFinDist = new Field({
      name: 'Distance from parallel edge',
      type: FieldType.Length,
      min: 0,
      allowMin: true,
      requiresInput: true,
    })
    this.rightFinFields = [
      'rightFinDepth',
      'rightFinDist',
    ]

    this.serFields = [
      'id',
      'name',
      'horizontalFinDepth',
      'horizontalFinDist',
      'leftFinDepth',
      'leftFinDist',
      'rightFinDepth',
      'rightFinDist',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Exterior Shading',
    }
  }

  static getTypeMetadata() {
    return {
      name: 'Exterior Shading',
      createType: ExteriorShadingType.createType,
      openEditor: ExteriorShadingType.openEditor,
      customEditor: true,
    }
  }

  static createType() {
    let type = ExteriorShadingType.create(generateItemName('Exterior Shading', gApp.proj().exteriorShadingTypes), true)
    gApp.proj().exteriorShadingTypes.push(type);
    sortItemsByName(gApp.proj().exteriorShadingTypes);
    return type
  }

  static openEditor(type) {
    let pathName = gApp.proj().isCommercial() ? 'building-externalshadings' : 'externalshadings';
    gApp.proj().editorState.selectedExteriorShading = type;
    gApp.router.push({name: pathName});
  }

  static getTableInfo() {
    return {
      typeName: 'Exterior Shading',
      newItemName: 'ExShading-CTR',
      allowDuplicate: true,
      columns: {
        'name': {
          label: 'Name',
        },
        'horizontalDepth': {
          label: 'Horizontal Depth',
        },
        'leftFinDepth': {
          label: 'Left Fin Depth',
        },
        'rightFinDepth': {
          label: 'Right Fin Depth',
        },
      }
    }
  }

  getTableData() {
    return {
      name: this.name.value,
      horizontalDepth: this.horizontalFinDepth.getValueStr(),
      leftFinDepth: this.leftFinDepth.getValueStr(),
      rightFinDepth: this.rightFinDepth.getValueStr(),
    }
  }

  getInputPage() {
    return {
      label: `Exterior Shadings - ${this.name.value}`,
      // TODO
      path: `externalshadings/${this.id}`,
    };
  }
};
setupClass(ExteriorShadingType)
