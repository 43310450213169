<script setup>
import { ref, onMounted, reactive, computed, watch, defineAsyncComponent } from 'vue'

const props = defineProps({
  typeName: String,
  field: {
    type: Object,
    required: true,
  }
});
let emits = defineEmits(['onDone'])

// Map of component names to their dynamic imports
let editorMap = {
  'Wall': defineAsyncComponent(() => import('./WallsView.vue')),
  'Roof': defineAsyncComponent(() => import('./RoofsView.vue')),
  'Window': defineAsyncComponent(() => import('./WindowsView.vue')),
  'Door': defineAsyncComponent(() => import('./DoorsView.vue')),
  'Skylight': defineAsyncComponent(() => import('./SkylightsView.vue')),
  'Exterior Shading': defineAsyncComponent(() => import('./ExternalShadingsView.vue')),
  'Interior Shading': defineAsyncComponent(() => import('./InternalShadingsView.vue')),
  'Buffer Space': defineAsyncComponent(() => import('./BufferSpacesView.vue')),
  'Schedule': defineAsyncComponent(() => import('./SchedulesView.vue')),
}

let editorComponent = computed(() => {
  return editorMap[props.typeName];
})

</script>

<template>
  <component
    :is="editorComponent"
    :selectionObject="field.makeSelectionObject()"
    @onDone="emits('onDone')"
  />
</template>

<style scoped>
</style>
