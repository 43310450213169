import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
  interpolateInMap, doubleInterpolateInMap,
  IdsMap, PleaseContactStr,
  IntervalTimer,
} from '../Base.js'
import { InputComponent } from '../Common/InputComponent.js'
import { CalcContext } from '../Common/CalcContext.js'
import { arraysEqual } from '../SharedUtils.js'
import * as ser from '../Common/SerUtil.js'

import { Field, FieldType, FieldGroup, SelectOrManualInput, } from '../Common/Field.js'
import { FieldWithVariableUnits, MultiTieredSelect, } from '../Common/FieldUtils.js'
import { Units } from '../Common/Units.js'

import {
  GetLightingPowerTable,
  GetSpecialAllowanceFactorsTable,
} from '../Data/LightingPower.js'
import { LuminaireType } from '../Data/LuminaireData.js'

import { gApp, DebugOn } from '../Globals.js'

import * as officeloads from '../MaterialData/OfficeLoadFactors.js'
import { ApplianceDataTable } from '../MaterialData/ApplianceDataTable.js'
import { SpaceTypesDataTable } from '../MaterialData/SpaceTypesDataTable.js'
import { GetMotorEfficiencyData } from '../MaterialData/MotorEfficiency.js'
import { ScheduleInput } from './ScheduleInput.js'

export let OccupancyEntryType = makeEnum({
  ByPeople: 'Enter # of people',
  BySpaceDensity: 'Enter by density',
})

/*
Activity Level
Seated	
Seated, very light work	
Moderately active office work	
Standing, light work; walking	
Walking, standing	
Sedentary work	
Light bench work	
Moderate dancing	
Walking 3 mph; light machine work	
Bowling	
Heavy work	
Heavy machine work; lifting	
Athletics	
*/
// TODO - this enum should really be read from the table
let ActivityLevel = makeEnum({
  Seated: 'Seated',
  SeatedVeryLightWork: 'Seated, very light work',
  ModeratelyActiveOfficeWork: 'Moderately active office work',
  StandingLightWorkWalking: 'Standing, light work; walking',
  WalkingStanding: 'Walking, standing',
  SedentaryWork: 'Sedentary work',
  LightBenchWork: 'Light bench work',
  ModerateDancing: 'Moderate dancing',
  Walking3mphLightMachineWork: 'Walking 3 mph; light machine work',
  Bowling: 'Bowling',
  HeavyWork: 'Heavy work',
  HeavyMachineWorkLifting: 'Heavy machine work; lifting',
  Athletics: 'Athletics',
})

class PeopleInternals extends InputComponent {
  init(spaceInternals) {
    this.spaceInternals = spaceInternals;

    this.occupancyEntryType = Field.makeSelect("Occupancy input", OccupancyEntryType, {bold: true})
    this.occupancyCount = new Field({
      name: 'Number of occupants',
      type: FieldType.People,
      requiresInput: true,
    })
    this.occupancyCount.setVisibility(() => {
      return this.occupancyEntryType.value == OccupancyEntryType.ByPeople
    })
    this.occupancyDensity = new Field({
      name: 'Occupancy density',
      type: FieldType.PeoplePer1000ft2,
      requiresInput: true,
    })
    this.occupancyDensity.setVisibility(() => {
      return this.occupancyEntryType.value == OccupancyEntryType.BySpaceDensity
    })
    this.helpInfo = null;
    this.updater.addWatchEffect('occupancy-entry-type', () => {
      let res = this.getNumOccupants();
      this.helpInfo = res.helpInfo;
    })
    this.occupancyTableData = SpaceTypesDataTable.getInstance().getOccupancyTableData()

    this.activityLevel = Field.makeSelect('Activity Level', ActivityLevel);
    this.activityLevelHelpInfo = null;
    this.updater.addWatchEffect('activity-level', () => {
      let data = this.getActivityLevelData(CalcContext.create());
      this.activityLevelHelpInfo = {
        helpText: `The activity level determines the sensible and latent loads per person (as well as the radiant fraction).`,
        relatedValues: {
          'Sensible Load': {
            label: 'Sensible load per person',
            value: data.S_P,
            units: Units.Load,
          },
          'Radiant Fraction': {
            label: 'Radiant fraction',
            value: data.F_rad * 100.0,
            units: Units.Percent,
          },
          'Latent Load': {
            label: 'Latent load per person',
            value: data.Latent_Heat,
            units: Units.Load,
          },
        }
      }
    })

    this.scheduleInput = ScheduleInput.create({
      name: 'Occupancy Schedule',
    })

    // Outputs
    this.outputSensibleLoad = new Field({
      name: 'Sensible load',
      type: FieldType.Load,
      isOutput: true,
    })
    this.outputRadiantFraction = new Field({
      name: 'Radiant fraction',
      type: FieldType.Percent,
      isOutput: true,
    })
    this.outputLatentLoad = new Field({
      name: 'Latent load',
      type: FieldType.Load,
      isOutput: true,
    })
    this.updater.addWatchEffect('outputs', () => {
      let ctx = CalcContext.create();
      let data = this.getActivityLevelData(ctx);
      let numOccupants = this.getNumOccupants().result;
      this.outputSensibleLoad.value = data.S_P * numOccupants;
      this.outputRadiantFraction.value = data.F_rad * 100;
      this.outputLatentLoad.value = data.Latent_Heat * numOccupants;
    })

    this.miscFields = [
      'activityLevel',
      'scheduleInput',
    ]

    this.fields = [
      'occupancyEntryType',
      'occupancyCount',
      'occupancyDensity',
      'activityLevel',
      'scheduleInput',
    ]
    this.serFields = [
      ...this.fields
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'People',
    }
  }

  getNumOccupants() {
    let res = {};
    if (this.occupancyEntryType.value == OccupancyEntryType.ByPeople) {
      res.result = this.occupancyCount.value;
      let helpText = `You may also enter the occupancy by density (per 1000 ft²). Refer to `
                    + `the reference table below for typical values.`
      res.helpInfo = {
        helpText: helpText
      }
    } else if (this.occupancyEntryType.value == OccupancyEntryType.BySpaceDensity) {
      let floorArea = this.spaceInternals.space.floorArea.value;
      res.result = Math.ceil(floorArea * this.occupancyDensity.value / 1000.0);
      let helpText = `Here, the occupancy is calculated as <b>OccupancyDensity * FloorArea / 1000.0</b>`
      res.helpInfo = {
        helpText: helpText,
        result: {
          label: '# Occupants',
          value: res.result,
          units: Units.None,
        },
        relatedValues: {
          FloorArea: {
            label: 'Floor Area',
            value: floorArea,
            units: Units.ft2,
          }
        }
      }
    } else {
      throw new Error("Unknown occupancy entry type: " + this.occupancyEntryType.value)
    }
    return res;
  }

  getActivityLevel() {
    return this.activityLevel.value;
  }

  getActivityLevelData(ctx) {
    let activityLevelDataTable = gApp.proj().activityLevelsData;
    // TODO - use table row ids instead of labels
    let activityLevel = ActivityLevel._labels[this.activityLevel.value]
    return activityLevelDataTable.getActivityLevelData(activityLevel);
  }

  getSchedule() {
    return this.scheduleInput.getSchedule();
  }

  getDiversityFactor() {
    return this.scheduleInput.getDiversityFactor()
  }
}
setupClass(PeopleInternals)

class LightingInternals extends InputComponent {
  init(spaceInternals) {
    this.spaceInternals = spaceInternals;

    this.power = new FieldWithVariableUnits({
      name: 'Power',
      type: FieldType.Load,
      units: Units.Load,
      unitOptions: [
        Units.Load,
        Units.LoadPerArea,
        Units.LoadMetric,
        Units.WattsPerFt2,
      ],
      requiresInput: true,
    })
    this.powerHelpInfo = {
      helpText: `Enter the power as a total load or a load per area (in imperial or metric units). `
                + `See the reference table below for typical values.\n Also see the "special allowance factors" table.`
    }
    this.powerData = GetLightingPowerTable()
    this.specialAllowanceFactorsData = GetSpecialAllowanceFactorsTable()

    this.luminaireType = Field.makeSelect('Luminaire type', LuminaireType)
    this.luminaireType.helpInfo = {
      helpText: `Select a luminaire type. This will determine the radiant fraction (% of sensible load that is radiant) and space fraction (% of sensible load that heats the space – the rest heats the plenum).`
    }

    this.outputSensibleLoad = new Field({
      name: 'Sensible Load',
      type: FieldType.Load,
      isOutput: true,
    })
    this.outputRadiantFraction = new Field({
      name: 'Radiant Fraction',
      type: FieldType.Percent,
      isOutput: true,
    })
    this.outputSpaceFraction = new Field({
      name: 'Space Fraction',
      type: FieldType.Percent,
      isOutput: true,
    })
    this.outputsHelpInfo = {
      helpText: `Lighting has a sensible load, a radiant fraction (the percentage of the sensible load that is radiant), and a space fraction `
        + `(the percentage of the sensible load that heats the space. The rest heats the plenum).`
    }
    this.updater.addWatchEffect('outputs', () => {
      let ctx = CalcContext.create();
      let luminaireData = this.getLuminaireData(ctx);
      let power = this.getPower();
      this.outputSensibleLoad.value = power;
      this.outputRadiantFraction.value = luminaireData.F_rad * 100.0;
      this.outputSpaceFraction.value = luminaireData.F_space * 100.0;
    })

    this.scheduleInput = ScheduleInput.create()

    this.fields = [
      'power',
      'luminaireType',
      'scheduleInput',
    ]
    this.serFields = [
      ...this.fields
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Lighting',
    }
  }

  getPower() {
    if (this.power.units == Units.Load) {
      return this.power.value
    } else if (this.power.units == Units.LoadMetric) {
      return this.power.getValueInUnits(Units.Load)
    } else if (this.power.units == Units.LoadPerArea) {
      return this.power.value * this.spaceInternals.space.floorArea.value;
    } else if (this.power.units == Units.WattsPerFt2) {
      return this.power.getValueInUnits(Units.LoadPerArea) * this.spaceInternals.space.floorArea.value;
    } else {
      throw new Error("Unknown power units: " + this.power.units)
    }
  }

  getLuminaireType() {
    return this.luminaireType.value;
  }

  getLuminaireData(ctx) {
    let luminaireDataTable = gApp.proj().luminairesData;
    //return luminaireDataTable.getLuminaireData(this.luminaireType.value);
    // TODO - modify the table to use ids
    let rowId = LuminaireType._labels[this.luminaireType.value]
    let luminaireData = luminaireDataTable.getLuminaireData(rowId);
    return luminaireData;
  }

  getSchedule() {
    return this.scheduleInput.getSchedule();
  }

  getDiversityFactor() {
    return this.scheduleInput.getDiversityFactor();
  }
}
setupClass(LightingInternals)

class Motor extends InputComponent {
  init() {
    this.power = new Field({
      name: 'Power',
      type: FieldType.Power,
      requiresInput: true,
    })
    this.quantity = new Field({
      name: 'Quantity',
      type: FieldType.Count,
      defaultValue: 1,
      allowMin: false,
    })

    this.efficiency = new Field({
      name: 'Efficiency',
      type: FieldType.Percent,
      requiresInput: true,
      allowMin: false,
    })
    this.efficiencyData = GetMotorEfficiencyData()

    this.outputSensibleLoad = new Field({
      name: 'Sensible Load',
      type: FieldType.Load,
      isOutput: true,
    })
    this.outputRadiantFraction = new Field({
      name: 'Radiant Fraction',
      type: FieldType.Percent,
      isOutput: true,
    })
    this.updater.addWatchEffect('outputs', () => {
      let sensibleLoad = this.calcSensibleLoad(CalcContext.create());
      this.outputSensibleLoad.value = sensibleLoad;
      this.outputRadiantFraction.value = this.getRadiantFraction() * 100.0;
    })
    
    this.scheduleInput = ScheduleInput.create()

    this.fields = [
      'power',
      'quantity',
      'efficiency',
      'scheduleInput',
    ]

    this.serFields = [
      ...this.fields
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Motor',
    }
  }

  calcSensibleLoad(ctx) {
    return ctx.eval('N_motor*2545*(P_motor/E_motor)', {
      N_motor: this.quantity.value,
      P_motor: this.power.value,
      E_motor: this.efficiency.value / 100.0,
    }, 'Motor_load')
  }

  getRadiantFraction() {
    // This is always 0.5
    return 0.5;
  }

  getSchedule() {
    return this.scheduleInput.getSchedule();
  }

  getDiversityFactor() {
    return this.scheduleInput.getDiversityFactor();
  }
}
setupClass(Motor)

class MotorsInput {
  init(spaceInternals) {
    this.motors = []

    this.serFields = [
      ser.arrayField('motors', () => { return Motor.create(); }),
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Motors',
    }
  }

  addMotor() {
    let motor = Motor.create()
    this.motors.push(motor)
    return motor
  }
}
setupClass(MotorsInput)

export let ApplianceLoadsEntryType = makeEnum({
  ManualEntry: 'Enter manually',
  SelectAppliance: 'Select from list',
  SelectByOfficeLoadFactor: 'Enter for office',
})

class ApplianceEntry extends InputComponent {
  init(applianceLoads) {
    this.spaceInternals = applianceLoads.spaceInternals;

    this.entryType = Field.makeSelect("Input type", ApplianceLoadsEntryType, {bold: true})
    this.entryType.helpInfo = {
      helpText: `You may enter the appliance loads manually, select an appliance from a list, or enter for an office space.`
    }

    this.applianceType = new MultiTieredSelect({
      name: 'Appliance Type',
      type: FieldType.Select,
      optionsMap: ApplianceDataTable.getInstance().getApplianceOptionsMap(),
    })
    this.applianceType.setVisibility(() => {
      return this.entryType.value == ApplianceLoadsEntryType.SelectAppliance
    })
    this.applianceType.helpInfo = null;
    this.applianceType.makeUpdater(() => {
      if (this.entryType.value != ApplianceLoadsEntryType.SelectAppliance) {
        return;
      }
      let data = this.getApplianceData(CalcContext.create());
      this.applianceType.helpInfo = {
        helpText: `Here is the data for this appliance:`,
        relatedValues: {
          'Sensible Load': {
            label: 'Sensible load',
            value: data.S_app,
            units: Units.Load,
          },
          'Radiant Fraction': {
            label: 'Radiant fraction',
            value: data.F_rad * 100.0,
            units: Units.Percent,
          },
          'Convective Fraction': {
            label: 'Convective fraction',
            value: data.F_conv * 100.0,
            units: Units.Percent,
          },
          'Latent Load': {
            label: 'Latent load',
            value: data.L_app,
            units: Units.Load,
          },
        }
      }
    })

    this.computerTypes = Field.makeSelect('Computer types', officeloads.TypicalComputerTypes)
    this.officeLoadsIntensity = new Field({
      name: 'Office Loads Intensity',
      type: FieldType.Select,
      choices: []
    })
    this.officeLoadsIntensity.makeChoicesUpdater(() => {
      let options = lookupData(officeloads.OfficeLoadFactors, [this.computerTypes.value]);
      return makeOptions(officeloads.OfficeLoadIntensities, Object.keys(options))
    })
    this.computerTypes.setVisibility(() => {
      return this.entryType.value == ApplianceLoadsEntryType.SelectByOfficeLoadFactor
    })
    this.officeLoadsIntensity.setVisibility(() => {
      return this.entryType.value == ApplianceLoadsEntryType.SelectByOfficeLoadFactor
    })
    this.officeLoadsIntensity.helpInfo = null;
    this.officeLoadsIntensity.makeUpdater(() => {
      if (this.entryType.value != ApplianceLoadsEntryType.SelectByOfficeLoadFactor) {
        return;
      }
      let data = this.getApplianceData(CalcContext.create());
      this.officeLoadsIntensity.helpInfo = {
        helpText: `Enter the computer types in the office and give an intensity factor. We will estimate the total office appliance loads by `
                  + `<b>SensibleLoadPerArea * FloorArea</b>.`,
        relatedValues: {
          'Sensible Load': {
            label: 'Sensible load / ft²',
            value: data.intermediateValues.Load,
            units: Units.Load,
          },
          'Radiant Fraction': {
            label: 'Radiant fraction',
            value: data.F_rad * 100.0,
            units: Units.Percent,
          },
          'Latent Load': {
            label: 'Latent load / ft²',
            value: data.intermediateValues.LatentLoad,
            units: Units.Load,
          },
          'Floor Area': {
            label: 'Floor area',
            value: this.spaceInternals.space.floorArea.value,
            units: Units.ft2,
          },
        }
      }
    })

    this.manualGroup = FieldGroup.fromDict({
      peakSensiblePowerOutput: new Field({
        name: 'Sensible Power',
        type: FieldType.Load,
        requiresInput: true,
      }),
      radiantFraction: new Field({
        name: 'Radiant Fraction',
        type: FieldType.Percent,
        requiresInput: true,
      }),
      peakLatentPowerOutput: new Field({
        name: 'Latent Power',
        type: FieldType.Load,
        requiresInput: true,
      }),
    })
    this.manualGroup.setVisibility(() => {
      return this.entryType.value == ApplianceLoadsEntryType.ManualEntry
    })

    this.quantity = new Field({
      name: 'Quantity',
      type: FieldType.Count,
      defaultValue: 1,
      allowMin: false,
    })
    this.quantity.makeUpdater((field) => {
      if (this.entryType.value == ApplianceLoadsEntryType.SelectByOfficeLoadFactor) {
        field.visible = false;
        field.value = 1
      } else {
        field.visible = true;
      }
    })

    this.outputSensibleLoad = new Field({
      name: 'Sensible Load',
      type: FieldType.Load,
      isOutput: true,
    })
    this.outputLatentLoad = new Field({
      name: 'Latent Load',
      type: FieldType.Load,
      isOutput: true,
    })
    this.outputFractionRadiant = new Field({
      name: 'Radiant fraction',
      type: FieldType.Percent,
      isOutput: true,
    })
    this.updater.addWatchEffect('appliance-outputs', () => {
      let ctx = CalcContext.create();
      let data = this.getApplianceData(ctx);  
      let N = this.getQuantity();
      this.outputSensibleLoad.value = N * data.S_app;
      this.outputLatentLoad.value = N * data.L_app;
      this.outputFractionRadiant.value = data.F_rad * 100.0;
    })
    this.outputFields = [
      'outputSensibleLoad',
      'outputFractionRadiant',
      'outputLatentLoad',
    ]

    this.scheduleInput = ScheduleInput.create()

    this.serFields = [
      'entryType',
      'applianceType',
      'computerTypes',
      'officeLoadsIntensity',
      'manualGroup',
      'quantity',
      'scheduleInput',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Appliance',
    }
  }

  getQuantity() {
    return this.quantity.value
  }

  getSchedule() {
    return this.scheduleInput.getSchedule();
  }

  getDiversityFactor() {
    return this.scheduleInput.getDiversityFactor();
  }

  // Use to calculate appliance sensible loads
  getApplianceData(ctx) {
    let res = {}
    res.S_app = 0;
    res.L_app = 0;
    res.F_rad = 0;
    res.F_conv = 0;

    if (this.entryType.value == ApplianceLoadsEntryType.ManualEntry) {
      res.S_app = this.manualGroup.getField('peakSensiblePowerOutput').value
      res.L_app = this.manualGroup.getField('peakLatentPowerOutput').value
      res.F_rad = this.manualGroup.getField('radiantFraction').value / 100.0
      res.F_conv = 1 - res.F_rad
    } else if (this.entryType.value == ApplianceLoadsEntryType.SelectAppliance) {
      res = this._getApplianceDataForSelectApplianceType(ctx)
    } else if (this.entryType.value == ApplianceLoadsEntryType.SelectByOfficeLoadFactor) {
      //console.log("GETTING OFFICE LOAD DATA");
      res = this._getApplianceDataForSelectByOfficeLoadFactorType(ctx)
    } else {
      throw new Error("Unknown appliance entry type: " + this.entryType.value)
    }

    return res
  }

  _getApplianceDataForSelectApplianceType(ctx) {
    if (this.applianceType.value.length === 0) {
      // No appliance selected
      return {
        S_app: 0,
        L_app: 0,
        F_rad: 0,
        F_conv: 0,
      }
    }
    return ApplianceDataTable.getInstance().getApplianceData(
      this.applianceType.value)
  }

  _getApplianceDataForSelectByOfficeLoadFactorType(ctx) {
    let floorArea = this.spaceInternals.space.floorArea.value
    return officeloads.getApplianceDataForOfficeLoads(ctx,
      this.computerTypes.value, this.officeLoadsIntensity.value,
      floorArea);
  }

  getLatentLoad(ctx) {
    return this.getApplianceData(ctx).L_app;
  }
}
setupClass(ApplianceEntry)

class ApplianceLoads {
  init(spaceInternals) {
    this.spaceInternals = spaceInternals;
    this.appliances = []

    this.serFields = [
      ser.arrayField('appliances', () => { return ApplianceEntry.create(this); }),
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Appliances',
    }
  }

  addAppliance() {
    let appliance = ApplianceEntry.create(this)
    this.appliances.push(appliance)
    return appliance;
  }

  getNumAppliances() {
    return this.appliances.length
  }
}
setupClass(ApplianceLoads)

class MiscLoads {
  init(spaceInternals) {
    this.spaceInternals = spaceInternals;

    this.sensibleLoads = new FieldWithVariableUnits({
      name: 'Sensible Load',
      type: FieldType.Load,
      units: Units.Load,
      unitOptions: [Units.Load, Units.LoadPerArea, Units.WattsPerFt2],
    })
    this.sensibleLoadsRadiantFraction = new Field({
      name: 'Radiant Fraction',
      type: FieldType.Percent,
    })
    this.sensibleLoadsFields = [
      'sensibleLoads',
      'sensibleLoadsRadiantFraction',
    ]

    this.latentLoads = new FieldWithVariableUnits({
      name: 'Latent Load',
      type: FieldType.Load,
      unitOptions: [Units.Load, Units.LoadPerArea, Units.WattsPerFt2,
        Units.GPM, Units.PoundsPerMin
      ],
    })
    this.latentLoadsWaterTemp = new Field({
      name: 'Water Temperature',
      type: FieldType.Temperature,
    })
    this.latentLoadsWaterTemp.setVisibility(() => {
      return this.latentLoads.units == Units.GPM || this.latentLoads.units == Units.PoundsPerMin;
    })
    this.latentLoadsFields = [
      'latentLoads',
      'latentLoadsWaterTemp',
    ]

    this.scheduleInput = ScheduleInput.create({enableDiversityFactor: false})

    this.serFields = [
      'sensibleLoads',
      'sensibleLoadsRadiantFraction',
      'latentLoads',
      'latentLoadsWaterTemp',
      'scheduleInput',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Misc Loads',
    }
  }

  getSchedule() {
    return this.scheduleInput.getSchedule();
  }

  getDiversityFactor() {
    return this.scheduleInput.getDiversityFactor();
  }

  getSensibleLoad() {
    if (this.sensibleLoads.units == Units.Load) {
      return this.sensibleLoads.value
    } else if (this.sensibleLoads.units == Units.LoadPerArea) {
      return this.sensibleLoads.value * this.spaceInternals.space.floorArea.value
    } else if (this.sensibleLoads.units == Units.WattsPerFt2) {
      return this.sensibleLoads.getValueInUnits(Units.LoadPerArea) * this.spaceInternals.space.floorArea.value
    } else {
      throw new Error("Unknown sensible load units: " + this.sensibleLoads.units)
    }
  }

  getSensibleLoadRadiantFraction() {
    return this.sensibleLoadsRadiantFraction.value / 100.0;
  }

  getLatentLoad(ctx) {
    if (this.latentLoads.units == Units.Load) {
      return this.latentLoads.value
    } else if (this.latentLoads.units == Units.LoadPerArea) {
      return this.latentLoads.value * this.spaceInternals.space.floorArea.value
    } else if (this.latentLoads.units == Units.WattsPerFt2) {
      return this.latentLoads.getValueInUnits(Units.LoadPerArea) * this.spaceInternals.space.floorArea.value
    } else if (this.latentLoads.units == Units.GPM) {
      let h_g = 1061 + 0.444 * this.latentLoadsWaterTemp.value;
      return this.latentLoads.value * h_g * 499.2216;
    } else if (this.latentLoads.units == Units.PoundsPerMin) {
      let h_g = 1061 + 0.444 * this.latentLoadsWaterTemp.value;
      return this.latentLoads.value * h_g * 60;
    } else {
      throw new Error("Unknown latent load units: " + this.latentLoads.units)
    }
  }
}
setupClass(MiscLoads)

export class SpaceInternals {
  init(space) {
    this.space = space;

    this.people = PeopleInternals.create(this)
    this.lighting = LightingInternals.create(this)
    this.motors = MotorsInput.create(this)
    this.appliances = ApplianceLoads.create(this)
    this.miscLoads = MiscLoads.create(this)

    this.serFields = [
      'people',
      'lighting',
      'motors',
      'appliances',
      'miscLoads',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Internals',
    }
  }

  getNumAppliances() {
    return this.appliances.getNumAppliances()
  }

  getMiscLoad() {
    return this.miscLoads.getSensibleLoad();
  }
}
setupClass(SpaceInternals)
